import { ApiModel, CollectionModel, ViewModel, mergeTruthy, normalize, prepareTermForReg, useTranslate } from '@cyferd/client-engine';
import { styles } from './styles';
import { useCallback, useContext, useMemo, useState } from 'react';
import { getLabel } from '@utils';
import { CyList } from '@components/smart/CyList';
import { GlobalMenuHeader } from '../GlobalMenuHeader';
import { EMPTY } from 'rxjs';
import {
  DiffRow,
  activeRowSchema,
  allSchema,
  fieldSchema,
  getAllDiffs,
  getDetailGroupDiff,
  getSchemaDiff,
  getStateAfterUndo,
  groupsSchema,
  quickFiltersCursor
} from './resources';
import { TabList } from '../TabList';
import { getEntityFullInputList } from '@models/entity';
import { GlobalContext } from '../../../builder/state-mgmt/GlobalState';

export interface CollectionChangesProps {
  prev: CollectionModel.Collection;
  next: CollectionModel.Collection;
  onChange: (event: CollectionModel.Collection) => void;
  onClose: () => void;
}

const tabTitleMap = { fields: 'Fields', groups: 'Detail groups', all: 'All' };

export const CollectionChanges = ({ prev, next, onChange, onClose }: CollectionChangesProps) => {
  const { translate } = useTranslate();
  const { deps } = useContext(GlobalContext);
  const [cursor, setCursor] = useState<Parameters<ViewModel.CyListProps['onFetch']>[0]>({ searchString: '', quickFilters: [] });
  const [activeRow, setActiveRow] = useState<DiffRow>(null);
  const [activeTab, setActiveTab] = useState<string>(tabTitleMap.fields);
  const safePrev = useMemo(() => normalize.collection(prev), [prev]);
  const safeNext = useMemo(() => normalize.collection(next), [next]);

  const schemaInputs = useMemo(() => {
    const inputList = getEntityFullInputList(safeNext?.schema);
    return { properties: { next: { metadata: { inputList } }, prev: { metadata: { inputList } } } };
  }, [safeNext?.schema]);
  const completeFieldSchema = useMemo(() => mergeTruthy(fieldSchema, schemaInputs), [schemaInputs]);
  const completeGroupsSchema = useMemo(() => mergeTruthy(groupsSchema, schemaInputs), [schemaInputs]);
  const completeAllSchema = useMemo(() => mergeTruthy(allSchema, schemaInputs), [schemaInputs]);
  const completeActiveRowSchema = useMemo(() => mergeTruthy(activeRowSchema, schemaInputs), [schemaInputs]);

  const diffMap = useMemo(
    () => ({
      [tabTitleMap.fields]: getSchemaDiff(safePrev, safeNext, translate),
      [tabTitleMap.groups]: getDetailGroupDiff(safePrev, safeNext, translate),
      [tabTitleMap.all]: getAllDiffs(safePrev, safeNext)
    }),
    [safeNext, safePrev, translate]
  );
  const diffList = useMemo(
    () =>
      diffMap[activeTab].filter(diff => {
        const passesSearch =
          !cursor?.searchString ||
          [diff.propertyName, diff.title, ...(diff.path || /* istanbul ignore next */ [])].some(term =>
            new RegExp(prepareTermForReg(cursor?.searchString), 'i').test(term)
          );
        const quickFilters = (cursor?.quickFilters || /* istanbul ignore next */ []).map(({ id }) => id);
        const passesFilters = !quickFilters.length || quickFilters.includes(diff.change);
        return passesSearch && passesFilters;
      }),
    [activeTab, cursor.quickFilters, cursor?.searchString, diffMap]
  );

  const tabList = useMemo(
    () =>
      Object.values(tabTitleMap).map(title => ({
        title,
        displayName: [title, !!diffMap[title].length && `(${diffMap[title].length})`].filter(Boolean).join(' ')
      })),
    [diffMap]
  );

  const onChangeCursor = (event: Parameters<ViewModel.CyListProps['onFetch']>[0]) => {
    setCursor(event);
    return EMPTY;
  };

  const onRowClick = useCallback((event: any) => {
    if (event.change === 'modified') setActiveRow(event);
    return EMPTY;
  }, []);

  const onUndo = useCallback(
    (event: DiffRow) => {
      deps?.modalInteraction.onConfirm(
        {
          status: ViewModel.Status.WARN,
          icon: 'warning',
          title: `Discard changes?`,
          description: 'Are you sure you want to discard this change?',
          confirmLabel: { added: 'Remove', removed: 'Restore', modified: 'Undo' }[event.change]
        },
        () => onChange(getStateAfterUndo(prev, next, event))
      );
      return EMPTY;
    },
    [deps?.modalInteraction, next, onChange, prev]
  );

  const actionListChildren = useMemo(
    () =>
      [
        activeTab === tabTitleMap.fields && {
          type: ViewModel.CTAType.LINK,
          onClick: onUndo as any,
          helperText: 'Undo',
          important: true,
          icon: 'undo' as any
        },
        event => ({
          type: ViewModel.CTAType.LINK,
          disabled: event.item.change !== 'modified',
          onClick: onRowClick,
          important: true,
          icon: 'keyboard_arrow_right' as any
        })
      ].filter(Boolean),
    [activeTab, onRowClick, onUndo]
  );

  const onBack = useCallback(() => setActiveRow(null), []);
  const onChangeTab = useCallback((tab: string) => {
    setActiveRow(null);
    setActiveTab(tab);
    return EMPTY;
  }, []);

  const commonListProps = {
    fitToPage: true,
    framed: false,
    quickFiltersHidden: false,
    searchStringHidden: false,
    typeSelectorHidden: true,
    paginationHidden: true,
    orderHidden: true,
    advancedFiltersHidden: true,
    recordActionsHidden: true
  };

  return (
    <div data-testid="collection-changes" css={styles.container}>
      <GlobalMenuHeader
        title={activeRow ? getLabel([activeRow.typeLabel, activeRow.title, activeRow.propertyName]) : 'Unsaved changes'}
        onClose={onClose}
        onBack={activeRow && onBack}
      />
      <TabList activeTab={activeTab} onChangeTab={onChangeTab} tabList={tabList} />
      {!activeRow && (
        <div>
          {activeTab === tabTitleMap.fields && (
            <div data-testid="field-rows" css={styles.tabContent}>
              <CyList
                onClickItem={onRowClick}
                value={{
                  list: diffList as unknown as ApiModel.ApiRecord[],
                  query: { schema: completeFieldSchema, cursor, quickFilters: quickFiltersCursor },
                  totalCount: diffList.length
                }}
                actionListChildren={actionListChildren}
                onFetch={onChangeCursor}
                {...commonListProps}
              />
            </div>
          )}
          {activeTab === tabTitleMap.groups && (
            <div data-testid="group-rows" css={styles.tabContent}>
              <CyList
                onClickItem={onRowClick}
                value={{
                  list: diffList as unknown as ApiModel.ApiRecord[],
                  query: { schema: completeGroupsSchema, cursor, quickFilters: quickFiltersCursor },
                  totalCount: diffList.length
                }}
                actionListChildren={actionListChildren}
                onFetch={onChangeCursor}
                {...commonListProps}
              />
            </div>
          )}
          {activeTab === tabTitleMap.all && (
            <div data-testid="all-rows" css={styles.tabContent}>
              <CyList
                value={{
                  list: diffList as unknown as ApiModel.ApiRecord[],
                  query: { schema: completeAllSchema, cursor, quickFilters: quickFiltersCursor },
                  totalCount: diffList.length
                }}
                onFetch={onChangeCursor}
                {...commonListProps}
              />
            </div>
          )}
        </div>
      )}
      {!!activeRow && (
        <div data-testid="active-row" css={styles.tabContent}>
          <CyList
            fitToPage={true}
            value={{
              list: activeRow.deepDiff as unknown as ApiModel.ApiRecord[],
              query: { schema: completeActiveRowSchema, cursor: null },
              totalCount: diffList.length
            }}
            {...commonListProps}
          />
        </div>
      )}
    </div>
  );
};
