import { ComponentProps, useMemo } from 'react';
import { SelectDropdown } from '../SelectDropdown';
import { ApiModel, GeneralModel, capitalize } from '@cyferd/client-engine';
import { DOC_ID, useNavigateToDoc } from '../Docs/resources';
import { MenuOption } from '../OptionMenu';

const feActionTypeOptionList = Object.values(ApiModel.TriggerSchemaMap)
  .filter(actionConfig => actionConfig.hidden !== true)
  .map(actionConfig => ({
    label: actionConfig.name
      .split(/\.|_/g)
      .map((s, i) => (!i ? capitalize(s) : s.toLowerCase()))
      .join(' '),
    value: actionConfig.name,
    description: actionConfig.description
  }));

const beActionTypeOptionList = Object.values(ApiModel.FlowActionType)
  .map(value => ApiModel.FlowSchemaMap[value])
  .filter(actionConfig => !!actionConfig && actionConfig.hidden !== true)
  .map(actionConfig => ({
    label: actionConfig.name
      .split(/\.|_/g)
      .map((s, i) => (!i ? capitalize(s) : s.toLowerCase()))
      .join(' '),
    value: actionConfig.name,
    description: actionConfig.description
  }));

export interface ActionDropdownProps extends Omit<ComponentProps<typeof SelectDropdown>, 'options'> {
  subtype: GeneralModel.JSONSchemaSubtype;
}

export const ActionDropdown = ({ subtype, optionList, value, ...props }: ActionDropdownProps) => {
  const onNavigateToDoc = useNavigateToDoc();

  const completeOptionList = useMemo(
    () => [
      ...(optionList || []),
      {
        disabled: !value,
        image: 'help',
        important: true,
        testid: `action-help-btn`,
        tooltip: 'Help',
        onClick: () => onNavigateToDoc(`${DOC_ID.API_ACTIONS}-${value}`)
      } as MenuOption
    ],
    [onNavigateToDoc, optionList, value]
  );

  return (
    <SelectDropdown
      {...props}
      value={value}
      optionList={completeOptionList}
      options={(() => {
        if (subtype === GeneralModel.JSONSchemaSubtype.FRONTEND) return feActionTypeOptionList;
        return beActionTypeOptionList; // GeneralModel.JSONSchemaSubtype.BACKEND
      })()}
    />
  );
};
