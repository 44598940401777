import React, { Fragment } from 'react';
import { DropdownMenu, DropdownMenuProps } from '../DropdownMenu';
import { styles } from './styles';

export type FileInfoDropdownProps = {
  renderTrigger: DropdownMenuProps['renderTrigger'];
  value: Record<string, unknown>;
};

export default function FileInfoDropdown({ renderTrigger, value }: FileInfoDropdownProps): JSX.Element {
  return (
    <DropdownMenu renderTrigger={renderTrigger}>
      <div css={styles.tooltipContainer}>
        {Object.entries(value).map(([k, v]) => (
          <Fragment key={k}>
            <p key={k} css={styles.tooltipLabel}>
              {k}:
            </p>
            <p>{v && String(v)}</p>
          </Fragment>
        ))}
      </div>
    </DropdownMenu>
  );
}
