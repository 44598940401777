import React, { useMemo } from 'react';

import { CollectionModel, GeneralModel, getParsedActionChildren, useRecordActionsParser, ViewModel } from '@cyferd/client-engine';

import { COLOR, SECONDARY_COLOR } from '@constants';
import { CTAType } from '../CTA';
import { IconImage } from '../Icon/renderIcon';
import { OptionMenu } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { ToolTip } from '../Tooltip';
import { styles } from './styles';
import { TooltipInfo } from '../TooltipInfo';

export interface KanbanCardProps {
  id?: string;
  title?: string;
  subtitle?: string;
  imageUrl?: string;
  actionListChildren?: ViewModel.CyListProps['actionListChildren'];
  recordActions?: CollectionModel.Collection['recordActions'];
  fullItem?: any;
  testid?: string;
  color?: GeneralModel.Color.ThemeColor;
  index: number;
  disabled?: boolean;
  onClick?: (event?: any) => void;
}

export const KanbanCard = ({
  id,
  title,
  subtitle,
  imageUrl,
  actionListChildren,
  onClick,
  testid = 'kanban-card',
  fullItem,
  color,
  index,
  disabled,
  recordActions
}: KanbanCardProps) => {
  const isClickable = typeof onClick === 'function';
  const parsedActionChildren = useMemo(() => getParsedActionChildren(actionListChildren, { item: fullItem, index }), [actionListChildren, fullItem, index]);
  const parseRecordActions = useRecordActionsParser(recordActions);
  const parsedRecordActions = useMemo(() => parseRecordActions({ item: fullItem, index }), [fullItem, index, parseRecordActions]);
  const completeParsedActionChildren = useMemo(() => [...parsedRecordActions, ...parsedActionChildren], [parsedActionChildren, parsedRecordActions]);

  return (
    <div
      css={[styles.cardItem, isClickable && styles.cardItemClickable, !!COLOR[color] && styles.borderColor(color)]}
      key={id}
      data-testid={`${id}-card-btn`}
      onClick={event => onClick({ index, metaKey: event?.metaKey })}
    >
      {!!completeParsedActionChildren?.length && (
        <div css={styles.actionContainer}>
          <PreventClickPropagation>
            <OptionMenu
              defaultBtnSize={ViewModel.CTASize.SMALL}
              optionList={completeParsedActionChildren.map((action, actionIndex) => ({
                type: CTAType.LINK,
                label: action.label,
                testid: `${testid}-${id}-action-btn-${actionIndex}`,
                image: action.icon,
                disabled: disabled || !!action.disabled,
                onClick: action.onClick && (event => action.onClick(fullItem, { index, metaKey: event?.metaKey })),
                status: (action as any).status,
                color: action.color,
                tooltip: action.helperText,
                important: false
              }))}
            />
          </PreventClickPropagation>
        </div>
      )}
      {!!imageUrl && (
        <div css={styles.imageContainer}>
          <div css={styles.iconContainer} style={{ backgroundColor: SECONDARY_COLOR[color] }}>
            <div css={styles.iconContent}>
              <IconImage icon={imageUrl} title={title} iconProps={{ size: '2em', fill: COLOR.NEUTRAL_1 }} imageProps={{ css: styles.image }} />
            </div>
          </div>
        </div>
      )}
      <ToolTip text={<TooltipInfo title={title} description={subtitle} />}>
        <div css={styles.content({ color: COLOR[color] })}>
          <div css={styles.textContent}>
            <div css={styles.title}>{title}</div>
          </div>
          {!!subtitle && (
            <div css={styles.subtitle}>
              <span>{subtitle}</span>
            </div>
          )}
        </div>
      </ToolTip>
    </div>
  );
};

KanbanCard.displayName = 'KanbanCard';
