import React, { FC } from 'react';

import flags from '../../utils/flags.png';
import { styles } from './styles';

type Props = {
  code: string;
};

export const Flag: FC<Props> = ({ code }) => <div css={[styles.flag, styles[code]]} style={{ backgroundImage: `url(${flags})` }} />;
