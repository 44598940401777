import { useContext, useEffect, useMemo, useState } from 'react';
import { Location, useLocation } from 'react-router-dom';
import { Subject, takeUntil, tap } from 'rxjs';

import { ApiModel, ClientEngineContext, decodeQueryString, ofType, usePrevious } from '@cyferd/client-engine';
import { shouldChangeLocationSync } from '@constants';

export const processLocation = ({ pathname, search }: Location) => ({ pathname: pathname.slice(1), queryParams: decodeQueryString(search) });

export const useUrl = () => {
  const { action$ } = useContext(ClientEngineContext);
  const location = useLocation();
  const [{ search, pathname }, setSafeLocation] = useState<Location>(location);

  const prevLocation = usePrevious(location);

  useEffect(() => {
    /* istanbul ignore next line */
    if (shouldChangeLocationSync(location, prevLocation)) return setSafeLocation(location);

    const streamComplete$ = new Subject<void>();
    action$
      .pipe(
        takeUntil(streamComplete$),
        ofType(ApiModel.TriggerActionType.DISPATCH_SET_VIEW),
        tap(() => setSafeLocation(location))
      )
      .subscribe();
    return () => streamComplete$.next();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action$, location]);

  return useMemo(() => processLocation({ pathname, search } as Location), [pathname, search]);
};
