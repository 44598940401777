import { ApiModel } from '@cyferd/client-engine';
import { GeneralModel } from '@models';

export const NOTIFICATIONS_POINTER = 'notifications';
export const NOTIFICATION_LATEST_POINTER = 'notifications_dropdown';
export const NOTIFICATION_CURRENT_POINTER = 'notifications_current';

export const getInitialState = (): GeneralModel.NotificationState => ({
  list: null,
  latest: null,
  current: null
});

export const reducer = (state: GeneralModel.NotificationState = getInitialState(), action: GeneralModel.Action): GeneralModel.NotificationState => {
  const { type, payload } = action;

  switch (type) {
    case ApiModel.TriggerActionType.DISPATCH_SET_DATA:
      switch (payload.pointer) {
        case NOTIFICATIONS_POINTER:
          return { ...state, list: payload.value };
        case NOTIFICATION_LATEST_POINTER:
          return { ...state, latest: payload.value };
        case NOTIFICATION_CURRENT_POINTER:
          return { ...state, current: payload.value?.record };
        default:
          return state;
      }
    case ApiModel.TriggerActionType.DISPATCH_NOTIFICATION_READ:
      return {
        ...state,
        list: {
          ...state?.list,
          list: state?.list?.list?.map(notification => ({ ...notification, read: notification.id === payload?.id ? payload?.read : notification?.read }))
        },
        latest: {
          ...state?.latest,
          list: state?.latest?.list?.map(notification => ({ ...notification, read: notification.id === payload?.id ? payload?.read : notification?.read }))
        }
      };
    default:
      return state;
  }
};
