// istanbul ignore file
import { tableCellNeedsColor } from '@components/elements/Table/TableCell';
import { GeneralModel } from '@cyferd/client-engine';

const isBasicArray = format =>
  [
    GeneralModel.JSONSchemaFormat.ARRAY,
    GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
    GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT,
    GeneralModel.JSONSchemaFormat.FILE_LIST
  ].includes(format);

export const useEditTableRows = ({ items, definitionMap }) => {
  return items.map((item, itemIndex) => {
    return item.list.map(prop => {
      const definition = definitionMap[prop.definitionId];
      return {
        id: `${itemIndex}-${prop.definitionId}`,
        color: !isBasicArray(definition?.rawProperty?.format) && tableCellNeedsColor(prop.value) ? prop.color : undefined,
        rowColor: item.color,
        rowBgColor: item.rowColor,
        format: definition.property?.format,
        onClickItem: undefined,
        rawValue: prop.rawValue,
        value: prop.value,
        definitionId: prop.definitionId,
        fullItem: item.fullItem,
        definition
      };
    });
  });
};
