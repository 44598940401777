import { CollectionModel, GeneralModel, isObject, validateSchemaInstance, ViewModel } from '@cyferd/client-engine';

export const getFormErrors = ({
  model,
  value,
  parseData,
  currentStep,
  shouldValidate
}: {
  model: CollectionModel.Collection;
  value: ViewModel.CyFormProps['value'];
  parseData: GeneralModel.ParseDataFn;
  currentStep: CollectionModel.DetailGroup;
  shouldValidate?: boolean;
}) => {
  if (!shouldValidate || !model?.schema) return { formErrors: {}, groupErrors: {}, formRawErrors: [], groupRawErrors: [], associationErrors: [] };

  const formRawErrors = validateSchemaInstance({ value: isObject(value?.record) ? value?.record : {}, schema: model?.schema, parseData }).errors;

  const groupRawErrors = formRawErrors.filter(error => error.detailGroupId === currentStep?.id);

  /** @todo Fix - Errors from nested props will be on the wrong group when they return from the engine */
  return {
    formErrors: formRawErrors.reduce((total, curr) => ({ ...total, [curr.property]: curr.message }), {}),
    groupErrors: groupRawErrors.reduce((total, curr) => ({ ...total, [curr.property]: curr.message }), {}),
    formRawErrors,
    groupRawErrors
  };
};
