import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { styles } from './styles';
import {
  FlatDocTreeNode,
  docMap,
  getOptionalPlural,
  getPrettyTitlePath,
  getSearchResults,
  useSearchableDocs,
  isSearchValid,
  useFlatDocTree
} from './resources';
import { CTA, CTAType } from '../CTA';
import { RichText } from '../RichTextEditor';
import { ErrorBoundary, Translate, ViewModel, prepareTermForReg } from '@cyferd/client-engine';
import { Modal } from '../Modal';
import { COLOR, ENV, FOREGROUND_COLOR, TRANS } from '@constants';
import { SearchInput } from '../SearchInput';
import { Layout } from '../Layout';

export interface DocsProps {
  open: boolean;
  onClose: () => void;
}

export const Docs = ({ open, onClose }: DocsProps) => {
  const [activeId, setActiveId] = useState<string>();

  if (!open) return null;

  return (
    <Modal open={true} type={ViewModel.ModalType.FULL_SCREEN} title={TRANS.client.nav.builder.guide} onClose={onClose}>
      <div css={styles.modalContainer}>
        <DocsContent showNavDivision={true} activeId={activeId} onActiveIdChange={setActiveId} />
      </div>
    </Modal>
  );
};

export interface DocsContentProps {
  onActiveIdChange: (event: string) => void;
  activeId: string;
  showNavDivision?: boolean;
}

export const DocsContent = ({ showNavDivision, activeId, onActiveIdChange }: DocsContentProps) => {
  const contentRef = useRef<HTMLDivElement>();
  const flatDocTree = useFlatDocTree();
  const [collapsedList, setCollapsedList] = useState<string[]>(() => flatDocTree.map(n => n.id));
  const [search, setSearch] = useState<string>('');
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const activeTreeNode = useMemo(() => flatDocTree.find(n => n.id.toLowerCase() === activeId?.toLowerCase?.()) || flatDocTree[0], [activeId, flatDocTree]);
  const activeDoc = useMemo(() => docMap[activeTreeNode?.docId], [activeTreeNode?.docId]);
  const safeActiveId = activeTreeNode?.id;

  const searchableDocs = useSearchableDocs(flatDocTree);

  const searchResults = useMemo(() => getSearchResults(search, searchableDocs), [search, searchableDocs]);
  const searchIsValid = useMemo(() => isSearchValid(search), [search]);
  const searchIsVisible = useMemo(() => showSearch && searchIsValid, [searchIsValid, showSearch]);

  const onToggleCollapse = (event: string) => {
    setCollapsedList(prev => (prev.includes(event) ? prev.filter(i => i !== event) : [...prev, event]));
  };

  const onNavItemClick = (event: FlatDocTreeNode) => {
    if (event.docId) {
      contentRef.current?.scrollTo?.({ top: 0 });
      onActiveIdChange(event.id);
    }
    if (event.children) onToggleCollapse(event.id);
  };

  const onSearch = (event: string) => {
    setSearch(event);
    setShowSearch(true);
  };

  const onResultClick = (id: string) => {
    const doc = flatDocTree.find(d => d.id === id);
    setShowSearch(false);
    onNavItemClick(doc);
  };

  useEffect(() => {
    setCollapsedList(p => p.filter(i => !activeTreeNode.parentIdList.includes(i)));
  }, [activeTreeNode.parentIdList]);

  useEffect(() => {
    setTimeout(() => {
      document?.querySelector(`[data-selector="section-${safeActiveId}"]`)?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
    }, ENV.INPUT_DEBOUNCE_TIME);
  }, [safeActiveId]);

  return (
    <div css={styles.container} data-testid="cyferd-docs">
      <div css={styles.nav(showNavDivision)}>
        <div css={styles.searchInput}>
          <SearchInput alternative={true} value={search} onChange={onSearch} searchDelay={ENV.INPUT_DEBOUNCE_TIME} />
        </div>
        <Layout fitToPage={true}>
          <div>
            {flatDocTree.map(node => {
              const isActive = node.id === safeActiveId;
              const isCollapsible = Array.isArray(node.children);
              const isCollapsed = collapsedList.includes(node.id);
              const isHidden = node.hidden || collapsedList.some(i => node.parentIdList.includes(i));
              if (isHidden) return null;
              return (
                <div
                  data-testid="doc-nav-item"
                  onClick={() => onNavItemClick(node)}
                  css={styles.navItem(node.depth, isActive, isCollapsible)}
                  key={node.id}
                  data-selector={`section-${node.id}`}
                >
                  {isCollapsible && <CTA type={CTAType.LINK} testid="toggle-collapse" icon={isCollapsed ? 'chevron_right' : 'expand_more'} color="NEUTRAL_2" />}
                  <span>
                    <Translate>{node.title}</Translate>
                  </span>
                </div>
              );
            })}
          </div>
        </Layout>
      </div>
      <div ref={contentRef} css={[styles.doc, typeof activeDoc.content !== 'string' && /* istanbul ignore next */ styles.smoothScroll]}>
        {searchIsVisible && (
          <div css={styles.content} data-testid="search-results">
            <h2>
              {`${searchResults.totalMatches} match${searchResults.totalMatches === 1 ? /* istanbul ignore next */ '' : 'es'} found in ${
                searchResults.results.length
              } page${getOptionalPlural(searchResults.results.length)}`}
            </h2>
            <div css={styles.matches}>
              {searchResults.results.map((result, resultIndex) => (
                <div key={`${search}-${resultIndex}`} data-testid="search-match" css={styles.match} onClick={() => onResultClick(result.id)}>
                  <CTA type={ViewModel.CTAType.LINK} label={getPrettyTitlePath(result.titlePath)} />
                  <p css={styles.matchSummary}>
                    {result.matchSummary.split(new RegExp(prepareTermForReg(search), 'i')).map((chunk, chunkIndex) => {
                      return (
                        <Fragment key={chunkIndex}>
                          {!chunkIndex ? (
                            chunk
                          ) : (
                            <>
                              <span css={styles.highlighted}>{search}</span>
                              {chunk}
                            </>
                          )}
                        </Fragment>
                      );
                    })}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        {!!activeDoc && !searchIsVisible && (
          <div css={styles.content} data-testid="content">
            <h2 css={styles.title}>{getPrettyTitlePath(activeTreeNode?.titlePath)}</h2>
            <div>
              <ErrorBoundary>
                {typeof activeDoc.content === 'string' ? (
                  <RichText
                    value={
                      searchIsValid
                        ? activeDoc.content.replace(
                            new RegExp(prepareTermForReg(search), 'ig'),
                            str => `<strong style="background-color: ${COLOR.YW_6}; color: ${FOREGROUND_COLOR.YW_6}">${str}</strong>`
                          )
                        : activeDoc.content
                    }
                  />
                ) : (
                  /* istanbul ignore next */ activeDoc.content
                )}
              </ErrorBoundary>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
