import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DragStart, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';

import { ErrorBoundary, GeneralModel, Translate, prepareTermForReg, replaceDeep, useTranslate } from '@cyferd/client-engine';

import { FormulaInputType } from '../getFormulaInputType';
import {
  EVALUATOR_TAB,
  FormulaInputRow,
  OPTION_PREFIX,
  defaultInputList,
  escapeKeys,
  fxList,
  getFormulaInputRowItem,
  getHandlingEmptyPath,
  getStateAfterDrop,
  getStateAfterNew,
  scrollToElement,
  conditionalDotReplacement
} from '../resources';
import { getStyles } from './styles';
import { useTestingHelper } from '@utils';
import { SearchInput } from '../../SearchInput';
import { Collapsible } from '../../Collapsible';
import { PreventClickPropagation } from '../../PreventClickPropagation';
import { JSONSyntaxEditor } from '../../JSONSyntaxEditor';
import { useCurrentTheme } from '@components/providers/UIprovider';
import { FormulaOption } from '../FormulaOption/FormulaOption';
import { EvaluatorTree } from '../EvaluatorTree';
import { EvaluatorKeyChangeModal } from '../EvaluatorKeyChangeModal';
import { TabList } from '@components/elements/TabList';
import { FormulaTester } from '../FormulaTester';

export interface EvaluatorUIProps {
  value: any;
  showAdvanced: boolean;
  disabled: boolean;
  inputList: FormulaInputRow[];
  height: string;
  hideTabs?: boolean;
  onChange: (arg: GeneralModel.EvaluatorFormula) => void;
}

export const EvaluatorUI = memo(({ value, showAdvanced, disabled, inputList, height, hideTabs, onChange }: EvaluatorUIProps) => {
  const { getTestIdProps } = useTestingHelper('evaluator-input');
  const [inputFilter, setInputFilter] = useState<string>('');
  const currentTheme = useCurrentTheme();
  const [activePath, setActivePath] = useState<string>();
  const [pathBeingDragged, setPathBeingDragged] = useState<string>();
  const [keyChangePath, setKeyChangePath] = useState<string>();
  const [tab, setTab] = useState<EVALUATOR_TAB>(EVALUATOR_TAB.INPUTS);
  const styles = useMemo(() => getStyles(height, tab), [height, tab]);
  const { translate } = useTranslate();

  const escapedValue = useMemo(() => escapeKeys(value), [value]);

  const inputListWithColor = useMemo(
    () => [
      ...defaultInputList,
      ...inputList,
      ...GeneralModel.Color.colorNameList
        .filter(c => !GeneralModel.Color.baseThemeConfig[c]?.hidden)
        .map(color =>
          getFormulaInputRowItem({
            label: currentTheme[color]?.label,
            formulaType: FormulaInputType.STRING,
            template: color,
            format: GeneralModel.JSONSchemaFormat.COLOR,
            groupName: 'Color',
            isAlias: true
          })
        )
    ],
    [currentTheme, inputList]
  );

  const completeOptions = useMemo(() => [inputListWithColor, fxList], [inputListWithColor]);

  const onChangeActive = useCallback((event: string) => setActivePath(p => (p === event ? null : event)), []);

  /* istanbul ignore next line */
  const onBeforeDragStart = useCallback((event: DragStart) => {
    setPathBeingDragged(event?.draggableId);
  }, []);

  /* istanbul ignore next line */
  const onOptionDragEnd = useCallback(
    (result: DropResult) => {
      const draggableId = result.draggableId.replace(new RegExp(`^${OPTION_PREFIX}`), '');
      const option = completeOptions.flat().find(o => o.id === draggableId);
      const newState = getStateAfterNew({
        state: value,
        result,
        option,
        label: translate(option?.label)
      });

      setActivePath(null);
      onChange(newState);
      setPathBeingDragged(null);
    },
    [completeOptions, onChange, translate, value]
  );

  /* istanbul ignore next line */
  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result?.destination?.droppableId) return;
      if (result?.draggableId?.startsWith?.(OPTION_PREFIX)) return onOptionDragEnd(result);
      setActivePath(null);
      onChange(getStateAfterDrop(value, result));
      setPathBeingDragged(null);
    },
    [onChange, onOptionDragEnd, value]
  );

  const onKeyChangeCancel = useCallback(() => setKeyChangePath(null), []);

  const onRenameKey = useCallback(
    (event: string) => {
      const parentPath = conditionalDotReplacement(keyChangePath.substring(0, keyChangePath.lastIndexOf('.')), 'unescape');
      const oldKey = conditionalDotReplacement(keyChangePath.substring?.(keyChangePath.lastIndexOf('.') + 1, keyChangePath.length), 'unescape');
      const parent$ = getHandlingEmptyPath(value, parentPath);
      const changed = Object.fromEntries(Object.entries(parent$).map(([k, v]) => [k === oldKey ? conditionalDotReplacement(event, 'unescape') : k, v]));
      onChange(replaceDeep(value, changed, parentPath));
      onKeyChangeCancel();
      setActivePath(null);
      const newLocation = [parentPath, event].join('.');
      scrollToElement(newLocation);
    },
    [keyChangePath, onChange, onKeyChangeCancel, value]
  );

  useEffect(() => {
    if (activePath) scrollToElement(activePath);
  }, [activePath]);

  return (
    <>
      {!!keyChangePath && <EvaluatorKeyChangeModal path={keyChangePath} onCancel={onKeyChangeCancel} onApply={onRenameKey} />}
      <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
        <div css={styles.editorContainer}>
          <div css={styles.sidebarContainer}>
            <div>
              {!hideTabs && (
                <TabList
                  tabList={[
                    { title: EVALUATOR_TAB.INPUTS, displayName: 'Fx & items' },
                    { title: EVALUATOR_TAB.PLAYGROUND, displayName: 'Test' }
                  ]}
                  activeTab={tab}
                  onChangeTab={setTab as any}
                />
              )}
            </div>
            {tab === EVALUATOR_TAB.PLAYGROUND && (
              <ErrorBoundary>
                <FormulaTester formula={value} height={`calc(${height} - 65px)`} />
              </ErrorBoundary>
            )}
            {tab === EVALUATOR_TAB.INPUTS && (
              <div css={styles.toolbarContainer}>
                <SearchInput value={inputFilter} onChange={setInputFilter} alternative={true} />
                <div css={[styles.toolbarList, styles.inputListContainer]}>
                  {completeOptions.filter(Boolean).map((list, topLevelGroupIndex) => {
                    const groupEntries = Object.entries(
                      list
                        .filter(
                          item =>
                            !item.isAlias &&
                            [...(item.keywords || /* istanbul ignore next */ []), item.label].some(word =>
                              new RegExp(prepareTermForReg(inputFilter), 'i').test(translate(word))
                            )
                        )
                        .sort(
                          /* istanbul ignore next */ (a, b) => {
                            if (!a.groupName) return 1;
                            if (!b.groupName) return -1;
                            return 0;
                          }
                        )
                        .reduce((total, curr) => {
                          const groupName = curr.groupName || /* istanbul ignore next */ 'More';
                          return { ...total, [groupName]: [...(total[groupName] || []), curr] };
                        }, {})
                    ) as [string, FormulaInputRow[]][];

                    return (
                      <Fragment key={topLevelGroupIndex}>
                        {groupEntries.map(([groupName, list], index) => (
                          <Collapsible
                            key={`${groupName}-${index}`}
                            open={(!index && !topLevelGroupIndex) || !!inputFilter}
                            renderHeader={(onToggle, toggleButton, isOpen) => (
                              <div data-testid="option-header" css={[styles.inputListHeader, !!isOpen && styles.inputListHeaderOpen]} onClick={onToggle}>
                                <PreventClickPropagation>{toggleButton}</PreventClickPropagation> <Translate>{groupName}</Translate>
                              </div>
                            )}
                          >
                            <div css={styles.inputListGroup}>
                              {list.map((item, i) => (
                                <Droppable key={`${groupName}-${item.id}-${i}`} droppableId={`${OPTION_PREFIX}${item.id}`} isDropDisabled={true}>
                                  {droppableProvided => (
                                    <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                                      <Draggable draggableId={`${OPTION_PREFIX}${item.id}`} index={i}>
                                        {draggableProvided => (
                                          <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                                            <FormulaOption item={item} />
                                          </div>
                                        )}
                                      </Draggable>
                                      {droppableProvided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              ))}
                            </div>
                          </Collapsible>
                        ))}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div css={styles.toolbarContainer}>
            {showAdvanced ? (
              <JSONSyntaxEditor
                expanded={true}
                avoidExpandOption={true}
                label=""
                disabled={disabled}
                onChange={onChange}
                value={value}
                height={`calc(${height} - 30px)`}
              />
            ) : (
              <div css={styles.toolbarList} {...getTestIdProps('formula-ui')}>
                <ErrorBoundary>
                  <EvaluatorTree
                    value={escapedValue}
                    inputList={inputList}
                    activePath={activePath}
                    pathBeingDragged={pathBeingDragged}
                    height={height}
                    readonly={disabled}
                    onChange={onChange}
                    onChangeActive={onChangeActive}
                    onKeyChange={setKeyChangePath}
                  />
                </ErrorBoundary>
              </div>
            )}
          </div>
        </div>
      </DragDropContext>
    </>
  );
});
