import { ComponentProps } from 'react';

import { ViewHeader } from '@components/platform/ViewHeader';

import { styles } from './styles';

export const TempHeader = (props: ComponentProps<typeof ViewHeader>) => {
  return (
    <div className={styles.container}>
      <ViewHeader {...props} />
    </div>
  );
};
