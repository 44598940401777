import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { actions, ApiModel, createUUID, tapOnSuccess, ViewModel } from '@cyferd/client-engine';
import { CTA, CTAType } from '@components/elements/CTA';
import { CyLayout } from '@components/smart/CyLayout';
import { useTestingHelper } from '@utils';

import { QUERY_PARAM, BUILDER_ROUTE, SECTION_NAME, BUILDER_DESCRIPTION, TRANS } from '@constants';
import { GlobalContext } from '../../../../state-mgmt/GlobalState';
import { getNavigateToArgs, getNavigateToTab } from '@utils/getNavigateToTab';
import { useOnNavigateToItem } from '@utils/useOnNavigateToItem';
import { BuilderCyList } from '../../../shared/BuilderCyList';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { ItemList } from '@components/elements/ItemList';
import { SchemaForm } from '../../../shared/SchemaForm';
import { TempHeader } from '../../../shared/TempHeader';
import { styles } from './styles';
import { Modal } from '@components/elements/Modal';
import { useRequest } from '@utils/useRequest';

export const GeneralList = () => {
  const id = useMemo(createUUID, []);
  const { getTestIdProps } = useTestingHelper('general-list');
  const request = useRequest();
  const { deps } = useContext(GlobalContext);
  const { item, setItem } = useContext<EditorContextValue<ApiModel.ApiRecord>>(EditorContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { search } = useLocation();
  const navigate = useNavigate();

  const onToggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  const onCreate = useCallback(() => {
    setItem({});
    onToggleModal();
  }, [setItem, onToggleModal]);

  const onCreateInvites = useCallback(() => {
    request(actions.coreSendInvitations(item))
      .pipe(
        tapOnSuccess(() => {
          onToggleModal();
          deps.refresh$.next(id);
        })
      )
      .subscribe();
  }, [onToggleModal, request, item, deps.refresh$, id]);

  const baseConfig: getNavigateToArgs = useMemo(
    () => ({ id: null, path: null, root: BUILDER_ROUTE.INVITATION_EDITOR.ROOT, search, key: QUERY_PARAM.RECORD_ID, push: navigate }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const actionListChildren = useMemo(
    () =>
      [
        {
          icon: 'edit',
          label: TRANS.client.buttons.edit,
          important: true,
          onClick: (item: any, event) => getNavigateToTab({ ...baseConfig, id: item.id, path: BUILDER_ROUTE.INVITATION_EDITOR.CHILDREN.GENERAL })(event)
        }
      ] as ViewModel.CyListProps['actionListChildren'],
    [baseConfig]
  );

  const onClickItem = useOnNavigateToItem(baseConfig, BUILDER_ROUTE.INVITATION_EDITOR.CHILDREN.GENERAL);

  useEffect(() => {
    setItem(undefined);
  }, [setItem]);

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <TempHeader
        title={SECTION_NAME.INVITATIONS}
        subtitle={BUILDER_DESCRIPTION.INVITATIONS}
        icon="join"
        actionListChildren={[{ label: TRANS.client.buttons.createNew, icon: 'add', onClick: onCreate as any, important: true }]}
      />
      <CyLayout itemHeight="calc(100vh - 125px)">
        <BuilderCyList
          componentName={id}
          onClickItem={onClickItem}
          id={ApiModel.ApiEntity.INVITATION}
          showDelete={true}
          key={ApiModel.ApiEntity.INVITATION}
          request={request}
          collectionId={ApiModel.ApiEntity.INVITATION}
          actionListChildren={actionListChildren}
        />
      </CyLayout>
      <Modal
        type={ViewModel.ModalType.FULL_SCREEN}
        open={isModalOpen}
        onClose={onToggleModal}
        title="Create invitation"
        footer={
          <ItemList>
            <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.close} onClick={onToggleModal} testid="close-btn" />
            <CTA type={CTAType.PRIMARY} label={TRANS.client.buttons.create} onClick={onCreateInvites} testid="create-btn" />
          </ItemList>
        }
      >
        <SchemaForm
          value={item}
          onChange={setItem}
          schema={ApiModel.FlowSchemaMap[ApiModel.TriggerActionType.CORE_SEND_INVITATIONS].input?.schema}
          detailGroupList={ApiModel.FlowSchemaMap[ApiModel.TriggerActionType.CORE_SEND_INVITATIONS].input?.detailGroupList}
          wrapDetailGroups={true}
          shouldValidate={true}
          type={ViewModel.CyFormType.STEPPER}
        />
      </Modal>
    </div>
  );
};
