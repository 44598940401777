import { ApiModel, normalize } from '@cyferd/client-engine';
import { GeneralModel } from '@models';
import { ViewState } from '@models/general';

export const getInitialState = (): ViewState => ({ view: null, timestamp: null });

export const reducer = (state: GeneralModel.ViewState = getInitialState(), action: GeneralModel.Action) => {
  const { type, payload } = action;

  switch (type) {
    case ApiModel.TriggerActionType.DISPATCH_SET_VIEW:
      if (state.view?.id === payload?.id) return state;
      return { ...state, view: normalize.view(payload, true), timestamp: Date.now() };
    default:
      return state;
  }
};
