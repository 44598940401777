import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { actions, ApiModel, GeneralModel, normalize, removeKeyList, ViewModel } from '@cyferd/client-engine';
import { Layout } from '@components/elements/Layout';
import { useTestingHelper } from '@utils';

import { ENV, getTempId, BUILDER_DESCRIPTION, BUILDER_ICON, QUERY_PARAM, BUILDER_ROUTE, SECTION_NAME, TRANS } from '@constants';
import { getNewView } from '@models/view';
import { getNavigateToArgs, getNavigateToTab } from '@utils/getNavigateToTab';
import { useOnNavigateToItem } from '@utils/useOnNavigateToItem';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { BuilderCyList } from '../../../shared/BuilderCyList';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { TempHeader } from '../../../shared/TempHeader';
import { styles } from './styles';
import { CyLayout } from '@components/smart/CyLayout';
import { useRequest } from '@utils/useRequest';

const tabList: ViewModel.LayoutTab[] = [
  { title: 'custom', displayName: TRANS.client.viewTabs.custom },
  { title: 'default', displayName: TRANS.client.viewTabs.core }
];

export const GeneralList = () => {
  const { getTestIdProps } = useTestingHelper('general-list');
  const request = useRequest();

  const { setItem } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);
  const { search } = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>(tabList[0].title);

  const baseConfig: getNavigateToArgs = useMemo(
    () => ({
      id: null,
      path: null,
      root: BUILDER_ROUTE.VIEW_EDITOR.ROOT,
      search,
      key: QUERY_PARAM.RECORD_ID,
      push: navigate
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onCreate = (meta: any) => {
    const item: ViewModel.View = getNewView();
    setItem(item);
    getNavigateToTab({ ...baseConfig, id: getTempId(), path: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.GENERAL })(meta);
  };

  const onDuplicate = useCallback(
    (base: ViewModel.View) => {
      const item: ViewModel.View = removeKeyList(
        { ...base, name: `${base.name} copy`, metadata: { ...base.metadata, clientEngineVersion: ENV.CLIENT_ENGINE_VERSION } },
        ['createdAt', 'updatedAt', 'createdBy', 'updatedBy', 'dsVersion', 'id']
      );
      getNavigateToTab({ ...baseConfig, id: getTempId(), path: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.GENERAL })();
      setTimeout(() => setItem(normalize.view(item)), 100); /** @todo this is a hack, find a better way of doing this */
    },
    [baseConfig, setItem]
  );

  const coreViewRequest = useCallback(() => request(actions.coreListCore({ pointer: GeneralModel.IGNORED_POINTER_ID })), [request]);

  const openExternalUrl = useOnOpenExternalUrl();

  const actionListChildren = useMemo(
    () =>
      [
        {
          icon: 'edit',
          label: TRANS.client.buttons.edit,
          important: true,
          onClick: (item: ViewModel.View, event) => getNavigateToTab({ ...baseConfig, id: item.id, path: BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.VIEW })(event)
        },
        { icon: 'content_copy', label: TRANS.client.buttons.copy, onClick: onDuplicate },
        { icon: 'zoom_in', label: TRANS.client.buttons.open, onClick: item => openExternalUrl(`${ENV.CLIENT_APP_URL}/${item.id}`) }
      ] as ViewModel.CyListProps['actionListChildren'],
    [baseConfig, onDuplicate, openExternalUrl]
  );

  const onClickItem = useOnNavigateToItem(baseConfig, BUILDER_ROUTE.VIEW_EDITOR.CHILDREN.VIEW);

  useEffect(() => {
    setItem(undefined);
  }, [setItem]);

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <TempHeader
        title={SECTION_NAME.VIEWS}
        icon={BUILDER_ICON.VIEWS}
        subtitle={BUILDER_DESCRIPTION.VIEWS}
        actionListChildren={[{ label: TRANS.client.buttons.createNew, icon: 'add', onClick: onCreate as any, important: true }]}
      />
      <Layout type={ViewModel.LayoutType.TAB} tabList={tabList} activeTab={activeTab} onChangeTab={setActiveTab as any}>
        <CyLayout itemHeight="calc(100vh - 175px)">
          <BuilderCyList
            onClickItem={onClickItem}
            request={request}
            id={ApiModel.ApiEntity.VIEW}
            componentName={ApiModel.ApiEntity.VIEW}
            showDelete={true}
            key={ApiModel.ApiEntity.VIEW}
            collectionId={ApiModel.ApiEntity.VIEW}
            actionListChildren={actionListChildren}
          />
        </CyLayout>
        <CyLayout itemHeight="calc(100vh - 175px)">
          <BuilderCyList
            request={coreViewRequest}
            collectionId={ApiModel.ApiEntity.VIEW}
            actionListChildren={actionListChildren.slice(1, actionListChildren.length)}
          />
        </CyLayout>
      </Layout>
    </div>
  );
};
