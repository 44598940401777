import React, { ComponentProps, useCallback, useId, useLayoutEffect, useState, memo } from 'react';
import { createPortal } from 'react-dom';
import { Observable } from 'rxjs';

import { GeneralModel, isObject, swallowError, Translate, useTranslate, ViewModel } from '@cyferd/client-engine';

import { COLOR, FONT_SIZE, HC_COLOR, TRANS } from '@constants';
import { CTA, CTAType, ICTAType } from '../CTA';
import { ToolTip } from '../Tooltip';
import { DropdownMenu } from '../DropdownMenu';
import { styles as dropdownMenuStyles } from '../DropdownMenu/styles';
import { IconImage } from '../Icon/renderIcon';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { styles } from './styles';
import { IconKeys } from '../Icon';
import { CSSProperties } from 'react';

export interface MenuOption {
  label?: string;
  tooltip?: string;
  image?: GeneralModel.IconName;
  color?: GeneralModel.Color.ThemeColor;
  disabled?: boolean;
  testid?: string;
  important?: boolean;
  onClick?: (event?: any) => any;
  onMouseEnter?: (event?: any) => any;
  onMouseLeave?: (event?: any) => any;
  type?: ICTAType;
  size?: ViewModel.CTASize;
  outlined?: boolean;
  allowAutofocus?: boolean;
  optionList?: MenuOption[];
  dot?: boolean;
  /** @deprecated */
  status?: ViewModel.Status;
}

export interface OptionMenuProps {
  renderButton?: ComponentProps<typeof DropdownMenu>['renderTrigger'];
  optionList: MenuOption[];
  horizontal?: number;
  vertical?: number;
  containerId?: string;
  maxImportant?: number;
  defaultBtnType?: ICTAType;
  defaultBtnSize?: ViewModel.CTASize;
  defaultBtnIcon?: IconKeys;
  defaultBtnTestid?: string;
  defaultTooltip?: string;
  defaultBtnColor?: GeneralModel.Color.ThemeColor;
  defaultDisabled?: boolean;
  defaultOutlined?: boolean;
  importantContainerStyle?: CSSProperties;
}

const DefaultBtn = ({
  onClick,
  isOpen,
  defaultBtnType = CTAType.ACTION_SECONDARY as ICTAType,
  defaultBtnSize,
  defaultBtnIcon,
  defaultBtnTestid = 'actions-btn',
  defaultTooltip = TRANS.client.buttons.actions,
  defaultOutlined = false,
  defaultBtnColor,
  defaultDisabled,
  triggerRef
}) => (
  <PreventClickPropagation>
    <div ref={triggerRef} css={[styles.triggerContainer, isOpen && !defaultBtnIcon && styles.UNSTABLE_rotation]}>
      <CTA
        testid={defaultBtnTestid}
        type={defaultBtnType}
        size={defaultBtnSize}
        color={defaultBtnColor}
        icon={defaultBtnIcon ?? 'MoreVert'}
        tooltip={defaultTooltip}
        outlined={defaultOutlined}
        disabled={defaultDisabled}
        onClick={onClick}
      />
    </div>
  </PreventClickPropagation>
);

export const OptionMenu = memo(
  ({
    renderButton,
    optionList,
    horizontal,
    vertical,
    containerId,
    maxImportant,
    defaultBtnType,
    defaultBtnSize,
    defaultBtnIcon,
    defaultBtnColor,
    defaultBtnTestid,
    defaultTooltip,
    defaultOutlined,
    defaultDisabled,
    importantContainerStyle
  }: OptionMenuProps) => {
    const { translate } = useTranslate();
    const defaultRenderBtn = useCallback(
      ({ onClick, isOpen, ref }) => (
        <DefaultBtn
          onClick={onClick}
          isOpen={isOpen}
          defaultBtnType={defaultBtnType}
          defaultBtnSize={defaultBtnSize}
          defaultBtnIcon={defaultBtnIcon}
          defaultBtnTestid={defaultBtnTestid}
          defaultTooltip={defaultTooltip}
          defaultBtnColor={defaultBtnColor}
          defaultOutlined={defaultOutlined}
          defaultDisabled={defaultDisabled}
          triggerRef={ref}
        />
      ),
      [defaultBtnColor, defaultBtnIcon, defaultBtnSize, defaultBtnTestid, defaultBtnType, defaultDisabled, defaultOutlined, defaultTooltip]
    );
    const innerContainerId = useId();
    const [container, setContainer] = useState<HTMLElement>();

    useLayoutEffect(() => {
      setContainer(document.getElementById(containerId || innerContainerId));
    }, [containerId, innerContainerId, optionList]);

    if (!optionList?.length) return null;

    const { importantList, regularList } = optionList.filter(isObject).reduce(
      (total, curr) => {
        const importantLimitReached = typeof maxImportant === 'number' && total.importantList.length >= maxImportant;
        if (
          !importantLimitReached &&
          (curr.important ||
            curr.optionList?.length ||
            (optionList?.length === 1 &&
              ([CTAType.ACTION, CTAType.ACTION_SECONDARY, CTAType.ACTION_TERTIARY].includes(curr.type as any) || (curr.type === CTAType.LINK && !curr.label))))
        ) {
          return { ...total, importantList: [...total.importantList, curr] };
        }

        return { ...total, regularList: [...total.regularList, curr] };
      },
      { importantList: [], regularList: [] } as Record<string, typeof optionList>
    );

    if (!importantList.length && !regularList.length) return null;

    return (
      <>
        <div id={innerContainerId} />
        <div css={styles.container} style={importantContainerStyle} data-selector="option-menu-container">
          {importantList.map((option, index) => (
            <div key={[option.label, option.image, index].join()}>
              {option.optionList?.length ? (
                <OptionMenu
                  defaultBtnType={option.type}
                  defaultBtnSize={option.size}
                  defaultBtnIcon={option.image}
                  defaultBtnTestid={option.testid}
                  defaultTooltip={option.tooltip}
                  defaultBtnColor={option.color}
                  defaultOutlined={option.outlined}
                  defaultDisabled={option.disabled}
                  optionList={option.optionList}
                />
              ) : (
                <CTA
                  label={option?.label}
                  icon={option?.image}
                  tooltip={option?.tooltip}
                  type={option?.type || CTAType.LINK}
                  size={option?.size}
                  onClick={option?.onClick}
                  disabled={!!option?.disabled}
                  testid={option?.testid}
                  status={option?.status}
                  color={option?.color}
                  outlined={option?.outlined}
                  allowAutofocus={option?.allowAutofocus}
                />
              )}
            </div>
          ))}
          {!!regularList.length && (
            <DropdownMenu
              renderTrigger={renderButton || defaultRenderBtn}
              horizontal={horizontal}
              vertical={vertical || 2}
              renderMenuWrapper={
                (({ css, onClick, children, style, ref }) => {
                  return (
                    !!container &&
                    createPortal(
                      <div css={dropdownMenuStyles.menuContainer}>
                        <div style={style} css={css} onClick={onClick} ref={ref}>
                          {children}
                        </div>
                      </div>,
                      container
                    )
                  );
                }) as any
              }
            >
              <div css={styles.dropdown}>
                {regularList.map((option, index) => {
                  const color = option.disabled ? COLOR.NEUTRAL_3 : HC_COLOR[option.color || 'NEUTRAL_1'];
                  return (
                    <ToolTip key={[option.label, option.image, index].join()} text={option.tooltip}>
                      <div
                        onMouseEnter={option.onMouseEnter || undefined}
                        onMouseLeave={option.onMouseLeave || undefined}
                        data-testid={option.testid || 'menu-option-btn'}
                        css={[styles.option, option.disabled && styles.disabled]}
                        onClick={event => {
                          if (typeof option.onClick !== 'function' || option.disabled) return;
                          const obs$ = option.onClick(event);
                          if (obs$ instanceof Observable) obs$.pipe(swallowError()).subscribe();
                        }}
                      >
                        <div css={styles.iconContainer}>
                          <IconImage
                            icon={option.image}
                            title={translate(option.label)}
                            iconProps={{ size: FONT_SIZE.M, fill: color, outlined: option.outlined }}
                            imageProps={{ size: FONT_SIZE.M, css: { objectFit: 'cover' } }}
                          />
                          {option.dot && <div data-testid="unread-flag" css={styles.dot} />}
                        </div>
                        <span style={{ color }}>
                          <Translate>{option.label || option.tooltip}</Translate>
                        </span>
                      </div>
                    </ToolTip>
                  );
                })}
              </div>
            </DropdownMenu>
          )}
        </div>
      </>
    );
  }
);
