import { FormulaInputRow, getFormulaInputRowItem } from '@components/elements/Evaluator/resources';
import { createUUID, GeneralModel, UserModel, ViewModel } from '@cyferd/client-engine';

export const getNewUser = (): UserModel.User => ({
  id: createUUID(),
  roles: [UserModel.UserRole.ENTITY_ADMIN, UserModel.UserRole.USER_ADMIN, UserModel.UserRole.INTEGRATION_ADMIN],
  name: 'New user',
  email: '',
  enabled: true
});

export const userInputList: FormulaInputRow[] = [
  getFormulaInputRowItem({ groupName: 'User', prefix: 'user', base: ViewModel.InputKey.USER }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'user',
    base: ViewModel.InputKey.USER,
    path: 'id',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    label: 'User ID'
  }),
  getFormulaInputRowItem({ groupName: 'User', prefix: 'user', base: ViewModel.InputKey.USER, path: 'email', format: GeneralModel.JSONSchemaFormat.EMAIL }),
  getFormulaInputRowItem({ groupName: 'User', prefix: 'user', base: ViewModel.InputKey.USER, path: 'name', format: GeneralModel.JSONSchemaFormat.TEXT }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'user',
    base: ViewModel.InputKey.USER,
    path: 'fullName',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    label: 'User full name'
  }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'user',
    base: ViewModel.InputKey.USER,
    path: 'recordImage',
    format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
    label: 'User record image'
  }),
  getFormulaInputRowItem({
    groupName: 'User',
    prefix: 'user',
    base: ViewModel.InputKey.USER,
    path: 'organisation',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({ groupName: 'User', prefix: 'user', base: ViewModel.InputKey.USER, path: 'roles', format: GeneralModel.JSONSchemaFormat.ARRAY })
];
