import { memo, useCallback, useMemo } from 'react';

import { createUUID, GeneralModel, isObject } from '@cyferd/client-engine';

import { SchemaForm } from '../../../../../shared/SchemaForm';
import { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { CTAType } from '@components/elements/CTA';
import { TRANS } from '@constants';

const schema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  properties: {
    value: {
      label: ' ',
      type: 'array',
      metadata: { ctaConfig: { label: 'Add value', image: 'add', type: CTAType.LINK }, unlimitedHeight: true },
      items: {
        label: ' ',
        type: 'object',
        required: ['key'],
        properties: {
          key: { type: 'string', label: TRANS.client.fields.titles.key },
          value: { type: 'any', label: TRANS.client.fields.titles.value, format: GeneralModel.JSONSchemaFormat.EVALUATION, default: '' }
        }
      }
    }
  }
};

export interface KeyValueMapProps {
  value: any;
  inputList: FormulaInputRow[];
  onChange: (event: any) => void;
}

export const KeyValueMap = memo(({ inputList, value, onChange }: KeyValueMapProps) => {
  const internalValue = useMemo(
    () => ({ value: Object.entries(isObject(value) ? value : {}).reduce((total, [key, v]) => [...total, { key, value: v }], []) }),
    [value]
  );

  const onInternalChange = useCallback(
    (event: { value: any[] }) => {
      onChange(event?.value?.reduce((t, row, index) => ({ ...t, [row?.key || `key_${index + 1}`]: row?.value }), {}));
    },
    [onChange]
  );

  return (
    <div>
      <SchemaForm addDefaults={false} schema={schema} value={internalValue} onChange={onInternalChange} inputList={inputList} />
    </div>
  );
});
