import React, { forwardRef, useContext } from 'react';

import { styles } from './styles';
import { UIContext } from '@components/providers/UIprovider';
import { Icon } from '@components/elements/Icon';
import { GeneralModel } from '@cyferd/client-engine';
import { COLOR, FOREGROUND_COLOR, HC_SECONDARY_COLOR } from '@constants';

export interface BannerProps {
  banner: {
    active: boolean;
    position: 'top' | 'bottom';
    icon?: GeneralModel.IconName;
    text?: string;
    bannerColorDark?: GeneralModel.Color.ThemeColor;
    bannerColorLight?: GeneralModel.Color.ThemeColor;
  };
}

export const Banner = forwardRef<HTMLDivElement, BannerProps>(({ banner }: BannerProps, ref) => {
  const { runtimeTheme } = useContext(UIContext);
  const bannerTop = banner?.position === 'top';
  const backgroundColor = runtimeTheme === 'light' || !banner?.bannerColorDark ? banner?.bannerColorLight : banner?.bannerColorDark;
  const textColor = FOREGROUND_COLOR[backgroundColor] ?? COLOR.HC_7;
  return (
    !!banner?.active &&
    (!!banner?.icon || !!banner?.text) && (
      <div
        data-testid="banner"
        css={[styles.bannerContainer, bannerTop ? styles.top : styles.bottom]}
        style={{ backgroundColor: COLOR[backgroundColor] ?? HC_SECONDARY_COLOR.HC_7 }}
        ref={ref}
      >
        {!!banner?.icon && <Icon name={banner.icon} fill={textColor} />}
        {!!banner?.text && <span style={{ color: textColor }}>{banner.text}</span>}
      </div>
    )
  );
});
