import { CollectionModel, GeneralModel, normalize, parseSchemaForFilters } from '@cyferd/client-engine';
import { SelectDropdown, SelectDropdownProps } from '../SelectDropdown';
import { styles } from './styles';
import { getLabel } from '@utils';
import { useCallback, useMemo } from 'react';
import { CTA, CTAType } from '../CTA';
import { TRANS } from '@constants';
import { Fieldset } from '../Fieldset';

export interface SortByValue {
  orderBy?: string;
  descending?: boolean;
}

export interface SortByProps {
  entity: CollectionModel.Collection;
  value: SortByValue;
  onChange: (sortBy: SortByValue) => void;
  disabled?: boolean;
  testId?: string;
}

export const SortBy = ({ value = {}, entity, onChange, testId = 'sortBy', disabled }: SortByProps) => {
  const { propOptionList } = useMemo(() => parseSchemaForFilters(normalize.collection(entity)), [entity]);
  const options: SelectDropdownProps['options'] = useMemo(
    () => [
      ...propOptionList
        .filter(({ format }) => GeneralModel.formatIsSortableMap[format])
        .map(({ displayNamePath, value }) => ({ label: getLabel(displayNamePath), value }))
    ],
    [propOptionList]
  );

  const boolToString = (val: boolean) => {
    if (typeof val !== 'boolean') return undefined;
    return String(val);
  };
  const stringToBool = (val: string) => {
    if (['true', 'false'].indexOf(val) !== -1) return val === 'true';
    return undefined;
  };

  const onFieldChange = useCallback((field: string) => onChange({ ...value, orderBy: field }), [onChange, value]);
  const onDescendingChange = useCallback((desc: string) => onChange({ ...value, descending: stringToBool(desc) }), [onChange, value]);
  const clear = useCallback(() => onChange({}), [onChange]);

  return (
    <Fieldset isDetailGroup={true} image="sort_by_alpha" title={TRANS.client.buttons.sortBy}>
      <div css={styles.dropdownContainer}>
        <SelectDropdown
          allowEmpty={true}
          testid={`${testId}-column-selector`}
          label="Field"
          options={options}
          onChange={onFieldChange}
          value={value.orderBy}
          disabled={disabled}
        />
      </div>
      <div css={styles.dropdownContainer}>
        <SelectDropdown
          allowEmpty={true}
          testid={`${testId}-operator-selector`}
          label={TRANS.client.buttons.sortBy}
          value={boolToString(value.descending)}
          options={[
            { label: 'A to Z', value: 'false' },
            { label: 'Z to A', value: 'true' }
          ]}
          onChange={onDescendingChange}
          disabled={disabled}
        />
      </div>
      <div css={styles.dropdownContainer}>
        <CTA type={CTAType.LINK} label="Reset" onClick={clear} disabled={!value.orderBy && !value.descending} />
      </div>
    </Fieldset>
  );
};
