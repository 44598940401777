import { memo, PropsWithChildren, ReactNode } from 'react';

import { useGetElementSize, useTestingHelper } from '@utils';

import { styles } from './styles';
import { SearchInput } from '@components/elements/SearchInput';

export type Props = PropsWithChildren<{
  testid?: string;
  search: string;
  setSearch: (search: string) => void;
  customAddButton?: ReactNode;
  screenTop?: number;
}>;

export const EntityEditorContainer = memo(({ children, testid, search, setSearch, customAddButton, screenTop }: Props) => {
  const { getTestIdProps } = useTestingHelper(testid || 'entity-editor-container');
  const { ref, top } = useGetElementSize();

  return (
    <div css={styles.container}>
      <div css={styles.searchContainer}>
        <SearchInput testid={getTestIdProps('search-input')['data-testid']} value={search} onChange={setSearch} />
        {customAddButton}
      </div>
      <div ref={ref} css={styles.content(screenTop ?? top)}>
        {children}
      </div>
    </div>
  );
});
