import { ApiModel, CollectionModel, GeneralModel, ViewModel } from '@cyferd/client-engine';
import { styles } from './styles';
import { Checkbox } from '../Checkbox';
import { FiltersModal } from '../FiltersModal';
import { ComponentProps, useContext } from 'react';
import { CyWrapperContext } from '@components/smart/CyWrapper/CyWrapper';
import { Observable } from 'rxjs';
import { CTAType } from '@components/elements/CTA';

const ctaProps: ComponentProps<typeof FiltersModal>['ctaProps'] = { type: CTAType.LINK, size: ViewModel.CTASize.SMALL, color: 'BRAND_1' };

export interface CalendarListProps {
  optionList: Pick<GeneralModel.JSONSchemaMetadata['optionList'][0], 'label' | 'value' | 'color'>[];
  testid?: string;
  value: string[];
  disabled?: boolean;
  calendarCollectionMap: Record<string, CollectionModel.Collection>;
  onChange: (event: string) => void;
  onChangeCollectionCursor: (key: string, filter: GeneralModel.FetchCriteria['filter']) => Observable<ApiModel.APIAction>;
}

export const CalendarList = ({
  testid = 'CalendarList',
  value,
  disabled,
  optionList,
  calendarCollectionMap,
  onChange,
  onChangeCollectionCursor
}: CalendarListProps) => {
  const { useFetchCollectionModel, renderSchemaForm } = useContext(CyWrapperContext);
  const fetchCollectionModel = useFetchCollectionModel();

  return (
    <div css={styles.container} data-testid={testid}>
      <p css={styles.title}>Calendars</p>
      <div css={styles.options}>
        {optionList?.map((option, index) => {
          const query = calendarCollectionMap[option.value];
          const cursor = (query as any)?.cursor;
          const isSelected = value?.includes?.(option.value as string);
          return (
            <div key={`${option.value}${index}`} css={[styles.option]}>
              <Checkbox
                testid="calendar-collection"
                disabled={disabled}
                value={isSelected}
                label={option.label}
                color={option.color as any}
                onChange={() => onChange(option.value as string)}
              />
              {!!query && isSelected && (
                <div>
                  <FiltersModal
                    entity={query}
                    fetchCollectionModel={fetchCollectionModel}
                    cursor={cursor}
                    onSubmit={event => onChangeCollectionCursor(option.value as string, event?.filter)}
                    renderSchemaForm={renderSchemaForm}
                    disabled={disabled}
                    ctaProps={ctaProps}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

CalendarList.displayName = 'CalendarList';
