import { ComponentProps } from 'react';

import { GeneralModel } from '@cyferd/client-engine';

import { IconList } from '../IconList';
import { OptionMenuProps } from '../OptionMenu';
import { InputWrapper } from '../InputWrapper';

export type RatingInputProps = {
  id?: string;
  icon?: GeneralModel.IconName;
  label?: string;
  value?: number;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
  description?: string;
  color?: GeneralModel.Color.ThemeColor;
  secondaryColor?: GeneralModel.Color.ThemeColor;
  optionList?: OptionMenuProps['optionList'];
  onChange: (value: number) => void;
  info?: string;
  disabledType?: GeneralModel.DisabledType;
} & Pick<ComponentProps<typeof IconList>, 'length' | 'roundBy'>;

export const RatingInput = ({
  id,
  icon,
  label,
  value,
  disabled,
  required,
  errorMessage,
  secondaryColor,
  color,
  length = 5,
  roundBy,
  description,
  onChange,
  optionList,
  info,
  disabledType
}: RatingInputProps) => {
  return (
    <div id={id} data-testid={`${id}-container`}>
      <InputWrapper
        description={description}
        disabled={disabled}
        disabledType={disabledType}
        errorMessage={errorMessage}
        id={id}
        label={label}
        onChange={onChange}
        optionList={optionList}
        required={required}
        testid={id}
        value={value}
        unlimitedHeight={false}
        info={info}
      >
        <div>
          <IconList
            onClick={!disabled && onChange}
            roundBy={roundBy}
            length={length}
            color={color}
            secondaryColor={secondaryColor}
            filled={value}
            name={icon}
            size="22px"
          />
        </div>
      </InputWrapper>
    </div>
  );
};
