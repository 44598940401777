import { useCallback, useMemo } from 'react';
import { Observable } from 'rxjs';

import { ApiModel, GeneralModel, Translate, swallowError, useTranslate } from '@cyferd/client-engine';

import { styles } from './styles';
import { CTA, CTAType } from '../CTA';
import { SelectDropdown, SelectDropdownType } from '../SelectDropdown';
import { TRANS } from '@constants';

export interface PaginationProps {
  cursor?: GeneralModel.FetchCriteria;
  count?: number;
  onFetch?: (criteria: GeneralModel.FetchCriteria) => Observable<ApiModel.APIAction>;
  disabled?: boolean;
  testid?: string;
  hideLimit?: boolean;
}

export const Pagination = ({ cursor, count, onFetch, testid = 'pagination', disabled, hideLimit }: PaginationProps) => {
  const { translate } = useTranslate();
  const { options: { skip = 0, limit = 10 } = {} } = cursor || { options: { skip: 0, limit: 10 } };
  const canPaginate = !!cursor && !!onFetch && !(!skip && !count);

  const possibleItemsPerPage = Array.from(new Set([5, 10, 25, 50, 100, limit].sort((a, b) => a - b)));
  const isPrevious: boolean = canPaginate && skip > 0;
  const isNext: boolean = canPaginate && count >= limit;

  const changePagination = useCallback(
    (direction?: 'forward' | 'backward', l?: number) => {
      /* istanbul ignore else */
      if (canPaginate) {
        const skipValueGoingBackwards = Math.max(0, skip - limit);
        onFetch({
          ...cursor,
          options: {
            ...cursor?.options,
            limit: l ?? limit,
            skip: typeof l === 'number' ? 0 : direction === 'forward' ? skip + limit : skipValueGoingBackwards
          }
        })
          .pipe(swallowError())
          .subscribe();
      }
    },
    [canPaginate, skip, onFetch, cursor, limit]
  );

  const selectOptions = possibleItemsPerPage.map(i => {
    return {
      label: translate(TRANS.client.pagination.items, { i }),
      value: i
    };
  });

  const pageNumber = useMemo(() => Math.floor(skip ? skip / limit + 1 : 1), [skip, limit]);

  return canPaginate ? (
    <div css={styles.mainContainer} data-testid={testid}>
      {!hideLimit && (
        <div css={styles.leftContainer}>
          <p>
            <Translate>{TRANS.client.pagination.display}</Translate>:
          </p>
          <SelectDropdown
            type={SelectDropdownType.PAGINATION}
            value={limit}
            options={selectOptions}
            label=""
            onChange={disabled ? null : item => changePagination(null, item)}
            testid={testid}
          />
        </div>
      )}
      <div css={styles.rightContainer}>
        <div css={[(!isPrevious || disabled) && styles.hidden]}>
          <CTA
            testid={`${testid}-previous-page`}
            icon="keyboard_arrow_left"
            type={CTAType.LINK}
            tooltip={TRANS.client.pagination.prev}
            onClick={() => changePagination('backward')}
            disabled={!isPrevious || disabled}
          />
        </div>
        <p css={styles.text} data-testid={`${testid}-from-to`}>
          {translate(TRANS.client.pagination.page, { pageNumber })}
        </p>
        <div css={[(!isNext || disabled) && styles.hidden]}>
          <CTA
            testid={`${testid}-next-page`}
            icon="keyboard_arrow_right"
            type={CTAType.LINK}
            tooltip={TRANS.client.pagination.next}
            onClick={() => changePagination('forward')}
            disabled={!isNext || disabled}
          />
        </div>
      </div>
    </div>
  ) : null;
};

Pagination.displayName = 'Pagination';
