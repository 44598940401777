import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { CollectionLookup, CollectionLookupProps } from '../../../CollectionLookup';
import { CTA, CTAType } from '../../../CTA';
import { styles as associationDropdownStyles } from '../../../AssociationInput/components/AssociationDropdown/styles';
import { ApiModel, GeneralModel, ViewModel } from '@cyferd/client-engine';
import { styles } from './styles';
import { PreventClickPropagation } from '../../../PreventClickPropagation';
import { TRANS } from '@constants';

export interface AssociationAdderProps {
  collectionId: string;
  apiQuery: ApiModel.ApiValue['query'];
  associationKey: string;
  delay?: number;
  defaultFilter: GeneralModel.FetchCriteria['filter'];
  fixedFilter: GeneralModel.FetchCriteria['fixedFilter'];
  disabled?: boolean;
  meta: ApiModel.ApiRecordAssociationMeta;
  onAdvSearch?: () => void;
  onNew?: () => void;
  onLink: (association: ApiModel.ApiAssociationChange, record: ApiModel.ApiRecord) => void;
  testid?: string;
}

export const AssociationAdder = ({
  collectionId,
  apiQuery,
  associationKey,
  delay,
  fixedFilter,
  defaultFilter,
  disabled,
  meta,
  onAdvSearch,
  onNew,
  onLink,
  testid = 'association-adder'
}: AssociationAdderProps) => {
  const [isAdding, setAdding] = useState<boolean>(false);

  const onToggleAdding = useCallback(() => setAdding(p => !p), []);

  const fetchCriteria = useMemo(() => {
    const filterConditions = [defaultFilter, !!meta?.add?.length && { $not: { id: { $in: meta?.add?.map(a => a?.id) } } }].filter(Boolean);

    return {
      collectionId,
      filter: { $and: filterConditions },
      associationKey,
      relatedTo: { collectionId: apiQuery?.cursor?.collectionId, id: apiQuery?.cursor?.id },
      excluded: true
    } as GeneralModel.FetchCriteria;
  }, [apiQuery?.cursor?.collectionId, apiQuery?.cursor?.id, associationKey, collectionId, defaultFilter, meta?.add]);

  const onInternalLink: ComponentProps<typeof CollectionLookup>['onChange'] = useCallback(
    (_, record) => !!record?.id && onLink({ id: record?.id }, record),
    [onLink]
  );

  const onInternalAdvSearch = useCallback(
    (onCloseDropdown: () => void) => {
      onAdvSearch();
      onCloseDropdown();
    },
    [onAdvSearch]
  );

  const onInternalNew = useCallback(
    (onCloseDropdown: () => void) => {
      onNew();
      onCloseDropdown();
    },
    [onNew]
  );

  const renderCustomOption: CollectionLookupProps['renderCustomOption'] = useCallback(
    ({ onClose }) => (
      <>
        {!!onNew && (
          <div css={associationDropdownStyles.createNewOption} data-testid={`${testid}-create-new`} onClick={() => onInternalNew(onClose)}>
            <CTA label={TRANS.client.buttons.createNew} icon="add" type={CTAType.LINK} />
          </div>
        )}
        {!!onAdvSearch && (
          <div css={associationDropdownStyles.createNewOption} data-testid={`${testid}-adv-search`} onClick={() => onInternalAdvSearch(onClose)}>
            <CTA label={TRANS.client.placeholder.assAdd} icon="search" type={CTAType.LINK} />
          </div>
        )}
      </>
    ),
    [testid, onAdvSearch, onInternalAdvSearch, onInternalNew, onNew]
  );

  const optionList = useMemo(
    () =>
      [
        {
          image: 'close',
          tooltip: TRANS.client.buttons.close,
          type: CTAType.LINK,
          onClick: onToggleAdding
        }
      ] as ComponentProps<typeof CollectionLookup>['optionList'],
    [onToggleAdding]
  );

  return (
    <div css={styles.container}>
      {!!isAdding && (
        <CollectionLookup
          testid={testid}
          disableFreeText={true}
          label="Add"
          collectionId={apiQuery?.cursor?.collectionId}
          fixedFilter={fixedFilter}
          fetchCriteria={fetchCriteria}
          disabled={disabled}
          delay={delay}
          hiddenOpenRecordBtn={true}
          optionList={optionList}
          optionContainerHeight={200}
          truncate={true}
          autoFocus={true}
          onChange={onInternalLink}
          renderCustomOption={renderCustomOption}
        />
      )}
      {!isAdding && (
        <div css={styles.addContainer}>
          <PreventClickPropagation>
            <CTA
              testid={`${testid}-toggle-add`}
              type={CTAType.TERTIARY}
              size={ViewModel.CTASize.SMALL}
              disabled={disabled}
              label="Add"
              icon="add"
              onClick={onToggleAdding}
            />
          </PreventClickPropagation>
        </div>
      )}
    </div>
  );
};
