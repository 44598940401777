import { useContext, useMemo } from 'react';

import { ApiModel, CollectionModel, GeneralModel, ViewModel } from '@cyferd/client-engine';

import { styles } from './styles';
import { BaseCard } from '../BaseCard';
import { CyWrapperContext } from '../../smart/CyWrapper/CyWrapper';

export interface CardListProps {
  value: ApiModel.ApiValue;
  actionListChildren?: ViewModel.CyListProps['actionListChildren'];
  recordActions?: CollectionModel.Collection['recordActions'];
  onClickItem?: ViewModel.CyListProps['onClickItem'];
  testid?: string;
  disabled?: boolean;
}

export const CardList = ({ value, actionListChildren, onClickItem, disabled, testid = 'cardListTile', recordActions }: CardListProps) => {
  const { useParsers } = useContext(CyWrapperContext);
  const { parseList } = useParsers(value?.query);
  const { items } = useMemo(() => parseList({ entity: value?.query, list: value?.list }), [parseList, value?.list, value?.query]);

  return (
    <div css={styles.cardContainer} data-testid={testid}>
      {items.map((item, index) => {
        const rowId = `${item.fullItem.id}-${index}`;
        return (
          <BaseCard
            id={rowId}
            key={rowId}
            title={item.title}
            imageUrl={item?.image}
            subtitle={item.description}
            color={item.color as GeneralModel.Color.ThemeColor}
            disabled={disabled}
            actionListChildren={actionListChildren}
            recordActions={recordActions}
            onClick={onClickItem && (event => onClickItem(item.fullItem, { index, metaKey: event?.metaKey }))}
            fullItem={item.fullItem}
            testid={testid}
            index={index}
          />
        );
      })}
    </div>
  );
};

CardList.displayName = 'CardList';
