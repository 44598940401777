import { forwardRef, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';

import { CTA, CTAType } from '../CTA';
import { PreventClickPropagation } from '../PreventClickPropagation';

export type CollapsibleProps = PropsWithChildren<{
  open?: boolean;
  testid?: string;
  renderHeader: (onToggle: () => void, toggleButton: ReactNode, isOpen: boolean) => ReactNode;
}>;

export const Collapsible = forwardRef<HTMLDivElement, CollapsibleProps>(
  ({ children, open: outerIsOpen, renderHeader, testid = 'collapsible' }: CollapsibleProps, ref) => {
    const [isOpen, setOpen] = useState<boolean>(!!outerIsOpen);

    useEffect(() => setOpen(!!outerIsOpen), [outerIsOpen]);

    const onToggle = useCallback(() => setOpen(prev => !prev), []);

    const defaultButton = (
      <PreventClickPropagation>
        <CTA testid={`${testid}-toggle-btn`} type={CTAType.LINK} icon={isOpen ? 'keyboard_arrow_down' : 'chevron_right'} onClick={onToggle} />
      </PreventClickPropagation>
    );

    return (
      <div data-testid={`${testid}-container`} ref={ref}>
        <div>{renderHeader(onToggle, defaultButton, isOpen)}</div>
        <div>{isOpen && children}</div>
      </div>
    );
  }
);

Collapsible.displayName = 'Collapsible';
