import { CollectionModel, ParsedList, Translate, ViewModel, getParsedActionChildren, useRecordActionsParser } from '@cyferd/client-engine';
import { COLOR } from '@constants';
import { styles } from './styles';
import { ToolTip } from '../Tooltip';
import { IconImage } from '../Icon/renderIcon';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { OptionMenu } from '../OptionMenu';
import { CTAType } from '../CTA';
import { TooltipInfo } from '../TooltipInfo';
import { ComponentProps } from 'react';

export interface ListItemProps {
  item: ParsedList['items'][0];
  isClickable?: boolean;
  id?: string;
  index?: number;
  actionListChildren?: ViewModel.CyListProps['actionListChildren'];
  recordActions?: CollectionModel.Collection['recordActions'];
  showDescription?: boolean;
  optionMenuOverride?: Partial<ComponentProps<typeof OptionMenu>>;
  disabled?: boolean;
  onClickItem?: ViewModel.CyListProps['onClickItem'];
}

export const ListItem = ({
  item,
  id,
  isClickable,
  index,
  actionListChildren,
  showDescription,
  optionMenuOverride,
  disabled,
  recordActions,
  onClickItem
}: ListItemProps) => {
  const parseRecordActions = useRecordActionsParser(recordActions);
  return (
    <li
      css={[styles.listWrapper, index === 0 && styles.firstChild, !!COLOR[item.color] && styles.borderColor({ backgroundColor: COLOR[item.color] })]}
      key={id}
      data-testid={id}
      onClick={!isClickable ? undefined : event => onClickItem(item.fullItem, { metaKey: event?.metaKey, index })}
    >
      <div data-selector="listItem" css={[styles.listItem, isClickable && styles.clickableListItem]}>
        <ToolTip text={<TooltipInfo title={item.title} description={item.description} />}>
          <div css={styles.infoContainer}>
            <IconImage
              title={item.title}
              testid="list-image"
              icon={item.image}
              iconProps={{ size: '25px', fill: COLOR.NEUTRAL_1 }}
              imageProps={{ css: { width: '25px', height: '25px', borderRadius: '100%', objectFit: 'cover', flexShrink: 0 } }}
            />
            <div css={styles.textContainer}>
              <span css={styles.title} data-testid={`${id}-title`}>
                <Translate>{item.title}</Translate>
              </span>
              {!!showDescription && !!item.description && (
                <div css={styles.subtitle}>
                  <span>
                    <Translate>{item.description}</Translate>
                  </span>
                </div>
              )}
            </div>
          </div>
        </ToolTip>
        <div>
          <PreventClickPropagation>
            <OptionMenu
              defaultBtnType={CTAType.LINK}
              optionList={[
                ...parseRecordActions({ item: item.fullItem, index }),
                ...getParsedActionChildren(actionListChildren, { item: item.fullItem, index })
              ].map(actionItem => ({
                label: actionItem.label,
                image: actionItem.icon,
                disabled: disabled || !!actionItem.disabled,
                onClick: actionItem.onClick && (event => actionItem.onClick(item.fullItem, { index, metaKey: event?.metaKey })),
                testid: `${id}-action-cta-${index}`,
                color: actionItem.color,
                status: (actionItem as any).status,
                type: actionItem.type || CTAType.ACTION,
                size: (actionItem as any).size || ViewModel.CTASize.SMALL,
                tooltip: actionItem.helperText,
                important: actionItem.important
              }))}
              {...optionMenuOverride}
            />
          </PreventClickPropagation>
        </div>
      </div>
    </li>
  );
};
