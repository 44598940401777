import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, from, mergeMap, Subject, takeUntil, tap } from 'rxjs';

import { GeneralModel, getAddressValue, ViewModel } from '@cyferd/client-engine';
import { useDropdownDimensions } from '@utils';
import { getElementMenuPosition } from '@utils/getElementMenuPosition';
import { useOutsideClick } from '@utils/useOutsideClick';

import { BaseForm } from '../../../../smart/CyForm/components/BaseForm';
import { CyWrapperContext } from '../../../../smart/CyWrapper';
import { CTAType } from '../../../CTA';
import { DropdownOption } from '../../../DropdownOption';
import { FieldsetContent } from '../../../Fieldset';
import { Input } from '../../../Input';
import { Modal } from '../../../Modal';
import { OptionMenu } from '../../../OptionMenu';
import { useOpenMapOption } from '../../useOpenMapOption';
import { styles } from './styles';
import { TRANS } from '@constants';

const fixedSchema: GeneralModel.JSONSchema = {
  type: 'object',
  properties: {
    additionalInfo: { type: 'string', title: 'Additional info', description: 'Unit, apartment, staircase, etc.', metadata: { detailOrder: 1 } }
  }
};

export interface AddressModalProps {
  title?: string;
  description?: string;
  value?: GeneralModel.AddressValue;
  disabled?: boolean;
  delay: number;
  schema?: GeneralModel.JSONSchema;
  icon?: GeneralModel.IconName;
  onChange: (value: GeneralModel.AddressValue) => void;
  onClose: () => void;
}

export const AddressModal = ({ title, description, value, disabled, delay, schema, icon = 'pin_drop', onClose, onChange }: AddressModalProps) => {
  const { geocode } = useContext(CyWrapperContext);
  const [internalValue, setInternalValue] = useState(value);
  const openMapOption = useOpenMapOption(internalValue);
  const [search, setSearch] = useState<string>(value?.summary);
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState<GeneralModel.AddressValue[]>([]);
  const search$ = useRef(new Subject<string>());

  const { mainRef, dropdownRef, triggerDimensions, menuDimensions, clientDimensions } = useDropdownDimensions();

  const addressSchema: GeneralModel.JSONSchema = useMemo(
    () => ({ type: 'object', required: schema?.required, properties: { ...schema?.properties } }),
    [schema?.properties, schema?.required]
  );

  const onToggleDropdown = (isOpen: boolean) => {
    setOpen(isOpen);
    setSearch('');
    setResults([]);
  };

  const onClickOutside = useCallback(() => onToggleDropdown(false), []);

  const outsideRef = useOutsideClick(onClickOutside);

  const onSearch = (searchString: string) => {
    setSearch(searchString);
    /* istanbul ignore else */
    if (searchString?.trim()?.length > 1) search$.current.next(searchString);
  };

  const onSelect = (option: GeneralModel.AddressValue) => {
    setInternalValue(option);
    onToggleDropdown(false);
  };

  const onInternalChange = () => {
    onChange(getAddressValue(internalValue));
    onClose();
  };

  useEffect(() => {
    const sync$ = new Subject<void>();

    search$.current
      .pipe(
        takeUntil(sync$),
        debounceTime(delay),
        tap(() => setResults([])),
        mergeMap(searchString => from(geocode({ q: searchString, key: GeneralModel.GEO_KEY }))),
        tap(({ results }) => {
          const distinctResults: GeneralModel.AddressValue[] = Object.values(
            [...results]
              /** most important will overwrite less important */
              .reverse()
              .reduce((total, curr) => {
                const cleanResult = getAddressValue(curr);
                return { ...total, [cleanResult.summary]: cleanResult };
              }, {})
          );
          setResults(distinctResults);
        })
      )
      .subscribe();

    return () => sync$.next();
  }, [delay, geocode]);

  const menuElementPosition = getElementMenuPosition({ triggerDimensions, menuDimensions, clientDimensions, verticalPadding: 2 });

  return (
    <Modal
      title={title}
      description={description}
      icon={icon}
      onClose={onClose}
      open={true}
      type={ViewModel.ModalType.FULL_SCREEN}
      footer={
        <OptionMenu
          defaultBtnType={CTAType.ACTION_SECONDARY}
          optionList={[
            { important: true, label: TRANS.client.buttons.cancel, testid: 'cancel', type: CTAType.SECONDARY, onClick: onClose },
            { important: true, label: TRANS.client.buttons.clear, disabled, testid: 'clear', type: CTAType.SECONDARY, onClick: () => setInternalValue(null) },
            { important: true, label: TRANS.client.buttons.apply, disabled, testid: 'apply', type: CTAType.PRIMARY, onClick: onInternalChange }
          ]}
        />
      }
    >
      <div css={styles.container}>
        <div ref={outsideRef}>
          <Input
            label={TRANS.client.placeholder.addressSearch}
            testid="search-address"
            onChange={onSearch}
            value={open ? search : internalValue?.summary}
            onFocus={() => onToggleDropdown(true)}
            type={GeneralModel.JSONSchemaFormat.TEXT}
            disabled={disabled || !!internalValue?.manual}
            optionList={[{ ...openMapOption, important: true, disabled: !internalValue?.lat || !internalValue?.lng }]}
            inputContainerRef={mainRef}
          />
          {open && !!results?.length && (
            <div
              data-testid="address-options-container"
              css={styles.dropdownContainer}
              ref={dropdownRef}
              style={{
                width: triggerDimensions.width,
                top: menuElementPosition.style.top
              }}
            >
              {results.map((option, index) => (
                <div key={index}>
                  <DropdownOption title={option.summary} highlight={internalValue?.summary === option.summary} value={option} onClick={onSelect} />
                </div>
              ))}
            </div>
          )}
        </div>
        <div css={styles.formContainer}>
          <BaseForm disabled={disabled} value={internalValue} schema={fixedSchema} wrapDetailGroups={false} onChange={setInternalValue} />
          <FieldsetContent>
            <BaseForm
              disabled={disabled}
              value={internalValue}
              schema={addressSchema}
              wrapDetailGroups={false}
              avoidAlphabeticalSort={true}
              shouldValidate={true}
              onChange={setInternalValue}
            />
          </FieldsetContent>
        </div>
      </div>
    </Modal>
  );
};
