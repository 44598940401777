import { css } from '@emotion/react';
import { FONT_SIZE, FONT_WEIGHT, GAP } from '@constants';

export const styles = {
  bannerContainer: css`
    width: 100%;
    min-height: 18px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: ${GAP.S};
    font-size: ${FONT_SIZE.XXS};
    font-weight: ${FONT_WEIGHT.BOLD};
    padding: ${GAP.XS} ${GAP.S};
    position: fixed;
    left: 0;
    z-index: 10;
  `,
  top: css`
    top: 0;
  `,
  bottom: css`
    bottom: 0;
  `
};
