import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR } from '@constants';
import { GeneralModel } from '@cyferd/client-engine';

export const columnBreakpoint = 325;

export const styles = {
  detailGroupContainer: (color?: GeneralModel.Color.ThemeColor) => css`
    background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    padding: ${GAP.M} ${GAP.M} ${GAP.M} ${COLOR[color] ? `calc(${GAP.M} + ${GAP.XS})` : GAP.M};
    border-radius: ${RADIUS.S};
    ${COLOR[color] &&
    `
      border: 1px solid ${SECONDARY_COLOR[color]};
      position: relative;
      &::before {
        position: absolute;
        top: ${GAP.XS};
        left: ${GAP.XS};
        content: '';
        height: calc(100% - calc(${GAP.XS} * 2));
        border-radius: ${RADIUS.S};
        width: ${GAP.XS};
        background-color: ${COLOR[color]};
      }
    `}
  `,
  container: css`
    align-items: flex-start;
    min-width: 0;
    display: grid;
    grid-gap: ${GAP.S} ${GAP.L};
    grid-auto-rows: max-content;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, ${columnBreakpoint}px), 1fr));
  `,
  headerContainer: css`
    margin-bottom: ${GAP.M};
  `,
  legendContainer: css`
    display: flex;
    gap: ${GAP.XXS};
    align-items: center;
  `,
  legend: css`
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
    display: flex;
    gap: ${GAP.XXS};
    align-items: center;
    color: ${COLOR.NEUTRAL_1}!important;
    span {
      color: ${COLOR.NEUTRAL_1}!important;
      fill: ${COLOR.NEUTRAL_1}!important;
    }
  `,
  titleContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `,
  isCollapsible: css`
    cursor: pointer;
  `,
  description: css`
    margin-top: ${GAP.XXS};
    word-break: break-word;
    color: ${COLOR.NEUTRAL_2};
  `
};
