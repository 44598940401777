import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, INPUT_HEIGHT } from '@constants';

export const styles = {
  container: css`
    display: flex;
    gap: ${GAP.XS};
    width: 100%;
    align-items: center;
    min-height: ${INPUT_HEIGHT};
  `,
  checkbox: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${FONT_SIZE.S};
    width: ${FONT_SIZE.S};
    margin: auto 0;
    cursor: pointer;
    flex-shrink: 0;

    border: 2px solid ${COLOR.NEUTRAL_3};
    border-radius: 2px;

    background-color: ${COLOR.BASE_BACKGROUND};

    :hover {
      border-color: ${COLOR.BRAND_1};
    }

    :focus {
      border-color: ${COLOR.BE_2};
    }
    :disabled {
      cursor: not-allowed;
      background-color: ${COLOR.NEUTRAL_3} !important;
    }
  `,
  checked: css`
    background-color: ${COLOR.BRAND_1};

    :hover {
      background-color: ${COLOR.BRAND_1};
    }
  `,
  icon: css`
    fill: ${COLOR.BASE_BACKGROUND};
    display: flex;
    font-size: ${FONT_SIZE.XS};

    svg {
      height: ${FONT_SIZE.S};
      width: ${FONT_SIZE.S};
    }
  `,
  iconIndeterminate: css`
    display: flex;

    span {
      font-size: ${FONT_SIZE.S};
      font-weight: ${FONT_WEIGHT.BOLD};
    }
  `,
  checkboxElement: css`
    flex: 1;
    display: inline-flex;
    cursor: pointer;
  `,
  checkboxElementDisabled: css`
    cursor: not-allowed;
  `,
  label: css`
    display: flex;
    flex-direction: column;
    margin-left: ${GAP.M};
    text-align: var(--text-align);
  `,
  labelLeft: css`
    --text-align: end;
    margin-left: initial;
    margin-right: ${GAP.M};
  `,
  text: css`
    width: 100%;
    font-weight: 400;
  `,
  title: css`
    color: ${COLOR.NEUTRAL_1};
    display: flex;
    align-items: center;
    gap: ${GAP.XXS};
  `,
  description: css`
    font-size: ${FONT_SIZE.XS};
    color: ${COLOR.NEUTRAL_2};
  `
};
