import {
  ViewModel,
  useCyActionEffect,
  useCyDataEffect,
  useCyIntervalEffect,
  useCyLoadEffect,
  useCyUnloadEffect,
  useCyReusableActionEffect
} from '@cyferd/client-engine';

export const CyDataEffect = (props: ViewModel.CyDataEffectProps) => {
  useCyDataEffect(props);
  return <div id={props.id} data-testid="cy-data-effect" />;
};
CyDataEffect.displayName = ViewModel.DisplayName.CY_DATA_EFFECT;

export const CyActionEffect = (props: ViewModel.CyActionEffectProps) => {
  useCyActionEffect(props);
  return <div id={props.id} data-testid="cy-action-effect" />;
};
CyActionEffect.displayName = ViewModel.DisplayName.CY_ACTION_EFFECT;

export const CyLoadEffect = (props: ViewModel.CyLoadEffectProps) => {
  useCyLoadEffect(props);
  return <div id={props.id} data-testid="cy-load-effect" />;
};
CyLoadEffect.displayName = ViewModel.DisplayName.CY_LOAD_EFFECT;

export const CyUnloadEffect = (props: ViewModel.CyUnloadEffectProps) => {
  useCyUnloadEffect(props);
  return <div id={props.id} data-testid="cy-unload-effect" />;
};
CyUnloadEffect.displayName = ViewModel.DisplayName.CY_UNLOAD_EFFECT;

export const CyIntervalEffect = (props: ViewModel.CyIntervalEffectProps) => {
  useCyIntervalEffect(props);
  return <div id={props.id} data-testid="cy-interval-effect" />;
};
CyIntervalEffect.displayName = ViewModel.DisplayName.CY_INTERVAL_EFFECT;

export const CyReusableActionEffect = (props: ViewModel.CyReusableActionEffectProps) => {
  useCyReusableActionEffect(props);
  return <div id={props.id} data-testid="cy-reusable-action-effect" />;
};
CyReusableActionEffect.displayName = ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT;
