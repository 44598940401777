import React, {
  Context,
  createContext,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { logger, useGetElementSize, useSafeState } from '@utils';
import { GeneralModel, noop } from '@cyferd/client-engine';
import { Global } from '@emotion/react';

import { THEME } from '@constants';
import { themeMap, themeStyleMap } from '@components/styles/themes';

export type UIContextType = Context<{
  openModals: MutableRefObject<any>[];
  setOpenModals: Dispatch<SetStateAction<MutableRefObject<any>[]>>;
  state: WPLStateContext;
  setState: Dispatch<SetStateAction<WPLStateContext>>;
  runtimeTheme: THEME;
  size: GeneralModel.AppSize;
}>;

export interface WPLStateContext {
  themeKey: THEME;
  [key: string]: any;
}

export const UIContext: UIContextType = createContext({
  openModals: [],
  setOpenModals: noop as any,
  state: {} as WPLStateContext,
  setState: noop as any,
  runtimeTheme: null,
  size: null
});

export type UIProviderProps = PropsWithChildren<{
  themeStorageKey?: string;
}>;

const mediaQuery = '(prefers-color-scheme: dark)';
/* istanbul ignore next */
const getThemeBasedOnMediaQuery = (event: any) => (event?.matches ? THEME.DARK : THEME.LIGHT);
const matchMedia = window.matchMedia || noop;

interface PopoverContextValue {
  currentPopoverId: string | null;
  openPopover: (popoverId: string) => void;
  closePopover: () => void;
  optionMenu: {
    currentOptionMenuId: string | null;
    openOptionMenu: (currentOptionMenuId: string) => void;
    closeOptionMenu: () => void;
  };
}

export const PopoverContext = createContext<PopoverContextValue>({
  openPopover: noop,
  closePopover: noop,
  currentPopoverId: null,
  optionMenu: {
    currentOptionMenuId: null,
    openOptionMenu: noop,
    closeOptionMenu: noop
  }
});

export const UIProvider = ({ themeStorageKey = 'theme', children }: UIProviderProps) => {
  const [openModals, setOpenModals] = useSafeState([]);
  const [state, setState] = useState({
    themeKey: (() => {
      const saved = localStorage.getItem(themeStorageKey) as THEME;
      return Object.values(THEME).includes(saved) ? saved : THEME.OS;
    })()
  });
  const [osTheme, setOsTheme] = useSafeState(getThemeBasedOnMediaQuery(matchMedia(mediaQuery)));
  const [currentPopoverId, setPopoverOpen] = useSafeState(null);
  const [currentOptionMenuId, setOptionMenuId] = useSafeState(null);
  const runtimeTheme: THEME = useMemo(
    () => (!Object.values(THEME).includes(state?.themeKey) || state?.themeKey === THEME.OS ? osTheme : state?.themeKey),
    [osTheme, state?.themeKey]
  );
  const { ref, width } = useGetElementSize();

  /* istanbul ignore next line */
  const size: any = useMemo(() => {
    if (width <= 576 || /Mobi|Mobile/.test(navigator?.userAgent)) return GeneralModel.AppSize.S;
    if (!width || width <= 1440) return GeneralModel.AppSize.M;
    return GeneralModel.AppSize.L;
  }, [width]);

  const value = useMemo(() => ({ openModals, setOpenModals, state, setState, runtimeTheme, size }), [openModals, setOpenModals, state, runtimeTheme, size]);

  useEffect(() => {
    localStorage.setItem(themeStorageKey, state?.themeKey);
  }, [state?.themeKey, themeStorageKey]);

  /* istanbul ignore next */
  useEffect(() => {
    const modeMe = (event: any) => setOsTheme(getThemeBasedOnMediaQuery(event));
    matchMedia(mediaQuery)?.addEventListener('change', modeMe);
    return () => matchMedia(mediaQuery)?.removeEventListener('change', modeMe);
  }, [setOsTheme]);

  const closePopover = useCallback(() => {
    setPopoverOpen(null);
  }, [setPopoverOpen]);

  const openPopover = useCallback(
    (nextPopoverId: string) => {
      closePopover();
      setTimeout(() => setPopoverOpen(nextPopoverId), 50);
    },
    [setPopoverOpen, closePopover]
  );

  const closeOptionMenu = useCallback(() => {
    setOptionMenuId(null);
  }, [setOptionMenuId]);

  const openOptionMenu = useCallback(
    (nextOptionId: string) => {
      if (currentOptionMenuId) {
        closeOptionMenu();
      }
      setOptionMenuId(nextOptionId);
    },
    [currentOptionMenuId, setOptionMenuId, closeOptionMenu]
  );

  const popoverContextValue: PopoverContextValue = useMemo(
    () => ({ currentPopoverId, openPopover, closePopover, optionMenu: { closeOptionMenu, openOptionMenu, currentOptionMenuId } }),
    [closeOptionMenu, closePopover, currentOptionMenuId, currentPopoverId, openOptionMenu, openPopover]
  );

  return (
    <div ref={ref}>
      <UIContext.Provider value={value}>
        <Global styles={themeStyleMap[runtimeTheme]} />
        <PopoverContext.Provider value={popoverContextValue}>{children}</PopoverContext.Provider>
      </UIContext.Provider>
    </div>
  );
};

export const useCurrentTheme = (): GeneralModel.Color.Theme => {
  const { runtimeTheme } = useContext(UIContext);

  return themeMap[runtimeTheme];
};

export const usePopover = () => {
  const context = useContext(PopoverContext);
  /* istanbul ignore else */
  if (!context) {
    logger.warn('usePopover must be used within a PopoverProvider');
  }
  return context;
};
