import { useContext, useMemo } from 'react';

import { ApiModel, FlowModel, noop } from '@cyferd/client-engine';

import { BondEditor } from '../../../shared/BondEditor';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { EmptyLinkingPage } from '../../../../../@components/elements/EmptyLinkingPage';
import { LinkingPageDiagram, PredefinedDiagrams } from '../../../../../@components/elements/LinkingPageDiagram';

export const AppBondEditor = () => {
  const { item } = useContext<EditorContextValue<FlowModel.Flow>>(EditorContext);

  const cursor = useMemo(() => ({ associationKey: 'flowApps', relatedTo: { collectionId: ApiModel.ApiEntity.FLOW, id: item?.id } }), [item]);

  return (
    <BondEditor
      item={item}
      collectionId={ApiModel.ApiEntity.TAG}
      cursor={cursor}
      associationKey="flowApps"
      modalTitle="Unlinked apps"
      customEmptyState={
        <EmptyLinkingPage
          title="Nothing linked"
          subtitle="An app is a permission group. Only the users linked to an app can gain the access to the linked collections, flows, views and integration of that app"
          ctaLabel="Link app"
          ctaOnClick={noop}
          diagram={<LinkingPageDiagram value={PredefinedDiagrams.appLinkedFlows} />}
        />
      }
    />
  );
};
