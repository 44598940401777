import { ComponentProps } from 'react';

import { ViewModel } from '@cyferd/client-engine';

import { Adder } from '@components/elements/Adder';
import { TemplateName, templateOptions } from '../../schemas';
import { Modal } from '@components/elements/Modal';
import { TRANS } from '@constants';

export type Props = {
  onChange: (value: TemplateName) => void;
} & Pick<ComponentProps<typeof Modal>, 'open' | 'onClose'>;

export const DataTypeModal = ({ open, onChange, onClose }: Props) => {
  const onSelect = (templateName: TemplateName) => {
    onChange(templateName);
    onClose();
  };

  return (
    !!open && (
      <Modal
        open={true}
        onClose={onClose}
        title={TRANS.client.dataTypes.modal.title}
        description={TRANS.client.dataTypes.modal.description}
        icon="apps"
        type={ViewModel.ModalType.FULL_SCREEN}
      >
        <Adder options={templateOptions} onSelect={event => onSelect(event as TemplateName)} columnWidth="250px" />
      </Modal>
    )
  );
};
