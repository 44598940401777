import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';

export const styles = {
  optionList: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ${GAP.XXS};
  `,
  disabledOptionList: css`
    cursor: not-allowed;
  `,
  option: (color: GeneralModel.Color.ThemeColor, minWidth: number) => css`
    min-width: ${minWidth || 45}px;
    max-width: 200px;
    height: 28px;
    padding: ${GAP.XXS} ${GAP.M};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${GAP.XS};
    cursor: pointer;
    background-color: ${SECONDARY_COLOR.NEUTRAL_6};
    transition: all ${TRANSITION_SPEED};
    border: 1px ${COLOR.NEUTRAL_3} solid;
    border-radius: ${RADIUS.S};
    :hover {
      border-color: ${COLOR[color]};
      background-color: ${SECONDARY_COLOR[color]};
    }
  `,
  activeOption: (color: GeneralModel.Color.ThemeColor) => css`
    background-color: ${COLOR[color]}!important;
    border-color: ${COLOR[color]}!important;
  `,
  disabledOption: css`
    cursor: not-allowed;
    border-color: ${COLOR.NEUTRAL_3};
    background-color: ${COLOR.NEUTRAL_9};
    :hover {
      border-color: ${COLOR.NEUTRAL_3};
      background-color: ${COLOR.NEUTRAL_9};
    }
  `,
  optionError: css`
    border: 1px ${COLOR.HC_5} solid;
  `,
  optionLabel: css`
    font-size: ${FONT_SIZE.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `,
  activeOptionLabel: css`
    font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  `
};
