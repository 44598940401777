import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, TRANSITION_SPEED, SCROLLBAR, SECONDARY_COLOR } from '@constants';
import { GeneralModel } from '@cyferd/client-engine';

const cellBase = css`
  white-space: nowrap;

  :last-of-type {
    padding-inline-end: 0;
  }
`;
export const styles = {
  wrapper: css`
    overflow-x: auto !important;
    padding-block-end: 10px;
    display: contents;
    ${SCROLLBAR}
  `,
  table: css`
    width: 100%;
    border-collapse: collapse;
    position: relative;
    overflow: auto;
  `,
  header: css`
    position: sticky;
    top: 0;
    z-index: 1;
  `,
  row: css`
    height: 1px;
  `,
  clickableRow: css`
    transition: background-color ${TRANSITION_SPEED};
    cursor: pointer;
    :hover {
      background-color: ${COLOR.NEUTRAL_5};
    }
  `,
  lastColumnSticky: (id: string) => css`
    [data-selector='${id}']:last-of-type,
    [data-selector='${id}']:last-of-type {
      margin-right: 100px;
      position: sticky;
      right: 0;
      width: 0;
      background-color: ${COLOR.NEUTRAL_5};
      border-left: 1px solid ${COLOR.NEUTRAL_4};
    }
    [data-selector='${id}']:last-of-type {
      background-color: ${COLOR.BASE_BACKGROUND_ALT_1};
    }
  `,
  headItem: css`
    padding: ${GAP.S} ${GAP.XS};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.S};
    display: table-caption;
  `,
  cellTh: css`
    ${cellBase}
    padding: 0!important;
    margin: 0 !important;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    position: relative;
    border-bottom: 1px solid ${COLOR.NEUTRAL_4};
    font-weight: ${FONT_WEIGHT.BOLD};
    background-color: ${COLOR.NEUTRAL_5};
  `,
  cellTd: css`
    ${cellBase}
    height: inherit;
    @supports (-moz-appearance: none) {
      height: 100%; // fix needed for firefox so rows on tables don't collapse
    }
    max-width: 400px;
    vertical-align: middle;
    border-bottom: 1px solid ${COLOR.NEUTRAL_4};
  `,
  cellContainer: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
  `,
  cellContainerPadding: css`
    padding: ${GAP.XXS};
  `,
  cellContent: css`
    border-radius: ${RADIUS.S};
    color: ${COLOR.NEUTRAL_1};
    display: flex;
    align-items: center;
    overflow: hidden;
    min-width: 0;
    height: 100%;
    flex: 1;
    span {
      div,
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  `,
  cellPadding: css`
    padding: ${GAP.XS};
  `,
  firstItemContainer: (color: GeneralModel.Color.ThemeColor, hasColorBar: boolean) => css`
    display: flex;
    align-items: center;
    border-radius: ${RADIUS.XS};
    background-color: ${SECONDARY_COLOR[color]};
    max-width: 100%;
    ${hasColorBar &&
    `&:before {
      content: '';
      background-color: ${COLOR[color]};
      margin-right: ${GAP.XS};
      max-width: ${GAP.XS};
      width: ${GAP.XS};
      display: block;
      height: 16px;
      align-self: center;
      border-radius: ${RADIUS.XS};
    }`}
  `,
  recordColorTh: css`
    &:before {
      content: '';
      height: 100%;
      max-width: ${GAP.XS};
      width: ${GAP.XS};
      display: block;
    }
  `,
  rowColor: (color: string) => css`
    background-color: ${color};
  `,
  recordColor: (color: string) => css`
    position: sticky;
    left: 0;
    width: ${GAP.XS};
    border-radius: ${RADIUS.S};
    background-color: ${color || COLOR.BASE_BACKGROUND_ALT_1};
  `
};
