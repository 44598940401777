import { useCallback, useMemo, useRef, useState } from 'react';
import { GeneralModel, listToMap, prepareTermForReg } from '@cyferd/client-engine';
import { DropdownOption } from '../DropdownOption';
import { InputWrapper } from '../InputWrapper';
import { styles } from './styles';
import { Chip } from '../Chip';
import { SearchInput } from '../SearchInput';
import { useDropdownDimensions, getElementMenuPosition } from '@utils';
import { useOutsideClick } from '@utils/useOutsideClick';
import { CTA, CTAType } from '../CTA';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { IconImage } from '../Icon/renderIcon';
import { Checkbox } from '../Checkbox';
import { OptionMenuProps } from '../OptionMenu';
import { COLOR } from '@constants';
import { Icon } from '../Icon';

export interface IMultiOptionDropdown {
  optionList?: GeneralModel.JSONSchemaMetadata['optionList'];
  label?: string;
  testid?: string;
  value?: (string | number)[];
  disabled?: boolean;
  required?: boolean;
  onChange: (value: (string | number)[]) => void;
  description?: string;
  errorMessage?: string;
  info?: string;
  menuOptionList?: OptionMenuProps['optionList'];
  disabledType?: GeneralModel.DisabledType;
}

interface IMultiOptionDropdownItems {
  testid?: string;
  value?: (string | number)[];
  optionList: GeneralModel.JSONSchemaMetadata['optionList'];
  onSelect: (id: string) => void;
  onSelectAll: () => void;
  selectedOptions?: (string | number)[];
  mainRef: React.MutableRefObject<HTMLDivElement>;
}

const MultiOptionDropdownItems = ({ value: inputValue, testid, optionList, onSelect, onSelectAll, selectedOptions, mainRef }: IMultiOptionDropdownItems) => {
  const [search, setSearch] = useState('');
  const { dropdownRef, triggerDimensions, menuDimensions, clientDimensions } = useDropdownDimensions(mainRef);
  const {
    shouldRenderBottom,
    style: { top }
  } = getElementMenuPosition({ triggerDimensions, menuDimensions, clientDimensions, verticalPadding: 2 });

  const filteredList = useMemo(() => {
    if (search) {
      const regex = new RegExp(prepareTermForReg(search), 'i');
      return optionList.filter(({ value, label }) => regex.test(String(value)) || regex.test(String(label)));
    }
    return optionList;
  }, [optionList, search]);

  /* istanbul ignore next line */
  const containerStyle = useMemo(() => ({ width: triggerDimensions.width, top }), [top, triggerDimensions.width]);

  return (
    <div data-testid={`${testid}-results`} css={styles.listContainer} ref={dropdownRef} style={containerStyle}>
      <div css={styles.overwrite({ order: shouldRenderBottom ? /* istanbul ignore next */ 0 : 1 })}>
        <SearchInput searchDelay={0} autoFocus={true} value={search} onChange={setSearch} />
      </div>
      <div css={[styles.options, shouldRenderBottom ? /* istanbul ignore next */ styles.borderTop : styles.borderBottom]}>
        {!!filteredList && (
          <div css={[styles.item, styles.notHover]}>
            <div style={{ marginLeft: 'auto' }}>
              <CTA
                disabled={filteredList.length === selectedOptions?.length}
                onClick={onSelectAll}
                color="BRAND_1"
                type={CTAType.LINK}
                icon={{ name: 'add_box', position: 'end' }}
                testid="select-all"
              >
                Select all
              </CTA>
            </div>
          </div>
        )}
        {filteredList?.map(({ value, image, label, color }, index) => {
          const selected = inputValue?.includes(value);
          return (
            <div
              key={`${value}-${index}`}
              css={[styles.item, selected && styles.itemSelected, !!COLOR[color] && styles.itemWithColor]}
              data-testid={`${testid}-item-${index}`}
              onClick={() => onSelect(String(value))}
            >
              <div css={styles.colorBar} style={{ backgroundColor: COLOR[color] }}></div>
              <div data-testid="option" css={styles.itemChildren}>
                <PreventClickPropagation>
                  <Checkbox
                    id={String(value)}
                    color={(selected ? color || /* istanbul ignore next */ 'BRAND_1' : 'NEUTRAL_3') as GeneralModel.Color.ThemeColor}
                    name=""
                    testid={`${testid}-${index}`}
                    onChange={() => onSelect(String(value))}
                    label=""
                    value={selectedOptions?.includes(value)}
                  />
                </PreventClickPropagation>
                {!!label && <div>{label}</div>}
              </div>
              {!!image && (
                <IconImage
                  icon={image}
                  title={String(value)}
                  iconProps={{ size: '20px', fill: COLOR.NEUTRAL_1 }}
                  imageProps={{ css: { width: 20, height: 20, objectFit: 'cover', borderRadius: '100%' } }}
                />
              )}
            </div>
          );
        })}
      </div>
      {!filteredList.length && <DropdownOption title="No results found" testid={`${testid}-empty`} />}
    </div>
  );
};

export const MultiOptionDropdown = ({
  testid = 'MultiOptionDropdown-id',
  optionList = [],
  value,
  label,
  disabled,
  onChange,
  info,
  errorMessage,
  description,
  required,
  menuOptionList,
  disabledType
}: IMultiOptionDropdown) => {
  const [isOpen, setOpen] = useState(false);
  const mainRef = useRef<HTMLDivElement>();
  const safeValue = useMemo(() => (Array.isArray(value) ? value : []), [value]);
  const optionMap = useMemo(() => listToMap(optionList, {}, 'value'), [optionList]);

  const onEdit = useCallback(() => {
    /* istanbul ignore else */
    if (!disabled) setOpen(true);
  }, [disabled]);

  const onClickOutside = useCallback(() => setOpen(false), []);

  const onSelect = useCallback(
    (v: string | number) => {
      const hasValue = safeValue.includes(v);
      if (hasValue) return onChange(safeValue.filter(item => v !== item));
      return onChange([...safeValue, v]);
    },
    [onChange, safeValue]
  );

  const outsideRef: React.LegacyRef<HTMLDivElement> = useOutsideClick(onClickOutside);

  const onClear = () => onChange([]);
  const onSelectAll = () => onChange([...new Set([...safeValue, ...optionList.map(o => o.value)])]);

  return (
    <div ref={outsideRef}>
      <div onClick={onEdit} data-testid={testid} css={styles.mainContainer}>
        <InputWrapper
          testid={testid}
          disabled={!!disabled}
          disabledType={disabledType}
          required={required}
          optionList={menuOptionList}
          description={description}
          label={label}
          info={info}
          errorMessage={errorMessage}
          value={value?.join?.('-')}
          showPlaceholderLine={true}
          onClear={onClear}
          inputContainerRef={mainRef}
        >
          {!!safeValue.length ? (
            <div css={styles.inputValue}>
              <div css={styles.chipsContainer}>
                {safeValue.map((selectedValue, index) => {
                  const item = optionMap[selectedValue] || { value: selectedValue, label: String(selectedValue) };
                  return (
                    <div css={styles.chipContainer} key={`${item.value}-${index}`}>
                      <PreventClickPropagation>
                        <Chip
                          title={item.label}
                          color={(item.color || 'BRAND_1') as GeneralModel.Color.ThemeColor}
                          icon={item.image as GeneralModel.IconName}
                          id={String(item.value)}
                          disabled={disabled}
                          showCancel={false}
                          active={true}
                          compact={true}
                          onClick={() => onSelect(item?.value)}
                        />
                      </PreventClickPropagation>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div css={styles.expandIconContainer}>{!disabled && <Icon name="keyboard_arrow_down" size="14px" />}</div>
          )}
        </InputWrapper>

        {!!isOpen && (
          <MultiOptionDropdownItems
            value={safeValue}
            onSelectAll={onSelectAll}
            onSelect={onSelect}
            testid={testid}
            selectedOptions={value}
            optionList={optionList}
            mainRef={mainRef}
          />
        )}
      </div>
    </div>
  );
};

MultiOptionDropdown.displayName = 'MultiOptionDropdown';
