import { BaseFieldProps, GeneralModel, SchemaFormBaseProps } from '@cyferd/client-engine';
import { useCallback } from 'react';
import { getLabel } from '@utils';
import { FormulaInputRow, shouldAllowFormula } from '@components/elements/Evaluator/resources';
import { MenuOption } from '@components/elements/OptionMenu';
import { Checkbox } from '@components/elements/Checkbox';
import { Switch } from '@components/elements/Switch';
import { EvaluatorWrapper } from '@components/elements/Evaluator/EvaluatorWrapper';
import { InputWrapper } from '@components/elements/InputWrapper';

export const useRenderBoolean = ({
  getIds,
  allowFormula,
  inputList = [],
  getOptionMenu,
  getErrorMessage
}: {
  getIds: Function;
  allowFormula: boolean;
  inputList: FormulaInputRow[];
  getOptionMenu: (event: BaseFieldProps) => MenuOption[];
  getErrorMessage: (hasError: boolean, error: string) => string;
}): SchemaFormBaseProps['componentRecord']['renderBoolean'] =>
  useCallback(
    props => {
      const { id, displayNamePath, value, format, color, description, disabled, onChange, hasError, error, schema, disabledType, required } = props;

      const actualInputList = [...(schema.metadata?.inputList || []), ...(inputList || [])];
      return (
        <EvaluatorWrapper
          label={getLabel(displayNamePath)}
          allowFormula={shouldAllowFormula(allowFormula, schema.metadata?.allowFormula)}
          value={value}
          disabled={disabled}
          description={description}
          required={required}
          onChange={onChange}
          openModalOnFocus={schema?.metadata?.openModalOnFocus}
          format={format}
          inputList={actualInputList}
          info={schema?.info}
          color={color}
          disabledType={disabledType || schema?.metadata?.disabledType}
          optionList={getOptionMenu(props)}
        >
          {fixedProps => (
            <InputWrapper unframed={true}>
              {format === GeneralModel.JSONSchemaFormat.SWITCH ? (
                <Switch
                  {...getIds(id)}
                  value={fixedProps.value}
                  optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                  onChange={fixedProps.onChange}
                  name={getLabel(displayNamePath)}
                  label={getLabel(displayNamePath)}
                  description={description}
                  color={color}
                  disabled={disabled}
                  errorMessage={getErrorMessage(hasError, error)}
                  info={schema.info}
                />
              ) : (
                <Checkbox
                  {...getIds(id)}
                  value={fixedProps.value}
                  optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
                  onChange={fixedProps.onChange}
                  name={getLabel(displayNamePath)}
                  label={getLabel(displayNamePath)}
                  description={description}
                  color={color}
                  disabled={disabled}
                  errorMessage={getErrorMessage(hasError, error)}
                  info={schema.info}
                />
              )}
            </InputWrapper>
          )}
        </EvaluatorWrapper>
      );
    },
    [allowFormula, getErrorMessage, getIds, getOptionMenu, inputList]
  );
