import { ReactNode } from 'react';

import { styles } from './styles';

export interface TextProps {
  children: ReactNode;
  testid?: string;
}

// paragraph
// split on line break
// wrap in <p>
export const Text = ({ children, testid }: TextProps) => {
  return (
    <p css={styles.text} data-testid={testid || 'text'}>
      {children}
    </p>
  );
};

Text.displayName = 'Text';
