// istanbul ignore file
import { useCallback, useReducer, useMemo } from 'react';
import { getFormErrors } from '@components/smart/CyForm/getFormErrors';
import { ApiModel, DeepDiff, GeneralModel, ViewModel, applyMask, createUUID, getDeepDiff, mergeTruthy, ofType, tapOnSuccess } from '@cyferd/client-engine';
import { useParsers } from '@utils';
import { catchError, tap, EMPTY } from 'rxjs';
import { actions as uiActions } from '../../../../client-app/state-mgmt/ui/actions';
import { ToastStatus } from '@components/elements/Toast';
import { useDispatch } from 'react-redux';
import { finalize, Observable } from 'rxjs';
import { Payload, PayloadSchema } from '../types';
import useCyActions from '@utils/useCyActions';

type UseTableEditMode = (
  params: ViewModel.CyTableProps['editMode'] & {
    data: ViewModel.CyTableProps['data'];
    editMode: ViewModel.CyTableProps['editMode'];
    value: ViewModel.CyTableProps['value'];
    fetch?: () => Observable<ApiModel.APIAction>;
    componentNameList?: string[];
  }
) => {
  isEditing: boolean;
  completeValues: Record<string, any>;
  itemSchemas: Record<string, any>;
  errors: { [itemId: string]: { [definitionId: string]: string | string[] } };
  toggleEditing: () => void;
  handleEditChange: (e: any, itemId: string, definitionId: string, item?: any) => void;
  handleSave: () => void;
  changeCount: number;
  diffs: DeepDiff[];
  conflictingValues: undefined | Payload;
  onOverrideItems: (list: any[]) => void;
  handleCancel: () => void;
  handleUpdateValue: (value: any) => void;
  handleGoBackToEdit: () => void;
};
interface IEditValues {
  internalValues: Record<string, any>;
  internalItemSchemas: Record<string, any>;
  isEditing: boolean;
  conflictingValues: undefined | Payload;
}

const initialEditValues: IEditValues = {
  internalValues: {},
  internalItemSchemas: {},
  isEditing: false,
  conflictingValues: undefined
};

const editReducer = (state: IEditValues, action: any): IEditValues => {
  switch (action.type) {
    case 'TOGGLE_EDITING':
      return { ...state, isEditing: !state.isEditing };
    case 'SET_EDITED_VALUE':
      return { ...state, ...action.payload };
    case 'SET_CONFLICTING_VALUES':
      return { ...state, conflictingValues: action.payload };
    case 'RESET_EDITED_VALUE':
      return initialEditValues;
    default:
      return state;
  }
};

export const useTableEditMode: UseTableEditMode = ({ data, editMode: { enabled, onSaveType, collectionId, flowId }, value, fetch, componentNameList }) => {
  const [state, dispatchLocal] = useReducer(editReducer, initialEditValues);
  const dispatch = useDispatch();
  const { onFlowRun, onDispatchRefresh, onDataUpdateBulk } = useCyActions();
  const { parseData, parseRecord, parseSchemaProperty } = useParsers(value?.query);

  const { isEditing, conflictingValues, internalValues, internalItemSchemas } = state;
  const list = Object.values(internalValues);
  const parseRecordValue = useCallback((record: any) => parseRecord({ value: record, entity: value?.query }), [value?.query, parseRecord]);
  const parseRecordProperties = useCallback(
    (record: any) => getRecordParsedProperties({ record, schemaProperties: value?.query?.schema?.properties, fullItem: record, parseSchemaProperty }),
    [value?.query?.schema?.properties, parseSchemaProperty]
  );

  const dataSourceValuesObj = useMemo(() => value?.list?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}), [value?.list]);
  const dataSourceValuesSchemas = useMemo(() => Object.values(dataSourceValuesObj || {}).reduce((acc, item) => ({ ...acc, [item.id]: parseRecordProperties(item) }), {}), [dataSourceValuesObj, parseRecordProperties]);

  const completeValues = useMemo(() => ({ ...dataSourceValuesObj, ...internalValues }), [dataSourceValuesObj, internalValues]);
  const completeSchemas = useMemo(
    () => mergeTruthy(dataSourceValuesSchemas, internalItemSchemas),
    [dataSourceValuesSchemas, internalItemSchemas]
  );

  const diffs = useMemo(() => getDeepDiff(completeValues, dataSourceValuesObj), [completeValues, dataSourceValuesObj]);
  const changeCount = useMemo(() => diffs.length, [diffs]);

  const handleCancel = useCallback(() => {
    dispatchLocal({ type: 'RESET_EDITED_VALUE' });
    onDispatchRefresh({ componentNameList });
  }, [componentNameList, onDispatchRefresh]);

  const handleStartEditing = useCallback(() => {
    (fetch?.() || EMPTY).pipe(finalize(() => dispatchLocal({ type: 'TOGGLE_EDITING' }))).subscribe();
  }, [fetch]);

  const handleGoBackToEdit = useCallback(() => {
    dispatchLocal({ type: 'SET_CONFLICTING_VALUES', payload: undefined });
  }, []);

  const handleUpdateValue = useCallback(
    (value: any) => {
      const { values, schemas } = Object.keys(value).reduce(
        (acc, itemId) => {
          const completeItem = { ...completeValues[itemId], ...value[itemId] };
          return {
            values: {
              ...acc.values,
              [itemId]: parseRecordValue(completeItem).output
            },
            schemas: {
              ...acc.schemas,
              [itemId]: parseRecordProperties(completeItem)
            }
          };
        },
        { values: {}, schemas: {} }
      );

      dispatchLocal({
        type: 'SET_EDITED_VALUE',
        payload: {
          internalValues: mergeTruthy(internalValues, values, arg => arg !== undefined),
          internalItemSchemas: schemas
        }
      });
    },
    [dispatchLocal, completeValues, internalValues, parseRecordValue, parseRecordProperties]
  );

  const handleEditChange = useCallback(
    (newValue: any, itemId: string, definitionId: string) => {
      handleUpdateValue({ [itemId]: { [definitionId]: newValue } });
    },
    [handleUpdateValue]
  );

  const handleSuccess = useCallback(() => {
    onDispatchRefresh({ componentNameList });
    dispatch(
      uiActions.addToast({
        id: createUUID(),
        status: ToastStatus.SUCCESS,
        title: `${changeCount}${changeCount === 1 ? ' record' : ' records'} saved`
      })
    );
    dispatchLocal({ type: 'RESET_EDITED_VALUE' });
  }, [changeCount, dispatch, onDispatchRefresh, componentNameList]);

  const handleFail = useCallback(
    (response: Payload) => {
      if (response.summary.successful)
        dispatch(
          uiActions.addToast({
            id: createUUID(),
            status: ToastStatus.SUCCESS,
            title: `${response.summary.successful}${response.summary.successful === 1 ? ' record' : ' records'} saved`
          })
        );
      dispatchLocal({ type: 'SET_CONFLICTING_VALUES', payload: response });
    },
    [dispatch]
  );

  const handleSave = useCallback(() => {
    const saveObservable =
      onSaveType === ViewModel.CyTableOnSaveType.DEFAULT
        ? onDataUpdateBulk({ collectionId: data.collectionId, list, options: { reset: true } })
        : onSaveType === ViewModel.CyTableOnSaveType.COLLECTION
          ? onDataUpdateBulk({ collectionId, list, options: { reset: true } })
          : onFlowRun({ id: flowId, payload: { list } });

    saveObservable
      .pipe(
        ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
        tap(response => {
          const parsedResponse = PayloadSchema.safeParse(response);

          if ([ViewModel.CyTableOnSaveType.COLLECTION, ViewModel.CyTableOnSaveType.DEFAULT].includes(onSaveType)) {
            if (response.success) return handleSuccess();
            return handleFail(parsedResponse.data);
          }

          if (onSaveType === ViewModel.CyTableOnSaveType.FLOW) {
            if (parsedResponse.success) {
              if (parsedResponse.data.success) return handleSuccess();
              return handleFail(parsedResponse.data);
            }

            handleCancel();
            dispatch(
              uiActions.addToast({
                id: createUUID(),
                status: ToastStatus.SUCCESS,
                title: `Success`
              })
            );
          }
        }),
        catchError(error => {
          dispatch(
            uiActions.addToast({
              id: createUUID(),
              status: ToastStatus.ERROR,
              title: error?.payload?.error?.details?.description || 'Oh snap!',
              message: error?.payload?.error?.details?.message
            })
          );
          return EMPTY;
        })
      )
      .subscribe();
  }, [list, onSaveType, data.collectionId, collectionId, flowId, handleCancel, handleSuccess, handleFail, dispatch, onDataUpdateBulk, onFlowRun]);

  const onOverrideItems = useCallback(
    (list: any[]) => {
      if (conflictingValues && [ViewModel.CyTableOnSaveType.DEFAULT, ViewModel.CyTableOnSaveType.COLLECTION].includes(onSaveType)) {
        onDataUpdateBulk({
          collectionId: onSaveType === ViewModel.CyTableOnSaveType.DEFAULT ? data.collectionId : collectionId,
          list,
          options: { reset: true, force: true }
        })
          .pipe(
            ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
            tapOnSuccess(() => {
              handleSuccess();
            })
          )
          .subscribe();
      }
    },
    [conflictingValues, onSaveType, data.collectionId, collectionId, handleSuccess, onDataUpdateBulk]
  );

  const toggleEditing = useCallback(() => {
    return isEditing ? handleCancel() : handleStartEditing();
  }, [handleStartEditing, handleCancel, isEditing]);

  const errors = useMemo(
    () =>
      Object.keys(internalValues).reduce((acc, itemId) => {
        const { formErrors } = getFormErrors({
          model: value?.query,
          value: { record: internalValues[itemId], query: value?.query, totalCount: 1 },
          parseData,
          shouldValidate: true,
          currentStep: { id: 'none' }
        });
        return { ...acc, [itemId]: formErrors };
      }, {}),
    [internalValues, value?.query, parseData]
  );

  return {
    isEditing: enabled ? isEditing : false,
    itemSchemas: completeSchemas,
    completeValues,
    changeCount,
    conflictingValues,
    errors,
    diffs,
    onOverrideItems,
    toggleEditing,
    handleEditChange,
    handleSave,
    handleCancel,
    handleGoBackToEdit,
    handleUpdateValue
  };
};

const getRecordParsedProperties = ({ record, schemaProperties, fullItem, parseSchemaProperty }) => {
  return Object.keys(schemaProperties).reduce((acc, definitionId) => {
    return {
      ...acc,
      [definitionId]: getPropertyBaseProps({
        value: record[definitionId],
        schema: schemaProperties[definitionId],
        fullItem: fullItem,
        id: definitionId,
        parseSchemaProperty
      })
    };
  }, {});
};

// @todo: types
const getPropertyBaseProps = ({ value, schema, fullItem, id, parseSchemaProperty }) => {
  if (!schema) return { value };
  const evaluate = (formula: GeneralModel.EvaluatorFormula) => parseSchemaProperty(formula, { value, definition: schema, fullItem, path: id });

  const { metadata, format, type } = schema;

  const disabled: boolean = !!(metadata?.disabled === true || evaluate(metadata?.disabled) || !!metadata?.calculation);
  const disabledType = metadata?.disabledType;
  const color = evaluate(metadata?.color);
  const secondaryColor = evaluate(metadata?.secondaryColor);
  const icon = evaluate(metadata?.icon);
  const fieldValue = !metadata?.calculation ? value : applyMask(value, metadata?.mask);
  const maskedValue = !metadata?.calculation ? applyMask(value, metadata?.mask) : value;
  const propertyDisplayName = schema.label || schema.title;
  const displayNamePath = [[null, undefined, GeneralModel.JSONSchemaFormat.OBJECT].includes(format) && propertyDisplayName].filter(Boolean);

  return {
    id,
    disabled,
    disabledType,
    type,
    value: fieldValue,
    maskedValue,
    color,
    secondaryColor,
    icon,
    propertyDisplayName,
    displayNamePath,
    schema,
    format
  };
};
