import { FC, useState } from 'react';

import { ApiModel, createUUID, GeneralModel, ViewModel } from '@cyferd/client-engine';

import packageJson from '../../../../../package.json';
import { actions as uiActions } from '../../../../client-app/state-mgmt/ui/actions';
import { ApiPlayground } from '../DevView/components/ApiPlayground';
import { EvaluatorPlayground } from '../DevView/components/EvaluatorPlayground';
import { useDispatch } from 'react-redux';
import { ToastStatus } from '@components/elements/Toast';
import { Modal } from '@components/elements/Modal';
import { Layout } from '@components/elements/Layout';

export type Props = {
  onClose: () => void;
  open: boolean;
};

const Playground: FC<Props> = ({ onClose, open }) => {
  const dispatch = useDispatch();

  const [apiValue, setApiValue] = useState<ApiModel.APIAction>();
  const [evaluation, setEvaluation] = useState<GeneralModel.EvaluatorFormula>();

  const onCopyToClipboard = (item: any) => {
    navigator?.clipboard?.writeText?.(JSON.stringify(item, null, 4));
    dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.SUCCESS, title: `Copied` }));
  };

  const tabList: ViewModel.CyLayoutProps['tabList'] = [
    { title: `Evaluator playground (v${packageJson.dependencies['@cyferd/evaluator'].replace('^', '')})`, icon: 'terminal' },
    { title: 'API playground', icon: 'signal_cellular_alt' }
  ];

  const defaultTab = tabList[0].title;
  const [activeTab, setActiveTab] = useState(defaultTab);

  return (
    <Modal open={open} type={ViewModel.ModalType.FULL_SCREEN}>
      <div data-testid="playground-container">
        {/** editor */}
        <Layout type={ViewModel.LayoutType.TAB} tabList={tabList} activeTab={activeTab} onChangeTab={setActiveTab as any}>
          {/** evaluator playground */}
          <EvaluatorPlayground value={evaluation} onChange={setEvaluation} onClose={onClose} onCopyToClipboard={onCopyToClipboard} />
          {/** API playground */}
          <ApiPlayground value={apiValue} onChange={setApiValue} onClose={onClose} onCopyToClipboard={onCopyToClipboard} />
        </Layout>
      </div>
    </Modal>
  );
};

export { Playground };
