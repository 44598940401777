import { memo, useMemo } from 'react';

import { getStyles } from './styles';
import { useTestingHelper } from '@utils';
import { GeneralModel } from '@cyferd/client-engine';

export interface ProgressBarProps {
  color?: GeneralModel.Color.ThemeColor;
  size?: number;
  alt?: boolean;
}

export const ProgressBar = memo(({ color = 'BRAND_1', size = 7, alt }: ProgressBarProps) => {
  const { getTestIdProps } = useTestingHelper('progress-bar');
  const styles = useMemo(() => getStyles(color, size), [color, size]);

  if (alt) {
    return (
      <div {...getTestIdProps('container')} css={styles.containerAlt}>
        <div css={styles.barAlt} />
      </div>
    );
  }

  return (
    <div {...getTestIdProps('container')} css={styles.container}>
      <div css={styles.bar} />
    </div>
  );
});
