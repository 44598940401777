import { Modal } from '@components/elements/Modal';

import { styles } from './styles';
import { CTA, CTAType } from '@components/elements/CTA';
import { Spinner } from '@components/elements/Spinner';
import { ItemList } from '@components/elements/ItemList';
import { KeyValueData, KeyValueEditor } from '@components/elements/KeyValueEditor';
import { TRANS } from '@constants';

export type Props = {
  open?: boolean;
  loading?: boolean;
  saving?: boolean;
  onSave: () => void;
  onClose: () => void;
  onChange: (value: KeyValueData) => void;
  value: KeyValueData;
};

export const UserKeyValuesModal = ({ onSave, loading, saving, open, onClose, value, onChange }: Props) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="User access variables"
      footer={
        !loading && (
          <ItemList>
            <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={onClose} testid="cancel-button" disabled={loading || saving} />
            <CTA type={CTAType.PRIMARY} label={TRANS.client.buttons.save} onClick={onSave} testid="save-button" disabled={loading || saving} />
          </ItemList>
        )
      }
    >
      {loading ? (
        <div data-testid="user-key-value-loading" className={styles.content.loadingContainer}>
          <Spinner />
        </div>
      ) : (
        <KeyValueEditor value={value} onChange={onChange} disabled={saving} />
      )}
    </Modal>
  );
};
