import {
  CalendarAddOption,
  CalendarConfigList,
  CollectionModel,
  ErrorBoundary,
  GeneralModel,
  ParsedList,
  ViewModel,
  getCalendarConfigFromKey,
  getCalendarKey,
  getParsedActionChildren,
  getSafeType,
  getTimeFilter,
  normalize,
  ofTypeSetData,
  registry,
  swallowError,
  useFinalizeWhileMounted,
  useUnmountObservable
} from '@cyferd/client-engine';
import { styles } from './styles';
import { BBContainer } from '@components/elements/BBContainer';
import { CalendarControl } from '@components/elements/CalendarControl';
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { CalendarWeek } from '@components/elements/CalendarWeek';
import { CalendarSidePanel } from '@components/elements/CalendarSidePanel';
import { CalendarTitle } from '@components/elements/CalendarTitle';
import { CalendarList } from '@components/elements/CalendarList';
import { COLOR, ENV, TRANS } from '@constants';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CyWrapperContext } from '../CyWrapper';
import { getValidDayjs } from '@utils/getValidDayjs';
import dayjs from 'dayjs';
import { ProgressBar } from '@components/elements/ProgressBar';
import { EMPTY, Subject, debounceTime, mergeMap, takeUntil, tap, zip } from 'rxjs';
import { CalendarMonth } from '@components/elements/CalendarMonth';
import { SearchInput } from '@components/elements/SearchInput';
import { useGetElementSize } from '@utils';

const LIMIT = 100;
export const MIN_WIDTH_FULL_CALENDAR = 600;
const getCalendarLabel = (label: string, index: number): string => label?.trim?.() || `Calendar ${index + 1}`;

export const CyCalendar = ({
  id,
  componentName,
  referenceDate,
  calendarList,
  firstVisibleHour,
  type,
  actionListChildren,
  headerListChildren,
  fitToPage,
  effectChildren,
  onCellAction
}: ViewModel.CyCalendarProps) => {
  const { useAction, useParsers, useOnRefresh } = useContext(CyWrapperContext);
  const { ref, width } = useGetElementSize();
  const showSmallCalendar = width < MIN_WIDTH_FULL_CALENDAR;
  const safeReferenceDate = useMemo(() => getValidDayjs(dayjs(referenceDate)).toISOString(), [referenceDate]);
  const [month, setMonth] = useState<GeneralModel.DateLike>(safeReferenceDate);
  const [activeDay, setActiveDay] = useState<GeneralModel.DateLike>(null);
  const [focusDay, setFocusDay] = useState<GeneralModel.DateLike>(safeReferenceDate);
  const safeCalendarList = useMemo(() => (Array.isArray(calendarList) ? calendarList.filter(o => !!o?.collectionId && !!o?.fromField) : []), [calendarList]);
  const [activeCollections, setActiveCollections] = useState<string[]>(safeCalendarList.filter(o => !!o.startsSelected).map(o => getCalendarKey(o)));
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [resultMap, setResultMap] = useState<Record<string, ParsedList['items']>>({});
  const [calendarCollectionMap, setCalendarCollectionMap] = useState<Record<string, CollectionModel.Collection>>({});
  const [calendarType, setCalendarType] = useState<ViewModel.CyCalendarType>(getSafeType(type));
  const { parseList } = useParsers();
  const onList = useAction('coreList');
  const onDestroy$ = useUnmountObservable();
  const finalize = useFinalizeWhileMounted();

  const collectionOptionList = useMemo(
    () => safeCalendarList.map((o, i) => ({ label: getCalendarLabel(o.label, i), value: getCalendarKey(o), image: o.image, color: o.color || 'BRAND_1' })),
    [safeCalendarList]
  );

  const addOptionList: CalendarAddOption[] = useMemo(
    () =>
      safeCalendarList.map((o, i) => ({
        label: getCalendarLabel(o.label, i),
        collectionId: o.collectionId,
        color: o.color || 'BRAND_1',
        fromField: o.fromField,
        fromFieldFormat: calendarCollectionMap[getCalendarKey(o)]?.schema?.properties?.[o.fromField]?.format
      })),
    [calendarCollectionMap, safeCalendarList]
  );

  const calendarConfigList: CalendarConfigList[] = useMemo(
    () =>
      safeCalendarList
        .map(o => {
          if (!resultMap[getCalendarKey(o)] || !activeCollections.includes(getCalendarKey(o))) return null;
          return {
            items: resultMap[getCalendarKey(o)],
            color: (COLOR[o.color] ? o.color : 'BRAND_1') as GeneralModel.Color.ThemeColor,
            fromField: o.fromField,
            fromFieldFormat: calendarCollectionMap[getCalendarKey(o)]?.schema?.properties?.[o.fromField]?.format,
            toField: o.toField,
            toFieldFormat: calendarCollectionMap[getCalendarKey(o)]?.schema?.properties?.[o.toField]?.format
          };
        })
        .filter(Boolean),
    [activeCollections, calendarCollectionMap, resultMap, safeCalendarList]
  );

  const parsedHeaderListChildren = useMemo(
    () => getParsedActionChildren(headerListChildren)?.map(o => ({ ...o, image: o?.icon, size: ViewModel.CTASize.SMALL, disabled: loading || o?.disabled })),
    [headerListChildren, loading]
  );

  const onListSingleCollection = useCallback(
    ({
      key,
      collectionId,
      fromField,
      toField,
      filter,
      fixedFilter
    }: {
      key: string;
      collectionId: string;
      fromField: string;
      toField?: string;
      filter?: GeneralModel.FetchCriteria;
      fixedFilter?: GeneralModel.FetchCriteria;
    }) => {
      const storedCollectionCursor = (calendarCollectionMap[key] as any)?.cursor as GeneralModel.FetchCriteria;
      const completeFixedFilter = { $and: [getTimeFilter({ fromField, toField, calendarType, focusDay }), fixedFilter].filter(Boolean) };
      return onList({
        pointer: GeneralModel.IGNORED_POINTER_ID,
        query: {
          cursor: {
            ...storedCollectionCursor,
            collectionId,
            searchString: search,
            options: { limit: LIMIT, orderBy: fromField, descending: false },
            fixedFilter: completeFixedFilter,
            filter: filter ?? storedCollectionCursor?.filter
          }
        }
      }).pipe(
        takeUntil(onDestroy$),
        ofTypeSetData(),
        tap(response => {
          const collection = normalize.collection(response?.query, { validDetailGroupIdList: response?.query?.detailGroupList });
          setCalendarCollectionMap(prev => ({ ...prev, [key]: collection }));
          setResultMap(prev => ({ ...prev, [key]: parseList({ entity: collection, list: response?.list }).items }));
        }),
        swallowError()
      );
    },
    [calendarCollectionMap, calendarType, focusDay, onList, search, onDestroy$, parseList]
  );

  const onFetch = useCallback(() => {
    setLoading(true);
    return zip(
      safeCalendarList
        .filter(o => activeCollections.includes(getCalendarKey(o)))
        .map(o =>
          onListSingleCollection({
            key: getCalendarKey(o),
            collectionId: o.collectionId,
            fromField: o.fromField,
            toField: o.toField,
            fixedFilter: o.fixedFilter
          })
        )
    ).pipe(
      mergeMap(() => EMPTY),
      finalize(() => setLoading(false))
    );
  }, [activeCollections, finalize, onListSingleCollection, safeCalendarList]);

  const onRunFetch = useCallback(() => {
    onFetch().subscribe();
  }, [onFetch]);

  const onRunFetchRef = useRef(onRunFetch);
  onRunFetchRef.current = onRunFetch;
  const onDebouncedFetch$ = useRef(new Subject<void>());

  const onInternalChangeFocusDay = useCallback((event: GeneralModel.DateLike) => {
    setFocusDay(event);
    onDebouncedFetch$.current.next();
  }, []);

  const onChangeFocusDay = useCallback(
    (event: GeneralModel.DateLike) => {
      setMonth(event);
      onInternalChangeFocusDay(event);
      setActiveDay(null);
    },
    [onInternalChangeFocusDay]
  );

  const onChangeActiveDay = useCallback(
    (event: GeneralModel.DateLike) => {
      setMonth(event);
      onInternalChangeFocusDay(event);
      setActiveDay(activeDay === event ? null : event);
    },
    [activeDay, onInternalChangeFocusDay]
  );

  const onCloseActiveDay = useCallback(() => setActiveDay(null), []);

  const onChangeCollectionCursor = useCallback(
    (key: string, filter: GeneralModel.FetchCriteria['filter']) => {
      const calendarConfig = getCalendarConfigFromKey(key, safeCalendarList);
      return onListSingleCollection({
        key,
        collectionId: calendarConfig?.collectionId,
        fromField: calendarConfig?.fromField,
        toField: calendarConfig?.toField,
        filter,
        fixedFilter: calendarConfig?.fixedFilter
      });
    },
    [onListSingleCollection, safeCalendarList]
  );

  const onChangeActiveCollection = useCallback(
    (event: string) => {
      const isAdding = !activeCollections.includes(event);
      setActiveCollections(isAdding ? [...activeCollections, event] : activeCollections.filter(k => k !== event));
      if (isAdding) {
        const calendarConfig = getCalendarConfigFromKey(event, safeCalendarList);
        onListSingleCollection({
          key: event,
          collectionId: calendarConfig.collectionId,
          fromField: calendarConfig.fromField,
          toField: calendarConfig.toField,
          fixedFilter: calendarConfig.fixedFilter
        }).subscribe();
      }
    },
    [activeCollections, onListSingleCollection, safeCalendarList]
  );

  const onChangeType = useCallback((event: ViewModel.CyCalendarType) => {
    setCalendarType(event);
    onDebouncedFetch$.current.next();
  }, []);

  /** debounced fetch subs */
  useEffect(() => {
    const subscription = onDebouncedFetch$.current
      .pipe(
        debounceTime(ENV.INPUT_DEBOUNCE_TIME),
        tap(() => onRunFetchRef.current())
      )
      .subscribe();
    return () => subscription.unsubscribe();
  }, []);

  /** mount fetch */
  useEffect(() => {
    onRunFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnRefresh({ id, componentName }, onFetch);

  useEffect(() => {
    registry.updateProgItem({
      instanceId: 'unknown',
      node: { id, name: componentName, component: ViewModel.DisplayName.CY_CALENDAR, attrs: {}, contains: [] },
      state: { calendarCollectionMap, resultMap }
    });
  }, [calendarCollectionMap, componentName, id, resultMap]);

  useEffect(() => {
    setCalendarType(getSafeType(type));
  }, [type]);

  const renderHeader = () => {
    return (
      <header css={styles.header}>
        <ErrorBoundary>
          <CalendarTitle
            calendarType={calendarType}
            disabled={loading}
            currentDate={focusDay}
            referenceDate={safeReferenceDate}
            onChangeCalendarType={onChangeType}
            onChange={onChangeFocusDay}
            isMobile={showSmallCalendar}
          />
        </ErrorBoundary>
        <OptionMenu defaultBtnType={ViewModel.CTAType.LINK} optionList={parsedHeaderListChildren} />
      </header>
    );
  };

  return (
    <BBContainer fitToPage={fitToPage}>
      <div data-testid="CyCalendar" ref={ref} css={styles.container}>
        <BBContainer framed={true} testId="CyCalendar-bbContainer" cssOverload={showSmallCalendar ? styles.bbcontainer : undefined}>
          <div data-testid="effects">{effectChildren}</div>
          <div css={styles.leftBar}>
            {showSmallCalendar && renderHeader()}
            <ErrorBoundary>
              <CalendarControl
                calendarType={calendarType}
                disabled={loading}
                month={month}
                highlightedWeek={calendarType !== ViewModel.CyCalendarType.MONTH && focusDay}
                activeDay={calendarType !== ViewModel.CyCalendarType.DAY ? activeDay : focusDay}
                referenceDate={safeReferenceDate}
                onChangeMonth={setMonth}
                onChangeActiveDay={calendarType === ViewModel.CyCalendarType.MONTH || showSmallCalendar ? onChangeActiveDay : onChangeFocusDay}
              />
            </ErrorBoundary>
            <div css={styles.division} />
            <SearchInput
              value={search}
              onChange={setSearch}
              placeholder={TRANS.client.placeholder.calendarSearch}
              searchDelay={ENV.LOADING_DEBOUNCE_TIME}
              onClick={onRunFetch}
            />
            <ErrorBoundary>
              <CalendarList
                disabled={loading}
                optionList={collectionOptionList}
                value={activeCollections}
                calendarCollectionMap={calendarCollectionMap}
                onChange={onChangeActiveCollection}
                onChangeCollectionCursor={onChangeCollectionCursor}
              />
            </ErrorBoundary>
          </div>
        </BBContainer>
        {!showSmallCalendar && (
          <div css={styles.main}>
            <BBContainer framed={true} cssOverload={styles.mainContent}>
              {renderHeader()}
              {!!loading && (
                <div css={styles.progress}>
                  <ProgressBar color="BRAND_2" size={5} alt={true} />
                </div>
              )}
              <div css={styles.canvas}>
                <ErrorBoundary>
                  {[ViewModel.CyCalendarType.DAY, ViewModel.CyCalendarType.WEEK, null, undefined].includes(calendarType) && (
                    <CalendarWeek
                      type={calendarType}
                      disabled={loading}
                      week={focusDay}
                      firstVisibleHour={firstVisibleHour}
                      calendarConfigList={calendarConfigList}
                      referenceDate={safeReferenceDate}
                      activeDay={activeDay}
                      actionListChildren={actionListChildren}
                      addOptionList={addOptionList}
                      onCellAction={onCellAction}
                      onChangeActiveDay={onChangeActiveDay}
                    />
                  )}
                  {calendarType === ViewModel.CyCalendarType.MONTH && (
                    <CalendarMonth
                      disabled={loading}
                      month={focusDay}
                      calendarConfigList={calendarConfigList}
                      referenceDate={safeReferenceDate}
                      activeDay={activeDay}
                      actionListChildren={actionListChildren}
                      addOptionList={addOptionList}
                      onCellAction={onCellAction}
                      onChangeActiveDay={onChangeActiveDay}
                    />
                  )}
                </ErrorBoundary>
              </div>
            </BBContainer>
          </div>
        )}
        {!!activeDay && (
          <BBContainer framed={true} cssOverload={styles.sidePanelContainer}>
            <div css={styles.sidePanel}>
              <ErrorBoundary>
                <CalendarSidePanel
                  disabled={loading}
                  calendarConfigList={calendarConfigList}
                  activeDay={activeDay}
                  actionListChildren={actionListChildren}
                  addOptionList={addOptionList}
                  onCellAction={onCellAction}
                  onClose={onCloseActiveDay}
                />
              </ErrorBoundary>
            </div>
          </BBContainer>
        )}
      </div>
    </BBContainer>
  );
};

CyCalendar.displayName = ViewModel.DisplayName.CY_CALENDAR;
