import { useContext, useEffect } from 'react';
import { filter, mergeMap, Observable, Subject, takeUntil } from 'rxjs';

import { ApiModel, GeneralModel } from '@cyferd/client-engine';

import { GlobalContext } from './GlobalState';

export const useOnRefresh = (
  { id, componentName }: { id?: string; componentName?: string },
  onRefresh: (fetchCriteria?: GeneralModel.FetchCriteria) => Observable<ApiModel.APIAction>
) => {
  const { deps } = useContext(GlobalContext);

  useEffect(() => {
    const sync$ = new Subject<void>();
    deps.refresh$
      .pipe(
        takeUntil(sync$),
        filter((nodeId: string) => [id, componentName].includes(nodeId)),
        mergeMap(() => onRefresh())
      )
      .subscribe();
    return () => sync$.next();
  }, [componentName, id, deps.refresh$, onRefresh]);
};
