import React, { memo, ReactNode, useContext, useMemo, useState } from 'react';

import { ErrorBoundary, GeneralModel, isFx, isObject, safeParse, ViewModel } from '@cyferd/client-engine';

import { EvaluatorInput } from '../EvaluatorInput';
import { FormulaInputRow } from '../resources';
import { styles } from './styles';
import { MenuOption } from '../../OptionMenu';
import { useDataParser, useTestingHelper } from '@utils';
import { CTAType } from '../../CTA';
import { ArrayItemContext } from '../../../smart/CyForm/components/BaseForm/componentRecord/useRenderArrayItem';
import { TRANS } from '@constants';

export interface EvaluatorWrapperProps {
  maxValues?: number;
  value: GeneralModel.EvaluatorFormula;
  plainValueLabel?: string;
  disabled?: boolean;
  required?: boolean;
  allowFormula: GeneralModel.EvaluatorFormula<boolean>;
  fixToFormula?: boolean;
  label?: string;
  inputList?: FormulaInputRow[];
  placeholder?: string;
  description?: string;
  openModalOnFocus?: boolean;
  format: GeneralModel.JSONSchemaFormat;
  info?: string;
  color?: GeneralModel.Color.ThemeColor;
  disabledType?: GeneralModel.DisabledType;
  optionList?: MenuOption[];
  onChange: (value: any | string) => void;
  children: (props: { value: any; onChange: (value: any) => void; onPaste?: (value: any) => void; evaluatorOptionList?: MenuOption[] }) => ReactNode;
}

const emptyList = [];

export const EvaluatorWrapper = memo(
  ({
    value,
    onChange,
    disabled,
    allowFormula,
    fixToFormula,
    label = '',
    inputList,
    placeholder,
    children,
    description,
    format,
    required,
    openModalOnFocus = true,
    info,
    color,
    disabledType,
    optionList
  }: EvaluatorWrapperProps) => {
    const { getTestIdProps } = useTestingHelper('evaluator-wrapper');
    const { optionList: arrayOptionList } = useContext(ArrayItemContext);
    const baseOptionList = arrayOptionList?.length ? arrayOptionList : emptyList;
    const parseData = useDataParser();

    const isFormula = (() => {
      if (
        typeof value === 'string' &&
        [
          GeneralModel.JSONSchemaFormat.TEXT,
          GeneralModel.JSONSchemaFormat.EMAIL,
          GeneralModel.JSONSchemaFormat.URL,
          GeneralModel.JSONSchemaFormat.MULTILINE,
          GeneralModel.JSONSchemaFormat.IBAN,
          GeneralModel.JSONSchemaFormat.ISIN,
          GeneralModel.JSONSchemaFormat.DUNS,
          GeneralModel.JSONSchemaFormat.ISBN,
          GeneralModel.JSONSchemaFormat.QR,
          GeneralModel.JSONSchemaFormat.BARCODE,
          GeneralModel.JSONSchemaFormat.SQL,
          GeneralModel.JSONSchemaFormat.RICH_TEXT
        ].includes(format)
      ) {
        return false;
      }
      if ((isObject(value) || Array.isArray(value)) && !['object', 'array'].includes(GeneralModel.formatToTypeMap[format])) return true;
      return isFx(value);
    })();

    const [isEditingFormula, setIsEditingFormula] = useState<boolean>(false);

    const isFormulaActive = !!(isEditingFormula || fixToFormula || isFormula);

    const isReadonly = !!disabled && [GeneralModel.DisabledType.VIEW_ONLY, null, undefined].includes(disabledType);

    const isContainer = useMemo(
      () => !isFormula && [GeneralModel.JSONSchemaFormat.OBJECT, GeneralModel.JSONSchemaFormat.ARRAY].includes(format),
      [format, isFormula]
    );

    const evaluatorOptionList = useMemo(
      () =>
        [
          !isReadonly && {
            important: true,
            type: isContainer ? CTAType.ACTION_TERTIARY : CTAType.LINK,
            image: 'function',
            tooltip: 'Formula',
            testid: getTestIdProps('formula-toggle')['data-testid'],
            disabled,
            size: isContainer ? ViewModel.CTASize.SMALL : ViewModel.CTASize.MEDIUM,
            onClick: () => setIsEditingFormula(prev => !prev)
          },
          !!value && {
            testid: getTestIdProps('clipboard-btn')['data-testid'],
            tooltip: TRANS.client.buttons.copy,
            image: 'copy_all',
            disabled: !value,
            size: ViewModel.CTASize.MEDIUM,
            onClick: () => navigator.clipboard.writeText(JSON.stringify(value))
          },
          !isReadonly && {
            testid: getTestIdProps('clipboard-paste-btn')['data-testid'],
            tooltip: 'Paste',
            image: 'content_paste',
            disabled,
            size: ViewModel.CTASize.MEDIUM,
            onClick: async () => onChange?.(safeParse(await navigator.clipboard.readText()))
          },
          ...baseOptionList
        ] as MenuOption[],
      [baseOptionList, disabled, getTestIdProps, isContainer, isReadonly, onChange, value]
    );

    const evaluatorInputOptionList = useMemo(
      () => [...(Array.isArray(optionList) ? optionList : []), ...evaluatorOptionList].filter(isObject),
      [evaluatorOptionList, optionList]
    );

    const getActiveFormulaInput = () => (
      <ErrorBoundary>
        <EvaluatorInput
          openModalOnFocus={openModalOnFocus}
          open={isEditingFormula}
          setOpen={setIsEditingFormula}
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          inputList={inputList}
          placeholder={placeholder}
          optionList={evaluatorInputOptionList}
          description={description}
          format={format}
          required={required}
          info={info}
          color={color}
          disabledType={disabledType}
        />
      </ErrorBoundary>
    );

    if (!parseData(allowFormula, { item: value }))
      return (
        <ArrayItemContext.Provider value={{ optionList: emptyList }}>
          {children({ value, onChange, evaluatorOptionList: baseOptionList })}
        </ArrayItemContext.Provider>
      );

    return (
      <ArrayItemContext.Provider value={{ optionList: emptyList }}>
        <div {...getTestIdProps('container')} css={styles.container}>
          <div>
            <div css={styles.dropdownCombo}>
              <div css={styles.valueInput}>
                {isFormulaActive
                  ? getActiveFormulaInput()
                  : (() => {
                      try {
                        return <ErrorBoundary fallback={getActiveFormulaInput}>{children({ value, onChange, evaluatorOptionList })}</ErrorBoundary>;
                      } catch {
                        return getActiveFormulaInput();
                      }
                    })()}
              </div>
            </div>
          </div>
        </div>
      </ArrayItemContext.Provider>
    );
  }
);
