import { GeneralModel, Translate, isObject } from '@cyferd/client-engine';
import { CTA, CTAType, CtaProps } from '../CTA';
import { IconImage } from '../Icon/renderIcon';
import { COLOR, FOREGROUND_COLOR, GAP, HC_COLOR } from '@constants';
import { ToolTip } from '../Tooltip';
import { styles } from './styles';
import { TooltipInfo } from '../TooltipInfo';
import { memo, useCallback, useMemo, useState } from 'react';
import { Icon } from '../Icon';

export interface ChipProps {
  id?: string;
  title: string;
  description?: string;
  color?: GeneralModel.Color.ThemeColor;
  icon?: GeneralModel.IconName;
  active: boolean;
  disabled?: boolean;
  showCancel?: boolean;
  compact?: boolean;
  onClick: CtaProps['onClick'];
}

export const Chip = memo(({ id, title, description, color, icon, active, disabled, showCancel, compact, onClick }: ChipProps) => {
  const safeColorName: GeneralModel.Color.ThemeColor = useMemo(() => {
    if (disabled) return 'NEUTRAL_6';
    return COLOR[color] ? color : 'BRAND_3';
  }, [color, disabled]);

  return (
    <ToolTip text={<TooltipInfo title={title} description={description} />}>
      <div css={styles.container({ color: safeColorName, active, compact, disabled })}>
        <CTA testid={id || 'chip'} type={CTAType.SEEMLESS} onClick={onClick} disabled={disabled} hideLoading={true}>
          <div css={[styles.content, disabled && styles.disabled]}>
            <IconImage
              title=""
              icon={icon}
              iconProps={{
                outlined: true,
                fill: active ? FOREGROUND_COLOR[safeColorName] : HC_COLOR[safeColorName],
                size: compact ? '15px' : '19px',
                css: { lineHeight: 0 }
              }}
              imageProps={{ css: { width: 21, height: 21, objectFit: 'cover', borderRadius: '100%', margin: `-${GAP.XS} 0 -${GAP.XS} -${GAP.S}` } }}
            />
            <span css={styles.text({ color: safeColorName, active })}>
              <Translate>{title}</Translate>
            </span>
            {!!showCancel && !!active && <Icon name="check" size="16px" fill={FOREGROUND_COLOR[safeColorName]} />}
          </div>
        </CTA>
      </div>
    </ToolTip>
  );
});

export const ChipList = memo(
  ({ list, limited, collapsible, testid = 'chip-list' }: { list: ChipProps[]; limited?: boolean; collapsible?: boolean; testid?: string }) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const cleanList = list?.filter?.(isObject);

    const onToggle = useCallback(() => setOpen(p => !p), []);

    if (!cleanList?.length) return null;

    return (
      <div
        data-testid={testid}
        data-selector="chip-list"
        css={[styles.chipList, !!limited && styles.chipListLimited, !!collapsible && !isOpen && styles.collapsed]}
      >
        {!!collapsible && (
          <CTA testid="toggle-chip-collapse" type={CTAType.SEEMLESS} icon={isOpen ? 'keyboard_arrow_down' : 'chevron_right'} onClick={onToggle} />
        )}
        {list.map((props, index) => (
          <Chip key={`${props.id}-${index}`} {...props} />
        ))}
      </div>
    );
  }
);
