import { Spinner } from '@components/elements/Spinner';
import { ViewFormation, ViewFormationProps, ViewModel } from '@cyferd/client-engine';
import { useCallback } from 'react';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';
import { CyText } from '../CyText';

export const CyView = ({ componentName, viewId, headerHidden, framed, input, fitToPage, children }: ViewModel.CyViewProps) => {
  const renderBody: ViewFormationProps['renderBody'] = useCallback(
    ({ children, shouldShowHeader, headerProps }) => {
      const safeShouldShowHeader = shouldShowHeader && !headerHidden;
      const getSafeString = (str: string) => (safeShouldShowHeader && !!str && typeof str === 'string' ? str : undefined);
      return (
        <Layout
          title={getSafeString(headerProps?.title)}
          subtitle={getSafeString(headerProps?.subtitle)}
          image={getSafeString(headerProps?.icon)}
          actionListChildren={safeShouldShowHeader ? headerProps?.actionListChildren : undefined}
          framed={framed}
          fitToPage={fitToPage}
          showHeaderDivision={safeShouldShowHeader}
        >
          {children}
        </Layout>
      );
    },
    [framed, fitToPage, headerHidden]
  );

  const renderLoading = useCallback(
    () => (
      <div css={styles.loadingContainer}>
        <Spinner />
      </div>
    ),
    []
  );

  const renderError = useCallback(
    () => (
      <div css={styles.loadingContainer}>
        <CyText title="Whoops! An error occurred" titleAlignment={ViewModel.Alignment.CENTER} />
      </div>
    ),
    []
  );

  return (
    <ViewFormation componentName={componentName} viewId={viewId} input={input} renderLoading={renderLoading} renderBody={renderBody} renderError={renderError}>
      {children}
    </ViewFormation>
  );
};

CyView.displayName = ViewModel.DisplayName.CY_VIEW;
