import { ComponentProps } from 'react';

import { Adder, AdderOption } from '@components/elements/Adder';
import { ListItem } from '@components/elements/List';
/* istanbul ignore file | configs */
import { ApiModel, capitalize, CollectionModel, FlowModel, GeneralModel, isObject, recursiveMap } from '@cyferd/client-engine';
import { TRANS } from '@constants';

export const getPrettyActionTitle = (title: string) => capitalize(title?.split(/_|\./).join(' '));

export interface TabConfig {
  key: string;
  title: string;
  calculatedTitle?: (any) => string;
  image: GeneralModel.IconName;
  description?: string;
  color?: GeneralModel.Color.ThemeColor;
  hidden?: boolean;
}

export const tabConfigMap: Record<string, TabConfig> = Object.entries({
  input: {
    title: TRANS.client.nav.builder.tabs.flows.inputForm,
    color: 'BRAND_1',
    image: 'right_panel_open',
    description: 'Set the schema for the data that can be passed as parameters when this flow is run.'
  },
  prepare: {
    title: TRANS.client.nav.builder.tabs.flows.inputChanges,
    color: 'BRAND_1',
    image: 'handyman',
    description: 'Use calculated values to further update or add input values for this flow.'
  },
  state: {
    title: TRANS.client.nav.builder.tabs.flows.savedState,
    image: 'fact_check',
    color: 'BRAND_1',
    description: 'Manage the values that persist when the flow is saved, these values may be updated by the set state items in flow steps.'
  },
  output: { title: TRANS.client.nav.builder.tabs.flows.output, color: 'BRAND_1', image: 'right_panel_close', description: 'Set the result from the flow ' },
  start: { title: TRANS.client.nav.builder.tabs.flows.start, color: 'BRAND_1', image: 'play_circle' },
  steps: { title: TRANS.client.nav.builder.tabs.flows.steps, color: 'BRAND_1', image: 'stairs' },
  step_detail: { title: TRANS.client.nav.builder.tabs.flows.stepDetail, hidden: true },
  step_action: { title: TRANS.client.nav.builder.tabs.flows.stepAction, hidden: true },
  step_next: {
    title: TRANS.client.nav.builder.tabs.flows.stepNext,
    calculatedTitle: (step: FlowModel.FlowStep) =>
      `${TRANS.client.nav.builder.tabs.flows.stepNext} ${step?.onResult?.length ? `(${step?.onResult?.length})` : ''}`,
    hidden: true
  },
  step_error: {
    title: TRANS.client.nav.builder.tabs.flows.stepError,
    calculatedTitle: (step: FlowModel.FlowStep) =>
      `${TRANS.client.nav.builder.tabs.flows.stepError} ${step?.onError?.length ? `(${step?.onError?.length})` : ''}`,
    hidden: true
  }
}).reduce((total, [key, config]) => ({ ...total, [key]: { key, ...config } }), {});

export const listItemBase: ComponentProps<typeof ListItem>['item'] = { raw: {}, fullItem: {}, list: [] };

export const outputSchema: GeneralModel.JSONSchema = { type: 'string', label: '', format: GeneralModel.JSONSchemaFormat.EVALUATION };

export const detailSchema: GeneralModel.JSONSchema = {
  type: 'object',
  label: ' ',
  properties: {
    name: { type: 'string', label: TRANS.client.fields.titles.stepName },
    description: { type: 'string', label: TRANS.client.fields.titles.description, format: GeneralModel.JSONSchemaFormat.MULTILINE },
    id: { type: 'string', label: TRANS.client.fields.titles.id, metadata: { disabled: true, disabledType: GeneralModel.DisabledType.VIEW_ONLY } },
    metadata: {
      type: 'object',
      label: ' ',
      properties: {
        color: { type: 'string', label: TRANS.client.fields.titles.color, format: GeneralModel.JSONSchemaFormat.COLOR },
        image: { type: 'string', label: TRANS.client.fields.titles.icon, format: GeneralModel.JSONSchemaFormat.ICON_IMAGE }
      }
    },
    debug: { type: 'boolean', label: TRANS.client.fields.titles.debug },
    notes: {
      type: 'string',
      label: TRANS.client.fields.titles.adminNotes,
      format: GeneralModel.JSONSchemaFormat.RICH_TEXT,
      metadata: { fieldSize: GeneralModel.FieldSize.FULL }
    }
  }
};

export const getActionSchema = (actionType: ApiModel.IFlowActionType): GeneralModel.JSONSchema => ({
  type: 'object',
  label: ' ',
  required: ['action'],
  properties: {
    action: {
      type: 'string',
      label: TRANS.client.fields.titles.actionType,
      format: GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST,
      metadata: { subtype: GeneralModel.JSONSchemaSubtype.BACKEND, fieldSize: GeneralModel.FieldSize.FULL }
    },
    input: {
      label: ' ',
      type: 'object',
      format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
      metadata: {
        fieldSize: GeneralModel.FieldSize.FULL,
        subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
        allowFormula: true,
        isCollapsible: false,
        alt: false,
        collection: {
          detailGroupList: getActionDetailGroupList(actionType),
          schema: {
            ...recursiveMap(ApiModel.FlowSchemaMap[actionType]?.input?.schema || {}, (item, path) =>
              isObject(item) && path[path.length - 1] === 'metadata' ? { ...item, allowFormula: true } : item
            ),
            type: 'object',
            title: ' ',
            label: ' ',
            metadata: { allowFormula: true }
          }
        }
      }
    }
  }
});

export const getActionDetailGroupList = (actionType: ApiModel.IFlowActionType): CollectionModel.DetailGroup[] =>
  ApiModel.FlowSchemaMap[actionType]?.input?.detailGroupList || [];

export const getOnResultSchema = (flow: FlowModel.Flow): GeneralModel.JSONSchema => ({
  type: 'object',
  properties: {
    setState: {
      type: 'any',
      label: TRANS.client.fields.titles.stateChanges,
      format: GeneralModel.JSONSchemaFormat.EVALUATION,
      metadata: { fieldSize: GeneralModel.FieldSize.FULL }
    }, // mover a next steps
    onResult: {
      type: 'array',
      label: TRANS.client.nav.builder.tabs.flows.stepNext,
      metadata: { unlimitedHeight: true, fieldSize: GeneralModel.FieldSize.FULL },
      items: {
        type: 'object',
        label: TRANS.client.fields.titles.nextStepItem,
        required: ['goTo'],
        properties: {
          goTo: {
            type: 'string',
            label: TRANS.client.fields.titles.goTo,
            metadata: {
              optionList: Object.entries(flow?.steps || {}).map(([stepId, step]) => ({ value: stepId, label: step.name, description: step?.action }))
            }
          },
          if: { label: TRANS.client.fields.titles.condition, type: 'any', format: GeneralModel.JSONSchemaFormat.EVALUATION },
          title: { type: 'string', label: TRANS.client.fields.titles.lineName },
          description: { type: 'string', label: TRANS.client.fields.titles.description, format: GeneralModel.JSONSchemaFormat.MULTILINE },
          color: { type: 'string', label: TRANS.client.fields.titles.color, format: GeneralModel.JSONSchemaFormat.COLOR }
        }
      }
    }
  }
});

export const getOnErrorSchema = (flow: FlowModel.Flow): GeneralModel.JSONSchema => ({
  type: 'object',
  properties: {
    onError: {
      type: 'array',
      label: TRANS.client.nav.builder.tabs.flows.stepError,
      metadata: { unlimitedHeight: true, fieldSize: GeneralModel.FieldSize.FULL },
      items: {
        type: 'object',
        label: TRANS.client.fields.titles.nextStepItem,
        required: ['goTo'],
        properties: {
          goTo: {
            type: 'string',
            label: TRANS.client.fields.titles.goTo,
            metadata: {
              optionList: Object.entries(flow?.steps || {}).map(([stepId, step]) => ({ value: stepId, label: step.name, description: step?.action }))
            }
          },
          if: { label: TRANS.client.fields.titles.condition, type: 'any', format: GeneralModel.JSONSchemaFormat.EVALUATION },
          title: { type: 'string', label: TRANS.client.fields.titles.lineName },
          description: { type: 'string', label: TRANS.client.fields.titles.description, format: GeneralModel.JSONSchemaFormat.MULTILINE },
          color: { type: 'string', label: TRANS.client.fields.titles.color, format: GeneralModel.JSONSchemaFormat.COLOR }
        }
      }
    }
  }
});

export const defaultStepImage: GeneralModel.IconName = 'move_group';

const getGroupConfig = (groupId: string) => ApiModel.actionGroupMap[groupId] || { id: 'other', name: 'Other', order: 999 };

export const flowActionOptions: AdderOption[] = Object.values(ApiModel.FlowSchemaMap)
  .filter(config => !!config && config.hidden !== true)
  .map(config => (config?.groups?.length ? config?.groups : [null]).map(groupId => ({ config, groupId })))
  .flat()
  .map(({ config, groupId }) => ({
    id: config.id,
    item: {
      title: getPrettyActionTitle(config.name.substring(config.name.indexOf('.') + 1, config.name.length)),
      description: config.description,
      image: 'play_arrow',
      color: 'NEUTRAL_4'
    },
    group: getGroupConfig(groupId).name,
    groupId
  }))
  .sort((a, b) => getGroupConfig(a.groupId).order - getGroupConfig(b.groupId).order) as ComponentProps<typeof Adder>['options'];
