import { CTAType } from '@components/elements/CTA';
import { TRANS } from '@constants';
import { capitalize, CollectionModel, GeneralModel } from '@cyferd/client-engine';

export const flowFormDetailGroupList: CollectionModel.Collection['detailGroupList'] = [
  { id: 'info', name: 'Basic information', order: 1 },
  { id: 'input', name: 'Input', order: 2 }
];

export const flowFormSchema: GeneralModel.JSONSchema = {
  type: 'object',
  properties: {
    action: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
      label: TRANS.client.fields.titles.actionType,
      metadata: {
        detailGroupId: 'info',
        optionList: [
          {
            value: 'create',
            label: 'On create',
            color: 'GN_1'
          },
          {
            value: 'update',
            label: 'On update',
            color: 'BE_1'
          },
          {
            value: 'delete',
            label: 'On delete',
            color: 'RD_1'
          }
        ]
      }
    },
    block1: {
      format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
      metadata: {
        fixedValue: ' ',
        fieldSize: GeneralModel.FieldSize.FULL,
        unlimitedHeight: true,
        detailGroupId: 'info'
      }
    },
    title: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.TEXT,
      label: TRANS.client.fields.titles.title,
      metadata: { detailGroupId: 'info' }
    },
    description: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      label: TRANS.client.fields.titles.description,
      metadata: { detailGroupId: 'info' }
    },
    block2: {
      format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
      metadata: {
        fixedValue: '<h5>Advanced options</h5>',
        fieldSize: GeneralModel.FieldSize.FULL,
        unlimitedHeight: true,
        detailGroupId: 'info'
      }
    },
    condition: {
      title: 'Condition for the flow execution',
      description: '(optional)',
      type: 'boolean',
      format: GeneralModel.JSONSchemaFormat.SWITCH,
      default: true,
      metadata: { allowFormula: true, detailGroupId: 'info' }
    },
    token: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.TEXT,
      label: 'Token',
      metadata: { detailGroupId: 'info' }
    },
    runInForeground: {
      type: 'boolean',
      format: GeneralModel.JSONSchemaFormat.SWITCH,
      label: 'Run flow in foreground',
      description: 'Actions in the flow will be dispatched to the user',
      default: false,
      metadata: { detailGroupId: 'info' }
    },
    block3: {
      format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
      metadata: {
        fixedValue: '<h5>Payload</h5>',
        fieldSize: GeneralModel.FieldSize.FULL,
        unlimitedHeight: true,
        detailGroupId: 'info'
      }
    }
  }
};

export const recordActionFormSchema: GeneralModel.JSONSchema = {
  type: 'object',
  properties: {
    label: {
      title: TRANS.client.fields.titles.buttonlabel,
      info: TRANS.client.fields.info.buttonLabel,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.TEXT,
      metadata: {
        detailOrder: 11,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    modalTitle: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.TEXT,
      label: TRANS.client.fields.titles.modalTitle,
      info: TRANS.client.fields.info.modalTitle,
      metadata: {
        detailOrder: 12,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    title: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.TEXT,
      label: TRANS.client.fields.titles.adminName,
      info: TRANS.client.fields.info.adminName,
      metadata: {
        detailOrder: 13,
        detailGroupId: 'info'
      }
    },
    description: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      label: TRANS.client.fields.titles.adminNotes,
      info: TRANS.client.fields.info.adminNotes,
      metadata: {
        detailOrder: 14,
        detailGroupId: 'info'
      }
    },
    block1: {
      format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
      metadata: {
        detailOrder: 20,
        fixedValue: '<h5>Button</h5>',
        fieldSize: GeneralModel.FieldSize.FULL,
        unlimitedHeight: true,
        detailGroupId: 'info'
      }
    },
    type: {
      type: 'string',
      description: 'Button type',
      format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
      title: 'Type',
      metadata: {
        detailOrder: 21,
        detailGroupId: 'info',
        optionList: Object.values(CTAType)
          .filter(t => t !== CTAType.SEEMLESS)
          .map(t => ({ value: t, label: capitalize(t.split('-').join(' ')) })),
        allowFormula: true
      }
    },
    icon: {
      title: 'Icon',
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
      metadata: {
        detailOrder: 22,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    color: {
      title: 'Color',
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.COLOR,
      metadata: {
        detailOrder: 23,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    block2: {
      format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
      metadata: {
        detailOrder: 30,
        fixedValue: '<h5>Button visibility</h5>',
        fieldSize: GeneralModel.FieldSize.FULL,
        unlimitedHeight: true,
        detailGroupId: 'info'
      }
    },
    important: {
      title: 'Important',
      description: 'When applies, the button will be outside the 3 dot menu',
      type: 'boolean',
      default: false,
      metadata: {
        detailOrder: 31,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    hidden: {
      title: 'Hidden',
      type: 'boolean',
      default: false,
      metadata: {
        detailOrder: 32,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    disabled: {
      title: 'Disabled',
      type: 'boolean',
      default: false,
      metadata: {
        detailOrder: 33,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    block3: {
      format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
      metadata: {
        detailOrder: 40,
        fixedValue: '<h5>Action run options</h5>',
        fieldSize: GeneralModel.FieldSize.FULL,
        unlimitedHeight: true,
        detailGroupId: 'info'
      }
    },
    refreshAfter: {
      title: 'Refresh after',
      description: 'On success, the view will be refreshed',
      type: 'boolean',
      default: false,
      metadata: {
        detailOrder: 41,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    skipForm: {
      title: 'Skip form',
      description: '',
      type: 'boolean',
      default: false,
      metadata: {
        detailOrder: 42,
        detailGroupId: 'info',
        allowFormula: true
      }
    },
    confirmation: {
      title: 'Confirmation',
      description: 'show confirmation modal',
      type: 'boolean',
      default: true,
      metadata: {
        shouldCleanHidden: true,
        detailOrder: 43,
        detailGroupId: 'info',
        allowFormula: true,
        hidden: {
          $cyf_not: ['{{event.item.skipForm}}']
        }
      }
    },
    confirmationMessage: {
      title: TRANS.client.fields.titles.confirmationMessage,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      metadata: {
        detailOrder: 44,
        detailGroupId: 'info',
        allowFormula: true,
        hidden: {
          $cyf_not: ['{{event.item.skipForm}}']
        }
      }
    },
    block4: {
      format: GeneralModel.JSONSchemaFormat.INFO_BLOCK,
      metadata: {
        detailOrder: 50,
        fixedValue: '<h5>Payload</h5>',
        fieldSize: GeneralModel.FieldSize.FULL,
        unlimitedHeight: true,
        detailGroupId: 'info'
      }
    }
  }
};
