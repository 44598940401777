import React, { ComponentProps, memo, useEffect, useMemo, useState } from 'react';
import { Observable, Subject } from 'rxjs';

import {
  AssociationFilterOutput,
  CollectionModel,
  FilterModel,
  GeneralModel,
  normalize,
  parseAssociationsForFilters,
  parseSchemaForFilters,
  swallowError,
  tapOnSuccess,
  useFinalizeWhileMounted
} from '@cyferd/client-engine';

import { AdvancedFilter } from '../AdvancedFilter';
import { FilterRow } from '../AdvancedFilter/components/FilterRow';
import { styles } from './styles';
import Skeleton from '../Skeleton/Skeleton';

export interface FiltersProps {
  entity: CollectionModel.Collection;
  value: GeneralModel.FetchCriteria['filter'];
  enableAssociations?: boolean;
  delegateOptionsToValueInput?: boolean;
  fetchCollectionModel: (collectionId: string) => Observable<CollectionModel.Collection>;
  onChange: (filter: FilterModel.AdvancedFilterValue) => void;
  renderSchemaForm?: ComponentProps<typeof FilterRow>['renderSchemaForm'];
  disabled?: boolean;
  displayLabel?: boolean;
  allowFormula?: boolean;
}

enum Status {
  IDLE = 'idle',
  FINISHED = 'finished'
}

const ensureArrayNotEmpty = (obj: { [key: string]: any }) => {
  const result = Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      return { ...acc, [key]: [{}] };
    }
    return { ...acc, [key]: obj[key] };
  }, {});

  return Object.keys(result).some(key => result[key] !== obj[key]) ? result : obj;
};

export const Filters = memo(
  ({
    entity,
    value,
    enableAssociations,
    delegateOptionsToValueInput,
    fetchCollectionModel,
    onChange,
    renderSchemaForm,
    displayLabel = false,
    disabled,
    allowFormula
  }: FiltersProps) => {
    const innerValue = !Object.keys(value || {}).length ? { $and: [{}] } : ensureArrayNotEmpty(value);
    const { flatPropDefinitionMap, propOptionList } = useMemo(() => parseSchemaForFilters(normalize.collection(entity)), [entity]);
    const [associationFilterList, setAssociationFilterList] = useState<AssociationFilterOutput[]>([]);
    const [status, setStatus] = useState(!enableAssociations ? Status.FINISHED : Status.IDLE);
    const finalize = useFinalizeWhileMounted();

    const entityId = entity?.id;

    const content = (
      <div css={styles.conditionsContainer}>
        <div css={styles.loading}>
          <div className="row">
            <Skeleton.Content delay={0.4} />
            <Skeleton.Content delay={0.8} />
            <Skeleton.Content />
            <Skeleton.Avatar delay={0.4} size={30} />
            <Skeleton.Avatar size={30} />
          </div>
          <div className="buttons">
            <Skeleton.Content round={true} delay={0.4} />
            <Skeleton.Content round={true} />
          </div>
        </div>
      </div>
    );

    useEffect(() => {
      if (!enableAssociations) return;
      const streamComplete$ = new Subject<void>();
      parseAssociationsForFilters(entity?.schema, fetchCollectionModel)
        .pipe(
          swallowError(),
          tapOnSuccess(list => setAssociationFilterList(list)),
          finalize(() => setStatus(Status.FINISHED))
        )
        .subscribe();
      return () => streamComplete$.next();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId]);

    return (
      <Skeleton loading={status === Status.IDLE} content={content}>
        <div css={styles.conditionsContainer}>
          <AdvancedFilter
            displayLabel={displayLabel}
            disabled={disabled}
            value={innerValue}
            onChange={onChange}
            propOptionList={propOptionList}
            flatPropDefinitionMap={flatPropDefinitionMap}
            associationFilterList={associationFilterList}
            delegateOptionsToValueInput={delegateOptionsToValueInput}
            renderSchemaForm={renderSchemaForm}
            allowFormula={allowFormula}
          />
        </div>
      </Skeleton>
    );
  }
);
