import { ToastProps } from '@components/elements/Toast';
import { NotificationModel } from '@cyferd/client-engine';

export interface UIState {
  toastList: { toast: Partial<ToastProps>; content: any }[];
  messageList: Partial<NotificationModel.Notification>[];
  activeModalList: string[];
  loadingList: string[];
}

export const getInitialState = (): UIState => ({
  toastList: [],
  messageList: [],
  activeModalList: [],
  loadingList: []
});
