import { memo } from 'react';

import { styles } from './styles';
import { FONT_SIZE } from '../../../@constants';
import { IconKeys } from '../Icon';
import { IconImage } from '../Icon/renderIcon';
import { Translate } from '@cyferd/client-engine';

export interface StepperProps {
  currentStep: number;
  stepCount: number;
  title?: string;
  description?: string;
  icon?: IconKeys;
  testid?: string;
}

export const Stepper = memo(({ stepCount, currentStep, title, description, icon, testid = 'stepper' }: StepperProps) => (
  <div data-testid={`${testid}-container`} css={styles.container}>
    <div css={styles.info}>
      <div css={styles.textContainer}>
        <div css={styles.iconTitleContainer}>
          {!!icon && <IconImage title="" icon={icon} imageProps={{ size: FONT_SIZE.XXM }} iconProps={{ size: FONT_SIZE.XXM }} />}
          <h6 css={styles.title}>
            <Translate>{title}</Translate>
          </h6>
        </div>
        <p css={styles.description}>
          <Translate>{description}</Translate>
        </p>
      </div>
      <p css={styles.counter}>
        Step <Translate>{currentStep}</Translate>
        {!!stepCount && `/${stepCount}`}
      </p>
    </div>
    <div css={styles.progressBar}>
      <span css={styles.progressBarFill} data-testid={`${testid}-progress-bar-fill`} style={{ width: `${(currentStep / stepCount) * 100}%` }} />
    </div>
  </div>
));

Stepper.displayName = 'Stepper';
