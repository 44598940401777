import { THEME } from '@constants';
import { GeneralModel } from '@cyferd/client-engine';
import { css, SerializedStyles } from '@emotion/react';

export const themeMap = { [THEME.LIGHT]: GeneralModel.Color.LightTheme, [THEME.DARK]: GeneralModel.Color.DarkTheme };

export const themeStyleMap = Object.entries({
  [THEME.LIGHT]: GeneralModel.Color.LightTheme,
  [THEME.DARK]: GeneralModel.Color.DarkTheme
}).reduce(
  (total, [themeName, config]) => ({
    ...total,
    [themeName]: css`
      :root {
        ${Object.entries(config)
          .map(([key, item]) => [`--${key}: ${item.value};`, `--${key}_SECONDARY: ${item.secondary};`, `--${key}_FOREGROUND: var(--${item.foreground});`])
          .flat()
          .join('\n')}
        --shadow-color: ${config.ABSOLUTE_BLACK.value}${themeName === THEME.DARK ? '90' : '20'};
      }
    `
  }),
  {} as Record<GeneralModel.Color.ThemeColor, SerializedStyles>
);
