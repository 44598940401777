import { useMemo } from 'react';

import { GeneralModel, mergeTruthy } from '@cyferd/client-engine';
import { FormulaInputRow } from '@components/elements/Evaluator/resources';

import { SchemaForm } from '../../../SchemaForm';
import { creatorDetailGroupList, genericSchema, getFieldConfig, TemplateName } from '../../schemas';
import { getDataTypeLabel } from '@constants';

export interface PropertyBasicFormProps {
  inputList: FormulaInputRow[];
  value: GeneralModel.JSONSchema;
  parentFormat?: GeneralModel.JSONSchemaFormat;
  onChange: (value: GeneralModel.JSONSchema) => void;
}

export const PropertyBasicForm = ({ inputList, value, parentFormat, onChange }: PropertyBasicFormProps) => {
  const formatProp: GeneralModel.JSONSchema = useMemo(() => {
    if ([GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST, GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT].includes(parentFormat)) {
      return mergeTruthy(getFieldConfig('format'), {
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        metadata: {
          optionList: [GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST, GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST].map(value => ({
            value,
            label: getDataTypeLabel(value as TemplateName)
          }))
        }
      });
    }
    return getFieldConfig('format');
  }, [parentFormat]);

  const schema = useMemo(() => {
    const completeSchema = mergeTruthy(genericSchema, { properties: { format: formatProp, title: { description: `ID: ${value?.$id}` } } });
    return completeSchema;
  }, [formatProp, value?.$id]);

  return (
    <SchemaForm
      onChange={onChange}
      value={value}
      schema={schema}
      shouldValidate={true}
      inputList={inputList}
      wrapDetailGroups={false}
      detailGroupList={creatorDetailGroupList}
    />
  );
};
