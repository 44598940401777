import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { OverrideSchema } from './OverrideSchemaUI';
import { styles } from './styles';
import { CollectionModel, GeneralModel, noop, replaceDeep } from '@cyferd/client-engine';

import { SchemaCreator } from '../SchemaEditor/components/SchemaCreator';
import { get } from 'lodash';
import { useGetElementSize } from '@utils';
import { getOverridePathList, removeEmptyKeys } from './resources';
export interface ICollectionOverride {
  schema?: CollectionModel.Collection['schema'];
  detailGroupList?: CollectionModel.Collection['detailGroupList'];
  schemaOverride: Partial<GeneralModel.JSONSchema['properties']>;
  dgOverride: Record<string, Partial<CollectionModel.DetailGroup>>;
  onSchemaOverrideChange: (values: Record<string, any>) => void;
  onDgOverrideChange: (values: Record<string, CollectionModel.DetailGroup>) => void;
}

const inputList = [];

export const CollectionOverride = ({
  schema,
  detailGroupList,
  dgOverride,
  schemaOverride,
  onSchemaOverrideChange,
  onDgOverrideChange
}: ICollectionOverride) => {
  const { top, ref } = useGetElementSize();
  const [activePath, setActivePath] = useState<string[]>([]);
  const { stringPath, isDetailGroup, selectedOriginal, selectedOverride } = useMemo(() => {
    const stringPath = activePath.join('.');
    const dgOriginal = activePath.length === 1 && detailGroupList?.find(i => i.id === stringPath);
    const isDetailGroup = !!dgOriginal;
    const fieldOriginal: GeneralModel.JSONSchema = !isDetailGroup && !!activePath.length && get(schema, stringPath);
    const selectedOverride = isDetailGroup ? dgOverride?.[stringPath] : get({ properties: schemaOverride }, stringPath);
    return { stringPath, isDetailGroup, selectedOriginal: dgOriginal || fieldOriginal || null, selectedOverride };
  }, [activePath, detailGroupList, dgOverride, schema, schemaOverride]);

  const overrideIdList = useMemo(() => {
    return [...getOverridePathList({ properties: schemaOverride }).map(i => i.join('.')), ...Object.keys(dgOverride || /* istanbul ignore next */ {})].filter(
      Boolean
    );
  }, [dgOverride, schemaOverride]);

  const setActivePropertyId: ComponentProps<typeof SchemaCreator>['setActivePropertyId'] = useCallback((eventId, eventPath) => {
    setActivePath(eventPath.length ? eventPath : [eventId]);
  }, []);

  const onChange = (event: any) => {
    if (isDetailGroup) onDgOverrideChange(removeEmptyKeys({ ...(dgOverride || {}), [stringPath]: event }));
    else onSchemaOverrideChange(removeEmptyKeys(replaceDeep(schemaOverride || {}, event, activePath.slice(1, activePath.length).join('.'))));
  };

  return (
    <div data-testid="entity-app-override-modal" css={styles.container}>
      <div ref={ref}>
        <SchemaCreator
          screenTop={top + 110}
          allowApiFormats={false}
          properties={schema}
          detailGroupList={detailGroupList}
          onChange={noop}
          onChangeParent={noop}
          activePropertyId={(selectedOriginal as GeneralModel.JSONSchema)?.$id || (selectedOriginal as CollectionModel.DetailGroup)?.id}
          setActivePropertyId={setActivePropertyId}
          onChangeDetailGroupList={noop}
          onAddDetailGroup={noop}
          onRemoveDetailGroup={noop}
          onAddAssociation={noop}
          inputList={inputList}
          interactionHidden={true}
          overrideIdList={overrideIdList}
        />
      </div>
      <OverrideSchema original={selectedOriginal} override={selectedOverride} onChange={onChange} />
    </div>
  );
};
