import { useCallback, useContext, useMemo, useState } from 'react';
import { Modal } from '@components/elements/Modal';
import { ApiModel, CollectionModel, ErrorBoundary, SchemaFormBaseProps, ViewModel, isDeepEqual, normalize } from '@cyferd/client-engine';
import { TabList } from '@components/elements/TabList';

import { TRANS } from '@constants';
import { CTA, CTAType } from '@components/elements/CTA';
import { ItemList } from '@components/elements/ItemList';
import { useRequest } from '@utils/useRequest';
import { useSaveBond } from '@utils';
import { RecordLevelSecurity } from './RecordLevelSecurity';
import { CollectionOverride } from './CollectionOverride';
import { GlobalContext } from '../../../state-mgmt/GlobalState';

export interface SecurityOverrideModalProps {
  testid?: string;
  item: ApiModel.ApiRecord;
  onClose: () => void;
  onSuccess?: () => void;
  associationKey: 'appCollections' | 'collectionApps';
  entitySchema?: CollectionModel.Collection['schema'];
  detailGroupList?: SchemaFormBaseProps['detailGroupList'];
  appName?: string;
  collectionName?: string;
}

enum TabName {
  SECURITY = 'security',
  OVERRIDE = 'override'
}

export const SecurityOverrideModal = ({
  testid = 'SecurityOverrideModal',
  item,
  entitySchema,
  onClose,
  onSuccess,
  associationKey,
  detailGroupList,
  appName,
  collectionName
}: SecurityOverrideModalProps) => {
  const prevSchemaProperties = useMemo(() => item?.$r?.schema?.properties || item?.$r?.create?.schema?.properties || {}, [item]);
  const prevDetailGroupList = useMemo(() => item?.$r?.detailGroupList || item?.$r?.create?.detailGroupList || {}, [item]);
  const [schemaOverride, setSchemaOverride] = useState(prevSchemaProperties);
  const [dgOverride, setDgOverride] = useState(prevDetailGroupList);
  const [activeTab, setActiveTab] = useState<TabName>(TabName.SECURITY);
  const [loading, setLoading] = useState(false);
  const { deps } = useContext(GlobalContext);
  const request = useRequest();
  const { saveBond } = useSaveBond(
    request,
    onSuccess,
    /* istanbul ignore next */ () => {
      setLoading(false);
      onClose();
    }
  );

  const safeCollection = useMemo(() => normalize.collection({ detailGroupList, schema: entitySchema }), [detailGroupList, entitySchema]);

  const schemaCondition = useMemo(() => !isDeepEqual(prevSchemaProperties, schemaOverride), [schemaOverride, prevSchemaProperties]);
  const detailCondition = useMemo(() => !isDeepEqual(prevDetailGroupList, dgOverride), [dgOverride, prevDetailGroupList]);

  const disabled = !schemaCondition && !detailCondition;
  const counterOverridenValues = Object.keys({ ...schemaOverride, ...dgOverride }).reduce(acc => acc + 1, 0);

  const resetIsDisabled = useMemo(() => Object.keys({ ...schemaOverride, ...dgOverride }).length < 1, [schemaOverride, dgOverride]);

  /* istanbul ignore next */
  const onSaveOverride = useCallback(() => {
    setLoading(true);
    const data = {
      ...(schemaCondition ? { schema: { properties: { ...schemaOverride } } } : {}),
      ...(detailCondition ? { detailGroupList: { ...dgOverride } } : {})
    };
    return saveBond(item?.id, item?.collectionId, item?.$r?.id, { ...item?.$r, ...data }, associationKey);
  }, [saveBond, item?.id, item?.collectionId, item?.$r, schemaOverride, dgOverride, schemaCondition, detailCondition, associationKey]);

  const onReset = useCallback(() => {
    deps.modalInteraction.onConfirm(
      {
        status: ViewModel.Status.INFO,
        icon: 'restart_alt',
        title: `Reset collection?`,
        description: "You're trying to reset the collection . Are you sure you want to continue ?",
        confirmLabel: 'Reset'
      },
      () => {
        setSchemaOverride({});
        setDgOverride({});
      }
    );
  }, [deps.modalInteraction]);

  return (
    <Modal
      onClose={onClose}
      type={ViewModel.ModalType.LARGE}
      open={true}
      testid={testid}
      title={`"${collectionName}" from "${appName}" security`}
      description="Edit the security settings for the data collection"
      icon="add_moderator"
      footer={
        activeTab === TabName.OVERRIDE ? (
          <ItemList>
            <CTA type={CTAType.SECONDARY} color="HC_5" label="Reset" testid="cta-reset" disabled={resetIsDisabled} onClick={onReset} />
            <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={onClose} testid="save-cancel-override" disabled={loading} />
            <CTA type={CTAType.PRIMARY} label={TRANS.client.buttons.save} onClick={onSaveOverride} testid="save-cta-override" disabled={loading || disabled} />
          </ItemList>
        ) : null
      }
    >
      <TabList
        activeTab={activeTab}
        tabList={[
          { title: TabName.SECURITY, displayName: 'Record level security' },
          {
            title: TabName.OVERRIDE,
            displayName: `Collection override ${counterOverridenValues ? `(${counterOverridenValues})` : ''}`
          }
        ]}
        onChangeTab={setActiveTab as any}
      />
      {activeTab === TabName.SECURITY && (
        <ErrorBoundary>
          <RecordLevelSecurity onClose={onClose} onSuccess={onSuccess} item={item} entitySchema={entitySchema} associationKey={associationKey} />
        </ErrorBoundary>
      )}

      {activeTab === TabName.OVERRIDE && (
        <ErrorBoundary>
          <CollectionOverride
            schema={safeCollection.schema}
            detailGroupList={safeCollection.detailGroupList}
            schemaOverride={schemaOverride}
            dgOverride={dgOverride}
            onSchemaOverrideChange={setSchemaOverride}
            onDgOverrideChange={setDgOverride}
          />
        </ErrorBoundary>
      )}
    </Modal>
  );
};
