import { useMemo } from 'react';
import { Route, Routes } from 'react-router';

import { noop } from '@cyferd/client-engine';

import { BUILDER_ICON, BUILDER_ROUTE, SECTION_NAME } from '@constants';
import { EditorHome } from '../shared/EditorHome';
import { GeneralList } from './components/GeneralList';

export const DeviceManager = () => {
  const routeList = useMemo(() => [{ route: BUILDER_ROUTE.DEVICE_MANAGER.CHILDREN.LIST, label: null }], []);

  return (
    <EditorHome
      title={''}
      icon={BUILDER_ICON.DEVICE_MANAGER}
      editorTitle={SECTION_NAME.DEVICE_MANAGER}
      /** header */
      routeList={routeList}
      hideSubHeader={true}
      rootPath={BUILDER_ROUTE.DEVICE_MANAGER.ROOT}
      isCurrentDraft={false}
      onSave={noop}
      onRemove={noop}
      onSetOriginalValue={noop}
    >
      <Routes>
        <Route path="*" element={<GeneralList />} />
      </Routes>
    </EditorHome>
  );
};
