import React, { useMemo } from 'react';

import { CollectionModel, GeneralModel, getParsedActionChildren, useRecordActionsParser, ViewModel } from '@cyferd/client-engine';

import { CTA, CTAType } from '../CTA';
import { IconImage } from '../Icon/renderIcon';
import { OptionMenu } from '../OptionMenu';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { ToolTip } from '../Tooltip';
import { getStyles } from './styles';
import { TooltipInfo } from '../TooltipInfo';

export interface GridItemProps {
  id?: string;
  title?: string;
  subtitle?: string;
  imageUrl?: string;
  actionListChildren?: ViewModel.CyListProps['actionListChildren'];
  recordActions?: CollectionModel.Collection['recordActions'];
  fullItem?: any;
  testid?: string;
  color?: GeneralModel.Color.ThemeColor;
  index: number;
  density?: ViewModel.Density;
  disabled?: boolean;
  onClick?: (event?: any) => void;
}

export const GridItem = ({
  id,
  title,
  subtitle,
  imageUrl,
  actionListChildren,
  density,
  testid = 'grid-item',
  fullItem,
  color,
  index,
  disabled,
  recordActions,
  onClick
}: GridItemProps) => {
  const styles = useMemo(() => getStyles({ density, color }), [color, density]);

  const parsedActionChildren = useMemo(() => getParsedActionChildren(actionListChildren, { item: fullItem, index }), [actionListChildren, fullItem, index]);
  const parseRecordActions = useRecordActionsParser(recordActions);
  const parsedRecordActions = useMemo(() => parseRecordActions({ item: fullItem, index }), [fullItem, index, parseRecordActions]);

  const completeParsedActionChildren = useMemo(() => [...parsedRecordActions, ...parsedActionChildren], [parsedActionChildren, parsedRecordActions]);

  const createRenderTrigger = (itemId: string) => {
    const renderTrigger = ({ onClick, ref }) => (
      <PreventClickPropagation>
        <div data-selector="action-trigger" ref={ref}>
          <CTA type={CTAType.LINK} testid={`${testid}-${itemId}-toggle-more-actions`} tooltip="Actions" onClick={onClick} icon="more_vert" />
        </div>
      </PreventClickPropagation>
    );
    return renderTrigger;
  };

  const renderActionListChildren = (itemId: string) => {
    if (!completeParsedActionChildren?.length) return null;
    return (
      <div css={styles.righButtonContainer}>
        <PreventClickPropagation>
          <OptionMenu
            renderButton={createRenderTrigger(itemId)}
            optionList={completeParsedActionChildren.map((action, actionIndex) => ({
              as: CTAType.LINK,
              label: action.label,
              testid: `${testid}-${itemId}-action-btn-${actionIndex}`,
              image: action.icon,
              disabled: disabled || !!action.disabled,
              onClick: action.onClick && (event => action.onClick(fullItem, { index, metaKey: event?.metaKey })),
              status: (action as any).status,
              color: action.color,
              tooltip: action.helperText,
              important: false
            }))}
          />
        </PreventClickPropagation>
      </div>
    );
  };

  return (
    <div css={styles.container} key={id}>
      <div css={styles.actionContainer}>{renderActionListChildren(id)}</div>
      <ToolTip text={<TooltipInfo title={title} description={subtitle} />}>
        <div css={[styles.gridItem, typeof onClick === 'function' && styles.cardItemClickable]} onClick={onClick} data-testid={`${id}-grid-btn`}>
          <div css={styles.content}>
            <div css={styles.imageContainer}>
              <IconImage icon={imageUrl || 'widgets'} title={title} iconProps={styles.iconProps} imageProps={{ css: styles.image }} />
            </div>
            <div css={styles.textContent}>
              <div css={styles.title}>{title}</div>
            </div>
          </div>
        </div>
      </ToolTip>
    </div>
  );
};

GridItem.displayName = 'GridItem';
