import { GeneralModel, ParsedList, ViewModel, getParsedActionChildren, formatDateRange } from '@cyferd/client-engine';
import { styles } from './styles';
import { OptionMenu } from '../OptionMenu';
import { COLOR, FONT_SIZE } from '@constants';
import { CTAType } from '@components/elements/CTA';
import { IconImage } from '../Icon/renderIcon';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { PopoverTrigger } from '../Popover';
import { CalendarPopoverContent } from '../CalendarPopoverContent';
import dayjs from 'dayjs';

export interface CalendarCardProps {
  testid?: string;
  value: ParsedList['items'][0];
  color: GeneralModel.Color.ThemeColor;
  from: GeneralModel.DateLike;
  to?: GeneralModel.DateLike;
  actionListChildren?: ViewModel.CyCalendarProps['actionListChildren'];
  disabled?: boolean;
  isFullDayEvent: boolean;
}

export const CalendarCard = ({ testid = 'CalendarCard', value, color, from, to, actionListChildren, disabled, isFullDayEvent }: CalendarCardProps) => {
  return (
    <PopoverTrigger
      containerCss={styles.popover}
      contentCss={styles.popover}
      label={value.title}
      value={
        <CalendarPopoverContent disabled={disabled} from={from} to={to} value={value} isFullDayEvent={isFullDayEvent} actionListChildren={actionListChildren} />
      }
      color={color}
    >
      <div css={styles.container} data-testid={testid}>
        <div css={styles.bar} style={{ backgroundColor: COLOR[color] }} />
        <div css={styles.content}>
          <div css={styles.contentRow}>
            {!!COLOR[value.color] && <div css={styles.ball} style={{ backgroundColor: COLOR[value.color] }} />}
            <IconImage
              icon={value.image}
              title={value.title}
              iconProps={{ size: FONT_SIZE.M, fill: COLOR.NEUTRAL_1 }}
              imageProps={{ css: { width: 16, height: 16, objectFit: 'cover', borderRadius: '100%' } }}
            />
            <p data-testid="calendar-card-title" css={styles.title}>
              {value.title}
            </p>
          </div>
          <div css={styles.contentRow}>
            <IconImage icon="schedule" title="time" iconProps={{ size: FONT_SIZE.M, fill: COLOR.NEUTRAL_2 }} />
            <p data-testid="card-range" css={styles.time}>
              {isFullDayEvent ? dayjs(from).format('D MMMM YYYY') : formatDateRange(from, to)}
            </p>
          </div>
        </div>
        <div data-selector="calendar-card-actions" css={styles.actions}>
          <PreventClickPropagation>
            <OptionMenu
              defaultBtnColor="NEUTRAL_1"
              defaultBtnType={CTAType.LINK}
              optionList={getParsedActionChildren(actionListChildren, { item: value.fullItem }).map(actionItem => ({
                label: actionItem.label,
                image: actionItem.icon,
                disabled: disabled || !!actionItem.disabled,
                onClick: actionItem.onClick && (event => actionItem.onClick(value.fullItem, { metaKey: event?.metaKey })),
                testid: 'action',
                color: actionItem.color,
                type: CTAType.LINK,
                tooltip: actionItem.helperText,
                important: false
              }))}
            />
          </PreventClickPropagation>
        </div>
      </div>
    </PopoverTrigger>
  );
};

CalendarCard.displayName = 'CalendarCard';
