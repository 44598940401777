import { css } from '@emotion/react';
import { InlineFormulaConfig } from '../resources';
import { FormulaInputType, colorPerType } from '../getFormulaInputType';
import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR } from '@constants';

export const styles = {
  container: css`
    position: relative;
    width: 100%;
  `,
  singleLineContainer: css`
    height: 17px;
    position: relative;
    width: 100%;
    overflow: hidden;
  `,
  singleLineContent: css`
    position: absolute;
    width: max-content;
  `,
  ellipsis: css`
    position: absolute;
    right: 0;
    height: 17px;
    width: 35px;
    z-index: 1;
    background: linear-gradient(90deg, ${COLOR.TRANSPARENT} 0%, ${COLOR.BASE_BACKGROUND_ALT_2} 75%);
  `
};

export const getFormulaElementsStyles = (config: InlineFormulaConfig, singleLine: boolean) => {
  const typeColor = colorPerType[config.formulaInputRow.type];
  const isContainer = [FormulaInputType.ARRAY, FormulaInputType.OBJECT, FormulaInputType.FORMULA].includes(config.formulaInputRow.formulaType);

  return {
    text: css`
      display: inline-block;
      padding: 0 ${GAP.XXS} ${!singleLine ? GAP.XS : 0} 0;
      color: ${COLOR[typeColor]};
      font-size: ${FONT_SIZE.S};
      font-weight: ${FONT_WEIGHT.NORMAL};
      ${!!isContainer && `font-weight: ${FONT_WEIGHT.BOLD}`};
    `,
    comma: css`
      padding-right: ${GAP.XS};
      margin-left: -${GAP.XXS};
    `,
    key: css`
      font-weight: ${FONT_WEIGHT.NORMAL};
    `,
    chip: css`
      font-size: ${FONT_SIZE.XXS};
      display: inline-block;
      margin-right: ${GAP.XXS};
      background-color: ${SECONDARY_COLOR.NEUTRAL_3};
      border: 1px ${COLOR.NEUTRAL_3} solid;
      border-radius: ${RADIUS.L};
      padding: 0 ${GAP.M};
      flex-direction: row-reverse;
    `
  };
};
