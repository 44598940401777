import { memo, useCallback, useContext, useMemo, useState } from 'react';

import { ApiModel, capitalize, getSchemaPlaceholder, noop, ViewModel } from '@cyferd/client-engine';

import { defaultFormDetailGroupList } from '@constants';
import { userInputList } from '@models/user';
import { getViewDynamicInputList, viewInputList } from '@models/view';
import { complexListenerSchema } from './schemas';
import { styles } from './styles';
import { ArrayItemContext } from '@components/smart/CyForm/components/BaseForm';
import { Input } from '@components/elements/Input';
import { Modal } from '@components/elements/Modal';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTAType } from '@components/elements/CTA';
import { EditorContext, EditorContextValue } from '../../../builder/views/shared/EditorHome';
import { SchemaForm } from '../../../builder/views/shared/SchemaForm';

export interface TriggerFormProps {
  listener: ViewModel.ComplexListener;
  label?: string;
  description?: string;
  eventType?: string;
  onChange: (trigger: ViewModel.ComplexListener) => void;
}

export const TriggerForm = memo(({ label, description, listener, eventType, onChange }: TriggerFormProps) => {
  const { item } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);
  const [open, setOpen] = useState<boolean>(false);
  const { optionList: arrayOptionList } = useContext(ArrayItemContext);

  const onToggle = useCallback(() => setOpen(p => !p), []);

  const detailGroupList = [...defaultFormDetailGroupList, { name: 'Payload', id: 'payload', order: 2, collapsible: true }];

  const completeViewInputList = useMemo(() => [...getViewDynamicInputList(item), ...viewInputList, ...userInputList], [item]);

  const onInternalChange = useCallback(
    ({ payload, ...value }: any = { trigger: {} }) => {
      onChange({
        ...value,
        trigger: {
          ...value.trigger,
          payload: value.trigger.type === listener?.trigger?.type ? payload : getSchemaPlaceholder(ApiModel.TriggerSchemaMap[value.trigger.type]?.input?.schema)
        }
      });
    },
    [listener?.trigger?.type, onChange]
  );

  const value = useMemo(() => {
    const { trigger: { payload, ...trigger } = {}, ...rest } = listener;
    return {
      ...rest,
      trigger,
      payload
    };
  }, [listener]);

  return (
    <div data-testid="trigger-form">
      <Input
        label={label}
        value={
          listener?.label && listener?.label !== eventType
            ? listener?.label
            : listener?.trigger?.type
                ?.split(/\.|_/g)
                .map((s, i) => (!i ? capitalize(s) : s.toLowerCase()))
                .join(' ')
        }
        onChange={noop}
        onFocus={onToggle}
        optionList={arrayOptionList}
      />
      {!!open && (
        <Modal
          title={`Edit action ${label}`}
          description={description}
          open={true}
          onClose={onToggle}
          type={ViewModel.ModalType.FULL_SCREEN}
          footer={<OptionMenu optionList={[{ important: true, type: CTAType.PRIMARY, label: 'OK', onClick: onToggle }]} />}
        >
          <div className={styles.formsContainer}>
            <SchemaForm
              wrapDetailGroups={true}
              detailGroupList={detailGroupList}
              schema={complexListenerSchema}
              value={value}
              delayTime={0}
              onChange={onInternalChange}
              allowFormula={true}
              shouldValidate={false}
              inputList={completeViewInputList}
            />
          </div>
        </Modal>
      )}
    </div>
  );
});
