import { useCallback, useMemo } from 'react';
import { EMPTY } from 'rxjs';

import { ApiModel, CollectionModel, GeneralModel, ViewModel } from '@cyferd/client-engine';
import { CTA, CTAType } from '@components/elements/CTA';

import { ENV } from '@constants';
import { getLink } from '@utils/getLink';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { BuilderCyList } from '../../../BuilderCyList';
import { Modal } from '@components/elements/Modal';
import { useRequest } from '@utils/useRequest';

export interface CollectionSelectionModalProps {
  onChange: (collection: CollectionModel.Collection) => void;
  onClose: () => void;
}

export const CollectionSelectionModal = ({ onChange, onClose }: CollectionSelectionModalProps) => {
  const request = useRequest();

  const actionListChildren = useMemo(
    () =>
      [
        {
          label: 'Select',
          type: CTAType.LINK,
          important: true,
          onClick: event => {
            onChange(event as unknown as CollectionModel.Collection);
            onClose();
            return EMPTY;
          }
        }
      ] as ViewModel.CyListProps['actionListChildren'],
    [onChange, onClose]
  );

  const openExternalUrl = useOnOpenExternalUrl();
  const onClickItem = useCallback(
    (event: ApiModel.ApiRecord) => openExternalUrl(`${ENV.PUBLIC_URL}${getLink(event?.id, ApiModel.ApiEntity.ENTITY)}`),
    [openExternalUrl]
  );

  const initialFetchCriteria = useMemo(() => ({ collectionId: ApiModel.ApiEntity.ENTITY, options: { limit: 15 } }) as GeneralModel.FetchCriteria, []);

  return (
    <Modal
      type={ViewModel.ModalType.FULL_SCREEN}
      open={true}
      onClose={onClose}
      title="Select a collection"
      footer={<CTA type={CTAType.SECONDARY} label="OK" testid="close" onClick={onClose} />}
    >
      <BuilderCyList
        type={ViewModel.CyListType.TABLE}
        initialFetchCriteria={initialFetchCriteria}
        request={request}
        onClickItem={onClickItem}
        actionListChildren={actionListChildren}
      />
    </Modal>
  );
};
