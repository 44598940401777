import { useCallback } from 'react';
import { EMPTY } from 'rxjs';

import { ApiModel } from '@cyferd/client-engine';

import { getNavigateToArgs, getNavigateToTab } from './getNavigateToTab';

export const useOnNavigateToItem = (config: getNavigateToArgs, path: string) => {
  return useCallback(
    (item: ApiModel.ApiRecord, event?: any) => {
      getNavigateToTab({ ...config, id: item.id, path })(event);
      return EMPTY;
    },
    [config, path]
  );
};
