import { ReactNode, memo, useMemo } from 'react';

import { styles } from './styles';

import type { RequireAtLeastOne } from '@utils/type-tools';
import { Translate } from '@cyferd/client-engine';
type TitleAndControls = {
  controls?: ReactNode;
  titleControls?: ReactNode;
  title?: ReactNode;
  subtitle?: ReactNode;
  testid?: string;
};

type TitleAndOrIcon = RequireAtLeastOne<TitleAndControls, 'title' | 'controls'>;

export type HeaderProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & TitleAndOrIcon;

export const Header = memo(({ as, title, subtitle, controls, testid = 'header', titleControls }: HeaderProps) => {
  const TitleComponent = as || 'h1';

  const subtitleCompo = useMemo(
    () =>
      !!subtitle && typeof subtitle === 'string' ? (
        <p css={styles.subtitle}>
          <Translate>{subtitle}</Translate>
        </p>
      ) : (
        subtitle
      ),
    [subtitle]
  );

  return controls ? (
    <header css={styles.header} data-testid={testid}>
      <div css={styles.titleContainer}>
        {!!title && typeof title === 'string' ? (
          <TitleComponent css={styles.title}>
            <Translate>{title}</Translate>
          </TitleComponent>
        ) : (
          title
        )}
        {subtitleCompo}
        {titleControls}
      </div>
      <div css={styles.controls}>{controls}</div>
    </header>
  ) : (
    <div css={styles.titleContainer}>
      <TitleComponent css={styles.title} data-testid={testid}>
        <Translate>{title}</Translate>
      </TitleComponent>
      {subtitleCompo}
      {titleControls}
    </div>
  );
});

Header.displayName = 'Header';
