import { styles } from './styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DropdownOption } from '../DropdownOption';
import ReactDOM from 'react-dom';
import { Input } from '../Input';

export interface DropDownOptionEditTableProps {
  value: string | number;
  options: any[];
  onChange: (value: string | number) => void;
  autoFocus: boolean;
  innerSetEditing?: (value: boolean) => void;
}

export const DROPDOWN_PORTAL_ID = 'dropdown-root';

export const DropDownOptionEditTable = ({ value, options, onChange, autoFocus = false, innerSetEditing }: DropDownOptionEditTableProps) => {
  const [highlightedIndex, setHighlightedIndex] = useState(options.findIndex(option => option.value === value));
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const testid = 'DropDownOptionEditTable';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        setHighlightedIndex(prevIndex => (prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex));
        break;
      case 'ArrowUp':
        setHighlightedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        break;
      case 'Enter':
        if (isOpen) {
          setIsOpen(false);
          onChange(options[highlightedIndex].value);
        } else {
          setIsOpen(true);
          selectRef.current?.focus();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (autoFocus) {
      setIsOpen(true);
      selectRef.current?.focus();
    }
  }, [autoFocus]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    selectRef.current?.focus();
  };

  const handleOnChange = useCallback(
    (value: string | number) => {
      setIsOpen(false);
      onChange?.(value);
      innerSetEditing?.(false);
    },
    [onChange, setIsOpen, innerSetEditing]
  );

  return (
    <>
      <div ref={selectRef} onKeyDown={handleKeyDown} onClick={handleClick} data-testid={testid} css={styles.container}>
        <Input autoFocus={autoFocus} noWrapperPadding={true} value={value} placeholder="Select an option" onChange={null} />
      </div>
      {isOpen && (
        <DropdownPortal containerRef={selectRef}>
          <div css={styles.listContainer}>
            {options
              .filter(({ hidden }) => !hidden)
              .map(({ label, description, image, color, value: internalValue }, index) => (
                <DropdownOption
                  highlight={highlightedIndex === index}
                  key={`${internalValue}-${index}`}
                  value={internalValue}
                  onClick={handleOnChange}
                  title={label}
                  description={description}
                  image={image}
                  color={color}
                />
              ))}
            {!options.length && <DropdownOption title="No results found" testid={`${testid}-empty`} />}
          </div>
        </DropdownPortal>
      )}
    </>
  );
};

const DropdownPortal = ({ children, containerRef }) => {
  const [dropdownRoot, setDropdownRoot] = useState(null);

  useEffect(() => {
    const root = document.getElementById(DROPDOWN_PORTAL_ID) || document.body;
    setDropdownRoot(root);
  }, []);

  if (!dropdownRoot || !containerRef.current) return null;

  const { bottom, left } = containerRef.current.getBoundingClientRect();

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: `${bottom}px`,
        left: `${left}px`
      }}
    >
      {children}
    </div>,
    dropdownRoot
  );
};
