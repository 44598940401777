/* istanbul ignore file | flow builder patches */

import { BaseForm } from '@components/smart/CyForm/components/BaseForm';
import { GeneralModel, mergeTruthy, normalize } from '@cyferd/client-engine';

import { ComponentProps } from 'react';

export const getFlowComponentRecord: ComponentProps<typeof BaseForm>['getComponentRecord'] = base => {
  const renderDefault: (typeof base)['renderDefault'] = props => {
    switch (props.format) {
      case GeneralModel.JSONSchemaFormat.FILE:
      case GeneralModel.JSONSchemaFormat.FILE_LIST:
        return base.renderDefault({ ...props, type: 'any', format: GeneralModel.JSONSchemaFormat.EVALUATION });
      case GeneralModel.JSONSchemaFormat.ASSOCIATION:
        return base.renderDefault({
          ...props,
          type: 'object',
          format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
          schema: normalize.schema(
            {
              type: 'object',
              format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
              metadata: {
                subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
                collectionId: undefined,
                recordId: undefined,
                alt: false,
                collection: {
                  schema: {
                    type: 'object',
                    properties: {
                      add: {
                        label: 'Add',
                        type: 'any',
                        description: 'Add new value for the records',
                        info: 'Add a new value for records while keeping previous ones intact. This option is compatible with remove, modify, and create',
                        format: GeneralModel.JSONSchemaFormat.EVALUATION,
                        metadata: { detailOrder: 1 }
                      },
                      remove: {
                        label: 'Remove',
                        type: 'any',
                        description: 'Remove previous content',
                        info: 'Delete existing values in records. This option is compatible with add, modify, and create',
                        format: GeneralModel.JSONSchemaFormat.EVALUATION,
                        metadata: { detailOrder: 2 }
                      },
                      modify: {
                        label: 'Modify',
                        type: 'any',
                        description: 'Modify existing content',
                        info: 'Change existing values in records. This option is compatible with remove, add, and create',
                        format: GeneralModel.JSONSchemaFormat.EVALUATION,
                        metadata: { detailOrder: 3 }
                      },
                      create: {
                        label: 'Create',
                        type: 'any',
                        description: 'Create new relationship with the related content',
                        info: 'Establish a new relationship between related records. This option is compatible with remove, modify, and add',
                        format: GeneralModel.JSONSchemaFormat.EVALUATION,
                        metadata: { detailOrder: 4 }
                      },
                      clear: {
                        label: 'Clear',
                        type: 'boolean',
                        description: 'Delete previous content',
                        info: 'Delete all previous records and values. This option can only be used alone; it is not compatible with the other options',
                        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
                        metadata: { detailOrder: 5 }
                      }
                    }
                  }
                }
              }
            },
            { avoidAlphabeticalSort: true }
          )
        });
      case GeneralModel.JSONSchemaFormat.COLLECTION_RECORD:
        return base.renderDefault({ ...props, schema: mergeTruthy(props.schema, { metadata: { alt: props.schema?.metadata?.alt ?? true } }) });
      default:
        return base.renderDefault(props);
    }
  };
  return { ...base, renderDefault };
};
