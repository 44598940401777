import { FormulaInputRow, getFormulaInputRowItem } from '@components/elements/Evaluator/resources';
import { ENV, TRANS } from '@constants';
import {
  capitalize,
  createUUID,
  deepClone,
  fromCamelCase,
  GeneralModel,
  getComponentSchema,
  getFlatSchemaProps,
  getPointerList,
  getSchemaPlaceholder,
  normalize,
  ViewModel,
  prepareTermForReg,
  ApiModel
} from '@cyferd/client-engine';
import { getFieldLabelForEvaluatorRef } from './entity';

export const getNewView = (): ViewModel.View => ({
  id: createUUID(),
  name: 'New view',
  tag: undefined,
  contains: [
    {
      id: createUUID(),
      component: ViewModel.DisplayName.CY_LAYOUT,
      listeners: {},
      attrs: getSchemaPlaceholder(getComponentSchema(ViewModel.DisplayName.CY_LAYOUT)),
      contains: []
    }
  ],
  vars: {},
  triggers: {},
  header: { id: createUUID(), actionListChildren: [] },
  modals: {},
  globalHeader: {},
  metadata: {
    createdWithClientEngineVersion: ENV.CLIENT_ENGINE_VERSION,
    clientEngineVersion: ENV.CLIENT_ENGINE_VERSION
  }
});

const getFlatNodeList = (item: ViewModel.View | ViewModel.Node): ViewModel.Node[] => {
  return [item as ViewModel.Node, ...(item?.contains?.map(node => getFlatNodeList(node)) || [])].filter(Boolean).flat();
};

export const getViewDynamicInputList = (view: ViewModel.View): FormulaInputRow[] => [
  ...getFlatSchemaProps(view?.model?.schema, { includeArrays: true })
    .filter(p => p.path && p.path !== view?.model?.schema?.$id)
    .reduce(
      (total, p) =>
        [
          ...total,
          getFormulaInputRowItem({
            groupName: 'Inputs',
            base: 'input',
            path: `${p.path.replace(new RegExp(`^(${prepareTermForReg(view?.model?.schema?.$id)}).`), '')}`,
            label: getFieldLabelForEvaluatorRef(p),
            description: p.definition.description,
            format: p.definition.format
          })
        ].sort((a, b) => a.label.localeCompare(b.label)),
      []
    ),
  ...Object.entries(view?.vars || {}).map(([key, value]) =>
    getFormulaInputRowItem({
      groupName: 'Variables',
      prefix: 'view',
      base: ViewModel.InputKey.VARIABLES,
      path: key,
      description: JSON.stringify(value)
    })
  ),
  ...[
    ...Object.values(view?.modals || {})
      .map(getFlatNodeList)
      .flat(),
    ...getFlatNodeList(view).slice(1, Infinity)
  ].map(node =>
    getFormulaInputRowItem({
      groupName: 'Component names',
      prefix: 'view',
      type: 'string',
      id: node?.id,
      template: node?.name,
      label: node?.name,
      format: GeneralModel.JSONSchemaFormat.TEXT
    })
  ),
  ...getPointerList(normalize.view(view, true), Object.values(ViewModel.InputKey))
    .filter(pointer => !/^(event|~)/.test(pointer))
    .sort((a, b) => a.localeCompare(b))
    .map(pointer =>
      getFormulaInputRowItem({
        groupName: 'Used pointers',
        prefix: 'view',
        base: pointer,
        description: pointer
      })
    ),
  getFormulaInputRowItem({
    groupName: 'Looper',
    prefix: 'view',
    base: ViewModel.InputKey.LOOPER,
    label: 'Looper',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),
  getFormulaInputRowItem({ groupName: 'Looper', prefix: 'view', base: ViewModel.InputKey.LOOPER, label: 'Looper item', path: 'item' }),
  getFormulaInputRowItem({
    groupName: 'Looper',
    prefix: 'view',
    base: ViewModel.InputKey.LOOPER,
    label: 'Looper index',
    path: 'index',
    format: GeneralModel.JSONSchemaFormat.NUMBER
  }),
  getFormulaInputRowItem({
    groupName: 'Looper',
    prefix: 'view',
    base: ViewModel.InputKey.LOOPER,
    label: 'Looper parent loop',
    path: 'parent',
    format: GeneralModel.JSONSchemaFormat.OBJECT
  }),
  getFormulaInputRowItem({ groupName: 'Looper', prefix: 'view', base: ViewModel.InputKey.LOOPER, path: 'list', format: GeneralModel.JSONSchemaFormat.ARRAY }),
  getFormulaInputRowItem({
    groupName: 'Looper',
    prefix: 'view',
    base: ViewModel.InputKey.LOOPER,
    label: 'Looper loop index',
    path: 'looperIndex',
    format: GeneralModel.JSONSchemaFormat.NUMBER
  })
];

export const viewInputList: FormulaInputRow[] = [
  getFormulaInputRowItem({ groupName: 'URL', prefix: 'view', base: ViewModel.InputKey.URL, label: 'URL' }),
  getFormulaInputRowItem({ groupName: 'URL', prefix: 'view', base: ViewModel.InputKey.URL, path: 'queryParams', label: 'URL query params' }),
  getFormulaInputRowItem({
    groupName: 'URL',
    prefix: 'view',
    base: ViewModel.InputKey.URL,
    path: 'queryParams.id',
    label: 'URL ID',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'URL',
    prefix: 'view',
    base: ViewModel.InputKey.URL,
    path: 'queryParams.collectionId',
    label: 'URL collection ID',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'URL',
    prefix: 'view',
    base: ViewModel.InputKey.URL,
    path: 'pathname',
    label: 'URL pathname',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),

  getFormulaInputRowItem({ groupName: 'Platform', prefix: 'view', base: ViewModel.InputKey.PLATFORM }),
  getFormulaInputRowItem({
    groupName: 'Platform',
    prefix: 'view',
    base: ViewModel.InputKey.PLATFORM,
    path: 'app',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    description: 'builder/web/mobile'
  }),
  getFormulaInputRowItem({
    groupName: 'Platform',
    prefix: 'view',
    base: ViewModel.InputKey.PLATFORM,
    path: 'env',
    label: 'Platform environment',
    format: GeneralModel.JSONSchemaFormat.TEXT,
    description: 'production/dev'
  }),
  getFormulaInputRowItem({
    groupName: 'Platform',
    prefix: 'view',
    base: ViewModel.InputKey.PLATFORM,
    path: 'builderUrl',
    label: 'Builder URL',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'Platform',
    prefix: 'view',
    base: ViewModel.InputKey.PLATFORM,
    path: 'clientAppUrl',
    label: 'Client app URL',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'Platform',
    prefix: 'view',
    base: ViewModel.InputKey.PLATFORM,
    path: 'theme',
    label: 'Platform theme',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'Platform',
    prefix: 'view',
    base: ViewModel.InputKey.PLATFORM,
    path: 'size',
    label: 'Platform size',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'Platform',
    prefix: 'view',
    base: ViewModel.InputKey.PLATFORM,
    path: 'lang',
    label: 'Platform language',
    format: GeneralModel.JSONSchemaFormat.TEXT
  }),
  getFormulaInputRowItem({
    groupName: 'Platform',
    prefix: 'view',
    base: ViewModel.InputKey.PLATFORM,
    path: 'colors',
    label: 'Platform colors',
    format: GeneralModel.JSONSchemaFormat.ARRAY
  }),

  getFormulaInputRowItem({ groupName: 'Component context', prefix: 'context', base: ViewModel.InputKey.CONTEXT }),
  getFormulaInputRowItem({ groupName: 'Component context', prefix: 'context', base: ViewModel.InputKey.CONTEXT, path: 'node' }),
  getFormulaInputRowItem({ groupName: 'Component context', prefix: 'context', base: ViewModel.InputKey.CONTEXT, path: 'node.name' }),
  getFormulaInputRowItem({ groupName: 'Component context', prefix: 'context', base: ViewModel.InputKey.CONTEXT, path: 'node.component' }),
  getFormulaInputRowItem({ groupName: 'Component context', prefix: 'context', base: ViewModel.InputKey.CONTEXT, path: 'node.attrs' }),

  getFormulaInputRowItem({ groupName: 'Data', prefix: 'view', base: ViewModel.InputKey.LOCAL, label: 'Local' }),
  getFormulaInputRowItem({ groupName: 'Data', prefix: 'view', base: ViewModel.InputKey.LOCAL_ALT, label: 'Local', isAlias: true }),
  getFormulaInputRowItem({ groupName: 'Data', prefix: 'view', base: ViewModel.InputKey.GLOBAL, label: 'Global' }),

  getFormulaInputRowItem({ groupName: 'Event', prefix: 'view', base: ViewModel.InputKey.EVENT }),
  getFormulaInputRowItem({ groupName: 'Event', prefix: 'view', path: 'item', base: ViewModel.InputKey.EVENT }),
  getFormulaInputRowItem({ groupName: 'Event', prefix: 'view', path: 'meta', base: ViewModel.InputKey.EVENT }),
  getFormulaInputRowItem({ groupName: 'Event', prefix: 'view', path: 'result', base: ViewModel.InputKey.EVENT }),

  getFormulaInputRowItem({ groupName: 'View', prefix: 'view', base: ViewModel.InputKey.VIEW }),
  getFormulaInputRowItem({ groupName: 'View', prefix: 'view', path: 'id', base: ViewModel.InputKey.VIEW }),
  getFormulaInputRowItem({ groupName: 'View', prefix: 'view', path: 'name', base: ViewModel.InputKey.VIEW })
];

export const ComponentCategory = {
  LAYOUT: TRANS.client.viewCompoCategories.LAYOUT,
  INFORMATION: TRANS.client.viewCompoCategories.INFORMATION,
  DATA: TRANS.client.viewCompoCategories.DATA,
  OTHER: TRANS.client.viewCompoCategories.OTHER,
  EFFECT: TRANS.client.viewCompoCategories.EFFECT
};

/** information to configure the builder to offer different options per component */
export interface ComponentConfig {
  label: string;
  displayName: ViewModel.DisplayName;
  allowedChildren: ViewModel.DisplayName[];
  isAvailable: boolean;
  category: string;
  icon: GeneralModel.IconName;
  color: GeneralModel.Color.ThemeColor;
  flag: string; // TODO: from common-api
}

const effectList = [
  ViewModel.DisplayName.CY_DATA_EFFECT,
  ViewModel.DisplayName.CY_ACTION_EFFECT,
  ViewModel.DisplayName.CY_LOAD_EFFECT,
  ViewModel.DisplayName.CY_UNLOAD_EFFECT,
  ViewModel.DisplayName.CY_INTERVAL_EFFECT
];

export const componentConfig: Partial<Record<ViewModel.DisplayName, Partial<ComponentConfig>>> = {
  [ViewModel.DisplayName.CY_LAYOUT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_LAYOUT],
    color: 'BASE_BACKGROUND',
    icon: 'space_dashboard',
    category: ComponentCategory.LAYOUT,
    allowedChildren: Object.values(ViewModel.DisplayName).filter(e => ![ViewModel.DisplayName.CY_MODAL].includes(e))
  },
  [ViewModel.DisplayName.CY_BLANK]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_BLANK],
    color: 'NEUTRAL_4',
    icon: 'check_box_outline_blank',
    category: ComponentCategory.LAYOUT
  },
  [ViewModel.DisplayName.CY_MODAL]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_MODAL],
    color: 'NEUTRAL_1',
    icon: 'present_to_all',
    category: ComponentCategory.LAYOUT,
    allowedChildren: Object.values(ViewModel.DisplayName).filter(e => ![ViewModel.DisplayName.CY_MODAL].includes(e))
  },
  [ViewModel.DisplayName.CY_INFO]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_INFO],
    isAvailable: false,
    color: 'NEUTRAL_3',
    icon: 'article',
    category: ComponentCategory.INFORMATION
  },
  [ViewModel.DisplayName.CY_SEARCH]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_SEARCH],
    isAvailable: true,
    color: 'PE_1',
    icon: 'pageview',
    category: ComponentCategory.INFORMATION,
    allowedChildren: [...effectList]
  },
  [ViewModel.DisplayName.CY_ACTION]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_ACTION],
    isAvailable: true,
    color: 'PE_6',
    icon: 'mouse',
    category: ComponentCategory.INFORMATION,
    allowedChildren: [...effectList]
  },
  [ViewModel.DisplayName.CY_TEXT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_TEXT],
    isAvailable: true,
    color: 'RD_3',
    icon: 'hdr_auto',
    category: ComponentCategory.INFORMATION,
    allowedChildren: [...effectList]
  },
  [ViewModel.DisplayName.CY_IMAGE]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_IMAGE],
    isAvailable: true,
    color: 'BRAND_4',
    icon: 'image',
    category: ComponentCategory.INFORMATION,
    allowedChildren: [...effectList]
  },
  [ViewModel.DisplayName.CY_FORM]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_FORM],
    color: 'BRAND_2',
    icon: 'description',
    category: ComponentCategory.DATA,
    allowedChildren: [...effectList]
  },
  [ViewModel.DisplayName.CY_LIST]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_LIST],
    color: 'GN_4',
    icon: 'view_list',
    category: ComponentCategory.DATA,
    allowedChildren: [...effectList, ViewModel.DisplayName.CY_ACTION, ViewModel.DisplayName.CY_TEXT, ViewModel.DisplayName.CY_IMAGE]
  },
  [ViewModel.DisplayName.CY_KANBAN]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_KANBAN],
    color: 'OE_5',
    icon: 'view_kanban',
    category: ComponentCategory.OTHER,
    allowedChildren: [...effectList, ViewModel.DisplayName.CY_ACTION, ViewModel.DisplayName.CY_TEXT, ViewModel.DisplayName.CY_IMAGE]
  },
  [ViewModel.DisplayName.CY_TABLE]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_TABLE],
    color: 'OE_5',
    icon: 'table',
    category: ComponentCategory.OTHER,
    flag: 'CY_TABLE', // TODO: from common-api
    allowedChildren: [...effectList, ViewModel.DisplayName.CY_ACTION, ViewModel.DisplayName.CY_TEXT, ViewModel.DisplayName.CY_IMAGE]
  },
  [ViewModel.DisplayName.CY_CHART]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_CHART],
    color: 'RD_1',
    icon: 'pie_chart',
    category: ComponentCategory.DATA,
    allowedChildren: [...effectList]
  },
  [ViewModel.DisplayName.CY_CALENDAR]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_CALENDAR],
    color: 'OE_1',
    icon: 'calendar_month',
    category: ComponentCategory.OTHER,
    allowedChildren: [...effectList],
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_ACTIVITY_LOG]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_ACTIVITY_LOG],
    color: 'OE_1',
    icon: 'device_reset',
    category: ComponentCategory.OTHER,
    allowedChildren: [...effectList],
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_VIEW]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_VIEW],
    color: 'NEUTRAL_3',
    icon: 'view_quilt',
    category: ComponentCategory.LAYOUT,
    allowedChildren: Object.values(ViewModel.DisplayName).filter(e => ![ViewModel.DisplayName.CY_MODAL].includes(e)),
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_VIEW_CONTENT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_VIEW_CONTENT],
    color: 'NEUTRAL_2',
    icon: 'shelf_auto_hide',
    category: ComponentCategory.LAYOUT,
    allowedChildren: [],
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_LOAD_EFFECT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_LOAD_EFFECT],
    color: 'PE_5',
    icon: 'play_arrow',
    category: ComponentCategory.EFFECT,
    allowedChildren: [],
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_UNLOAD_EFFECT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_UNLOAD_EFFECT],
    color: 'PE_5',
    icon: 'stop_circle',
    category: ComponentCategory.EFFECT,
    allowedChildren: [],
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_DATA_EFFECT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_DATA_EFFECT],
    color: 'PE_5',
    icon: 'topic',
    category: ComponentCategory.EFFECT,
    allowedChildren: [],
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_ACTION_EFFECT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_ACTION_EFFECT],
    color: 'PE_5',
    icon: 'contactless',
    category: ComponentCategory.EFFECT,
    allowedChildren: [],
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_INTERVAL_EFFECT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_INTERVAL_EFFECT],
    color: 'PE_5',
    icon: 'schedule',
    category: ComponentCategory.EFFECT,
    allowedChildren: [],
    isAvailable: true
  },
  [ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT]: {
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT],
    color: 'PE_5',
    icon: 'play_circle',
    category: ComponentCategory.EFFECT,
    allowedChildren: [],
    isAvailable: true
  },
  [ViewModel.DisplayName.VIEW_HEADER]: {
    isAvailable: false,
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.VIEW_HEADER],
    color: 'BRAND_1',
    icon: 'credit_card',
    allowedChildren: []
  },
  [ViewModel.DisplayName.NOT_FOUND]: { label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.NOT_FOUND], isAvailable: false, allowedChildren: [] },
  [ViewModel.DisplayName.GLOBAL_HEADER]: {
    isAvailable: false,
    label: TRANS.client.ViewCompoNames[ViewModel.DisplayName.GLOBAL_HEADER],
    color: 'BRAND_3',
    icon: 'web_asset',
    allowedChildren: []
  }
};

export const getPrettyDisplayName = (displayName: string = ''): string => {
  return capitalize(fromCamelCase(displayName.replace(/^cy/, '')).trim());
};

export const getComponentConfig = (displayName: ViewModel.DisplayName): ComponentConfig => ({
  ...{
    displayName,
    label: getPrettyDisplayName(displayName),
    allowedChildren: [],
    category: ComponentCategory.INFORMATION,
    isAvailable: true,
    icon: 'check_box_outline_blank',
    color: 'NEUTRAL_4',
    flag: null
  },
  ...deepClone(componentConfig[displayName] || ({} as Partial<ComponentConfig>))
});

export const getPartialViewSchema = (label: string, info?: any): GeneralModel.JSONSchema => ({
  type: 'object',
  label,
  info,
  metadata: { subtype: GeneralModel.JSONSchemaSubtype.FRAMED },
  properties: {
    viewId: {
      label: TRANS.client.fields.titles.customView,
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.COLLECTION_LOOKUP,
      metadata: { collectionId: ApiModel.ApiEntity.VIEW, fieldSize: GeneralModel.FieldSize.FULL, allowFormula: true }
    },
    input: {
      type: 'object',
      format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
      default: {},
      metadata: {
        subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
        fieldSize: GeneralModel.FieldSize.FULL,
        collectionId: ApiModel.ApiEntity.VIEW,
        recordId: '{{event.parent.value.viewId}}',
        allowFormula: true,
        hidden: { $cyf_not: [{ $cyf_exists: ['{{event.parent.value.viewId}}'] }] }
      }
    }
  }
});
