import React, { ChangeEvent, forwardRef, useCallback, useContext, useEffect, useId, useMemo, useRef, useState } from 'react';

import { applyMask, GeneralModel, isObject, useTranslate, ViewModel } from '@cyferd/client-engine';

import { THEME } from '@constants';
import { styles } from './styles';
import { CyWrapperContext } from '../../smart/CyWrapper';
import { InputSuggestions } from './components/InputSuggestions';
import { MenuOption, OptionMenuProps } from '../OptionMenu';
import { CTAType } from '../CTA';
import dayjs from 'dayjs';
import { getValidDayjs } from '@utils/getValidDayjs';
import { UIContext } from '@components/providers/UIprovider';
import { InputWrapper } from '../InputWrapper';
import { useOutsideClick } from '@utils/useOutsideClick';

/** note not all string/number/integer formats are handled by this input  */
export type InputTypes =
  | GeneralModel.JSONSchemaFormat.ADDRESS
  | GeneralModel.JSONSchemaFormat.TEXT
  | GeneralModel.JSONSchemaFormat.COLOR
  | GeneralModel.JSONSchemaFormat.DATE
  | GeneralModel.JSONSchemaFormat.DATE_TIME_U
  | GeneralModel.JSONSchemaFormat.EMAIL
  | GeneralModel.JSONSchemaFormat.MONTH
  | GeneralModel.JSONSchemaFormat.WEEK
  | GeneralModel.JSONSchemaFormat.TIME
  | GeneralModel.JSONSchemaFormat.PASSWORD
  | GeneralModel.JSONSchemaFormat.URL
  | GeneralModel.JSONSchemaFormat.MULTILINE
  | GeneralModel.JSONSchemaFormat.NUMBER
  | GeneralModel.JSONSchemaFormat.RANGE;

type InputClipboardEventCallback = (event: React.ClipboardEvent<HTMLInputElement>) => void;
type TextareaClipboardEventCallback = (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;

export interface InputProps {
  autoFocus?: boolean;
  allowFileInput?: boolean;
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  id?: string;
  label?: string;
  max?: number;
  maxLength?: number;
  min?: number;
  minLength?: number;
  color?: GeneralModel.Color.ThemeColor;
  name?: string;
  onChange: (value: any) => void;
  onCopy?: InputClipboardEventCallback | TextareaClipboardEventCallback;
  onPaste?: InputClipboardEventCallback | TextareaClipboardEventCallback;
  placeholder?: string;
  required?: boolean;
  rows?: number;
  testid?: string;
  type?: InputTypes;
  value?: string | number;
  metadata?: GeneralModel.JSONSchemaMetadata;
  optionList?: OptionMenuProps['optionList'];
  suggestionList?: GeneralModel.JSONSchema['metadata']['suggestionList'];
  onFocus?: () => void;
  onBlur?: () => void;
  showClear?: boolean;
  onCustomClear?: () => void;
  disableFocusable?: boolean;
  readOnly?: boolean;
  info?: string;
  unlimitedHeight?: boolean;
  disabledType?: GeneralModel.DisabledType;
  noWrapperPadding?: boolean;
  path?: string[];
  inputContainerRef?: React.RefObject<HTMLDivElement>;
}

export const Input = forwardRef(
  (
    {
      autoFocus,
      allowFileInput,
      description,
      disabled,
      errorMessage,
      id,
      name,
      onChange,
      onCopy,
      onPaste,
      placeholder,
      color,
      required,
      testid,
      type = GeneralModel.JSONSchemaFormat.TEXT,
      value,
      metadata: { mask, calculation } = {},
      onFocus,
      onBlur,
      optionList,
      showClear,
      onCustomClear,
      disableFocusable,
      readOnly = false,
      suggestionList,
      info,
      unlimitedHeight,
      disabledType,
      path,
      inputContainerRef: outerInputContainerRef,
      ...props
    }: InputProps,
    ref
  ) => {
    const { translate } = useTranslate();
    const [internalValue, setInternalValue] = useState([null, undefined].includes(value) ? '' : value);

    const { useOnOpenEmailClient, useOnOpenExternalUrl } = useContext(CyWrapperContext);
    const onOpenEmailClient = useOnOpenEmailClient();
    const onOpenExternalUrl = useOnOpenExternalUrl();
    const generatedId = useId();
    const internalId = id || generatedId;
    const safeValue = internalValue || internalValue === 0 ? internalValue : '';
    const [isOpen, setOpen] = useState<boolean>(false);
    const onClickOutside = useCallback(() => setOpen(false), []);
    const outsideRef: React.LegacyRef<HTMLDivElement> = useOutsideClick(onClickOutside);

    const { runtimeTheme } = useContext(UIContext);
    const textareaRef = useRef<HTMLTextAreaElement>();
    const inputRef = useRef<HTMLInputElement>();
    const innerInputContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(inputRef.current);
        } else {
          ref.current = inputRef.current;
        }
      }
    }, [ref]);

    const isDateLike = useMemo(
      () =>
        [
          GeneralModel.JSONSchemaFormat.DATE,
          GeneralModel.JSONSchemaFormat.DATE_TIME,
          GeneralModel.JSONSchemaFormat.DATE_TIME_U,
          GeneralModel.JSONSchemaFormat.MONTH,
          GeneralModel.JSONSchemaFormat.WEEK,
          GeneralModel.JSONSchemaFormat.TIME
        ].includes(type),
      [type]
    );

    const isDateTime = useMemo(() => [GeneralModel.JSONSchemaFormat.DATE_TIME_U, GeneralModel.JSONSchemaFormat.DATE_TIME].includes(type), [type]);

    const [focused, setFocused] = useState(false);

    const onSelectSuggestion = useCallback(
      newVal => {
        setOpen(false);
        onChange(newVal);
      },
      [onChange]
    );

    const onInternalBlur = useCallback(() => {
      if (typeof onBlur === 'function') onBlur();
      setFocused(false);
    }, [onBlur]);

    const onInternalFocus = useCallback(() => {
      if (typeof onFocus === 'function') onFocus();
      setFocused(true);
      setOpen(true);
    }, [onFocus]);

    const shouldMaskValue = !calculation && !!mask;
    const maskedValue = shouldMaskValue ? applyMask(safeValue, mask) || '' : '';
    const showMaskedvalue = useMemo(() => !!(!focused && shouldMaskValue), [focused, shouldMaskValue]);

    const inputValue = useMemo(() => {
      if (showMaskedvalue) return String(maskedValue);
      if (!!safeValue && isDateTime) {
        return getValidDayjs(dayjs(new Date(safeValue).getTime() - new Date(safeValue).getTimezoneOffset() * 60000))
          .toISOString()
          .slice(0, -1);
      }
      if (!!safeValue && type === GeneralModel.JSONSchemaFormat.DATE && !/^(?!0{4})\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(safeValue as string)) {
        return getValidDayjs(dayjs(new Date(safeValue))).format('YYYY-MM-DD');
      }
      return safeValue;
    }, [isDateTime, maskedValue, safeValue, showMaskedvalue, type]);

    const safeType = useMemo(() => {
      if (([GeneralModel.JSONSchemaFormat.CURRENCY, GeneralModel.JSONSchemaFormat.NUMBER].includes(type) && disabled) || (shouldMaskValue && showMaskedvalue)) {
        return GeneralModel.JSONSchemaFormat.TEXT;
      }
      if ([GeneralModel.JSONSchemaFormat.CURRENCY].includes(type)) return GeneralModel.JSONSchemaFormat.NUMBER;
      if (
        [
          GeneralModel.JSONSchemaFormat.ADDRESS,
          GeneralModel.JSONSchemaFormat.URL,
          GeneralModel.JSONSchemaFormat.ICON,
          GeneralModel.JSONSchemaFormat.CREDIT_CARD_NUMBER,
          GeneralModel.JSONSchemaFormat.IBAN,
          GeneralModel.JSONSchemaFormat.ISIN,
          GeneralModel.JSONSchemaFormat.DUNS,
          GeneralModel.JSONSchemaFormat.ISBN,
          GeneralModel.JSONSchemaFormat.QR,
          GeneralModel.JSONSchemaFormat.BARCODE,
          GeneralModel.JSONSchemaFormat.PHONE_NUMBER,
          GeneralModel.JSONSchemaFormat.COLOR
        ].includes(type)
      ) {
        return GeneralModel.JSONSchemaFormat.TEXT;
      }

      if ([GeneralModel.JSONSchemaFormat.DATE_TIME_U].includes(type)) return GeneralModel.JSONSchemaFormat.DATE_TIME;

      return type;
    }, [type, disabled, showMaskedvalue, shouldMaskValue]);

    const isNumericType = useMemo(() => [...GeneralModel.typeToFormatMap.number, ...GeneralModel.typeToFormatMap.integer].includes(safeType), [safeType]);

    useEffect(() => {
      if (Number(value) !== Number(internalValue) || !isNumericType) setInternalValue(value);
    }, [internalValue, isNumericType, value]);

    const errorId = errorMessage ? `${id}-error` : undefined;
    const internalTestid = testid || `${type}-input`;

    const hasMoreThanOneAction = optionList?.length > 0;

    const safeOptionList = useMemo(
      () =>
        [
          type === GeneralModel.JSONSchemaFormat.URL && {
            testid: `${internalTestid}-open-url-btn`,
            type: CTAType.LINK,
            onClick: () => onOpenExternalUrl(safeValue as string),
            image: 'open_in_new',
            disabled: !!errorMessage || !safeValue,
            tooltip: 'Open URL',
            important: !hasMoreThanOneAction,
            size: ViewModel.CTASize.MEDIUM
          },
          type === GeneralModel.JSONSchemaFormat.EMAIL && {
            testid: `${internalTestid}-open-email-client-btn`,
            type: CTAType.LINK,
            onClick: () => onOpenEmailClient(safeValue as string),
            image: 'mail',
            disabled: !!errorMessage || !safeValue,
            tooltip: 'Send email',
            important: !hasMoreThanOneAction,
            size: ViewModel.CTASize.MEDIUM
          },
          ...(optionList || [])
        ]
          .filter(isObject)
          .map(o => (o.size === ViewModel.CTASize.MEDIUM ? o : { ...o, size: ViewModel.CTASize.MEDIUM })) as MenuOption[],
      [errorMessage, hasMoreThanOneAction, internalTestid, onOpenEmailClient, onOpenExternalUrl, optionList, safeValue, type]
    );

    const onInternalChange = useCallback(
      (newValue: string) => {
        const parsedValue: number | string = (() => {
          if (newValue === '') return isDateLike ? undefined : null;
          if (isNumericType) return Number(newValue);
          if (isDateTime) return new Date(newValue).toISOString();
          return newValue;
        })();
        setInternalValue(newValue);
        /* istanbul ignore else */
        if (!isNumericType || (inputValue !== newValue && inputValue === '') || (inputValue !== newValue && Number(newValue) !== Number(inputValue)))
          onChange(parsedValue);
      },
      [isNumericType, inputValue, onChange, isDateLike, isDateTime]
    );

    const onClear = () => {
      if (typeof onCustomClear === 'function') onCustomClear();
      else onInternalChange('');
    };

    const onInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onInternalChange(event.target.value);
    };

    /* istanbul ignore next line */
    useEffect(() => {
      const preventWheel = (e: WheelEvent) => e.preventDefault();
      const element = inputRef?.current;
      if (!element || element.type !== 'number') return;
      element.addEventListener('wheel', preventWheel, { passive: false });
      return function cleanEventListener() {
        element.removeEventListener('wheel', preventWheel);
      };
    }, [inputRef]);

    return (
      <div ref={outsideRef}>
        <InputWrapper
          color={color}
          allowFileInput={allowFileInput}
          description={description}
          disabled={!!disabled}
          errorMessage={errorMessage}
          focused={focused}
          id={id}
          label={props.label}
          onChange={onChange}
          onClear={![null, undefined, ''].includes(value as string) && !!showClear && !disabled && onClear}
          optionList={safeOptionList}
          required={required}
          testid={testid}
          value={inputValue}
          unlimitedHeight={safeType === GeneralModel.JSONSchemaFormat.MULTILINE}
          info={info}
          disabledType={disabledType}
          showPlaceholderLine={true}
          inputContainerRef={outerInputContainerRef || innerInputContainerRef}
          noLabelPadding={props.noWrapperPadding}
        >
          <div css={styles.wrappedContent}>
            {safeType === GeneralModel.JSONSchemaFormat.MULTILINE ? (
              <textarea
                ref={textareaRef}
                autoComplete="off"
                autoFocus={autoFocus}
                value={inputValue}
                id={internalId}
                name={name}
                placeholder={translate(placeholder)}
                disabled={!!disabled}
                onChange={onInputChange}
                onPaste={onPaste as TextareaClipboardEventCallback}
                onCopy={onCopy as TextareaClipboardEventCallback}
                css={styles.wrappedTextarea}
                aria-errormessage={errorId}
                aria-invalid={JSON.stringify(!!errorMessage) as 'true' | 'false'}
                rows={
                  props.rows ||
                  Math.max(
                    1,
                    Math.min(
                      unlimitedHeight ? Infinity : 20,
                      Math.floor((String(value).length * 6) / Number(textareaRef.current?.scrollWidth || Infinity)) + String(value).split(/\n/).length
                    )
                  )
                }
                required={required}
                maxLength={props.maxLength}
                minLength={props.minLength}
                data-testid={internalTestid}
                data-focusable={!disableFocusable && !disabled}
                onBlur={onInternalBlur}
                onFocus={onInternalFocus}
              />
            ) : (
              <input
                aria-errormessage={errorId}
                aria-invalid={JSON.stringify(!!errorMessage) as 'true' | 'false'}
                autoFocus={autoFocus}
                autoComplete={type === GeneralModel.JSONSchemaFormat.PASSWORD ? 'new-password' : 'off'}
                css={[styles.wrappedInput, runtimeTheme === THEME.DARK && /* istanbul ignore next */ styles.darkScheme]}
                data-focusable={!disableFocusable && !disabled}
                data-testid={internalTestid}
                disabled={!!disabled}
                id={internalId}
                ref={inputRef}
                max={props.max}
                min={props.min}
                name={name}
                onChange={onInputChange}
                onCopy={onCopy as InputClipboardEventCallback}
                onPaste={onPaste as InputClipboardEventCallback}
                placeholder={translate(placeholder)}
                required={required}
                type={safeType}
                step={type === GeneralModel.JSONSchemaFormat.TIME ? 1 : undefined}
                value={inputValue}
                onBlur={onInternalBlur}
                onFocus={onInternalFocus}
                readOnly={readOnly}
              />
            )}
          </div>
        </InputWrapper>
        {!!suggestionList?.length && !!isOpen && (
          <InputSuggestions
            path={path}
            onChange={onSelectSuggestion}
            suggestionList={suggestionList}
            testId={internalTestid}
            value={value}
            inputContainerRef={outerInputContainerRef || innerInputContainerRef}
          />
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
