/* istanbul ignore file */

import { EvaluatorTree } from '@components/elements/Evaluator/EvaluatorTree';
import { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { RichText } from '@components/elements/RichTextEditor';
import { noop } from '@cyferd/client-engine';
import { DragDropContext } from '@hello-pangea/dnd';

export interface ApiRefFormulasProps {
  fx: FormulaInputRow;
}

export const evaluatorPagesReferencesSearchContent = `<p>Another key aspect of the evaluator is that we can reference data we have available, so it can be used as input for our formulas. For example, lets imagine we have the following data available:</p><p><br></p>
<pre class="ql-syntax" spellcheck="false">
{
  "user": {
      "name": "Michael",
      "email": "test@test.test"
  }
}
</pre>

<p><br></p><p>In order to use this data as, we need to use a&nbsp;reference. A reference indicates the evaluator it needs to look for a specific value within the data available by wrapping the name of the value we want within curly braces like these <strong>{{reference}}</strong>. For example, if we want to welcome a user we would do:</p><p><br></p>

<p>A formula that would return <strong>Hello Michael!</strong> would look like this:</p>
`;

export const EvaluatorPagesReferences = () => {
  return (
    <DragDropContext onDragEnd={noop}>
      <RichText value={evaluatorPagesReferencesSearchContent} />
      <div>
        <EvaluatorTree
          readonly={true}
          value={{ $cyf_concatenate: ['Hello ', '{{user.name}}', '!'] }}
          inputList={[]}
          activePath={null}
          pathBeingDragged={null}
          height="120px"
          onChange={noop}
          onChangeActive={noop}
          onKeyChange={noop}
        />
      </div>
    </DragDropContext>
  );
};

export const EvaluatorPagesExamples = () => {
  return (
    <DragDropContext onDragEnd={noop}>
      <RichText
        value={`<p>The evaluator provides a list of&nbsp;<strong>Formulas</strong>&nbsp;that can take specific inputs and output a result.<br/><br/>For example, we can add numbers. In this case adding "1 + 1" outputs 3</p>`}
      />
      <div>
        <EvaluatorTree
          readonly={true}
          value={{ $cyf_add: [1, 2] }}
          inputList={[]}
          activePath={null}
          pathBeingDragged={null}
          height="100px"
          onChange={noop}
          onChangeActive={noop}
          onKeyChange={noop}
        />
      </div>
      <RichText
        value={`<p>A key feature is that <strong>formulas</strong> can be combined. We can use a formula as the input for another formula.<br/>For example, we could do some complex math. In this case "(5*2) + (10/2)" outputs 15</p>`}
      />
      <div>
        <EvaluatorTree
          readonly={true}
          value={{ $cyf_add: [{ $cyf_multiply: [5, 2] }, { $cyf_divide: [10, 2] }] }}
          inputList={[]}
          activePath={null}
          pathBeingDragged={null}
          height="210px"
          onChange={noop}
          onChangeActive={noop}
          onKeyChange={noop}
        />
      </div>
      <RichText value={`<p>This is not just limited to numbers, we could also do text transformations. This example would output "Hello!!"</p>`} />
      <div>
        <EvaluatorTree
          readonly={true}
          value={{
            $cyf_concatenate: [{ $cyf_upper: [{ $cyf_left: [{ $cyf_trim: ['   hello   '] }, 1] }] }, { $cyf_lower: [{ $cyf_right: ['HELLO', 4] }] }, '!!']
          }}
          inputList={[]}
          activePath={null}
          pathBeingDragged={null}
          height="310px"
          onChange={noop}
          onChangeActive={noop}
          onKeyChange={noop}
        />
      </div>
    </DragDropContext>
  );
};
