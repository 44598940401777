import React, { useCallback, useContext, useMemo } from 'react';

import { CollectionModel, GeneralModel } from '@cyferd/client-engine';
import { FormulaInputRow } from '@components/elements/Evaluator/resources';

import { getEntityFullInputList } from '@models/entity';
import { useParsers } from '@utils/useParsers';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { SchemaForm } from '../../../shared/SchemaForm';
import { getSettingsSchema, settingsDetailGroupList } from './helpers';
import { EmptyState } from '@components/elements/EmptyState';

export const DefaultSettings = () => {
  const { item, setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);
  const { parseData } = useParsers();

  const value = useMemo(
    () => ({
      recordTitle: item?.schema?.properties?.recordTitle?.metadata?.calculation,
      recordDescription: item?.schema?.properties?.recordDescription?.metadata?.calculation,
      recordImage: item?.schema?.properties?.recordImage?.metadata?.calculation,
      recordColor: item?.schema?.properties?.recordColor?.metadata?.calculation,
      globalSearchable: item?.globalSearchable,
      defaultTab: item?.defaultTab,
      limit: item?.options?.limit,
      descending: item?.options?.descending,
      orderBy: item?.options?.orderBy,
      defaultKanbanField: item?.defaultKanbanField
    }),
    [
      item?.schema?.properties?.recordTitle?.metadata?.calculation,
      item?.schema?.properties?.recordDescription?.metadata?.calculation,
      item?.schema?.properties?.recordImage?.metadata?.calculation,
      item?.schema?.properties?.recordColor?.metadata?.calculation,
      item?.globalSearchable,
      item?.defaultTab,
      item?.options?.limit,
      item?.options?.descending,
      item?.options?.orderBy,
      item?.defaultKanbanField
    ]
  );

  const schema: GeneralModel.JSONSchema = useMemo(() => getSettingsSchema(item, parseData), [item, parseData]);

  const inputList: FormulaInputRow[] = useMemo(() => getEntityFullInputList(item?.schema), [item?.schema]);

  const onChange = useCallback(
    (event: CollectionModel.Collection | any) => {
      setItem({
        ...item,
        schema: {
          ...item.schema,
          properties: {
            ...item.schema.properties,
            recordTitle: {
              ...item.schema.properties.recordTitle,
              metadata: { ...item.schema.properties.recordTitle?.metadata, calculation: event.recordTitle }
            },
            recordDescription: {
              ...item.schema.properties.recordDescription,
              metadata: { ...item.schema.properties.recordTitle?.metadata, calculation: event.recordDescription }
            },
            recordImage: {
              ...item.schema.properties.recordImage,
              metadata: { ...item.schema.properties.recordTitle?.metadata, calculation: event.recordImage }
            },
            recordColor: {
              ...item.schema.properties.recordColor,
              metadata: { ...item.schema.properties.recordTitle?.metadata, calculation: event.recordColor }
            }
          }
        },
        options: { ...item.options, limit: event?.limit, orderBy: event?.orderBy, descending: event?.descending },
        defaultKanbanField: event.defaultKanbanField,
        globalSearchable: event?.globalSearchable,
        defaultTab: event?.defaultTab
      });
    },
    [item, setItem]
  );

  if (!item) return <EmptyState />;

  return (
    <div id="collection-default-settings">
      <SchemaForm detailGroupList={settingsDetailGroupList} onChange={onChange} schema={schema} value={value} wrapDetailGroups={true} inputList={inputList} />
    </div>
  );
};
