import React from 'react';

import { SvgProps } from '@components/elements/Icon/SVG/types';

const StarsSvg = ({ ...props }: SvgProps) => (
  <svg width={204} height={139} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={58.041} cy={78.429} r={55.502} fill="var(--NEUTRAL_3)" />
    <circle r={35.5} transform="matrix(-1 0 0 1 157.5 46.5)" fill="var(--NEUTRAL_3)" />
    <circle r={4.5} transform="matrix(-1 0 0 1 144.5 107.5)" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <circle r={4} transform="matrix(-1 0 0 1 13 129)" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <circle r={2} transform="matrix(-1 0 0 1 3.38 116.196)" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <circle r={2} transform="matrix(-1 0 0 1 12 19)" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 7h4M21 5v4M166.841 100.496H173M169.92 97.417v6.159" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <circle r={2.5} transform="matrix(-1 0 0 1 156.5 92.5)" stroke="var(--BRAND_1)" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M36.833 29.217 33.786 64.51a2 2 0 0 1-1.047 1.59L4.064 81.501c-1.401.753-1.406 2.76-.01 3.52l29.847 16.22a2 2 0 0 1 .994 1.309l7.627 33.124c.343 1.489 2.162 2.059 3.293 1.033l28.543-25.903c.38-.345.879-.53 1.392-.518l37.497.898c1.537.037 2.539-1.602 1.805-2.953L99.648 79.843a2 2 0 0 1-.186-1.425l7.917-32.709a2 2 0 0 0-2.263-2.445L71.084 48.76a2 2 0 0 1-1.463-.334L39.97 27.748c-1.263-.88-3.005-.064-3.137 1.469Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.551 80.316a1 1 0 1 0 1.898-.632l-1.898.632Zm8.987-4.541a1 1 0 0 0 1.924-.55l-1.924.55Zm-7.09 3.909c-.72-2.165-1.146-4.671-1.01-6.702.14-2.118.832-3.236 1.834-3.52l-.544-1.924c-2.325.657-3.135 3.04-3.286 5.31-.158 2.36.33 5.133 1.11 7.468l1.897-.632Zm.824-10.222c1.122-.317 2.38.225 3.62 1.555 1.214 1.302 2.185 3.142 2.646 4.758l1.924-.55c-.539-1.884-1.654-4.014-3.108-5.572-1.428-1.531-3.42-2.739-5.626-2.115l.544 1.924ZM69.051 71.816a1 1 0 0 0 1.898-.632l-1.898.632Zm2.45-10.316.186.982-.187-.982Zm6.08 7.894a1 1 0 0 0 1.838-.788l-1.838.788Zm-6.632 1.79c-.462-1.385-.818-3.615-.7-5.534.058-.962.23-1.765.508-2.327.266-.541.578-.774.93-.84l-.373-1.965c-1.149.218-1.9 1.003-2.352 1.921-.442.898-.644 1.997-.71 3.089-.133 2.188.261 4.673.8 6.288l1.897-.632Zm.738-8.702c.903-.171 1.939.397 3.09 1.86 1.105 1.404 2.063 3.324 2.804 5.052l1.838-.788c-.76-1.772-1.8-3.887-3.07-5.5-1.224-1.555-2.938-2.987-5.035-2.588l.373 1.964Z"
      fill="var(--BRAND_1)"
    />
    <path
      d="M79.718 77.743 49.9 87.456c-.69.225-.928 1.093-.472 1.659 4.056 5.037 9.167 13.728 22.083 9.136 9.178-3.263 10.067-13.79 9.477-19.7-.062-.616-.682-1-1.271-.808Z"
      fill="var(--NEUTRAL_4)"
    />
    <path
      d="m49.9 87.456-.309-.95.31.95Zm29.818-9.713.31.951-.31-.95Zm1.27.808.996-.1-.995.1Zm-9.476 19.7.335.942-.335-.942ZM49.43 89.115l.778-.627-.778.627Zm.782-.708 29.817-9.713-.62-1.901-29.817 9.713.62 1.901Zm29.783-9.757c.286 2.869.207 6.822-1.026 10.419-1.225 3.57-3.56 6.736-7.79 8.24l.67 1.884c4.946-1.759 7.644-5.489 9.011-9.475 1.358-3.96 1.428-8.226 1.125-11.266l-1.99.198ZM71.177 97.31c-6.184 2.199-10.361 1.194-13.48-.851-3.228-2.116-5.383-5.354-7.49-7.97l-1.557 1.254c1.95 2.421 4.38 6.047 7.95 8.388 3.679 2.412 8.515 3.457 15.247 1.063l-.67-1.884Zm8.85-18.615c.004 0 .003 0 0 0a.02.02 0 0 1-.009-.004.067.067 0 0 1-.025-.04l1.99-.198c-.128-1.286-1.412-2.038-2.575-1.66l.62 1.902Zm-30.436 7.812c-1.39.452-1.814 2.153-.941 3.236l1.557-1.254a.051.051 0 0 1-.011-.025.079.079 0 0 1 .002-.035.052.052 0 0 1 .01-.02h-.001.004l-.62-1.902Z"
      fill="var(--BRAND_1)"
    />
    <path
      d="m163.495 2.925-16.678 22.167a2 2 0 0 1-1.386.786l-21.22 2.267c-1.476.157-2.272 1.813-1.473 3.065l10.857 17.002c.271.424.372.934.283 1.429l-3.989 22.253a2 2 0 0 0 2.433 2.298l24.142-5.768a2.002 2.002 0 0 1 1.24.101l25.846 10.86a2 2 0 0 0 2.775-1.844V55.495c0-.472.166-.928.47-1.288l14.363-17.069c.964-1.146.373-2.906-1.087-3.238l-22.759-5.172a2 2 0 0 1-1.432-1.256l-8.911-24.04c-.55-1.483-2.523-1.77-3.474-.507Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M146.5 51.5c2.954 8.98 16.74 7.873 20.684.832" stroke="var(--BRAND_1)" strokeWidth={2} strokeLinecap="round" />
    <path
      d="M148.847 38.926c-.171.861-.522 1.577-.926 2.041-.414.477-.804.61-1.084.554-.28-.055-.589-.328-.789-.927-.195-.583-.245-1.378-.074-2.24.171-.862.522-1.578.926-2.042.414-.476.804-.61 1.084-.554.28.056.589.328.789.927.195.583.245 1.379.074 2.24ZM168.149 41.16c-.172.861-.523 1.577-.926 2.041-.414.477-.804.61-1.084.554-.28-.055-.589-.328-.789-.927-.195-.583-.246-1.378-.074-2.24.171-.862.522-1.578.926-2.042.414-.476.803-.61 1.084-.554.28.056.589.329.789.927.195.583.245 1.379.074 2.24Z"
      fill="var(--BRAND_4)"
      stroke="var(--BRAND_1)"
    />
    <path
      d="M34.042 64.95 37 26l17.5 12-11.853-4.559a1 1 0 0 0-1.346.771l-5.23 31.854a1 1 0 0 1-.44.675L11.337 82.627a1 1 0 0 0 .032 1.694L37.18 99.808c.206.124.361.318.436.546l8.936 27.279a1 1 0 0 0 1.573.471l25.602-20.386a1 1 0 0 1 .623-.218H111a1 1 0 0 0 1-1V103l5 8.5-41.606-.991c-.253-.006-.5.085-.689.253L43 139l-7.902-36.545a1.002 1.002 0 0 0-.51-.673L0 83.5l33.513-17.742a1 1 0 0 0 .529-.808ZM97.304 77.24 98.636 80 107 43l-4.333.521 2.065 1.078a1 1 0 0 1 .481 1.218l-8.338 23.765a10 10 0 0 0 .43 7.657Z"
      fill="var(--NEUTRAL_4)"
      style={{
        mixBlendMode: 'multiply'
      }}
    />
    <path
      d="m49 88 3.5 5L81 83v-5.5L49 88Z"
      fill="var(--NEUTRAL_4)"
      style={{
        mixBlendMode: 'multiply'
      }}
    />
    <g
      style={{
        mixBlendMode: 'multiply'
      }}
      fill="var(--NEUTRAL_4)"
    >
      <path d="m156.507 66.09-23.118 4.247a1.04 1.04 0 0 1-1.175-.695c-.335-1.007-1.786-.923-2.005.115l-.605 2.872a1.836 1.836 0 0 0 2.242 2.16l24.505-6.127c.421-.105.865-.07 1.265.1l26.104 11.06a2 2 0 0 0 2.78-1.842V55.738a2 2 0 0 1 .479-1.299l14.862-17.41c.979-1.147.385-2.923-1.088-3.25L177 28.5l16.74 7.048a2 2 0 0 1 .873 2.975L184.29 53.578c-.19.277-.308.597-.341.932l-1.703 17.031a2 2 0 0 1-2.6 1.706l-22.168-7.094a2 2 0 0 0-.971-.062ZM170.253 24.946l-4.899-20.932a.95.95 0 0 1 1.818-.538L176 28l-4.483-1.63a2.002 2.002 0 0 1-1.264-1.424ZM134.459 44.847 123 31l11.5 19 .406-3.657a1.997 1.997 0 0 0-.447-1.496Z" />
    </g>
  </svg>
);

export default StarsSvg;
