import { SvgProps } from '../types';

export const Success = ({ size, ...props }: SvgProps) => (
  <svg width={size || 24} height={size || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Z" fill="var(--GN_3)" fillOpacity={0.1} />
    <path
      d="M17.681 8.382a.328.328 0 0 0-.469 0l-6.843 6.637c-.056.056-.131.056-.188 0l-3.393-3.3a.328.328 0 0 0-.47 0 .328.328 0 0 0 0 .469l3.395 3.3c.15.15.356.225.543.225a.75.75 0 0 0 .544-.225l6.844-6.638a.33.33 0 0 0 .037-.468Z"
      fill="var(--GN_2)"
    />
  </svg>
);
