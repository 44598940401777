import {
  CollectionModel,
  DeepDiff,
  GeneralModel,
  capitalize,
  fromCamelCase,
  getDeepDiff,
  getFlatSchemaProps,
  listToMap,
  mergeTruthy,
  normalize,
  removeDeep,
  replaceDeep,
  useTranslate
} from '@cyferd/client-engine';
import { getLabel } from '@utils';
import { getFieldConfig } from '../../../builder/views/shared/SchemaEditor/schemas';
import { get } from 'lodash';

const findFieldConfig = (path: string[], configMap: Record<string, ReturnType<typeof getFlatSchemaMap>[0]>): ReturnType<typeof getFlatSchemaMap>[0] => {
  return path.reduce(
    (found, _, index) => found || configMap[path.slice(0, path.length - index).join('.')],
    undefined as ReturnType<typeof getFlatSchemaMap>[0]
  );
};

const getFlatSchemaMap = (schema: GeneralModel.JSONSchema) =>
  listToMap(getFlatSchemaProps(schema, { includeArrays: true }).map(i => ({ ...i, id: i.definitionPath.join('.') })));

const getIsFieldPath = (path: string[]) => ['properties', 'items'].includes(path[path.length - 2]);
const ignoredFields = ['detailOrder', 'order'];

export interface DiffRow extends DeepDiff {
  typeLabel?: string;
  title: string;
  propertyName?: string;
  property?: string;
  deepDiff?: DiffRow[];
}

export const quickFiltersCursor: CollectionModel.QuickFilter[] = [
  { id: 'added', name: 'Added', color: 'GN_1' },
  { id: 'removed', name: 'Removed', color: 'RD_1' },
  { id: 'modified', name: 'Modified', color: 'BE_1' }
];

const changeColorFx = {
  $cyf_switch: [{ $cyf_eq: ['{{event.item.change}}', 'added'] }, 'GN_1', { $cyf_eq: ['{{event.item.change}}', 'removed'] }, 'RD_1', 'BE_1']
};

export const diffSchema = normalize.schema(
  {
    type: 'object',
    properties: {
      recordColor: { type: 'string', metadata: { calculation: changeColorFx, detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } },
      title: { label: ' ', type: 'string' },
      recordTitle: { type: 'string', metadata: { calculation: '{{event.item.title}}', detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } },
      recordDescription: {
        type: 'string',
        metadata: { calculation: '{{event.item.propertName}}', detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER }
      },
      propertyName: { label: 'Property', type: 'string' },
      prev: { label: 'Previous value', type: 'any', format: GeneralModel.JSONSchemaFormat.EVALUATION },
      next: { label: 'Current value', type: 'any', format: GeneralModel.JSONSchemaFormat.EVALUATION },
      change: { type: 'string', metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } },
      displayChange: { label: 'Change', type: 'string', metadata: { color: changeColorFx, calculation: { $cyf_capitalize: ['{{event.item.change}}'] } } }
    }
  },
  { avoidAlphabeticalSort: true }
);

export const fieldSchema = normalize.schema(mergeTruthy(diffSchema, { properties: { title: { label: 'Field' } } }));
export const groupsSchema = normalize.schema(mergeTruthy(diffSchema, { properties: { title: { label: 'Group' } } }));
export const allSchema = normalize.schema(
  mergeTruthy(diffSchema, {
    properties: { title: { label: 'Path' }, propertyName: { metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } } }
  })
);

export const activeRowSchema = normalize.schema(
  mergeTruthy(diffSchema, {
    properties: { title: { label: 'Path' }, propertyName: { metadata: { detailPriority: GeneralModel.JSONSchemaPropertyPriority.OTHER } } }
  })
);

export const getSchemaDiff = (
  safePrev: CollectionModel.Collection,
  safeNext: CollectionModel.Collection,
  translate: ReturnType<typeof useTranslate>['translate']
): DiffRow[] => {
  const flatSchemaPrev = getFlatSchemaMap(safePrev.schema);
  const flatSchemaNext = getFlatSchemaMap(safeNext?.schema);

  return getDeepDiff(safePrev?.schema, safeNext?.schema, { getShouldCompareIterable: ({ path }) => path[path.length - 2] === 'metadata' })
    .map(diff => {
      const fieldConfig = findFieldConfig(diff.path, diff.change === 'removed' ? flatSchemaPrev : flatSchemaNext);
      const property = getIsFieldPath(diff.path) ? null : diff.path[diff.path.length - 1];
      return {
        ...diff,
        typeLabel: 'Fields',
        path: ['schema', ...diff.path],
        title: getLabel(fieldConfig?.displayNamePath),
        property,
        propertyName: getIsFieldPath(diff.path)
          ? 'Field'
          : translate(getFieldConfig(property as any)?.label)?.trim?.() || capitalize(fromCamelCase(property || /* istanbul ignore next */ '')),
        deepDiff: getDeepDiff(diff.prev, diff.next).map(deep => ({ ...deep, title: getLabel(deep.path), propertyName: null }))
      };
    })
    .filter(
      diff =>
        diff.deepDiff.length && diff.propertyName !== 'Field name' /** normalization for title/label prevents undo */ && !ignoredFields.includes(diff.property)
    );
};

export const getDetailGroupDiff = (
  safePrev: CollectionModel.Collection,
  safeNext: CollectionModel.Collection,
  translate: ReturnType<typeof useTranslate>['translate']
): DiffRow[] => {
  const detailGroupMapPrev = listToMap(safePrev?.detailGroupList);
  const detailGroupMapNext = listToMap(safeNext?.detailGroupList);

  return getDeepDiff(detailGroupMapPrev, detailGroupMapNext, { maxDepth: 2 })
    .map(diff => ({
      ...diff,
      typeLabel: 'Detail groups',
      path: ['detailGroupList', ...diff.path], // path is incomplete, but array changes can't be safetely undone
      title: detailGroupMapNext[diff.path[0]]?.name || detailGroupMapPrev[diff.path[0]]?.name,
      propertyName: translate(getFieldConfig(diff.path[1] as any)?.label)?.trim?.() || capitalize(fromCamelCase(diff.path[1] || '')) || 'Group',
      property: diff.path[1],
      deepDiff: getDeepDiff(diff.prev, diff.next).map(deep => ({ ...deep, title: getLabel(deep.path), propertyName: null }))
    }))
    .filter(diff => diff.deepDiff.length && !ignoredFields.includes(diff.property));
};

export const getAllDiffs = (safePrev: CollectionModel.Collection, safeNext: CollectionModel.Collection): DiffRow[] => {
  return getDeepDiff(safePrev, safeNext).map(diff => ({
    ...diff,
    typeLabel: '',
    title: getLabel(diff.path),
    propertyName: diff.path[diff.path.length - 1],
    property: diff.path[diff.path.length - 1],
    deepDiff: []
  }));
};

export const getStateAfterUndo = (prev: CollectionModel.Collection, next: CollectionModel.Collection, diff: DiffRow): CollectionModel.Collection => {
  const stringPath = diff.path.join('.');
  const output = (() => {
    switch (diff.change) {
      case 'added':
        return removeDeep(next, stringPath);
      case 'removed':
      case 'modified':
        return replaceDeep(next, get(prev, stringPath), stringPath);
    }
  })();
  return normalize.collection(output);
};
