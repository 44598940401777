import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { decodeQueryString, encodeQueryString } from '@cyferd/client-engine';

export interface Options {
  replace?: boolean;
}

export const useQueryParamState = <S = { [key: string]: any }>() => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const state = useMemo(() => decodeQueryString<S>(search), [search]);

  const setState = useCallback(
    (setAction: (prev: S) => S, { replace }: Options = {}) => navigate({ pathname, search: encodeQueryString(setAction(state)) }, { replace }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, state]
  );

  return useMemo(() => [state, setState] as [S, (setAction: (prev: S) => S, options?: Options) => void], [state, setState]);
};
