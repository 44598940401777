import { useCallback, useEffect, useMemo, useState } from 'react';

import { CollectionModel, GeneralModel, normalize, ViewModel } from '@cyferd/client-engine';

import { useSaveBond } from '@utils/useSaveBond';
import { ItemList } from '@components/elements/ItemList';
import { CTA, CTAType } from '@components/elements/CTA';
import { useRequest } from '@utils/useRequest';
import { TRANS } from '@constants';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';
import { SchemaForm } from '../SchemaForm';

export interface RecordLevelSecurityProps {
  item: any;
  onClose: () => void;
  associationKey: string;
  entitySchema?: CollectionModel.Collection['schema'];
  onSuccess?: () => void;
}

export const RecordLevelSecurity = ({ onClose, onSuccess, item, entitySchema, associationKey }: RecordLevelSecurityProps) => {
  const request = useRequest();
  const [state, setState] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);

  const { saveBond } = useSaveBond(request, onSuccess, () => setLoading(false));

  const onSaveBond = useCallback(() => {
    setLoading(true);
    saveBond(
      item.id,
      item.collectionId,
      item.$r?.id,
      {
        ...item.$r,
        create: { ...item.$r?.create, ...state.create },
        read: { ...item.$r?.read, ...state.read },
        update: { ...item.$r?.update, ...state.update },
        delete: { ...item.$r?.delete, ...state.delete }
      },
      associationKey
    ).subscribe();
  }, [item, saveBond, state, associationKey]);

  useEffect(() => {
    if (item.$r) {
      setState({
        create: { ...item.$r?.create, enabled: item.$r?.create?.enabled ?? true },
        read: { ...item.$r?.read, enabled: item.$r?.read?.enabled ?? true },
        update: { ...item.$r?.update, enabled: item.$r?.update?.enabled ?? true },
        delete: { ...item.$r?.delete, enabled: item.$r?.delete?.enabled ?? true }
      });
    }
  }, [item.$r]);

  const schema = useMemo(() => entitySchema || /* istanbul ignore next */ item.schema, [item, entitySchema]);
  const entity = useMemo(() => normalize.collection({ name: '', schema, detailGroupList: [] } as CollectionModel.Collection), [schema]);
  const enabled = useMemo(
    () =>
      ({
        label: 'Enabled',
        type: 'boolean',
        format: GeneralModel.JSONSchemaFormat.CHECKBOX,
        default: true,
        metadata: { allowFormula: true }
      }) as GeneralModel.JSONSchema,
    []
  );

  const tabsSchema: CollectionModel.Collection = useMemo(
    () =>
      normalize.collection({
        detailGroupList: [
          { id: 'read', name: 'Read', image: 'menu_book', collapsible: true, startsCollapsed: false },
          { id: 'create', name: 'Create', image: 'edit', collapsible: true, startsCollapsed: true },
          { id: 'update', name: 'Update', image: 'cached', collapsible: true, startsCollapsed: true },
          { id: 'delete', name: 'Delete', image: 'delete', collapsible: true, startsCollapsed: true }
        ],
        schema: {
          type: 'object',
          properties: {
            read: {
              type: 'object',
              label: ' ',
              metadata: { detailGroupId: 'read' },
              properties: {
                enabled,
                filter: {
                  label: 'Filters',
                  format: GeneralModel.JSONSchemaFormat.EVALUATION,
                  type: 'any',
                  metadata: {
                    expanded: true,
                    allowFormula: true,
                    hidden: { $cyf_ne: ['{{event.parent.value.enabled}}', true] },
                    entity,
                    fieldSize: GeneralModel.FieldSize.FULL
                  }
                }
              }
            },
            create: {
              type: 'object',
              label: ' ',
              metadata: { detailGroupId: 'create' },
              properties: {
                enabled,
                schema: {
                  label: 'Schema (deprecated)',
                  description: 'This no longer works: replicate this in the collection override tab',
                  type: 'object',
                  format: GeneralModel.JSONSchemaFormat.JSON,
                  metadata: { disabled: true, hidden: !state?.create?.schema, fieldSize: GeneralModel.FieldSize.FULL }
                }
              }
            },
            update: {
              type: 'object',
              label: ' ',
              metadata: { detailGroupId: 'update' },
              properties: {
                enabled,
                filter: {
                  label: 'Filters',
                  format: GeneralModel.JSONSchemaFormat.EVALUATION,
                  type: 'any',
                  metadata: {
                    expanded: true,
                    allowFormula: true,
                    hidden: { $cyf_ne: ['{{event.parent.value.enabled}}', true] },
                    entity,
                    fieldSize: GeneralModel.FieldSize.FULL
                  }
                },
                schema: {
                  label: 'Schema (deprecated)',
                  description: 'This no longer works: replicate this in the collection override tab',
                  type: 'object',
                  format: GeneralModel.JSONSchemaFormat.JSON,
                  metadata: { disabled: true, hidden: !state?.update?.schema, fieldSize: GeneralModel.FieldSize.FULL }
                }
              }
            },
            delete: {
              type: 'object',
              label: ' ',
              metadata: { detailGroupId: 'delete' },
              properties: {
                enabled,
                filter: {
                  label: 'Filters',
                  format: GeneralModel.JSONSchemaFormat.EVALUATION,
                  type: 'any',
                  metadata: {
                    expanded: true,
                    allowFormula: true,
                    hidden: { $cyf_ne: ['{{event.parent.value.enabled}}', true] },
                    entity,
                    fieldSize: GeneralModel.FieldSize.FULL
                  }
                }
              }
            }
          }
        }
      }),
    [enabled, entity, state?.create?.schema, state?.update?.schema]
  );

  return (
    <div data-testid="entity-app-security-modal">
      <Layout type={ViewModel.LayoutType.FULL} fitToPage={true}>
        <SchemaForm schema={tabsSchema.schema} detailGroupList={tabsSchema.detailGroupList} onChange={setState} value={state} wrapDetailGroups={true} />
      </Layout>
      <div css={styles.ctaContainer}>
        <ItemList>
          <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={onClose} testid="save-cancel" disabled={loading} />
          <CTA type={CTAType.PRIMARY} label={TRANS.client.buttons.save} onClick={onSaveBond} testid="save-cta" disabled={loading} />
        </ItemList>
      </div>
    </div>
  );
};
