import { ParsedListHeadItem, ViewModel } from '@cyferd/client-engine';
import { useCallback } from 'react';
import { useDataSource } from '../../../../hooks';

export const useColumnClick = (updateCursor: ReturnType<typeof useDataSource>['updateCursor'], cursor?: ViewModel.CyTableProps['value']['query']['cursor']) => {
  const onColumnClick = useCallback(
    (col: ParsedListHeadItem, sorting: boolean) => {
      if (!cursor) return;
      updateCursor({
        ...cursor,
        options: { ...cursor?.options, orderBy: col.definitionId, descending: sorting ? !cursor?.options?.descending : false }
      });
    },
    [updateCursor, cursor]
  );
  return onColumnClick;
};
