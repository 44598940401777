import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { EMPTY } from 'rxjs';

import { CollectionModel, GeneralModel, ViewModel, getComponentSchema, getDummyFromSchema, normalize } from '@cyferd/client-engine';
import { CyKanban } from '@components/smart/CyKanban';
import { CyList } from '@components/smart/CyList';

import { EmptyState } from '@components/elements/EmptyState';
import { Layout } from '@components/elements/Layout';
import { TRANS } from '@constants';
import { getComponentConfig } from '@models/view';
import { CtaProps } from '@components/elements/CTA';
import { CyFormBase } from '@components/smart/CyForm';

const compoTypeDiv = ':::';

const decodeCompoTypeCombo = (str: string) => {
  const [component, type] = str.split(compoTypeDiv);
  return { component, type };
};

const encodeCompoTypeCombo = (component: ViewModel.DisplayName, type: string) => {
  return `${component}${compoTypeDiv}${type}`;
};

const menuConfig = { defaultBtnIcon: 'settings' as any, defaultBtnType: ViewModel.CTAType.LINK };

const previewOptions = [
  ...[ViewModel.DisplayName.CY_FORM, ViewModel.DisplayName.CY_LIST]
    .map(displayName => {
      const config = getComponentConfig(displayName);
      return getComponentSchema(displayName).properties.type.metadata.optionList.map(({ value, label, image }) => ({
        value: encodeCompoTypeCombo(displayName, value as string),
        label,
        icon: image,
        color: config.color
      }));
    })
    .flat(),
  {
    value: encodeCompoTypeCombo(ViewModel.DisplayName.CY_KANBAN, ''),
    label: getComponentConfig(ViewModel.DisplayName.CY_KANBAN).label,
    icon: getComponentConfig(ViewModel.DisplayName.CY_KANBAN).icon,
    color: getComponentConfig(ViewModel.DisplayName.CY_KANBAN).color
  }
];

const removeAssCursors = (schema: GeneralModel.JSONSchema): GeneralModel.JSONSchema => {
  return {
    ...schema,
    properties: Object.fromEntries(
      Object.entries(schema?.properties || {}).map(([k, prop]) => {
        /* istanbul ignore if */
        if (prop?.format === GeneralModel.JSONSchemaFormat.ASSOCIATION) {
          return [k, { ...prop, metadata: { ...prop.metadata, association: {} } }];
        }
        return [k, prop];
      })
    )
  };
};

export interface ExampleEditorProps {
  collection: CollectionModel.Collection;
  onCollectionChange: (item: CollectionModel.Collection) => void;
  onToggleSize?: () => void;
  toggleIcon?: CtaProps['icon'];
}

export const ExampleEditor = ({ collection, toggleIcon = 'zoom_out_map', onCollectionChange, onToggleSize }: ExampleEditorProps) => {
  const [previewConfig, setPreviewConfig] = useState(decodeCompoTypeCombo(previewOptions[0].value));
  const [previewTitle, setPreviewTitle] = useState(previewOptions[0].label);
  const [optionField, setOptionField] = useState<string>();

  const normalizedCollection = useMemo(() => normalize.collection(collection), [collection]);
  const example = normalizedCollection?.examples?.[0];

  const bbValue = useMemo(
    () => ({
      record: example,
      list: [example, ...Array.from(Array(4)).map(() => getDummyFromSchema(normalizedCollection?.schema))].filter(Boolean),
      query: { cursor: {}, schema: removeAssCursors(normalizedCollection?.schema), detailGroupList: normalizedCollection?.detailGroupList },
      totalCount: 0
    }),
    [example, normalizedCollection?.detailGroupList, normalizedCollection?.schema]
  );

  const onInternalChange = useCallback(
    (value: ComponentProps<typeof CyFormBase>['value']) => {
      onCollectionChange({ ...collection, examples: [value?.record] });
      return EMPTY;
    },
    [collection, onCollectionChange]
  );

  const options = useMemo(
    () => [
      { icon: toggleIcon, type: ViewModel.CTAType.LINK, onClick: onToggleSize, hidden: !onToggleSize, helperText: 'Toggle size', important: true },
      ...previewOptions.map(o => ({
        ...o,
        onClick: () => {
          setPreviewConfig(decodeCompoTypeCombo(o.value));
          setPreviewTitle(o.label);
        },
        important: false
      }))
    ],
    [toggleIcon, onToggleSize]
  ) as ComponentProps<typeof Layout>['actionListChildren'];

  if (!collection) return <EmptyState />;

  return (
    <Layout
      title={TRANS.client.nav.builder.tabs.preview}
      subtitle={previewTitle}
      type={ViewModel.LayoutType.FULL}
      framed={true}
      actionListChildren={options}
      menuConfig={menuConfig}
    >
      {previewConfig.component === ViewModel.DisplayName.CY_FORM && (
        <CyFormBase
          fitToPage={true}
          onChange={onInternalChange}
          value={bbValue}
          type={previewConfig.type as ViewModel.CyFormType}
          showErrorsBeforeSave={true}
          autofocusDisabled={true}
        />
      )}
      {previewConfig.component === ViewModel.DisplayName.CY_LIST && (
        <CyList
          fitToPage={true}
          framed={false}
          value={bbValue}
          type={previewConfig.type as ViewModel.CyListType}
          typeSelectorHidden={true}
          paginationHidden={true}
          searchStringHidden={true}
          orderHidden={true}
          advancedFiltersHidden={true}
          quickFiltersHidden={true}
          recordActionsHidden={true}
        />
      )}
      {previewConfig.component === ViewModel.DisplayName.CY_KANBAN && (
        <CyKanban
          fitToPage={true}
          title=" "
          framed={false}
          value={bbValue}
          paginationHidden={true}
          searchStringHidden={true}
          advancedFiltersHidden={true}
          quickFiltersHidden={true}
          recordActionsHidden={true}
          optionListField={optionField}
          onChangeOptionField={setOptionField as any}
          optionFieldHidden={false}
        />
      )}
    </Layout>
  );
};
