import { Icon } from '../Icon';
import { COLOR, FONT_SIZE } from '@constants';
import { styles } from './styles';
import { PopoverTrigger } from '../Popover';
import { ReactNode } from 'react';

export interface IInfoForLabel {
  label?: string;
  value?: string | ReactNode;
  testId?: string;
}

export const InfoForLabel = ({ label, value, testId = 'InfoForLabel' }: IInfoForLabel) => {
  if (!value) return null;
  return (
    <PopoverTrigger label={label} value={value}>
      <div css={styles.infoWrapper} data-testid={`${testId}-info-icon`} data-selector="info-icon">
        <Icon name="info" outlined={true} size={FONT_SIZE.M} fill={COLOR.BRAND_1} />
      </div>
    </PopoverTrigger>
  );
};
