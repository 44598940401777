import React, { useContext, useMemo } from 'react';
import { styles } from './styles';
import { useDropdownDimensions } from '@utils';
import { getElementMenuPosition } from '@utils/getElementMenuPosition';
import { ApiModel, GeneralModel, SchemaFormContext, SchemaFormContextValue } from '@cyferd/client-engine';
import { DropdownOption } from '../../../DropdownOption';

export interface InputSuggestionsProps {
  value?: string | number;
  onChange: (value: string) => void;
  suggestionList: GeneralModel.JSONSchema['metadata']['suggestionList'];
  testId?: string;
  inputContainerRef: React.MutableRefObject<HTMLDivElement>;
  path?: string[];
}

export const InputSuggestions: React.FC<InputSuggestionsProps> = ({ value, onChange, suggestionList, testId = 'input', inputContainerRef, path }) => {
  const { dropdownRef, triggerDimensions, menuDimensions, clientDimensions } = useDropdownDimensions(inputContainerRef);
  const menuElementPosition = getElementMenuPosition({ triggerDimensions, menuDimensions, clientDimensions, verticalPadding: 2 });
  const { fullValue, useParsers } = useContext<SchemaFormContextValue<ApiModel.ApiRecord>>(SchemaFormContext);
  const { parseSchemaProperty } = useParsers();

  const cleanSuggestionList = useMemo(
    () =>
      (suggestionList || [])
        .filter(o => ![null, undefined, ''].includes(o?.value as any))
        .map(s => ({
          ...s,
          value: String(s.value),
          hidden: !!parseSchemaProperty(s.hidden, {
            value: value,
            definition: null,
            fullItem: fullValue,
            path: path?.join?.('.')
          })
        }))
        .filter(option => !option.hidden),
    [fullValue, parseSchemaProperty, path, suggestionList, value]
  );

  const filteredSuggestions = useMemo(() => {
    if (!value) return cleanSuggestionList;
    return cleanSuggestionList.filter(suggestion =>
      [suggestion.value.toLowerCase(), suggestion.label?.toLowerCase()].filter(Boolean).some(v => v.includes(String(value).toLowerCase()))
    );
  }, [cleanSuggestionList, value]);

  return filteredSuggestions?.length ? (
    <div
      css={styles.container}
      data-testid={`${testId}-suggestions`}
      style={{ width: triggerDimensions.width, top: menuElementPosition.style.top }}
      ref={dropdownRef}
    >
      {filteredSuggestions.map((sugg, i) => (
        <div key={`${sugg.value}-${sugg.label}-${i}`} css={styles.optionContainer}>
          <DropdownOption
            title={sugg.label || sugg.value}
            description={sugg.description}
            image={sugg.image}
            value={sugg.value}
            color={sugg.color as GeneralModel.Color.ThemeColor}
            onClick={onChange}
            testid={`${testId}-${sugg.value}-suggestion`}
          />
        </div>
      ))}
    </div>
  ) : null;
};
