import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { ViewModel } from '@cyferd/client-engine';
import { css } from '@emotion/css';
import { ComponentConfig } from '@models/view';

export const styles = {
  listContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: ${GAP.XS};
    width: 100%;
  `,
  nodeContainer: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,

  listTitle: css`
    cursor: pointer;
    border-top: 1px ${COLOR.NEUTRAL_3} solid;
    padding-top: ${GAP.XS};
    margin-top: ${GAP.S};
    margin-bottom: ${GAP.XXS};
  `,
  listTitleText: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_1};
  `,
  infoContainer: css`
    display: flex;
    align-items: center;
    gap: ${GAP.XXS};
    overflow: hidden;
  `
};

export const getNodeStyles = ({
  isActive,
  isHighlighted,
  nestedLevel,
  componentConfig,
  displayName
}: {
  isActive: boolean;
  isHighlighted: boolean;
  nestedLevel: number;
  componentConfig: ComponentConfig;
  displayName: ViewModel.DisplayName;
}) => {
  const colorName = [ViewModel.DisplayName.CY_LAYOUT, ViewModel.DisplayName.CY_BLANK].includes(displayName) ? 'NEUTRAL_2' : componentConfig.color;
  return {
    node: css`
      cursor: pointer;
      padding: 0 ${GAP.XS};
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 28px;
      transition: background-color ${TRANSITION_SPEED};
      border-radius: ${RADIUS.S};
      margin-left: calc(${GAP.S} * ${nestedLevel});
      :hover {
        background-color: ${COLOR.NEUTRAL_4};
      }
      ${!!isActive && `background-color: ${SECONDARY_COLOR[colorName]}!important;`}
      ${!!isHighlighted && `background-color: ${SECONDARY_COLOR.NEUTRAL_1}!important;`}
    `,
    title: css`
      font-size: ${FONT_SIZE.XS};
      color: ${COLOR.NEUTRAL_1};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    iconFill: COLOR[colorName],
    invisible: css`
      visibility: hidden;
    `
  };
};
