import { TRANS, defaultFormDetailGroupList, defaultFormDetailGroupMap } from '@constants';
import { capitalize, CollectionModel, createUUID, GeneralModel, UserModel } from '@cyferd/client-engine';

export const detailGroupList: CollectionModel.DetailGroup[] = [
  ...defaultFormDetailGroupList,
  { id: 'roles', name: 'Roles configuration', order: defaultFormDetailGroupList.length },
  { id: 'access', name: 'Access', order: defaultFormDetailGroupList.length + 1 }
];

export const enabledSchema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['name', 'email', 'enabled'],
  properties: {
    enabled: {
      type: 'boolean',
      label: TRANS.client.fields.titles.enabled,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX
    }
  }
};
export const schema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['name', 'email', 'enabled'],
  properties: {
    name: {
      type: 'string',
      label: TRANS.client.fields.titles.name,
      minLength: 1,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id, disabled: true }
    },
    fullName: { type: 'string', label: TRANS.client.fields.titles.fullName, metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id, disabled: true } },
    email: {
      type: 'string',
      label: TRANS.client.fields.titles.email,
      format: GeneralModel.JSONSchemaFormat.EMAIL,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id, disabled: true }
    },
    roles: {
      $id: createUUID(),
      type: 'array',
      format: GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST,
      label: TRANS.client.fields.titles.roles,
      uniqueItems: true,
      metadata: { detailOrder: 1, detailGroupId: defaultFormDetailGroupMap.configuration.id },
      items: {
        $id: createUUID(),
        type: 'string',
        format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
        metadata: {
          optionList: Object.values(UserModel.UserRole).map(value => ({
            label: value === UserModel.UserRole.ENTITY_ADMIN ? 'Collection admin' : capitalize(value.replace(/_/g, ' ')),
            value
          }))
        }
      }
    },
    blockMobileAccess: {
      type: 'boolean',
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      label: TRANS.client.fields.titles.blockMobileAccess,
      default: false,
      metadata: {
        detailOrder: 2,
        detailGroupId: defaultFormDetailGroupMap.configuration.id
      }
    }
  }
};
