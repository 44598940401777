import { useMemo, useState } from 'react';
import { filter, takeUntil, tap } from 'rxjs';

/* istanbul ignore file | @todo */
import { ApiModel, GeneralModel, noop, swallowError, useUnmountObservable, ViewModel } from '@cyferd/client-engine';
import { CTAType } from '@components/elements/CTA';

import { useParsers } from '@utils/useParsers';
import { SchemaForm } from '../../../SchemaForm';
import { styles } from '../../styles';
import { Layout } from '@components/elements/Layout';
import { JSONSyntaxEditor } from '@components/elements/JSONSyntaxEditor';
import { useRequest } from '@utils/useRequest';
import { getActionDetailGroupList } from '../../../../FlowEditor/components/FlowDefinition/resources';
import { OptionMenu } from '@components/elements/OptionMenu';
import { TRANS } from '@constants';

export interface ApiPlaygroundProps {
  value: ApiModel.APIAction;
  onChange: (value: ApiModel.APIAction) => void;
  onClose: () => void;
  onCopyToClipboard: (item: any) => void;
}

export const ApiPlayground = ({ value, onChange, onClose, onCopyToClipboard }: ApiPlaygroundProps) => {
  const [apiResponseList, setApiResponseList] = useState<ApiModel.APIAction[]>([]);
  const request = useRequest();
  const onDestroy$ = useUnmountObservable();

  const { parseData } = useParsers();

  const onRunApi = () => {
    setApiResponseList([]);
    const onResponse = r => setApiResponseList(p => [...p, r]);
    return request(parseData(value, {})).pipe(
      takeUntil(onDestroy$),
      filter(action => ![...Object.values(ApiModel.ApiActionType)].includes(action?.type)),
      tap({ next: onResponse, error: onResponse }),
      swallowError()
    );
  };

  const detailGroupList = useMemo(
    () => [...getActionDetailGroupList(value?.type), { id: 'action', name: 'Action type' }, { id: 'payload', name: 'Payload' }],
    [value?.type]
  );

  return (
    <Layout type={ViewModel.LayoutType.FULL}>
      <Layout>
        <div className={styles.fixedHeight}>
          <SchemaForm
            allowFormula={true}
            value={value}
            onChange={onChange}
            detailGroupList={detailGroupList}
            wrapDetailGroups={true}
            delayTime={0}
            schema={{
              type: 'object',
              properties: {
                type: {
                  type: 'string',
                  label: 'Action type',
                  format: GeneralModel.JSONSchemaFormat.ACTION_TYPE_OPTION_LIST,
                  metadata: { detailOrder: 1, fieldSize: GeneralModel.FieldSize.FULL }
                },
                payload: {
                  format: GeneralModel.JSONSchemaFormat.COLLECTION_RECORD,
                  type: 'object',
                  title: 'Payload',
                  metadata: {
                    hidden: {
                      $cyf_exists: ['{{event.item.type}}'],
                      $not: true
                    },
                    collectionId: 'Flow',
                    recordId: '{{event.item.type}}',
                    fieldSize: GeneralModel.FieldSize.FULL,
                    subtype: GeneralModel.JSONSchemaSubtype.FRAMED,
                    detailOrder: 2,
                    allowFormula: true,
                    isCollapsible: false
                  }
                }
              }
            }}
          />
        </div>
        {!!apiResponseList.length && (
          <div className={styles.fixedHeight}>
            {apiResponseList.length > 1 && <p>{apiResponseList.length} responses</p>}
            {apiResponseList.map((r, i) => (
              <JSONSyntaxEditor expanded={true} key={i} height={270} label={`Response ${i + 1}`} onChange={noop} value={r} disabled={true} />
            ))}
          </div>
        )}
      </Layout>
      <footer className={styles.footer}>
        <OptionMenu
          optionList={[
            {
              important: true,
              testid: 'output-panel-cancel',
              type: CTAType.SECONDARY,
              label: TRANS.client.buttons.cancel,
              onClick: onClose
            },

            {
              testid: 'output-panel-copy',
              important: true,
              type: CTAType.SECONDARY,
              label: TRANS.client.buttons.copyReq,
              image: 'content_copy',
              onClick: () => onCopyToClipboard(parseData(value, {}))
            },

            {
              testid: 'output-panel-copy',
              important: true,
              type: CTAType.SECONDARY,
              label: TRANS.client.buttons.copyReqRaw,
              image: 'content_copy',
              onClick: () => onCopyToClipboard(value)
            },
            {
              important: true,
              testid: 'output-panel-api-run',
              type: CTAType.PRIMARY,
              label: 'Run',
              onClick: onRunApi
            }
          ]}
        />
      </footer>
    </Layout>
  );
};
