import type { MenuOption } from '@components/elements/OptionMenu';
import { ApiModel, DeepDiff, GeneralModel, ParsedList, ViewModel } from '@cyferd/client-engine';
import { Observable } from 'rxjs';
import { z } from 'zod';
import { useDataSource } from './hooks/useDataSource';

const ErrorCodeSchema = z.enum(['DENIED', 'NOT_FOUND', 'OTHERS', 'UPDATE_CONFLICT', 'VALIDATION_ERROR'] as const);

const ErrorDetailSchema = z.object({
  verb: z.string(),
  input: z.record(z.string(), z.any()),
  code: ErrorCodeSchema,
  description: z.string(),
  message: z.string().optional(),
  details: z.record(z.string(), z.any()).optional()
});

const SummarySchema = z.object({
  total: z.number(),
  successful: z.number(),
  failed: z.number(),
  ignored: z.number()
});

export const PayloadSchema = z.object({
  success: z.boolean(),
  summary: SummarySchema,
  failed: z.array(ErrorDetailSchema),
  successful: z.array(z.any())
});

export type ErrorCode = z.infer<typeof ErrorCodeSchema>;
export type ErrorDetail = z.infer<typeof ErrorDetailSchema>;
export type Payload = z.infer<typeof PayloadSchema>;

export type ReadOnlyTableProps = {
  refreshId: string;
  hideSorting?: boolean;
  isLoading?: boolean;
  rowActions?: ViewModel.CyTableProps['rowActions'];
  value?: ApiModel.ApiValue;
  head?: ParsedList['head'];
  definitionMap?: ParsedList['definitionMap'];
  items?: ParsedList['items'];
  updateCursor?: ReturnType<typeof useDataSource>['updateCursor'];
};

type EditModeType = ViewModel.CyTableProps['editMode'];
export interface ITableEditModeParams extends EditModeType {
  value: ViewModel.CyTableProps['value'];
  fetch?: () => Observable<ApiModel.APIAction>;
  componentNameList?: string[];
}
export interface ITableEditModeProps {
  isEditing: boolean;
  completeValues: Record<string, any>;
  errors: { [itemId: string]: { [definitionId: string]: string | string[] } };
  changeCount: number;
  diffs: DeepDiff[];
  conflictingValues: undefined | Payload;

  toggleEditing: () => void;
  handleEditChange: (e: any, itemId: string, definitionId: string, item?: any) => void;
  handleSave: (collectionId: string) => void;
  handleCancel: () => void;
  handleGoBackToEdit: () => void;
  onOverrideItems: (option: { collectionId: string; list: any[] }) => void;
}

export interface IUseOnClickParams {
  rowActions?: ViewModel.CyTableProps['rowActions'];
  isLoading?: boolean;
  refreshId: string;
}

export interface IUseOnClickProps {
  onClick?: (item: Record<string, any>, meta: Record<string, any>) => void;
}

export interface IUseTableBodyParams {
  definitionMap: ReadOnlyTableProps['definitionMap'];
  items: ReadOnlyTableProps['items'];
  schemaProperties?: ApiModel.ApiValue['query']['schema']['properties'];
  isLoading?: boolean;
  dropdownContainerId: string;
  onClick: (item, index) => void;
  hasRecordActions?: boolean;
  parseRecordActions?: (item, index) => MenuOption[];
}

export interface IUseHeaderActionsParams {
  isLoading?: boolean;
  header: ViewModel.CyTableProps['header'];
  value: ViewModel.CyTableProps['value'];
}

export interface IUseCyTableActionsProps {
  actions?: MenuOption[];
  additionalRecordActions?: ViewModel.CyTableProps['rowActions']['additionalRecordActions'];
  quickFilters?: GeneralModel.FetchCriteria['quickFilters'];
}
