import { capitalize, CollectionModel, createUUID, decodeQueryString, GeneralModel, isObject } from '@cyferd/client-engine';

import { ENV } from './env';
import { Location } from 'react-router-dom';
import { TRANS } from './translations';
import { JSONSchema6TypeName } from 'json-schema';

export const TRIGGER_FORMAT: string = 'trigger';
export const DATA_TYPE_FORMAT: string = 'data-type';
export const VIEW_COMPONENT_FORMAT: string = 'view-component';

export const GLOBAL_HEADER_SETTINGS_KEY = 'global-header-settings';
export const LANG_KEY = 'lang';

export const GENERAL = {
  NODE_DETAIL_ID: 'node-detail-container',
  PROPERTY_DETAIL_ID: 'property-detail-container',
  LOADING_KEY: {
    SAVE: 'save',
    DELETE: 'delete',
    LOAD: 'load'
  }
};

export const defaultFormDetailGroupMap = {
  basic: { id: createUUID(), name: TRANS.client.groups.titles.basic, order: 1 } as CollectionModel.DetailGroup,
  naming: { id: createUUID(), name: TRANS.client.groups.titles.naming, order: 2 } as CollectionModel.DetailGroup,
  date: {
    id: createUUID(),
    name: TRANS.client.groups.titles.date,
    order: 3,
    hidden: { $cyf_not: [{ $cyf_exists: ['{{event.item.createdAt}}'] }] }
  } as CollectionModel.DetailGroup,
  configuration: { id: createUUID(), name: TRANS.client.groups.titles.configuration, order: 4 } as CollectionModel.DetailGroup,
  advanced: {
    id: createUUID(),
    name: TRANS.client.groups.titles.advanced,
    order: 5,
    collapsible: true,
    startsCollapsed: !ENV.IS_TEST
  } as CollectionModel.DetailGroup,
  defaultDisplay: { id: createUUID(), name: TRANS.client.groups.titles.defaultDisplay, order: 7 } as CollectionModel.DetailGroup
};

export const defaultFormDetailGroupList = Object.values(defaultFormDetailGroupMap);

export const dateSchemaProps: GeneralModel.JSONSchema['properties'] = {
  id: {
    type: 'string',
    label: TRANS.client.fields.titles.id,
    metadata: { disabled: true, disabledType: GeneralModel.DisabledType.VIEW_ONLY }
  },
  createdAt: {
    type: 'string',
    label: TRANS.client.fields.titles.createdAt,
    format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
    metadata: { disabled: true, disabledType: GeneralModel.DisabledType.VIEW_ONLY, detailGroupId: defaultFormDetailGroupMap.date.id }
  },
  updatedAt: {
    type: 'string',
    label: TRANS.client.fields.titles.updatedAt,
    format: GeneralModel.JSONSchemaFormat.DATE_TIME_U,
    metadata: { disabled: true, disabledType: GeneralModel.DisabledType.VIEW_ONLY, detailGroupId: defaultFormDetailGroupMap.date.id }
  },
  createdBy: {
    type: 'string',
    label: TRANS.client.fields.titles.createdBy,
    format: GeneralModel.JSONSchemaFormat.TEXT,
    metadata: { disabled: true, disabledType: GeneralModel.DisabledType.VIEW_ONLY, detailGroupId: defaultFormDetailGroupMap.date.id }
  },
  updatedBy: {
    type: 'string',
    label: TRANS.client.fields.titles.updatedBy,
    format: GeneralModel.JSONSchemaFormat.TEXT,
    metadata: { disabled: true, disabledType: GeneralModel.DisabledType.VIEW_ONLY, detailGroupId: defaultFormDetailGroupMap.date.id }
  }
};

export const dateSchema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  properties: dateSchemaProps
};

export const fileTypeFormatList = [
  { value: 'application/pdf', label: 'PDF' },
  { value: 'application/json', label: 'JSON' },
  { value: 'application/xml', label: 'XML' },
  { value: 'image/*', label: 'Image' },
  { value: 'video/*', label: 'Video' },
  { value: 'audio/*', label: 'Audio' },
  { value: 'text/plain', label: 'Plain text' },
  { value: 'text/csv', label: 'CSV' },
  { value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', label: 'DOCX' },
  { value: 'application/vnd.ms-excel', label: 'XLS' },
  { value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', label: 'XLSX' }
];

export const getTempId = () => `temp-${createUUID()}`;
export const isTempId = (id: string) => /^temp-/.test(id);

export const isSavedRecord = (record: any) => isObject(record) && typeof record.id === 'string' && !isTempId(record.id);

const specialViewQsMap = {
  APP_HOME: 'id',
  DETAIL: 'collectionId',
  TABLE: 'collectionId'
};

export const shouldChangeLocationSync = (location: Location, prevLocation: Location): boolean => {
  const qs = decodeQueryString(location?.search);
  const prevQs = decodeQueryString(prevLocation?.search);
  const specialQsField = specialViewQsMap[location.pathname.replace(/^\//, '')];
  return (
    /** is same view */
    location.pathname === prevLocation?.pathname &&
    /** is NOT a core view AND is a core view and id and collectionId are the same */
    (!specialQsField || qs[specialQsField] === prevQs[specialQsField])
  );
};

export enum MOBILE_AGENTS {
  ANDROID = 'Android',
  IOS = 'iOS'
}

export const ANDROID_PACKAGE_NAME = 'com.cyferd.clientapp';
export const IOS_APP_ID = '1594395840';

export const getDataTypeLabel = (templateName: GeneralModel.JSONSchemaFormat): string => {
  return TRANS.client.dataTypes.names[templateName] || capitalize(templateName).replace(/-/g, ' ').trim();
};

export const getBasicDataTypeIcon = (type: JSONSchema6TypeName): GeneralModel.IconName => {
  switch (type) {
    case 'string':
      return 'font_download';
    case 'integer':
    case 'number':
      return 'pin';
    case 'boolean':
      return 'check_box';
    case 'object':
      return 'data_object';
    case 'array':
      return 'data_array';
    case 'any':
      return 'asterisk';
    case 'null':
      return 'check_box_outline_blank';
  }
};

export const getDataTypeIcon = (templateName: GeneralModel.JSONSchemaFormat | 'null'): GeneralModel.IconName => {
  switch (templateName) {
    case GeneralModel.JSONSchemaFormat.TEXT:
      return 'font_download';
    case GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST:
      return 'list';
    case GeneralModel.JSONSchemaFormat.INLINE_STRING_OPTION_LIST:
      return 'view_week';
    case GeneralModel.JSONSchemaFormat.COLOR:
      return 'palette';
    case GeneralModel.JSONSchemaFormat.DATE:
      return 'calendar_month';
    case GeneralModel.JSONSchemaFormat.DATE_TIME:
    case GeneralModel.JSONSchemaFormat.DATE_TIME_U:
      return 'today';
    case GeneralModel.JSONSchemaFormat.EMAIL:
      return 'mail';
    case GeneralModel.JSONSchemaFormat.MONTH:
      return 'calendar_month';
    case GeneralModel.JSONSchemaFormat.WEEK:
      return 'date_range';
    case GeneralModel.JSONSchemaFormat.TIME:
      return 'schedule';
    case GeneralModel.JSONSchemaFormat.PASSWORD:
      return 'lock';
    case GeneralModel.JSONSchemaFormat.URL:
      return 'http';
    case GeneralModel.JSONSchemaFormat.QR:
      return 'qr_code';
    case GeneralModel.JSONSchemaFormat.BARCODE:
      return 'barcode';
    case GeneralModel.JSONSchemaFormat.PHONE_NUMBER:
      return 'call';
    case GeneralModel.JSONSchemaFormat.CURRENCY:
      return 'paid';
    case GeneralModel.JSONSchemaFormat.MULTILINE:
    case GeneralModel.JSONSchemaFormat.RICH_TEXT:
    case GeneralModel.JSONSchemaFormat.INFO_BLOCK:
      return 'article';
    case GeneralModel.JSONSchemaFormat.NUMBER:
      return 'pin';
    case GeneralModel.JSONSchemaFormat.RATING:
      return 'star';
    case GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST:
      return 'format_list_numbered_rtl';
    case GeneralModel.JSONSchemaFormat.RANGE:
      return 'arrow_range';
    case GeneralModel.JSONSchemaFormat.NUMERIC_RANGE:
      return 'hdr_weak';
    case GeneralModel.JSONSchemaFormat.CHECKBOX:
      return 'check_box';
    case GeneralModel.JSONSchemaFormat.SWITCH:
      return 'toggle_on';
    case GeneralModel.JSONSchemaFormat.OBJECT:
      return 'data_object';
    case GeneralModel.JSONSchemaFormat.FILE:
      return 'draft';
    case GeneralModel.JSONSchemaFormat.ADDRESS:
      return 'map';
    case GeneralModel.JSONSchemaFormat.ARRAY:
      return 'data_array';
    case GeneralModel.JSONSchemaFormat.FILE_LIST:
      return 'folder';
    case GeneralModel.JSONSchemaFormat.ICON:
    case GeneralModel.JSONSchemaFormat.ICON_IMAGE:
      return 'image';
    case GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST:
      return 'filter_none';
    case GeneralModel.JSONSchemaFormat.MULTI_OPTION_LIST_ALT:
      return 'checklist';
    case GeneralModel.JSONSchemaFormat.SQL:
    case GeneralModel.JSONSchemaFormat.GRAPHQL:
    case GeneralModel.JSONSchemaFormat.YAML:
    case GeneralModel.JSONSchemaFormat.XML:
      return 'code';
    case GeneralModel.JSONSchemaFormat.JSON:
      return 'data_object';
    case GeneralModel.JSONSchemaFormat.ASSOCIATION:
      return 'share';
    case 'null':
      return 'check_box_outline_blank';
    default:
      return 'database';
  }
};
