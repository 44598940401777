import React, { useMemo } from 'react';

import { capitalize, getTimeFromNow, isValidText, NotificationModel } from '@cyferd/client-engine';

import { IconImage } from '@components/elements/Icon/renderIcon';
import { COLOR } from '@constants';
import { styles } from './styles';
import { RichText } from '@components/elements/RichTextEditor';
import { Modal } from '@components/elements/Modal';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTA, CTAType } from '@components/elements/CTA';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { useNotificationUtils } from '@utils';

export type NotificationModalProps = {
  testid?: string;
  notification: NotificationModel.Notification;
  onClose: () => void;
};

export const NotificationModal = ({ notification, onClose, testid = 'NotificationModal' }: NotificationModalProps) => {
  const hasValidContent = useMemo(() => isValidText(notification?.body), [notification?.body]);
  const { onClickNotificationLink } = useNotificationUtils();

  const onClickLink = () => {
    onClose();
    onClickNotificationLink(notification?.link);
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      testid={`${testid}-modal`}
      title={notification?.title}
      description={getTimeFromNow(notification?.createdAt)}
      footer={<OptionMenu optionList={[{ important: true, type: CTAType.PRIMARY, onClick: onClose, label: 'OK' }]} />}
    >
      <div css={styles.mainContainer} data-testid="notification-modal">
        {!!notification && (
          <div>
            {!!notification?.recordImage && (
              <div css={styles.image}>
                <IconImage
                  icon={notification?.recordImage}
                  title=""
                  iconProps={{ css: [styles.imageUrl.base], size: '50px', fill: COLOR.BRAND_1 }}
                  imageProps={{ css: [styles.imageUrl.base, styles.imageUrl.image], size: '50px' }}
                />
              </div>
            )}
            {!!notification?.type && <div css={styles.type}>{capitalize(notification?.type)}</div>}
            {!!notification?.body && hasValidContent && (
              <div css={styles.body}>
                <RichText value={notification?.body as string} />
              </div>
            )}
            {!!notification?.link?.path && (
              <div css={styles.link}>
                <PreventClickPropagation>
                  <CTA type={CTAType.LINK} label={notification?.link?.title} onClick={onClickLink} testid="notif-link" />
                </PreventClickPropagation>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
