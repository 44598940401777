import { GeneralModel, getParsedActionChildren, Translate, ViewModel } from '@cyferd/client-engine';

import { COLOR, CONTAINER_WIDTH } from '@constants';
import { useGetElementSize } from '@utils/useGetElementSize';
import App from './assets/AppSvg';
import Calendar from './assets/CalendarSvg';
import Disconnected from './assets/DisconnectedSvg';
import Empty from './assets/EmptySvg';
import Error from './assets/ErrorSvg';
import Favorite from './assets/FavoriteSvg';
import NoResults from './assets/NoResultsSvg';
import Oops from './assets/OopsSvg';
import { getTextAlignmentStyle, iconSizeMap, styles, getFlexAlignmentStyle } from './styles';
import { Icon, IconKeys, IconProps } from '@components/elements/Icon';
import { CTA, CTAType } from '@components/elements/CTA';

export const imageAssetList = {
  [ViewModel.FixedImageAsset.APP]: App,
  [ViewModel.FixedImageAsset.FAVORITE]: Favorite,
  [ViewModel.FixedImageAsset.CALENDAR]: Calendar,
  [ViewModel.FixedImageAsset.OOPS]: Oops,
  [ViewModel.FixedImageAsset.DISCONNECTED]: Disconnected,
  [ViewModel.FixedImageAsset.ERROR]: Error,
  [ViewModel.FixedImageAsset.EMPTY]: Empty,
  [ViewModel.FixedImageAsset.NO_RESULTS]: NoResults
};
/** @deprecated */
export const CyInfo = ({
  id,
  title,
  subtitle,
  icon,
  alignment,
  actionListChildrenAlignment,
  textAlignment,
  iconSize,
  actionListChildren
}: ViewModel.CyInfoProps) => {
  const myTestid = 'cyInfo';
  const { ref, width } = useGetElementSize();

  const isVertical = width < CONTAINER_WIDTH.S;

  return (
    <div css={[styles.container, getFlexAlignmentStyle(alignment)]}>
      <div id={id} css={[styles.infoContainer, getFlexAlignmentStyle(alignment)]} ref={ref} data-testid={myTestid}>
        {!!icon && (
          <div css={[styles.imageContainer, isVertical && styles.imageContainerVertical]}>
            {(() => {
              const Image = imageAssetList[icon];
              if (Image) return <Image />;
              if (GeneralModel.iconNameList.includes(icon as GeneralModel.IconName)) {
                return (
                  <Icon
                    size={(iconSizeMap[iconSize] || iconSizeMap[ViewModel.CyInfoIconSize.L]) as IconProps['size']}
                    name={icon as IconKeys}
                    fill={COLOR.BRAND_1}
                    title={icon}
                  />
                );
              }
              return <img src={icon} alt={icon} css={styles.img} />;
            })()}
          </div>
        )}
        {title || subtitle ? (
          <div
            css={[
              styles.textContainer,
              isVertical && styles.textContainerVertical,
              getTextAlignmentStyle(textAlignment || (isVertical ? ViewModel.Alignment.CENTER : /* istanbul ignore next */ ViewModel.Alignment.LEFT))
            ]}
          >
            {!!title && (
              <h3 css={styles.title}>
                <Translate>{title}</Translate>
              </h3>
            )}
            {!!subtitle && (
              <p css={styles.subtitle}>
                <Translate>{subtitle}</Translate>
              </p>
            )}
          </div>
        ) : null}
      </div>
      <div css={[styles.actionsContainer, getFlexAlignmentStyle(actionListChildrenAlignment || alignment)]}>
        {getParsedActionChildren(actionListChildren).map((actionChild, index) => (
          <div key={index} css={styles.actionButton}>
            <CTA
              testid={`${myTestid}-action-cta-${index}`}
              type={actionChild.type || CTAType.LINK}
              label={actionChild.label}
              icon={actionChild.icon}
              disabled={!!actionChild.disabled}
              onClick={actionChild.onClick && (event => actionChild.onClick(undefined, { metaKey: event?.metaKey }))}
              status={(actionChild as any).status}
              tooltip={actionChild.helperText}
              color={actionChild.color}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

CyInfo.displayName = ViewModel.DisplayName.CY_INFO;
