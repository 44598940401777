import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import { ClientEngineContext, ErrorBoundary, GeneralModel, ViewModel } from '@cyferd/client-engine';

import { Search, SearchProps } from '@components/elements/Search';
import { styles } from './styles';
import { Shortlink } from './Shortlink/Shortlink';
import { Print } from './Print';
import { MainNavigation } from '@components/elements/MainNavigation';
import { CyWrapperContext } from '@components/smart/CyWrapper';
import { CTA, CTAType } from '@components/elements/CTA';
import { Storage } from '@utils/storage';
import { GLOBAL_HEADER_SETTINGS_KEY, TRANS } from '@constants';
import { GlobalToolbar } from '@components/elements/GlobalToolbar';
import { UIContext } from '@components/providers/UIprovider';
import { useGetElementSize } from '@utils';
import { Banner } from '@components/platform/Banner/Banner';

export type GlobalHeaderProps = PropsWithChildren<{
  hidden?: boolean;
  searchMenu?: SearchProps;
  hiddenFavorites?: boolean;
  hiddenApps?: boolean;
  hiddenSearch?: boolean;
  hiddenShortlink?: boolean;
  hiddenNotifications?: boolean;
  hiddenPreferences?: boolean;
  hiddenPrint?: boolean;
  storage?: Storage;
}>;

export const GlobalHeader = ({
  children,
  hidden,
  searchMenu,
  hiddenSearch,
  hiddenShortlink,
  hiddenFavorites,
  hiddenApps,
  hiddenNotifications,
  hiddenPreferences,
  hiddenPrint,
  storage = new Storage()
}: GlobalHeaderProps) => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const initialPinned: boolean = useMemo(() => storage.get(GLOBAL_HEADER_SETTINGS_KEY), [storage]);
  const [isPinned, setPinned] = useState<boolean>(!!initialPinned);
  const [isLeftPanelOpen, setLeftPanelOpen] = useState<boolean>(false);
  const user = useUserSelector();
  const { useAction } = useContext(CyWrapperContext);
  const onNavigateTo = useAction('dispatchNavigateTo');
  const isAdmin = !!user?.roles?.length;
  const { size } = useContext(UIContext);
  const banner = user?.tenant?.banner;
  const bannerTop = banner?.position === 'top';
  const { ref: bannerRef, height } = useGetElementSize();

  const onChangePinned = useCallback(() => {
    storage.set(GLOBAL_HEADER_SETTINGS_KEY, !isPinned);
    setPinned(!isPinned);
    setLeftPanelOpen(true);
  }, [isPinned, storage]);

  const renderBanner = useCallback(() => {
    return <Banner banner={banner} ref={bannerRef} />;
  }, [banner, bannerRef]);

  return (
    <>
      {!hidden && (
        <>
          {bannerTop && renderBanner()}
          <header css={styles.header} data-testid="global-header" data-no-print={true} style={{ top: bannerTop ? height : 0 }}>
            <div css={styles.container}>
              <div css={styles.section}>
                {isAdmin && <MainNavigation isPinned={isPinned} isOpen={isLeftPanelOpen} onChangePinned={onChangePinned} onOpenChange={setLeftPanelOpen} />}
                <CTA
                  testid="home-btn"
                  outlined={true}
                  icon="home"
                  type={CTAType.LINK}
                  size={ViewModel.CTASize.LARGE}
                  color="NEUTRAL_1"
                  tooltip={TRANS.client.nav.builder.home}
                  onClick={event => onNavigateTo({ path: '/' }, event)}
                />
              </div>
              <div css={[styles.section, styles.searchSection]}>
                <ErrorBoundary>
                  {!hiddenSearch && <Search {...searchMenu} compact={size === GeneralModel.AppSize.S} expandCtaProps={{ color: 'HC_1' }} />}
                </ErrorBoundary>
              </div>

              <div css={styles.section}>
                {size === GeneralModel.AppSize.L && (
                  <>
                    <ErrorBoundary>{!hiddenShortlink && <Shortlink />}</ErrorBoundary>
                    <ErrorBoundary>{!hiddenPrint && <Print />}</ErrorBoundary>
                    <div css={styles.division} />
                  </>
                )}
                <GlobalToolbar
                  hiddenFavorites={hiddenFavorites}
                  hiddenApps={hiddenApps}
                  hiddenNotifications={hiddenNotifications}
                  hiddenPreferences={hiddenPreferences}
                  hiddenShortlink={hiddenShortlink}
                  hiddenPrint={hiddenPrint}
                />
              </div>
            </div>
          </header>
          <div css={styles.placeholder}></div>
        </>
      )}
      <div
        css={[isAdmin && isPinned && styles.pinnedMainNav, isAdmin && isPinned && isLeftPanelOpen && styles.pinnedOpenMainNav]}
        style={{ paddingBottom: bannerTop ? 0 : height, paddingTop: bannerTop ? height : 0 }}
      >
        {children}
      </div>
      {!bannerTop && renderBanner()}
    </>
  );
};

GlobalHeader.displayName = 'GlobalHeader';
