import { CollectionModel } from '@cyferd/client-engine';
import { EditorContext, EditorContextValue } from '../../../builder/views/shared/EditorHome';
import { useContext } from 'react';
import { Sidebar } from '../Sidebar';
import { CollectionChanges } from './CollectionChanges';
import { useOutsideClick } from '@utils/useOutsideClick';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { styles } from './styles';

export interface CollectionChangesSidebarProps {
  original: CollectionModel.Collection;
  open: boolean;
  onClose: () => void;
}

export const CollectionChangesSidebar = ({ original, open, onClose }: CollectionChangesSidebarProps) => {
  const { item, setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);
  const outsideRef = useOutsideClick(onClose);

  if (!item) return null;
  return (
    <div ref={outsideRef}>
      <Sidebar open={open} wide={true}>
        {!!open && (
          <PreventClickPropagation containerCss={styles.sidepanelContent}>
            <CollectionChanges prev={original} next={item} onClose={onClose} onChange={setItem} />
          </PreventClickPropagation>
        )}
      </Sidebar>
    </div>
  );
};
