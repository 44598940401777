import { ViewModel } from '@cyferd/client-engine';
import { Pagination } from '@components/elements/Pagination';
import { useDataSource } from '../../hooks';

type CyTablePaginationProps = {
  value: ViewModel.CyTableProps['value'];
  updateCursor: ReturnType<typeof useDataSource>['updateCursor'];
  disabled: boolean;
  hidden: boolean;
  cannotPaginate: boolean;
};

export const CyTablePagination = ({ value, updateCursor, disabled, hidden, cannotPaginate }: CyTablePaginationProps) => {
  if (hidden || cannotPaginate) return null;
  return (
    <div data-testid="CyTablePagination">
      <Pagination cursor={value?.query?.cursor} count={value?.list?.length} onFetch={updateCursor} disabled={disabled} testid="CyTablePagination" />
    </div>
  );
};
