import { css } from '@emotion/react';
import { FlatFormulaRow } from '../resources';
import { colorPerType } from '../getFormulaInputType';
import { COLOR, FONT_SIZE, FOREGROUND_COLOR, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';

export const getIndentValue = (depth: number) => `calc(calc(${GAP.XL} * ${depth}) + ${GAP.L})`;

const HEIGHT = '22px;';

export const getStyles = (row: FlatFormulaRow, isActive: boolean, offsetDropzone: boolean, readonly: boolean, isActiveContent: boolean) => {
  const typeColor = colorPerType[row.valueType];
  const hoverStyle = `background-color: ${COLOR.NEUTRAL_5};`;
  return {
    content: css`
      flex: 1;
    `,
    invisible: css`
      display: none;
    `,
    container: css`
      height: ${HEIGHT};
      overflow: hidden;
      ${!readonly && `cursor: pointer;`}
      position: relative;
      transition: background-color calc(${TRANSITION_SPEED} / 2);
      [data-selector='row-actions'] {
        transition: all 0.7s;
        opacity: 0;
        width: 0;
        overflow: hidden;
      }
      :hover {
        [data-selector='row-actions'] {
          opacity: 1;
          width: auto;
        }
        ${!readonly && hoverStyle}
      }
      ${isActiveContent && hoverStyle}
    `,
    indent: css`
      margin-left: ${getIndentValue(row.depth)}; // indentation per depth plus a margin to make up for the chevron negative margin
      flex: 1;
    `,
    isDraggingAbove: css`
      border-top: 1px ${COLOR.BRAND_1} solid;
    `,
    isDraggingOver: css`
      border-bottom: 1px ${COLOR.BRAND_1} solid;
      margin-left: calc(${getIndentValue(row.depth + (offsetDropzone ? 2 : 1))});
    `,
    dropzone: css`
      padding-left: ${getIndentValue(row.depth + 1)};
      overflow: hidden;
      height: 1px;
      ${isActiveContent && hoverStyle}
    `,
    closingDropzone: (index: number) => css`
      padding-left: ${getIndentValue(row.depth - index)};
      height: 2px;
    `,
    dropzonesSeparator: css`
      height: 2px; // a div between droppable elements is needed so the library allows dragging through items without getting stuck in small ones
      ${isActiveContent && hoverStyle}
    `,
    inline: css`
      display: flex;
      gap: ${GAP.XS};
      align-items: center;
    `,
    chevronContainer: css`
      height: ${HEIGHT};
      position: absolute;
      left: ${GAP.L};
    `,
    actions: css`
      padding-left: ${GAP.L};
      align-items: baseline;
    `,
    key: css`
      color: ${COLOR[colorPerType.object]};
      background-color: ${COLOR.BASE_BACKGROUND};
      border-radius: ${RADIUS.S};
      padding: ${GAP.XXS} ${GAP.XS};
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    `,
    formulaContainer: css`
      transition: background-color calc(${TRANSITION_SPEED} / 2);
      background-color: ${isActive ? COLOR[typeColor] : SECONDARY_COLOR[typeColor]};
      border-radius: ${RADIUS.S};
      padding: ${GAP.XXS} ${GAP.XS};
    `,
    formulaLabel: css`
      color: ${isActive ? FOREGROUND_COLOR[typeColor] : COLOR.BASE_FOREGROUND};
    `,
    formulaIconColor: isActive ? FOREGROUND_COLOR[typeColor] : COLOR.BASE_FOREGROUND,
    containerLabel: css`
      transition: background-color calc(${TRANSITION_SPEED} / 2);
      border: 1px ${COLOR[typeColor]} solid;
      color: ${isActive ? FOREGROUND_COLOR[typeColor] : COLOR[typeColor]};
      background-color: ${isActive ? COLOR[typeColor] : COLOR.BASE_BACKGROUND};
      border-radius: ${RADIUS.S};
      padding: ${GAP.XXS} ${GAP.XS};
    `,
    value: css`
      transition: background-color calc(${TRANSITION_SPEED} / 2);
      color: ${isActive ? FOREGROUND_COLOR[typeColor] : COLOR[typeColor]};
      background-color: ${COLOR.BASE_BACKGROUND};
      padding: ${GAP.XXS} ${GAP.XS} ${GAP.XXS} 0;
      padding-left: 0;
      ${isActive &&
      `
      background-color: ${COLOR[typeColor]};
      border-radius: ${RADIUS.S};
      padding-left: ${GAP.XS};
      `};
      flex: 1;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    `,
    arrayDot: css`
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${COLOR[colorPerType.array]};
      margin-right: ${GAP.XXXS};
    `,
    literalColor: css`
      background-color: ${COLOR[row.value]};
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px ${COLOR.BASE_FOREGROUND} solid;
    `,
    lineIndicator: css`
      display: inline-block;
      width: ${HEIGHT}; // up to 3 chars
      overflow: hidden;
      font-size: ${FONT_SIZE.XXS};
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    `
  };
};
