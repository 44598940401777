import { useMemo } from 'react';

import { getParsedActionChildren, ViewModel } from '@cyferd/client-engine';

import { CTA } from '@components/elements/CTA';
import { Modal } from '@components/elements/Modal';
import { styles } from './styles';

export const CyModal = ({ id, title, subtitle, icon, actionListChildren, children, onClose, type, effectChildren }: ViewModel.CyModalProps) => {
  const myTestid = 'cyModal';
  const parsedActionChildren = useMemo(() => getParsedActionChildren(actionListChildren), [actionListChildren]);
  const footer = !!parsedActionChildren?.length && (
    <div css={styles.actionListContainer}>
      {parsedActionChildren.map((action, index) => (
        <div key={index}>
          <CTA
            type={action.type}
            label={action.label}
            icon={(!!action.icon as null) && { name: action.icon, title: action.label }}
            disabled={!!action.disabled}
            onClick={action.onClick}
            testid={`${myTestid}-footer-action-btn-${index}`}
            status={(action as any).status}
            tooltip={action.helperText}
            color={action.color}
          />
        </div>
      ))}
    </div>
  );
  return (
    <Modal id={id} type={type} open={true} onClose={onClose} title={title} description={subtitle} icon={icon} footer={footer} testid={myTestid}>
      <div data-testid="effects">{effectChildren}</div>
      {children}
    </Modal>
  );
};

CyModal.displayName = ViewModel.DisplayName.CY_MODAL;
