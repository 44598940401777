import { useCallback, useContext, useMemo } from 'react';

import { GeneralModel, ViewModel } from '@cyferd/client-engine';

import { Input } from '../Input';
import { FlagPicker } from './components/FlagPicker';
import { styles } from './styles';
import { countries } from './utils/countries';
import { InputChangeSection } from './utils/types';
import { CyWrapperContext } from '../../smart/CyWrapper';
import { OptionMenuProps } from '../OptionMenu';
import { CTAType } from '../CTA';
import { InputDescription } from '../InputDescription';
import { InputErrorMesssage } from '../InputErrorMessage';

export type PhoneInputProps = {
  id?: string;
  defaultValue?: string;
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  color?: GeneralModel.Color.ThemeColor;
  onChange: (phone: string) => void;
  placeholder?: string;
  preferredOptionList?: string[];
  required?: boolean;
  optionList?: OptionMenuProps['optionList'];
  value: string;
  info?: string;
  disabledType?: GeneralModel.DisabledType;
};

const splitPhoneNumber = (value: string) => {
  const regex = new RegExp(`^(${countries.map(({ dialCode }) => `\\${dialCode}`).join('|')})?\\s*([0-9()-\\s]+)`);
  return value?.match?.(regex)?.slice(1, 3);
};

export const PhoneInput = ({
  id,
  description,
  disabled,
  errorMessage,
  label,
  color,
  onChange,
  placeholder,
  preferredOptionList,
  required,
  value,
  optionList,
  info,
  disabledType
}: PhoneInputProps) => {
  const { useOnOpenExternalUrl } = useContext(CyWrapperContext);
  const onOpenExternalUrl = useOnOpenExternalUrl();
  const formattedNumber = useMemo(() => splitPhoneNumber(value), [value]);

  const onPressCallButton = useCallback(() => onOpenExternalUrl(`tel:${value}`), [onOpenExternalUrl, value]);

  const onChangeInput = useCallback(
    (event: string, eventType: InputChangeSection) => {
      const prefix = eventType === 'prefix' ? event : formattedNumber?.[0];
      const num = eventType === 'prefix' ? formattedNumber?.[1] : event;
      const newValue = splitPhoneNumber([prefix, ' ', num].filter(Boolean).join(' '))?.join(' ').replace(/\s\s/g, ' ');
      onChange(newValue);
    },
    [formattedNumber, onChange]
  );

  const onChangePhoneInput = useCallback((val: string) => onChangeInput(val, 'phone'), [onChangeInput]);
  const hasMoreThanOneAction = optionList?.length > 0;

  return (
    <div id={id} data-testid={id}>
      <div css={styles.phoneInputContainer}>
        <FlagPicker
          value={formattedNumber?.[0]}
          onChange={onChangeInput}
          preferredCountries={preferredOptionList}
          disabled={disabled}
          hasError={!!errorMessage}
          disabledType={disabledType}
        />
        <div css={styles.phoneNumber}>
          <Input
            disabled={disabled}
            label={label}
            name={label}
            color={color}
            onChange={onChangePhoneInput}
            placeholder={placeholder}
            required={required}
            testid="phone-input"
            value={formattedNumber?.[1]}
            showClear={true}
            onCustomClear={() => onChange(null)}
            errorMessage={errorMessage}
            disabledType={disabledType}
            optionList={[
              {
                testid: 'phone-action',
                onClick: onPressCallButton,
                type: CTAType.LINK,
                image: 'call',
                tooltip: 'Call phone number',
                important: !hasMoreThanOneAction,
                size: ViewModel.CTASize.MEDIUM
              },
              ...(optionList || [])
            ]}
            info={info}
          />
        </div>
      </div>
      <InputDescription description={description} />
      <InputErrorMesssage id={id} message={errorMessage} showLine={!!value} />
    </div>
  );
};
