import { createContext, Dispatch, memo, PropsWithChildren, useCallback, useEffect, useReducer } from 'react';

import { noop, useIsMounted } from '@cyferd/client-engine';

import { ENV } from '@constants';
import { GeneralModel } from '@models';
import { combineReducers } from '@utils/combineReducers';
import { reducer as uiReducer } from './ui/reducer';
import { getInitialState as getUiInitialState } from './ui/state';

export const getInitialState = (): GeneralModel.GlobalState => ({
  ui: getUiInitialState()
});

export const combinedReducer = combineReducers<GeneralModel.GlobalState>({
  ui: uiReducer
});

export const GlobalContext = createContext<{ state: GeneralModel.GlobalState; deps: GeneralModel.Deps; dispatch: Dispatch<any> }>({
  state: getInitialState(),
  dispatch: noop,
  deps: null
});

export const GlobalProvider = memo(
  ({
    children,
    deps,
    initialState,
    combinedReducers
  }: PropsWithChildren<{
    initialState: GeneralModel.GlobalState;
    deps: GeneralModel.Deps;
    combinedReducers: GeneralModel.Reducer<GeneralModel.GlobalState>;
  }>) => {
    const [state, dispatchRaw] = useReducer(combinedReducers, initialState);
    const isMounted = useIsMounted();
    const dispatch = useCallback(
      (action: GeneralModel.Action<any, any>) => {
        /* istanbul ignore next line | these logs are just for debugging */
        if (!/^\[session\]|^\[ui\]/.test(action?.type)) deps.logger.debug(ENV.APP_LOGGER_HEADER, `action "${action?.type}"`, action?.payload);
        isMounted.current && dispatchRaw(action);
      },
      [deps.logger, isMounted]
    );
    useEffect(() => deps.stateSnapshot.set(state), [state]); // eslint-disable-line react-hooks/exhaustive-deps
    return <GlobalContext.Provider value={{ state, dispatch, deps }}>{children}</GlobalContext.Provider>;
  }
);
