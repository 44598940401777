// istanbul ignore file
import { useCallback, useMemo, useState } from 'react';
import { applyMask, capitalize, GeneralModel, noop } from '@cyferd/client-engine';
import { Input } from '@components/elements/Input';
import { TableCell } from '@components/elements/Table/TableCell';
import { styles } from './styles';
import { Icon } from '@components/elements/Icon';
import { COLOR, FONT_SIZE, TRANS } from '@constants';
import { PopoverTrigger } from '@components/elements/Popover';
import { ReadonlyFormat } from '@components/elements/ReadonlyFormat';
import { Switch } from '@components/elements/Switch';
import { Checkbox } from '@components/elements/Checkbox';
import { DropDownOptionEditTable } from '@components/elements/DropDownOptionEditTable';
import { OptionMenu } from '@components/elements/OptionMenu';
import { onCopy } from './onCopy';

const renderCellInput = ({ format, ...props }) => {
  switch (format) {
    case GeneralModel.JSONSchemaFormat.CHECKBOX:
      return <Checkbox label="" autoFocus={true} {...props} />;
    case GeneralModel.JSONSchemaFormat.SWITCH:
      return <Switch label="" autoFocus={true} {...props} />;
    case GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST:
    case GeneralModel.JSONSchemaFormat.NUMBER_OPTION_LIST:
      return <DropDownOptionEditTable {...props} autoFocus={true} value={props.value} onChange={props.onChange} options={props.options} />;
    default:
      return <Input type={format} autoFocus={true} noWrapperPadding={true} onChange={props.onChange} {...props} />;
  }
};

export interface EditTableCellProps {
  rowIndex: number;
  colIndex: number;
  setEditing: (rowIndex: number, colIndex: number, value: boolean) => void;
  itemId: string;
  setSelected: (rowIndex: number, colIndex: number) => void;
  setCursor?: (params: { row: number; col: number }) => void;
  inSelection?: boolean;
  selected: boolean;
  editable: boolean;
  edited: boolean;
  col: any;
  editing: boolean;
  error?: string;
  onChange: (value: any, itemId: string, definitionId: string) => void;
  value: any;
  definitionId: string;
}

export const EditTableCell = ({
  rowIndex,
  colIndex,
  setEditing,
  itemId,
  setSelected,
  selected,
  setCursor,
  inSelection,
  editable,
  edited,
  col,
  editing,
  error,
  onChange,
  value,
  definitionId
}: EditTableCellProps) => {
  const innerOnChange = useCallback((value: any) => onChange(value, itemId, definitionId), [onChange, itemId, definitionId]);
  const innerSetSelected = useCallback(() => setSelected(rowIndex, colIndex), [setSelected, rowIndex, colIndex]);
  const innerSetEditing = useCallback(value => setEditing(rowIndex, colIndex, value), [setEditing, rowIndex, colIndex]);
  const innerSetCursor = useCallback(() => setCursor({ row: rowIndex, col: colIndex }), [setCursor, rowIndex, colIndex]);

  const onKeyDown = useCallback(
    (e: any) => {
      if (!editable) return;
      if (e.key === 'Enter') {
        e.preventDefault();
        innerSetEditing(!editing);
      }
      if (editing) return;
      if (e.key === 'Backspace' || e.key === 'Delete') {
        e.preventDefault();
        innerOnChange(undefined);
      }
    },
    [innerOnChange, innerSetEditing, editable, editing]
  );

  const mask = useMemo(() => col.definition?.property?.metadata?.mask, [col.definition?.property?.metadata?.mask]);
  const visibleValue = useMemo(() => applyMask(value, mask) ?? value, [mask, value]);
  const options = useMemo(() => col.definition?.property?.metadata?.optionList, [col.definition?.property?.metadata?.optionList]);

  const [initialValue] = useState(value);
  const resetValue = useCallback(() => innerOnChange(initialValue), [innerOnChange, initialValue]);

  const optionList = useMemo(
    () => [
      {
        label: TRANS.client.buttons.copy,
        image: 'copy_all' as GeneralModel.IconName,
        testid: `copy-${rowIndex}-${colIndex}`,
        onClick: onCopy
      },
      {
        label: TRANS.client.buttons.paste,
        image: 'content_paste' as GeneralModel.IconName,
        testid: `paste-${rowIndex}-${colIndex}`,
        onClick: noop
      },
      ...(value !== initialValue
        ? [
            {
              label: TRANS.client.buttons.reset,
              image: 'replay' as GeneralModel.IconName,
              testid: `reset-${rowIndex}-${colIndex}`,
              onClick: resetValue
            }
          ]
        : [])
    ],
    [colIndex, initialValue, resetValue, rowIndex, value]
  );

  const renderCell = ({ onClick, ref }) => (
    <div
      onContextMenu={e => {
        e.preventDefault();
        onClick();
      }}
      data-testid={`option-menu-${rowIndex}-${colIndex}`}
      css={styles.menuTrigger}
    >
      <div ref={ref} css={styles.menuAnchor} />
      <div css={styles.innerCell}>
        {error && !editing && (
          <div css={styles.erroredCell} data-testid={`error-${rowIndex}-${colIndex}`}>
            <PopoverTrigger value={capitalize(error)} color="RD_1">
              <Icon name="warning" fill={COLOR.RD_1} size={FONT_SIZE.M} title="Error" />
            </PopoverTrigger>
          </div>
        )}
        {editing && (
          <div css={styles.inputContainer} data-testid={`editing-${rowIndex}-${colIndex}`}>
            {renderCellInput({ onChange: innerOnChange, value, format: col.format, options, testid: `cell-input-${rowIndex}-${colIndex}`, innerSetEditing })}
          </div>
        )}
        <div css={editing && styles.hidden} data-testid={`readonly-${rowIndex}-${colIndex}`}>
          <TableCell
            id={col.id}
            color={col.color}
            format={col.format}
            testid={`${rowIndex}-${colIndex}`}
            rowIndex={rowIndex}
            item={
              <ReadonlyFormat
                item={{
                  value: visibleValue,
                  calculatedValue: value,
                  rawValue: value,
                  color: col.color,
                  definitionId: col.definition.id
                }}
                fullRecord={col.fullItem}
                definition={col.definition}
                collectionId={col.collectionId}
                recordId={col.recordId}
                rootFieldSchema={col.definition}
              />
            }
          />
        </div>
      </div>
    </div>
  );
  const selection = inSelection || selected;

  return (
    <td
      aria-selected={selected}
      aria-colindex={colIndex}
      css={[styles.cellTd, ((edited && !editing) && styles.editedTd), !editable && styles.nonEditableTd, inSelection && styles.inSelection]}
      data-itemid={itemId}
      data-definitionid={definitionId}
      data-colindex={colIndex}
      data-rowindex={rowIndex}
      data-inselection={selection}
      data-value={visibleValue}
      data-testid={`edit-table-cell-${rowIndex}-${colIndex}`}
      onMouseDown={editing || selection ? null : (e) => {
        e.preventDefault();
        if (e.buttons == 1 || e.buttons == 3) innerSetSelected()
      }}
      onDoubleClick={editable && !editing ? innerSetEditing : null}
      onKeyDown={selected && editable ? onKeyDown : null}
      onMouseOver={e => {
        e.preventDefault();
        if (e.buttons == 1 || e.buttons == 3) {
          innerSetCursor();
        }
      }}
      tabIndex={0}
    >
      <OptionMenu optionList={optionList} renderButton={renderCell} />
    </td>
  );
};
