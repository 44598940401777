import React, { PropsWithChildren, createContext, useCallback, useMemo } from 'react';
import { BehaviorSubject, Observable, filter, take, tap } from 'rxjs';

export const SemaphoreContext = createContext<{
  semaphore$: Observable<boolean>;
  unlock: () => void;
}>(undefined);

export const SemaphoreProvider = ({ children }: PropsWithChildren) => {
  const obs$ = useMemo(() => new BehaviorSubject(true), []);
  const unlock = useCallback(() => {
    obs$.next(true);
  }, [obs$]);
  const semaphore$ = useMemo(
    () =>
      obs$.pipe(
        filter(value => value === true),
        take(1),
        tap(() => obs$.next(false))
      ),
    [obs$]
  );

  return <SemaphoreContext.Provider value={{ semaphore$, unlock }}>{children}</SemaphoreContext.Provider>;
};
