import React, { ComponentProps, useCallback, useMemo, useState } from 'react';

import { CollectionModel, EvaluatorModel, GeneralModel, getFormulaKey, isObject, normalize, removeKeyList } from '@cyferd/client-engine';

import { colorPerType, FormulaInputType } from '../getFormulaInputType';
import { FormulaInputRow, formulaMap, unescapeKeys } from '../resources';
import { styles } from './styles';
import { BaseForm } from '../../../smart/CyForm/components/BaseForm';
import { COLOR, ENV, getDataTypeIcon, getDataTypeLabel, TRANS } from '@constants';
import { FormulaTooltipContent } from '../FormulaTooltip';
import { Fieldset } from '@components/elements/Fieldset';
import { DOC_ID, useNavigateToDoc } from '@components/elements/Docs/resources';

const validFormatList = [
  GeneralModel.JSONSchemaFormat.MULTILINE,
  GeneralModel.JSONSchemaFormat.NUMBER,
  GeneralModel.JSONSchemaFormat.CHECKBOX,
  GeneralModel.JSONSchemaFormat.COLOR,
  GeneralModel.JSONSchemaFormat.ICON_IMAGE,
  GeneralModel.JSONSchemaFormat.RICH_TEXT,
  GeneralModel.JSONSchemaFormat.DATE,
  GeneralModel.JSONSchemaFormat.DATE_TIME_U,
  GeneralModel.JSONSchemaFormat.MONTH,
  GeneralModel.JSONSchemaFormat.WEEK,
  GeneralModel.JSONSchemaFormat.TIME,
  GeneralModel.JSONSchemaFormat.SQL,
  GeneralModel.JSONSchemaFormat.JSON
];

const defaultFormatByTypeMap = {
  string: GeneralModel.JSONSchemaFormat.MULTILINE,
  number: GeneralModel.JSONSchemaFormat.NUMBER,
  boolean: GeneralModel.JSONSchemaFormat.CHECKBOX,
  object: GeneralModel.JSONSchemaFormat.JSON,
  array: GeneralModel.JSONSchemaFormat.JSON,
  null: GeneralModel.JSONSchemaFormat.JSON,
  any: GeneralModel.JSONSchemaFormat.JSON,
  undefined: GeneralModel.JSONSchemaFormat.JSON
};

const detailGroupList: CollectionModel.Collection['detailGroupList'] = [
  { id: 'options', name: 'Options', startsCollapsed: true },
  { id: 'advanced', name: 'Advanced', startsCollapsed: true }
];

const fxProps = Object.entries<GeneralModel.JSONSchema>(EvaluatorModel.formulaOptionsSchema.properties).reduce(
  (total, [key, props]) => {
    const category = props.metadata?.detailGroupId || 'ungrouped';
    return { ...total, [category]: { ...total[category], [key]: props } };
  },
  {} as {
    ungrouped: Record<string, GeneralModel.JSONSchema>;
    options: Record<string, GeneralModel.JSONSchema>;
    advanced: Record<string, GeneralModel.JSONSchema>;
  }
);

export interface FormulaInputProps {
  value: any;
  config: FormulaInputRow;
  inputList: FormulaInputRow[];
  onChange?: (value: GeneralModel.EvaluatorFormula) => void;
}

export const FormulaInput = ({ value, config, inputList, onChange }: FormulaInputProps) => {
  const onNavigateToDoc = useNavigateToDoc();
  const cleanType = (config.formulaType === FormulaInputType.REFERENCE ? 'string' : config.formulaType) as GeneralModel.JSONSchema['type'];

  const [selectedFormat, setSelectedFormat] = useState<GeneralModel.JSONSchemaFormat>(
    (() => {
      /** is color */
      if (config.formulaType === FormulaInputType.STRING && !!COLOR[config.template]) {
        return GeneralModel.JSONSchemaFormat.COLOR;
      }
      /** is icon */
      if (config.formulaType === FormulaInputType.STRING && GeneralModel.iconNameList.includes(value)) {
        return GeneralModel.JSONSchemaFormat.ICON_IMAGE;
      }
      if (config.formulaType === FormulaInputType.REFERENCE || !validFormatList.includes(config.output?.format)) {
        return defaultFormatByTypeMap[String(cleanType)];
      }
      return config.output?.format;
    })()
  );

  const isFormula = config.formulaType === FormulaInputType.FORMULA;
  const formulaKey = useMemo(() => isFormula && getFormulaKey(value), [isFormula, value]);

  const suggestionList: GeneralModel.JSONSchemaMetadata['suggestionList'] = inputList
    .filter(i => i.formulaType === FormulaInputType.REFERENCE && ['string', 'number'].includes(typeof i.template))
    .map(i => ({ value: i.template, label: i.label, description: i.description, color: colorPerType[i.type], image: getDataTypeIcon(i.format) }));

  const schema = useMemo(
    () =>
      normalize.schema(
        {
          type: 'object',
          required: ['formula'],
          properties: {
            type: {
              type: 'string',
              format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
              label: ' ',
              metadata: {
                hidden: !!isFormula,
                optionList: validFormatList.map(value => ({
                  color: colorPerType[GeneralModel.formatToTypeMap[value]],
                  value,
                  label: getDataTypeLabel(value as any),
                  image: getDataTypeIcon(value as any)
                }))
              }
            },
            value: {
              type: GeneralModel.formatToTypeMap[selectedFormat],
              format: selectedFormat,
              label: TRANS.client.fields.titles.value,
              metadata: { hidden: !!isFormula, suggestionList, expanded: true }
            },
            formula: {
              type: 'string',
              format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
              label: 'Fx',
              metadata: {
                hidden: !isFormula,
                optionList: EvaluatorModel.formulaList
                  .filter(key => !formulaMap[key].isAlias)
                  .map(key => ({
                    label: formulaMap[key].label,
                    value: key,
                    image: getDataTypeIcon(formulaMap[key].output?.format as any),
                    color: colorPerType[formulaMap[key].type],
                    description: formulaMap[key].description
                  }))
              }
            },
            options: { type: 'object', label: ' ', properties: fxProps.ungrouped, metadata: { hidden: !isFormula } }
          }
        },
        { validDetailGroupIdList: detailGroupList.map(({ id }) => id), avoidAlphabeticalSort: true }
      ),
    [isFormula, selectedFormat, suggestionList]
  );

  const optionsSchema = useMemo(
    () =>
      normalize.schema(
        { type: 'object', properties: { options: { type: 'object', label: ' ', properties: fxProps.options, metadata: { hidden: !isFormula } } } },
        { validDetailGroupIdList: detailGroupList.map(({ id }) => id), avoidAlphabeticalSort: true }
      ),
    [isFormula]
  );

  const advSchema = useMemo(
    () =>
      normalize.schema(
        { type: 'object', properties: { options: { type: 'object', label: ' ', properties: fxProps.advanced, metadata: { hidden: !isFormula } } } },
        { validDetailGroupIdList: detailGroupList.map(({ id }) => id), avoidAlphabeticalSort: true }
      ),
    [isFormula]
  );

  const internalValue = useMemo(
    () => ({ value: unescapeKeys(value), type: selectedFormat, formula: formulaKey, options: isFormula && removeKeyList(value, [formulaKey]) }),
    [value, selectedFormat, formulaKey, isFormula]
  );

  const onInternalChange: ComponentProps<typeof BaseForm>['onChange'] = useCallback(
    (v, keyChanged) => {
      if (keyChanged === 'value') onChange(v?.value);
      if (keyChanged === 'type' && !!v?.type) setSelectedFormat(v.type);
      if (keyChanged === 'formula' && !!v?.formula) onChange({ ...removeKeyList(value, [formulaKey]), [v?.formula]: value[formulaKey] });
      if (keyChanged === 'options' && isObject(v?.options)) {
        onChange({ ...Object.fromEntries(Object.entries(v.options).filter(([_, o]) => ![null, undefined].includes(o))), [v?.formula]: value[formulaKey] });
      }
    },
    [formulaKey, onChange, value]
  );

  const getOptionMenu: ComponentProps<typeof BaseForm>['getOptionMenu'] = useCallback(
    props => {
      if (props.id === 'formula')
        return [
          {
            disabled: !props.value,
            image: 'help',
            important: true,
            testid: `action-help-btn`,
            tooltip: 'Help',
            onClick: () => onNavigateToDoc(`${DOC_ID.API_FORMULAS}-${props.value}`)
          }
        ];
      return [];
    },
    [onNavigateToDoc]
  );

  return (
    <div css={styles.container} onClick={event => event.stopPropagation()}>
      <div css={[styles.inputContainer, !!isFormula && styles.formulaContainer]} data-testid="formula-input">
        <BaseForm
          delayTime={ENV.INPUT_DEBOUNCE_TIME}
          detailGroupList={detailGroupList}
          avoidInitialSync={true}
          value={internalValue}
          schema={schema}
          onChange={onInternalChange}
          getOptionMenu={getOptionMenu}
          shouldValidate={false}
        />
        <BaseForm
          delayTime={ENV.INPUT_DEBOUNCE_TIME}
          detailGroupList={detailGroupList}
          avoidInitialSync={true}
          value={internalValue}
          schema={optionsSchema}
          onChange={onInternalChange}
        />
        <BaseForm
          delayTime={ENV.INPUT_DEBOUNCE_TIME}
          detailGroupList={detailGroupList}
          avoidInitialSync={true}
          value={internalValue}
          schema={advSchema}
          onChange={onInternalChange}
        />
        {!!isFormula && (
          <Fieldset isDetailGroup={true}>
            <FormulaTooltipContent item={config} />
          </Fieldset>
        )}
      </div>
    </div>
  );
};
