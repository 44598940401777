import { COLOR, GAP, GLOBAL_HEADER_HEIGHT, SHADOW, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const MENU_WIDTH = '335px';

export const styles = {
  pane: css`
    position: fixed;
    top: ${GLOBAL_HEADER_HEIGHT};
    right: -${MENU_WIDTH};
    z-index: 10;
    width: ${MENU_WIDTH};
    max-width: 100%;
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${GAP.L};
    box-shadow: ${SHADOW.S};
    height: calc(100% - ${GLOBAL_HEADER_HEIGHT});
    transition: right calc(${TRANSITION_SPEED} / 2);
  `,
  wide: css`
    width: 750px;
  `,
  paneOpen: css`
    right: 0;
  `
};
