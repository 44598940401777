import { useCallback, useContext, useRef, useState, useMemo } from 'react';
import { CyWrapperContext } from '../../smart/CyWrapper';
import {
  ApiModel,
  GeneralModel,
  isDeepEqual,
  mergeTruthy,
  ofTypeSetData,
  parseSchemaProperty,
  swallowError,
  useFinalizeWhileMounted,
  useOlderReference,
  useUnmountObservable
} from '@cyferd/client-engine';
import { Subject, takeUntil, tap } from 'rxjs';

export const isValidCollectionId = (id: any) => !!id && typeof id === 'string' && !/{{.+}}/.test(id);

export const useCollectionLookupFetch = ({
  collectionId,
  value,
  fetchCriteria,
  fixedFilter,
  onGetSuccess,
  parseData,
  fullValue,
  limit,
  path
}: {
  collectionId: GeneralModel.EvaluatorFormula<string>;
  value?: string;
  fetchCriteria?: GeneralModel.FetchCriteria;
  fixedFilter?: GeneralModel.FetchCriteria['fixedFilter'];
  onGetSuccess: (response: any) => any;
  parseData: GeneralModel.ParseDataFn;
  fullValue: ApiModel.ApiRecord;
  limit: number;
  path?: string[];
}) => {
  const shouldUseOldBaseCursor = useOlderReference();
  const { useAction } = useContext(CyWrapperContext);
  const finalize = useFinalizeWhileMounted();

  const onCoreGet = useAction('coreGet');
  const onCoreList = useAction('coreList');

  const onDestroy$ = useUnmountObservable();
  const onCancel$ = useRef(new Subject<void>());

  const [isLoadingList, setLoadingList] = useState<boolean>(false);
  const [isLoadingRecord, setLoadingRecord] = useState<boolean>(false);
  const [listRes, setListRes] = useState<ApiModel.ApiValue>(undefined);
  const allItemsRetrieved = useMemo(() => !!(listRes?.list?.length - (listRes?.query?.cursor?.options?.skip || 0) < limit), [limit, listRes]);

  const parseCriteria = useCallback(
    (criteria: any) =>
      parseSchemaProperty(criteria, {
        parseData,
        fullItem: fullValue,
        path: path?.join?.('.'),
        definition: null,
        value: collectionId
      }),
    [collectionId, fullValue, parseData, path]
  );

  const parsedBaseCursor: GeneralModel.FetchCriteria = useMemo(
    () => mergeTruthy(parseCriteria({ collectionId }), parseCriteria(fetchCriteria)),
    [collectionId, fetchCriteria, parseCriteria]
  );

  const baseCursor = shouldUseOldBaseCursor(parsedBaseCursor, (o, n) => !isDeepEqual(o, n));

  const onGet = useCallback(() => {
    if (!isValidCollectionId(baseCursor?.collectionId) || !isValidCollectionId(value)) return;
    setLoadingRecord(true);
    onCoreGet({
      pointer: GeneralModel.IGNORED_POINTER_ID,
      query: { cursor: { collectionId: baseCursor?.collectionId, id: value } }
    })
      .pipe(
        takeUntil(onDestroy$),
        ofTypeSetData(),
        tap(onGetSuccess),
        swallowError(),
        finalize(() => setLoadingRecord(false))
      )
      .subscribe();
  }, [baseCursor?.collectionId, finalize, onCoreGet, onDestroy$, onGetSuccess, value]);

  const onList = useCallback(
    (searchString: string, loadMore?: boolean) => {
      if (!isValidCollectionId(baseCursor?.collectionId)) return;
      if (!loadMore) setListRes(undefined);
      setLoadingList(true);
      onCoreList({
        pointer: GeneralModel.IGNORED_POINTER_ID,
        query: {
          cursor: { ...baseCursor, searchString, fixedFilter, options: { limit, ...baseCursor.options, skip: loadMore ? listRes?.list?.length : 0 } }
        }
      })
        .pipe(
          takeUntil(onDestroy$),
          takeUntil(onCancel$.current),
          ofTypeSetData(),
          tap(response => setListRes(loadMore ? { ...response, list: [...listRes?.list, ...response.list] } : response)),
          finalize(() => setLoadingList(false)),
          swallowError()
        )
        .subscribe();
    },
    [baseCursor, onCoreList, fixedFilter, limit, listRes?.list, onDestroy$, finalize]
  );

  return {
    isLoadingList,
    isLoadingRecord,
    allItemsRetrieved,
    baseCursor,
    onGet,
    onList,
    listRes,
    setListRes,
    onCancel$
  };
};
