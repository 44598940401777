import jwt_decode from 'jwt-decode';
import { FC, useEffect, useState } from 'react';
import { map, takeUntil, tap } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { decodeQueryString, swallowError, TokenSet, useFinalizeWhileMounted, useUnmountObservable } from '@cyferd/client-engine';

import { CTA, CTAType } from '@components/elements/CTA';
import { Spinner } from '@components/elements/Spinner';
import { challengeKey, getChallenge } from './getChallenge';
import { styles } from './styles';

/* istanbul ignore next line */
const defaultOnNavigate = (href: string) => {
  window.location.href = href;
};

export interface LoginPageProps {
  tokenUrl: string;
  authUrl: string;
  onTokenChange: (event: TokenSet) => void;
  Image: FC;
  Logo: FC<{ width?: number; height?: number }>;
  onNavigate?: (event: string) => void;
}

export const LoginPage = ({ tokenUrl, authUrl, onTokenChange, Image, Logo, onNavigate = defaultOnNavigate }: LoginPageProps) => {
  const { code } = decodeQueryString(window.location.href.split('?')[1]);
  const onDestroy$ = useUnmountObservable();
  const [codes, setCodes] = useState<{ verifier: string; challenge: string }>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const finalize = useFinalizeWhileMounted();

  useEffect(() => {
    if (typeof code !== 'string' || !codes) return;
    setLoading(true);
    ajax
      .post<{ access_token: string; refresh_token: string }>(tokenUrl, `grant_type=code&code=${encodeURIComponent(code)}&code_verifier=${codes?.verifier}`, {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      })
      .pipe(
        takeUntil(onDestroy$),
        map(({ response }) => {
          const exp = jwt_decode<any>(response?.access_token)?.exp;
          return { access: response?.access_token, refresh: response?.refresh_token, exp } as TokenSet;
        }),
        tap(tokenSet => {
          sessionStorage.removeItem(challengeKey);
          onTokenChange(tokenSet);
        }),
        swallowError(),
        finalize(() => setLoading(false))
      )
      .subscribe();
  }, [code, codes, finalize, onDestroy$, onTokenChange, tokenUrl]);

  useEffect(() => {
    getChallenge().pipe(takeUntil(onDestroy$), tap(setCodes)).subscribe();
  }, [onDestroy$]);

  return (
    <>
      <div css={styles.container} data-testid="login-container">
        {!!isLoading && <Spinner />}
        {!isLoading && (
          <div>
            <div css={styles.content}>
              <div css={styles.infoContainer}>
                <div>
                  <Logo />
                  <p css={styles.title}>Welcome to Cyferd</p>
                  <p css={styles.subtitle}>
                    All your data in one platform.
                    <br />
                    Easier than ever.
                  </p>
                  {!!codes && (
                    <CTA
                      testid="login-btn"
                      onClick={() => onNavigate(`${authUrl}?redirect_uri=${encodeURIComponent(window.location.href)}&code_challenge=${codes.challenge}`)}
                      label="Continue"
                      type={CTAType.PRIMARY}
                    />
                  )}
                </div>
              </div>
              <div css={styles.imageContainer}>
                <Image />
              </div>
            </div>
          </div>
        )}
      </div>
      <div css={styles.bottomContainer}>
        <div css={styles.poweredByContainer}>
          <div>Powered by</div>
          <div>
            <Logo width={130} height={25} />
          </div>
        </div>
      </div>
    </>
  );
};
