import {
  ApiModel,
  CollectionModel,
  GeneralModel,
  SchemaFormContext,
  SchemaFormContextValue,
  getFlatSchemaProps,
  isDeepEqual,
  normalize,
  ofTypeSetData,
  swallowError,
  useFinalizeWhileMounted,
  useUnmountObservable
} from '@cyferd/client-engine';
import { SelectDropdown } from '../SelectDropdown';
import { OptionMenuProps } from '../OptionMenu';
import { ComponentProps, useContext, useEffect, useMemo, useState } from 'react';
import { isValidCollectionId } from '../CollectionLookup/useCollectionLookupFetch';
import { takeUntil, tap } from 'rxjs';
import { CyWrapperContext } from '../../smart/CyWrapper/CyWrapper';
import { getLabel } from '@utils';

export interface CollectionFieldProps {
  id?: string;
  value?: string;
  description?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  required?: boolean;
  testid?: string;
  onChange: (value: string) => void;
  collectionId: GeneralModel.EvaluatorFormula<string>;
  collection?: CollectionModel.Collection;
  autoFocus?: boolean;
  optionList?: OptionMenuProps['optionList'];
  condition?: GeneralModel.EvaluatorFormula<boolean>;
  info?: string;
  path?: string[];
  disabledType?: GeneralModel.DisabledType;
}

export const CollectionField = ({
  id,
  value,
  description,
  disabled,
  errorMessage,
  label,
  required,
  testid,
  onChange,
  collectionId,
  optionList,
  condition,
  info,
  path,
  disabledType,
  collection
}: CollectionFieldProps) => {
  const { useAction } = useContext(CyWrapperContext);
  const { fullValue, useParsers } = useContext<SchemaFormContextValue<ApiModel.ApiRecord>>(SchemaFormContext);
  const onDestroy$ = useUnmountObservable();
  const onCoreDescribe = useAction('coreDescribe');
  const [internalCollection, setInternalCollection] = useState<CollectionModel.Collection>(collection);
  const [isLoading, setLoading] = useState<boolean>(false);
  const finalize = useFinalizeWhileMounted();

  const { parseSchemaProperty } = useParsers({ collectionId });
  const parsedCollectionId = useMemo(() => {
    const parsed = parseSchemaProperty(collectionId, { fullItem: fullValue, path: path?.join?.('.'), definition: null, value: collectionId });
    return !!parsed && typeof parsed === 'string' ? parsed : undefined;
  }, [collectionId, fullValue, parseSchemaProperty, path]);

  const options = useMemo(() => {
    return getFlatSchemaProps(internalCollection?.schema)
      .filter(
        prop =>
          prop?.path &&
          !parseSchemaProperty(condition, {
            event: { ...prop.definition, item: prop.path, id: prop.definition.key },
            fullItem: fullValue,
            path: path?.join?.('.'),
            definition: null,
            value: collectionId
          })
      )
      .map(prop => ({
        value: prop.path,
        label: getLabel(prop.displayNamePath),
        color: prop.definition.metadata?.color,
        image: !!GeneralModel.iconConfigMap[prop.definition.metadata?.icon] && prop.definition.metadata?.icon,
        description: prop.definition.description
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [internalCollection?.schema, collectionId, condition, fullValue, parseSchemaProperty, path]) as ComponentProps<typeof SelectDropdown>['options'];

  useEffect(() => {
    if (!isValidCollectionId(parsedCollectionId)) return;
    setLoading(true);
    onCoreDescribe({ query: { cursor: { collectionId: parsedCollectionId } }, pointer: GeneralModel.IGNORED_POINTER_ID })
      .pipe(
        takeUntil(onDestroy$),
        ofTypeSetData(),
        tap(value => setInternalCollection(normalize.collection(value?.query))),
        swallowError(),
        finalize(() => setLoading(false))
      )
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedCollectionId]);

  useEffect(() => {
    /* istanbul ignore next line */
    if (!isDeepEqual(collection, internalCollection)) setInternalCollection(collection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  return (
    <div data-testid="collection-field">
      <SelectDropdown
        id={id}
        testid={testid}
        name={label}
        label={label}
        options={options}
        description={description}
        required={required}
        allowEmpty={!required}
        disabled={isLoading || disabled}
        value={value}
        optionList={optionList}
        onChange={onChange}
        errorMessage={errorMessage}
        info={info}
        disabledType={disabledType}
      />
    </div>
  );
};
