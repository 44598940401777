/* istanbul ignore file | @todo */
import { useCallback } from 'react';
import { Route, Routes } from 'react-router';

import { BUILDER_ICON, BUILDER_ROUTE, QUERY_PARAM, SECTION_NAME, TRANS } from '@constants';
import { EditorHome } from '../shared/EditorHome';
import { DocsContent } from '@components/elements/Docs';
import { useQueryParamState } from '@utils';

export const Guide = () => {
  return (
    <EditorHome
      title={TRANS.client.nav.builder.guide}
      icon={BUILDER_ICON.HELP}
      editorTitle={SECTION_NAME.GUIDE}
      /** header */
      routeList={[]}
      hideSubHeader={false}
      rootPath={BUILDER_ROUTE.GUIDE.ROOT}
      isCurrentDraft={false}
      hideDevView={true}
      onSave={null}
      onRemove={null}
      onSetOriginalValue={null}
    >
      <Routes>
        <Route path="*" element={<Content />} />
      </Routes>
    </EditorHome>
  );
};

const Content = () => {
  const [queryParamState, setQueryParamState] = useQueryParamState();

  const activeId = queryParamState?.[QUERY_PARAM.GUIDE_SECTION];

  const onActiveIdChange = useCallback((id: string) => setQueryParamState(prev => ({ ...prev, [QUERY_PARAM.GUIDE_SECTION]: id })), [setQueryParamState]);

  return <DocsContent activeId={activeId} onActiveIdChange={onActiveIdChange} />;
};
