import { ChartDataOutput, ViewModel, useTranslate } from '@cyferd/client-engine';

import { styles } from './styles';
import { Subscription } from 'rxjs';
import { PieChart as MUIPieChart } from '@mui/x-charts';
import { Spinner } from '../Spinner';
import { ChartLegend } from '../ChartLegend';
import { useCurrentTheme } from '@components/providers/UIprovider';
import { Header } from '../Header';
import { useCallback, useMemo } from 'react';
import { CyText } from '@components/smart/CyText';
import { TRANS } from '@constants';
import { useGetElementSize } from '@utils';

const SERIE_CONFIG = {
  type: 'pie',
  innerRadius: '45%',
  paddingAngle: 3,
  cornerRadius: 5
};

export type PieChartProps = {
  id?: string;
  value?: ChartDataOutput;
  isLoading?: boolean;
  testid?: string;
  onClickItem?: (event: any, meta: any) => Subscription;
} & Pick<ViewModel.CyChartProps, 'title' | 'subtitle' | 'config'>;

export const PieChart = ({ id, title, subtitle, value, isLoading, testid, onClickItem }: PieChartProps) => {
  const currentTheme = useCurrentTheme();
  const { ref, width } = useGetElementSize();
  const { translate } = useTranslate();
  const unlabeledItemLabel = useMemo(() => translate(TRANS.client.emptyStates.unlabeledPieItem), [translate]);
  const {
    data,
    metadata: { legendHidden }
  } = value;

  const series = useMemo(
    () => [
      {
        ...SERIE_CONFIG,
        data: data.map(({ calculatedValue, color, x, value }, i) => ({
          id: `pie-${id}-data-${i}`,
          value: calculatedValue,
          maskedValue: value,
          label: x || unlabeledItemLabel,
          color: currentTheme?.[color]?.value || 'transparent'
        })),
        valueFormatter: v => v.maskedValue || v.value
      }
    ],
    [id, data, currentTheme, unlabeledItemLabel]
  );

  const isNotEmpty = useMemo(() => !!series.length && !!series[0].data.length, [series]);
  const handleClickItem = useCallback((evt, d) => onClickItem(data[d?.dataIndex]?.fullItem, { metaKey: evt?.metaKey }), [data, onClickItem]);

  return (
    <div id={id} data-testid={testid || 'bar-chart'}>
      <Header title={title} subtitle={subtitle} />
      <>
        {isLoading ? (
          <div data-testid="loading" css={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : isNotEmpty ? (
          <div css={styles.chartWrapper}>
            <div ref={ref} css={styles.chartContainer(width)}>
              <MUIPieChart
                series={series as any}
                slotProps={{ legend: { hidden: true }, pieArc: { classes: { root: 'pieArcRoot' } } }}
                margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
                onItemClick={onClickItem ? handleClickItem : undefined}
              />
            </div>
            {!legendHidden && (
              <div css={styles.legendContainer}>
                <ChartLegend series={series[0].data.map(({ id, label, color }) => ({ dataKey: id, color, label }))} direction="column" />
              </div>
            )}
          </div>
        ) : (
          <div css={styles.empty}>
            <CyText content={translate(TRANS.client.emptyStates.cyList)} titleAlignment={ViewModel.Alignment.CENTER} />
          </div>
        )}
      </>
    </div>
  );
};
