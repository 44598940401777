import { useDataSource } from '../../../hooks';
import { useState } from 'react';

export const useSearchString = ({ updateCursor }: { updateCursor: ReturnType<typeof useDataSource>['updateCursor'] }) => {
  const [searchString, setSearchString] = useState('');

  const onSearchString = () => {
    updateCursor({ searchString });
  };

  return { searchString, setSearchString, onSearchString };
};
