import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { ErrorBoundary, Evaluator, GeneralModel, ViewModel } from '@cyferd/client-engine';
import { GAP, TRANS } from '@constants';

import { EvaluatorUI } from '../EvaluatorUI';
import { EVALUATOR_PORTAL_ID, FormulaInputRow } from '../resources';
import { styles } from './styles';
import { MenuOption, OptionMenu } from '../../OptionMenu';
import { useTestingHelper } from '@utils';
import { Modal } from '../../Modal';
import { CTA, CTAType } from '../../CTA';
import { FormulaPreview } from '../FormulaPreview';
import { EvaluatorInputContext } from './EvaluatorInputContext';

export interface EvaluatorInputProps {
  id?: string;
  value: GeneralModel.EvaluatorFormula;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  inputList?: FormulaInputRow[];
  placeholder?: string;
  openModalOnFocus?: boolean;
  open?: boolean;
  optionList: MenuOption[];
  description?: string;
  info?: string;
  format: GeneralModel.JSONSchemaFormat;
  color?: GeneralModel.Color.ThemeColor;
  disabledType?: GeneralModel.DisabledType;
  setOpen?: (val: boolean) => void;
  onChange?: (value: GeneralModel.EvaluatorFormula) => void;
}

export const EvaluatorInput = ({
  id = 'evaluator-input',
  value,
  disabled,
  required,
  label,
  inputList = [],
  openModalOnFocus,
  open,
  setOpen,
  placeholder,
  onChange,
  optionList,
  description,
  format,
  info,
  color,
  disabledType
}: EvaluatorInputProps) => {
  const { getTestIdProps } = useTestingHelper('evaluator-input');
  const [isEditing, setEditing] = useState<boolean>(!!open);
  const [internalValue, setInternalValue] = useState<GeneralModel.EvaluatorFormula>(value);
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

  const evaluatorInputContext = useContext(EvaluatorInputContext);

  const onToggleAdv = useCallback(() => setShowAdvanced(p => !p), []);

  const getStringFormula = (v: GeneralModel.EvaluatorFormula): string => {
    if (v === undefined) return '';
    try {
      const referenceLabel = typeof v === 'string' && inputList.find(i => i.template === v)?.label;
      if (referenceLabel) return `Reference: ${referenceLabel}`;
      return Evaluator.parse(v).toString().slice(0, 150).replace(/\s/g, '');
    } catch {
      /* istanbul ignore next | legacy but leaving catch just in case */
      return JSON.stringify(v).slice(0, 150).replace(/\s/g, '');
    }
  };

  const onToggleModal = useCallback(() => setEditing(prev => !prev), []);

  const onCancel = () => {
    setInternalValue(value);
    onToggleModal();
    setOpen?.(false);
  };

  const onApply = () => {
    onChange(internalValue);
    onToggleModal();
    setOpen?.(false);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value, isEditing]);

  useEffect(() => {
    setEditing(open);
  }, [open]);

  return (
    <>
      <div css={styles.readOnlyContainer} {...getTestIdProps('input-container')}>
        <FormulaPreview
          inputList={inputList}
          formula={value}
          testid={id}
          name={id}
          label={label}
          onChange={onChange}
          value={getStringFormula(value)}
          onFocus={openModalOnFocus ? onToggleModal : undefined}
          disabled={disabled}
          placeholder={placeholder}
          optionList={optionList}
          description={description}
          required={required}
          info={info}
          format={format}
          color={color}
          disabledType={disabledType}
        />
      </div>
      {!!isEditing &&
        createPortal(
          <ErrorBoundary>
            <Modal
              avoidLimitingHeight={true}
              open={isEditing}
              type={ViewModel.ModalType.FULL_SCREEN}
              title={[evaluatorInputContext?.titlePrefix, label].filter(Boolean).join(' : ')}
              footer={
                <div css={styles.footer}>
                  <CTA testid="advanced-switch" type={CTAType.LINK} label={showAdvanced ? 'Show formula' : 'Show as JSON'} onClick={onToggleAdv} />
                  <OptionMenu
                    optionList={[
                      {
                        testid: 'cancel',
                        type: CTAType.SECONDARY,
                        important: true,
                        label: TRANS.client.buttons.cancel,
                        onClick: onCancel
                      },
                      !disabled && {
                        testid: 'apply',
                        type: CTAType.PRIMARY,
                        important: true,
                        label: TRANS.client.buttons.apply,
                        onClick: onApply
                      }
                    ]}
                  />
                </div>
              }
            >
              <EvaluatorUI
                height={`calc(100vh - ${GAP.XL} - ${GAP.XL} - 150px)`}
                value={internalValue}
                showAdvanced={showAdvanced}
                disabled={disabled}
                inputList={inputList}
                onChange={setInternalValue}
              />
            </Modal>
          </ErrorBoundary>,
          document.getElementById(EVALUATOR_PORTAL_ID)
        )}
    </>
  );
};
