// istanbul ignore file
import { useEffect, useRef } from 'react';
import { DeepDiff, parseList } from '@cyferd/client-engine';
import { useNavigableGrid } from './useNavigableGrid';
import { TableHead } from '@components/elements/Table/TableHead';
import { styles } from './styles';
import { useTableHeader } from '../../hooks';
import { useEditTableRows } from './useEditTableRows';
import { EditTableCell } from './EditTableCell';
import { COLOR } from '@constants';
import { onPaste } from './onPaste';
import { isEditable } from './constants';
import { onCopy } from './onCopy';
type EditTableProps = {
  isLoading?: boolean;
  head?: ReturnType<typeof parseList>['head'];
  definitionMap?: ReturnType<typeof parseList>['definitionMap'];
  items?: ReturnType<typeof parseList>['items'];
  itemSchemas?: Record<string, any>;
  completeValues?: Record<string, any>;
  errors?: Record<string, any>;
  diffs?: DeepDiff[];
  handleEditChange?: (value: any, itemId: string, definitionId: string) => void;
  handleUpdateValue?: (value: any) => void;
};

export const EditTable = ({
  definitionMap,
  items,
  itemSchemas,
  isLoading,
  head,
  handleEditChange,
  handleUpdateValue,
  diffs,
  completeValues,
  errors
}: EditTableProps) => {
  const bodyRef = useRef(null);

  const { selected, isSelected, setCellSelected, isEditing, setCellEditing, isInSelection, setCellCursor } = useNavigableGrid({
    bounds: { rows: items.length, cols: items[0]?.list.length },
    containerRef: bodyRef
  });

  const tableHeaderItems = useTableHeader({ head, definitionMap, isLoading, hideSorting: true });
  const rows = useEditTableRows({ items, definitionMap });

  useEffect(() => {
    document.addEventListener('copy', onCopy);
    return () => document.removeEventListener('copy', onCopy);
  }, []);

  useEffect(() => {
    const handler = e => onPaste(e, { containerRef: bodyRef, handleUpdateValue, itemSchemas, selectedCol: selected?.col, selectedRow: selected?.row });
    document.addEventListener('paste', handler);
    return () => document.removeEventListener('paste', handler);
  }, [handleEditChange, selected?.col, selected?.row, handleUpdateValue, itemSchemas]);

  return (
    <table css={styles.table}>
      <TableHead id="@todo" testid="@todo" head={tableHeaderItems} lastColumnSticky={false} />
      <tbody ref={bodyRef}>
        {rows?.map((row, rowIndex) => {
          const mainColor = COLOR[row[0]?.rowColor];
          return (
            <tr data-testid="table-row" key={`tr-${rowIndex}`} aria-rowindex={rowIndex} css={styles.row}>
              <td css={styles.recordColor(mainColor)} />
              {row.map((col, colIndex) => {
                const itemId = col.fullItem?.id;
                const itemCompleted = completeValues?.[itemId];
                const definitionId = col.definitionId;
                // @todo: get colors, etc, from schema
                const schema = itemSchemas?.[itemId]?.[definitionId];
                const editable = !schema?.disabled && isEditable(schema);

                return (
                  <EditTableCell
                    // @todo: simplify props
                    key={`${rowIndex}-${colIndex}`}
                    selected={isSelected(rowIndex, colIndex)}
                    inSelection={isInSelection(rowIndex, colIndex)}
                    editable={editable}
                    editing={isEditing(rowIndex, colIndex)}
                    itemId={itemId}
                    definitionId={definitionId}
                    error={errors?.[itemId]?.[definitionId]}
                    edited={!!diffs.find(d => d.path[0] === itemId && d.path[1] === definitionId)}
                    value={itemCompleted ? itemCompleted?.[definitionId] : col.rawValue}
                    setSelected={setCellSelected}
                    setEditing={setCellEditing}
                    setCursor={setCellCursor}
                    col={col}
                    colIndex={colIndex}
                    rowIndex={rowIndex}
                    onChange={handleEditChange}
                  />
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
