import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP, RADIUS, SECONDARY_COLOR, TRANSITION_SPEED } from '@constants';
import { GeneralModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';

export const cellHeight = 119;
export const eventHeight = 25;
export const dayCellHeaderHeight = 30;

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  grid: css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  `,
  headerItem: css`
    background-color: ${COLOR.NEUTRAL_5};
    padding: ${GAP.S};
    display: flex;
    gap: ${GAP.S};
    align-items: center;
    justify-content: center;
    color: ${COLOR.NEUTRAL_1};
    font-size: ${FONT_SIZE.M};
    border-top: 5px solid ${COLOR.BASE_BACKGROUND_ALT_1};
    margin-top: 5px;
  `,
  headerTxt: css`
    font-size: ${FONT_SIZE.S};
    font-weight: ${FONT_WEIGHT.NORMAL};
  `,
  content: css`
    flex: 1;
    overflow: auto;
    border: 0.7px solid ${COLOR.NEUTRAL_4};
    border-top: 0;
  `,
  dayCell: css`
    cursor: pointer;
    height: ${cellHeight}px;
    border: 0.7px solid ${COLOR.NEUTRAL_4};
    border-top: 0;
    transition: all ${TRANSITION_SPEED};
    [data-selector='add-options'] {
      display: none;
    }
    :hover {
      [data-selector='add-options'] {
        display: block;
      }
      background-color: ${COLOR.NEUTRAL_5};
    }
  `,
  dayCellHeader: css`
    padding: 0 ${GAP.M};
    height: ${dayCellHeaderHeight}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 5px solid ${COLOR.TRANSPARENT};
    overflow: hidden;
  `,
  dayCellHeaderText: css`
    color: ${COLOR.NEUTRAL_3};
    font-size: ${FONT_SIZE.M};
    font-weight: ${FONT_WEIGHT.NORMAL};
    display: inline-block;
  `,
  isFromCurrentMonth: css`
    color: ${COLOR.NEUTRAL_2};
  `,
  activeDay: css`
    border-top-color: ${COLOR.BRAND_1};
  `,
  activeDayText: css`
    color: ${COLOR.BRAND_1};
    font-weight: ${FONT_WEIGHT.EXTRA_BOLD};
  `,
  referenceDayText: css`
    border-radius: 100%;
    background-color: ${SECONDARY_COLOR.BRAND_1};
    font-size: ${FONT_SIZE.XS};
    margin: -${GAP.XS};
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  dayCellBody: css`
    margin-top: ${GAP.XS};
    padding: 0 ${GAP.M};
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
  `,
  cellEventContainer: css`
    width: 100%;
    position: relative;
    height: ${eventHeight}px;
  `,
  cellEvent: ({
    color,
    withBorder,
    cellsCovered,
    isFirstOfManyDaysInRow,
    isLargeEndingCell,
    isMidSection
  }: {
    color: GeneralModel.Color.ThemeColor;
    withBorder: boolean;
    cellsCovered: number;
    isFirstOfManyDaysInRow: boolean;
    isLargeEndingCell: boolean;
    isMidSection: boolean;
  }) => css`
    ${isMidSection && 'visibility: hidden;'}
    position: absolute;
    height: ${eventHeight}px;
    z-index: ${isFirstOfManyDaysInRow ? 2 : 1};
    width: ${(() => {
      if (cellsCovered <= 1) return '100%';
      return `calc(calc(${cellsCovered * 100}% + calc(${GAP.S} * ${cellsCovered})) - ${GAP.XXL})`;
    })()};
    ${isLargeEndingCell &&
    `
      right: 0;
      width: calc(100% + ${GAP.XXL});
    `}
    cursor: pointer;
    background-color: ${SECONDARY_COLOR[color]};
    ${withBorder ? `border-left: 4px solid ${COLOR[color]}` : ''};
    border-radius: ${RADIUS.S};
    padding: ${GAP.XS};
  `,
  cellEventTitle: css`
    font-size: ${FONT_SIZE.XXS};
    color: ${COLOR.NEUTRAL_1};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  `,
  endOfManyTitle: css`
    text-align: right;
  `,
  eventMore: (color: GeneralModel.Color.ThemeColor) => css`
    cursor: pointer;
    background-color: ${COLOR[color]};
    color: ${FOREGROUND_COLOR[color]};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${GAP.XS} 0;
    border-radius: ${RADIUS.S};
    font-size: ${FONT_SIZE.XXS};
  `,
  invisible: css`
    visibility: hidden;
  `,
  addOptionsContainer: css`
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${cellHeight - dayCellHeaderHeight * 2}px;
  `
};
