import { useContext } from 'react';

import { createUUID, GeneralModel, ViewModel } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { dateSchema, defaultFormDetailGroupList, defaultFormDetailGroupMap, TRANS } from '@constants';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { SchemaForm } from '../../../shared/SchemaForm';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';

const schema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      label: TRANS.client.fields.titles.name,
      minLength: 1,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    title: {
      type: 'string',
      label: TRANS.client.fields.titles.publicName,
      info: 'A public name is the name visible to your users. If a public name is created, "Name" will only be visible to Admins in the builder.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    description: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      label: TRANS.client.fields.titles.description,
      info: 'This description will be how it appears to your users.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    tag: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.STRING_OPTION_LIST,
      label: TRANS.client.fields.titles.tag,
      metadata: {
        detailGroupId: defaultFormDetailGroupMap.basic.id,
        optionList: [
          { label: 'Detail', value: 'DETAIL' },
          { label: 'Table', value: 'TABLE' },
          { label: 'App home', value: 'APP_HOME' }
        ]
      }
    },
    recordColor: {
      type: 'string',
      label: TRANS.client.fields.titles.color,
      info: "This color will be displayed with the view when it's included in a List.",
      format: GeneralModel.JSONSchemaFormat.COLOR,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    recordImage: {
      type: 'string',
      label: TRANS.client.fields.titles.icon,
      info: "This icon will be displayed with the view when it's included in a List.",
      format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    notes: {
      type: 'string',
      label: TRANS.client.fields.titles.adminNotes,
      info: 'This description is only visible to admin users',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    globalSearchable: {
      type: 'boolean',
      label: TRANS.client.fields.titles.globalSearch,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      info: "If true, this view's name, or public name, will be searchable and visible to users in search results.",
      default: false,
      metadata: {
        detailGroupId: defaultFormDetailGroupMap.basic.id
      }
    },
    hideInApps: {
      type: 'boolean',
      default: false,
      label: TRANS.client.fields.titles.hideInApps,
      info: 'Hide this view from appearing in any linked app home pages',
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    model: {
      label: TRANS.client.fields.titles.input,
      type: 'object',
      format: GeneralModel.JSONSchemaFormat.COLLECTION_LITE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    }
  }
};

export const GeneralInfo = () => {
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<ViewModel.View>>(EditorContext);

  if (!item) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <Layout type={ViewModel.LayoutType.TWO_ALT_1}>
        <SchemaForm schema={schema} value={item} onChange={setItem} detailGroupList={defaultFormDetailGroupList} wrapDetailGroups={true} />
        <SchemaForm schema={dateSchema} value={item} onChange={setItem} detailGroupList={defaultFormDetailGroupList} wrapDetailGroups={true} />
      </Layout>
    </div>
  );
};
