import { applyMask, GeneralModel } from '@cyferd/client-engine';

import { Slider, SliderProps } from '../Slider';
import { styles } from './styles';
import { OptionMenuProps } from '../OptionMenu';
import { InputWrapper } from '../InputWrapper';

export interface SliderInputProps extends Omit<SliderProps, 'showSteps'> {
  id?: string;
  label: string;
  description?: string;
  subtype?: GeneralModel.JSONSchemaSubtype;
  required?: boolean;
  errorMessage?: string;
  mask?: GeneralModel.JSONSchemaMetadata['mask'];
  optionList?: OptionMenuProps['optionList'];
  info?: string;
  disabledType?: GeneralModel.DisabledType;
}

export const SliderInput = ({ id, testid = 'slider-input', mask, description, optionList, ...props }: SliderInputProps) => {
  return (
    <div data-testid={`${testid}-container`}>
      <InputWrapper
        description={description}
        disabled={!!props.disabled}
        disabledType={props.disabledType}
        errorMessage={props.errorMessage}
        id={id}
        label={props.label}
        onChange={props.onChange}
        optionList={optionList}
        required={props.required}
        testid={id}
        value={props.value}
        unlimitedHeight={true}
        info={props.info}
      >
        <div css={styles.inputContainer} data-testid={!!props.disabled ? `${testid}-disabled` : undefined}>
          <div css={styles.valueContainer}>
            {Array.isArray(props.value) ? (
              <span>{props.value.map(val => applyMask(val, mask)).join(' - ')}</span>
            ) : (
              <span>{applyMask(typeof props.value === 'number' ? props.value : props.minimum, mask)}</span>
            )}
          </div>
          <Slider
            value={props.value}
            minimum={props.minimum}
            maximum={props.maximum}
            onChange={props.onChange}
            multipleOf={props.multipleOf}
            uniqueItems={props.uniqueItems}
            color={props.color}
            range={props.range}
            disabled={props.disabled}
            disabledType={props.disabledType}
            showSteps={props.subtype === GeneralModel.JSONSchemaSubtype.STEPPED}
          />
          <div css={styles.rangeContainer}>
            <span css={styles.rangeLeft}>{applyMask(props.minimum, mask)}</span>
            <span css={styles.rangeRight}>{applyMask(props.maximum, mask)}</span>
          </div>
        </div>
      </InputWrapper>
    </div>
  );
};

SliderInput.displayName = 'SliderInput';
