import { Fragment, useCallback, useMemo } from 'react';
import { FormulaInputType, colorPerType } from '../getFormulaInputType';
import { FlatFormulaRow, UNIQUE_PATH_PREFIX, defaultInputList, getBelowPath, getCleanPath, getFormulaChipList, conditionalDotReplacement } from '../resources';
import { getStyles } from './styles';
import { CTA, CTAType } from '@components/elements/CTA';
import { COLOR, FONT_SIZE, TRANS, getDataTypeIcon } from '@constants';
import { isIcon } from '@utils';
import { Icon } from '@components/elements/Icon';
import { FormulaTooltip } from '../FormulaTooltip';
import { Chip } from '@components/elements/Chip';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { OptionMenu, OptionMenuProps } from '@components/elements/OptionMenu';
import { useCurrentTheme } from '@components/providers/UIprovider';
import { EvaluatorModel, GeneralModel, Translate, ViewModel, safeParse } from '@cyferd/client-engine';
import { TypeToCast } from '../cast';

export interface EvaluatorTreeRowProps {
  row: FlatFormulaRow;
  collapsed: boolean;
  isActive: boolean;
  isActiveContent: boolean;
  pathBeingDragged: string;
  quickAddOptions: OptionMenuProps['optionList'];
  readonly?: boolean;
  onToggleCollapsed: (event: string) => void;
  onChangeActive: (event: string) => void;
  onKeyChange: (event: string) => void;
  onWrap: (event: string) => void;
  onRemove: (event: string) => void;
  onUnwrap: (event: string) => void;
  onDuplicate: (event: string) => void;
  onDeleteContent: (event: string) => void;
  onPasteAndReplace: (event: string) => void;
  onPasteInside: (event: string) => void;
  onCopyToClipboard: (event: any) => void;
  onCast: (path: string, type: TypeToCast) => void;
}

export const EvaluatorTreeRow = ({
  row,
  collapsed,
  isActive,
  isActiveContent,
  pathBeingDragged,
  quickAddOptions,
  readonly,
  onToggleCollapsed,
  onChangeActive,
  onKeyChange,
  onWrap,
  onRemove,
  onUnwrap,
  onDuplicate,
  onDeleteContent,
  onPasteAndReplace,
  onPasteInside,
  onCopyToClipboard,
  onCast
}: EvaluatorTreeRowProps) => {
  const theme = useCurrentTheme();
  const isBeingDragged = row.path === pathBeingDragged;
  const isContentBeingDragged = !isBeingDragged && row.path.startsWith(pathBeingDragged);
  const isRawType = [FormulaInputType.REFERENCE, FormulaInputType.STRING, FormulaInputType.NUMBER, FormulaInputType.BOOLEAN, FormulaInputType.NULL].includes(
    row.type
  );
  const valueIsFormula = row.type === FormulaInputType.FORMULA;
  const valueIsArray = row.type === FormulaInputType.ARRAY;
  const valueIsObject = row.type === FormulaInputType.OBJECT;
  const allowsContent = [valueIsFormula, valueIsArray, valueIsObject].some(Boolean);
  const isCollapsible = useMemo(
    () =>
      [
        valueIsArray,
        valueIsObject,
        valueIsFormula && EvaluatorModel.formulaMap[row.formulaKey]?.input?.maxItems !== 0,
        valueIsFormula && !!Object.keys(row.value[row.formulaKey]).length
      ].some(Boolean),
    [row.formulaKey, row.value, valueIsArray, valueIsFormula, valueIsObject]
  );

  const contentLength = useMemo(() => allowsContent && Object.keys(row.value[row.formulaKey] || row.value).length, [allowsContent, row.formulaKey, row.value]);
  const hasLength = !!contentLength;
  const hasOneChild = contentLength === 1;

  const isParentRoot = !row.parentConfig;
  const isParentFormula = row.parentConfig?.type === FormulaInputType.FORMULA;
  const isParentArray = row.parentConfig?.type === FormulaInputType.ARRAY;
  const isParentObject = row.parentConfig?.type === FormulaInputType.OBJECT;
  const parentAllowsContent = [isParentFormula, isParentArray, isParentObject, isParentRoot && hasOneChild].some(Boolean);

  const isLiteralColor = row.type === FormulaInputType.STRING && !!COLOR[row.value];
  const valueIsIcon = row.type === FormulaInputType.STRING && isIcon(row.value);
  const formulaChips = useMemo(() => valueIsFormula && getFormulaChipList(row.value), [valueIsFormula, row.value]);

  const abovePath = allowsContent && row.path;
  const belowPath = allowsContent && !hasLength && [isParentFormula, isParentArray].some(Boolean) && getBelowPath(row.path);

  const canUnwrap = parentAllowsContent && allowsContent && hasLength;

  const cleanDD = getCleanPath(row.ddId);
  const safeClosingDropzones = row.closingDropzones.filter(p => p !== cleanDD);

  const styles = useMemo(() => getStyles(row, isActive, allowsContent, readonly, isActiveContent), [allowsContent, isActive, isActiveContent, readonly, row]);

  const onToggleCollapsedInternal = useCallback(() => onToggleCollapsed(row.path), [onToggleCollapsed, row.path]);
  const onChangeActiveInternal = useCallback(() => onChangeActive(row.path), [onChangeActive, row.path]);
  const onInternalKeyChange = useCallback(() => onKeyChange(row.path), [onKeyChange, row.path]);
  const onInternalRemove = useCallback(() => onRemove(row.path), [onRemove, row.path]);

  return (
    <div css={isContentBeingDragged && styles.invisible} id={row.path}>
      {/** dropzones above items that allow content. Dropzones for the items themselves point inside them */}
      {!!abovePath && (
        <>
          <Droppable droppableId={abovePath} isDropDisabled={readonly}>
            {(droppableProvided, snapshot) => (
              <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} css={styles.dropzone}>
                <div css={!!snapshot.isDraggingOver && /* istanbul ignore next */ styles.isDraggingAbove} />
                <div>{droppableProvided.placeholder}</div>
              </div>
            )}
          </Droppable>
          <div data-testid="above-dropzone-division" css={styles.dropzonesSeparator} />
        </>
      )}
      <div data-testid="above-dropzone-division" css={styles.dropzonesSeparator} />
      <Droppable droppableId={row.ddId} isDropDisabled={readonly || isBeingDragged}>
        {(droppableProvided, snapshot) => (
          <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
            <Draggable draggableId={row.path || UNIQUE_PATH_PREFIX} index={row.index} isDragDisabled={readonly || !row.path}>
              {draggableProvided => (
                <div {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps} ref={draggableProvided.innerRef}>
                  <div data-testid="evaluator-tree-row" data-path={row.path} css={[styles.container, styles.inline]} onClick={onChangeActiveInternal}>
                    <div>
                      {!isBeingDragged && (
                        <span css={styles.lineIndicator} style={{ color: isActiveContent ? COLOR.BASE_FOREGROUND : COLOR.NEUTRAL_3 }}>
                          {row.index + 1}
                        </span>
                      )}
                    </div>
                    <div css={[styles.inline, styles.chevronContainer]}>
                      {!isBeingDragged && isCollapsible && (
                        <PreventClickPropagation>
                          <CTA
                            testid="toggle-collapse"
                            type={CTAType.LINK}
                            icon={!collapsed ? 'keyboard_arrow_down' : 'chevron_right'}
                            color="NEUTRAL_2"
                            onClick={onToggleCollapsedInternal}
                          />
                        </PreventClickPropagation>
                      )}
                    </div>
                    <div css={[styles.indent, styles.inline]}>
                      <div css={styles.inline}>
                        <div css={styles.content}>
                          <FormulaTooltip item={row.formulaInputRow} avoidWrapping={readonly} avoidOpenOnClick={true}>
                            <div css={styles.inline} onClick={onChangeActiveInternal}>
                              {/** array indicator */}
                              {isParentArray && <div data-testid="array-item-indicator" css={styles.arrayDot} />}
                              {/** title with background color */}
                              <div css={[styles.inline, valueIsFormula && styles.formulaContainer]}>
                                {isParentObject && (
                                  <PreventClickPropagation>
                                    <p data-testid="key-indicator" css={styles.key} onClick={onInternalKeyChange}>
                                      <Translate>{conditionalDotReplacement(row.key, 'unescape')}</Translate> {!valueIsFormula && ':'}
                                    </p>
                                  </PreventClickPropagation>
                                )}
                                {valueIsFormula && (
                                  <div data-testid="formula-title" css={styles.inline}>
                                    <p css={styles.formulaLabel}>
                                      <Translate>{row.formulaInputRow.label}</Translate>
                                    </p>
                                    <Icon name="function" size={FONT_SIZE.S} fill={styles.formulaIconColor} />
                                  </div>
                                )}
                                {valueIsArray && (
                                  <p data-testid="array-indicator" css={styles.containerLabel}>
                                    <Translate>{row.formulaInputRow.label}</Translate>
                                  </p>
                                )}
                                {valueIsObject && (!isParentObject || !hasLength) && (
                                  <p data-testid="object-indicator" css={styles.containerLabel}>
                                    <Translate>{row.formulaInputRow.label}</Translate>
                                  </p>
                                )}
                              </div>
                              {formulaChips?.map?.(chipProps => (
                                <div key={chipProps.id} css={styles.inline}>
                                  <Chip {...chipProps} />
                                </div>
                              ))}
                              {/** value */}
                              {!!isLiteralColor && (
                                <>
                                  <div data-testid="literal-color" css={styles.literalColor} />
                                  <p data-testid="raw-type" css={styles.value}>
                                    {theme[row.value]?.label}
                                  </p>
                                </>
                              )}
                              {!!valueIsIcon && (
                                <>
                                  <div data-testid="literal-icon">
                                    <Icon name={row.value} size="18px" fill={COLOR[colorPerType[row.valueType]]} />
                                  </div>
                                  <p data-testid="raw-type" css={styles.value}>
                                    {safeParse(row.formulaInputRow.label)}
                                  </p>
                                </>
                              )}
                              {!!isRawType && !isLiteralColor && !valueIsIcon && (
                                <p data-testid="raw-type" css={styles.value}>
                                  {row.formulaInputRow.label}
                                </p>
                              )}
                            </div>
                          </FormulaTooltip>
                        </div>
                        {!readonly && (
                          <div data-selector="row-actions">
                            <PreventClickPropagation>
                              <div css={[styles.inline, styles.actions]}>
                                <div>
                                  <OptionMenu
                                    defaultBtnTestid="quick-row-actions"
                                    defaultTooltip="Menu"
                                    defaultBtnColor="NEUTRAL_1"
                                    defaultOutlined={true}
                                    defaultBtnSize={ViewModel.CTASize.SMALL}
                                    defaultBtnType={CTAType.LINK}
                                    optionList={
                                      [
                                        {
                                          type: CTAType.LINK,
                                          size: ViewModel.CTASize.SMALL,
                                          important: false,
                                          testid: 'wrap-row',
                                          image: 'wrap_text',
                                          outlined: true,
                                          label: 'Wrap in formula',
                                          onClick: () => onWrap(row.path)
                                        },
                                        !!row.path && {
                                          testid: 'duplicate-row',
                                          type: CTAType.LINK,
                                          size: ViewModel.CTASize.SMALL,
                                          image: 'add_to_photos',
                                          outlined: true,
                                          important: false,
                                          label: 'Duplicate',
                                          onClick: () => onDuplicate(row.path)
                                        },
                                        {
                                          testid: 'copy-row',
                                          label: TRANS.client.buttons.copy,
                                          image: 'content_copy',
                                          important: false,
                                          outlined: true,
                                          onClick: () => onCopyToClipboard(row.value)
                                        },
                                        !!allowsContent && {
                                          testid: 'paste-inside-row',
                                          label: 'Paste inside',
                                          image: 'content_paste_go',
                                          important: false,
                                          outlined: true,
                                          onClick: () => onPasteInside(row.path)
                                        },
                                        {
                                          testid: 'paste-row',
                                          label: 'Paste and replace',
                                          image: 'cycle',
                                          important: false,
                                          outlined: true,
                                          onClick: () => onPasteAndReplace(row.path)
                                        }
                                      ].filter(Boolean) as OptionMenuProps['optionList']
                                    }
                                  />
                                </div>
                                <div>
                                  <OptionMenu
                                    defaultBtnTestid="cast-row-actions"
                                    defaultTooltip="Change type"
                                    defaultBtnIcon="category"
                                    defaultBtnColor="NEUTRAL_1"
                                    defaultOutlined={true}
                                    defaultBtnSize={ViewModel.CTASize.SMALL}
                                    defaultBtnType={CTAType.LINK}
                                    optionList={[
                                      { label: 'formula', type: FormulaInputType.FORMULA, format: GeneralModel.JSONSchemaFormat.JSON },
                                      ...defaultInputList
                                    ].map(i => ({
                                      type: CTAType.LINK,
                                      size: ViewModel.CTASize.SMALL,
                                      important: false,
                                      testid: `cast-${i.type}`,
                                      color: colorPerType[i.type === FormulaInputType.FORMULA ? 'object' : i.type],
                                      image: i.type === FormulaInputType.FORMULA ? 'function' : getDataTypeIcon(i.format),
                                      outlined: true,
                                      label: `Change to ${i.label}`,
                                      onClick: () => onCast(row.path, i.type as any)
                                    }))}
                                  />
                                </div>
                                {allowsContent && (
                                  <div>
                                    <OptionMenu
                                      defaultBtnTestid="quick-add-actions"
                                      defaultTooltip="Add"
                                      defaultBtnIcon="add_circle"
                                      defaultBtnColor="NEUTRAL_1"
                                      defaultOutlined={true}
                                      defaultBtnSize={ViewModel.CTASize.SMALL}
                                      defaultBtnType={CTAType.LINK}
                                      optionList={quickAddOptions.map(a => ({ ...a, onClick: () => a.onClick(row.ddId) }))}
                                    />
                                  </div>
                                )}
                                <div>
                                  <OptionMenu
                                    defaultBtnTestid="delete-actions"
                                    defaultTooltip={TRANS.client.buttons.delete}
                                    defaultBtnIcon="delete"
                                    defaultBtnColor="RD_3"
                                    defaultBtnSize={ViewModel.CTASize.SMALL}
                                    defaultBtnType={CTAType.LINK}
                                    defaultOutlined={true}
                                    optionList={[
                                      canUnwrap && {
                                        testid: 'unwrap-row',
                                        type: CTAType.LINK,
                                        size: ViewModel.CTASize.SMALL,
                                        image: 'workspaces',
                                        outlined: true,
                                        color: 'RD_3',
                                        label: 'Unwrap content',
                                        onClick: () => onUnwrap(row.path)
                                      },
                                      allowsContent &&
                                        hasLength && {
                                          testid: 'delete-content-row',
                                          type: CTAType.LINK,
                                          size: ViewModel.CTASize.SMALL,
                                          image: 'radio_button_checked',
                                          outlined: true,
                                          color: 'RD_3',
                                          label: 'Clear content',
                                          onClick: () => onDeleteContent(row.path)
                                        },
                                      {
                                        testid: 'delete-row-option',
                                        type: CTAType.LINK,
                                        size: ViewModel.CTASize.SMALL,
                                        image: 'cancel',
                                        outlined: true,
                                        color: 'RD_3',
                                        label: TRANS.client.buttons.delete,
                                        onClick: onInternalRemove
                                      }
                                    ]}
                                  />
                                </div>
                              </div>
                            </PreventClickPropagation>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div css={[!!snapshot.isDraggingOver && /* istanbul ignore next */ styles.isDraggingOver]} />
                </div>
              )}
            </Draggable>
            <div>{droppableProvided.placeholder}</div>
          </div>
        )}
      </Droppable>
      {/** dropzones below items that allow content. Dropzones for the items themselves point inside them */}
      {!!belowPath && (
        <>
          <div data-testid="below-dropzone-division" css={styles.dropzonesSeparator} />
          <Droppable droppableId={belowPath} isDropDisabled={readonly}>
            {(droppableProvided, snapshot) => (
              <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} css={styles.dropzone}>
                <div css={!!snapshot.isDraggingOver && /* istanbul ignore next */ styles.isDraggingAbove} />
                <div>{droppableProvided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </>
      )}
      {safeClosingDropzones.map((closingPath, index) => (
        <Fragment key={closingPath}>
          <div data-testid="closing-dropzone-division" css={styles.dropzonesSeparator} />
          <Droppable droppableId={closingPath} isDropDisabled={readonly}>
            {(droppableProvided, snapshot) => (
              <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} css={[styles.dropzone, styles.closingDropzone(index)]}>
                <div css={!!snapshot.isDraggingOver && /* istanbul ignore next */ styles.isDraggingAbove} />
                <div>{droppableProvided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </Fragment>
      ))}
    </div>
  );
};

EvaluatorTreeRow.displayName = 'EvaluatorTreeRow';
