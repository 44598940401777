import React, { memo, useContext, useMemo } from 'react';

import { GeneralModel, getParsedActionChildren, Translate, ViewModel } from '@cyferd/client-engine';

import { useGetElementSize } from '@utils';
import { styles } from './styles';
import { COLOR, FOREGROUND_COLOR } from '@constants';
import { IconImage } from '@components/elements/Icon/renderIcon';
import { OptionMenu } from '@components/elements/OptionMenu';
import { UIContext } from '@components/providers/UIprovider';

export const ViewHeader = memo(({ id, icon, title, subtitle, actionListChildren, color }: ViewModel.ViewHeaderProps) => {
  const { size } = useContext(UIContext);
  const { ref, width } = useGetElementSize();
  const safeColor = COLOR[color] ? color : 'BRAND_1';

  const parsedActionChildren = useMemo(() => getParsedActionChildren(actionListChildren), [actionListChildren]);

  return (
    <>
      <header id={id} data-testid="viewHeader" css={styles.headerContainer} style={{ width, backgroundColor: COLOR[safeColor] }} data-no-print={true}>
        <div css={styles.innerContainer}>
          <div css={styles.header}>
            <div css={styles.rightContainer}>
              <IconImage
                icon={icon}
                title={title}
                iconProps={{ css: styles.iconContainer, size: '32px', fill: FOREGROUND_COLOR[safeColor] }}
                imageProps={{ css: styles.imageAsIcon }}
                testid={`viewHeader-icon`}
              />
              <div css={styles.titleContainer}>
                <h2 css={styles.title} style={{ color: FOREGROUND_COLOR[safeColor] }} data-testid={`viewHeader-title`}>
                  <Translate>{title}</Translate>
                </h2>
                {!!subtitle && (
                  <p css={styles.subtitle} style={{ color: FOREGROUND_COLOR[safeColor] }} data-testid={`viewHeader-subtitle`}>
                    <Translate>{subtitle}</Translate>
                  </p>
                )}
              </div>
            </div>
            {!!parsedActionChildren?.length && (
              <div data-testid={`viewHeader-actions-container`} css={styles.actionContainer}>
                <OptionMenu
                  defaultBtnType={ViewModel.CTAType.ACTION_TERTIARY}
                  optionList={parsedActionChildren.map(actionItem => ({
                    tooltip: actionItem.helperText,
                    image: actionItem.icon,
                    ...actionItem,
                    important: size === GeneralModel.AppSize.S ? false : /* istanbul ignore next */ actionItem.important,
                    disabled: !!actionItem.disabled,
                    type: actionItem.type || ViewModel.CTAType.ACTION_TERTIARY,
                    size: [ViewModel.CTAType.PRIMARY, ViewModel.CTAType.SECONDARY, ViewModel.CTAType.TERTIARY].includes(actionItem.type)
                      ? ViewModel.CTASize.SMALL
                      : ViewModel.CTASize.MEDIUM
                  }))}
                />
              </div>
            )}
          </div>
        </div>
      </header>
      <div ref={ref} css={styles.placeholder} data-no-print={true}></div>
    </>
  );
});

ViewHeader.displayName = ViewModel.DisplayName.VIEW_HEADER;
