import React, { useContext, useMemo } from 'react';

import { ApiModel, CollectionModel, ViewModel } from '@cyferd/client-engine';

import { ListItem } from './ListItem';
import { CyWrapperContext } from '../../smart/CyWrapper';

export interface ListProps {
  value: ApiModel.ApiValue;
  actionListChildren?: ViewModel.CyListProps['actionListChildren'];
  recordActions?: CollectionModel.Collection['recordActions'];
  onClickItem?: ViewModel.CyListProps['onClickItem'];
  testid?: string;
  showDescription?: boolean;
  density?: ViewModel.Density;
  disabled?: boolean;
}

export const List = ({ value, actionListChildren, showDescription, density, disabled, onClickItem, testid = 'list', recordActions }: ListProps) => {
  const { useParsers } = useContext(CyWrapperContext);
  const { parseList } = useParsers(value?.query);
  const { items } = useMemo(() => parseList({ entity: value?.query, list: value?.list }), [parseList, value?.list, value?.query]);
  const isClickable = typeof onClickItem === 'function';
  const cleanShowDescription = density === ViewModel.Density.L || showDescription;

  return (
    <ul data-testid={testid}>
      {items.map((item, index) => {
        const rowId = `${testid}-${item.fullItem.id}-${index}`;
        return (
          <ListItem
            key={rowId}
            item={item}
            isClickable={isClickable}
            id={rowId}
            disabled={disabled}
            index={index}
            actionListChildren={actionListChildren}
            recordActions={recordActions}
            onClickItem={onClickItem}
            showDescription={cleanShowDescription}
          />
        );
      })}
    </ul>
  );
};

List.displayName = 'List';
