import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationModel, useTimeout } from '@cyferd/client-engine';

import { ENV } from '@constants';
import { GeneralModel } from '@models';
import { actions as uiActions } from '../../state-mgmt/ui/actions';
import { styles } from './styles';
import { NotificationModal } from '@components/platform/NotificationModal';
import { NotificationCard } from '@components/elements/NotificationCard';

export const MessageList = memo(() => {
  const [active, setActive] = useState<NotificationModel.Notification>();
  const dispatch = useDispatch();
  const dismissList = useRef<string[]>([]);
  const timeoutRunner = useTimeout();

  const maxMessages = 3;
  const { messageList } = useSelector((state: GeneralModel.State) => state.ui);
  const visibleMessageList = useMemo(() => messageList.slice(0, maxMessages), [messageList]);

  const onClose = useCallback((id: string) => dispatch(uiActions.removeMessage(id)), [dispatch]);

  useEffect(() => {
    const unsetList = [messageList[0]]
      .filter(Boolean)
      .filter(({ id }) => !dismissList.current.includes(id))
      .map(({ id }) => id);
    dismissList.current = [...dismissList.current, ...unsetList];
    unsetList.forEach(id => timeoutRunner(() => onClose(id), ENV.TOAST_TIME));
  }, [messageList, onClose, timeoutRunner]);

  return (
    <div data-testid="message-list-container" className={styles.container}>
      {active && <NotificationModal notification={active as any} onClose={() => setActive(null)} />}
      {[...visibleMessageList].reverse().map(props => (
        <div key={props.id} className={styles.item}>
          <NotificationCard
            notification={props}
            onClick={() => {
              setActive(props as any);
              onClose(props.id);
            }}
          />
        </div>
      ))}
    </div>
  );
});
