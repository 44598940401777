import { SvgProps } from '@components/elements/Icon/SVG/types';

const OopsSvg = ({ ...props }: SvgProps) => (
  <svg width={447} height={256} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M313.36 236.635c68.095 0 131.691-48.162 131.691-79.526C445.051 125.746 365.2 87 191.903 87" stroke="var(--NEUTRAL_5)" strokeWidth={3} />
    <path d="M226.903 79.5h86.5l43 11-43 105.5 27.5 45-59.978 8.889-2.408-45.69L226.903 79.5Z" fill="var(--BASE_BACKGROUND)" />
    <path d="M129.403 88c-80.69 0-128 46.637-128 76s72.467 75.951 272.533 75.951" stroke="var(--NEUTRAL_4)" strokeWidth={2} strokeLinecap="round" />
    <circle cx={153.473} cy={212.386} r={3.07} stroke="var(--BRAND_1)" />
    <circle r={2.005} transform="scale(-1 1) rotate(15 -1016.554 -1229.453)" stroke="var(--BRAND_1)" />
    <circle opacity={0.5} r={0.866} transform="scale(-1 1) rotate(15 -984.377 -1284.98)" stroke="var(--BRAND_1)" />
    <path d="M211.568 204.987v6.088M214.612 208.031h-6.088" stroke="var(--BRAND_1)" strokeLinecap="round" />
    <circle cx={194.042} cy={219.109} r={3.07} stroke="var(--NEUTRAL_4)" />
    <circle cx={182.26} cy={206.614} r={1.999} stroke="var(--BRAND_1)" />
    <path
      d="m321.038 224.435-17.519-28.957c-4.188-1.616-16.689.365-16.689.365s16.342 26.331 23.53 32.849c4.634 1.255 10.678-4.257 10.678-4.257Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      opacity={0.3}
      d="m307.246 224.802 10.12-6.305m-14.439.943 11.44-6.226m-15.854.118 12.551-5.501m-16.861-.93 14.165-4.251m-18.275-1.757 15.182-4.272"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="m311.637 230.746 16.481-15.701 12.935 3.502-16.109 20.429c-1.082-.072-4.137-.591-7.699-2.084-3.563-1.494-5.223-4.72-5.608-6.146Z"
      fill="var(--NEUTRAL_3)"
    />
    <path
      d="M224.159 185.838c2.333-.493 11.354 1.353 15.573 2.338-1.797 2.666-2.746 17.576-2.996 24.697l-14.184-3.698c-.436-7.573-.725-22.843 1.607-23.337Z"
      fill="var(--NEUTRAL_4)"
    />
    <path d="m232.837 186.339 36.354-.446 8.748 11.42h-45.102v-10.974Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
    <path
      opacity={0.3}
      d="m238.414 186.487.437 10.442m6.636-10.687.72 10.777m6.291-10.778 1.064 10.868m5.6-10.958 1.756 11.047m6.601.154-1.756-11.047"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="m263.573 190.727 9.877-32.087c4.517.488 15.917 1.768 25.376 2.985 9.459 1.216 22.003 2.205 27.093 2.548-3.555 15.54-9.111 24.07-15.309 37.397-2.285.709-8.198 7.559-22.717 4.07-14.518-3.49-22.08-13.462-24.32-14.913Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_3)"
    />
    <path d="M306.985 162.749c.25-1.984.765-7.083.818-11.611l-18.865.642-.124 8.675 18.171 2.294Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
    <path d="m288.92 157.067 18.325 1.768m.189-4.073-18.461-.692" stroke="var(--NEUTRAL_3)" />
    <path
      d="m249.918 130.525-8.925 8.925c3.451 3.333 11.71 10.996 17.137 14.995 6.783 4.998 9.282 7.497 17.136 12.495 6.569.572 7.021-4.284 6.426-6.783-2.737-2.737-9.71-9.639-15.708-15.351-5.998-5.713-13.21-11.901-16.066-14.281Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      opacity={0.3}
      d="m246.705 144.449 8.925-8.926m-2.499 14.638 8.569-8.926m-1.785 13.924 8.211-8.211m-1.785 13.566 8.211-6.783m-1.785 11.067 7.14-6.069"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="m225.641 133.381 14.281 2.142 7.854-6.783 1.071-16.78-13.923-3.57-1.071 7.855 6.783 1.428-1.428 9.639h-8.211l-1.429-9.996-8.925 2.499 4.998 13.566Z"
      fill="var(--NEUTRAL_4)"
    />
    <path d="M238.851 129.811c-.714 1.071-2.499 4.284 2.142 7.854l7.14-7.14c-4.641-5.712-8.568-1.785-9.282-.714Z" fill="var(--NEUTRAL_4)" />
    <path d="m322.354 121.918-13.277 43.914 14.558 1.065 8.977-42.955-10.258-2.024Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
    <path
      opacity={0.3}
      d="m311.271 158.858 13.289 2.754m-10.633-10.845 12.162 2.683m-9.599-11.12 11.381 2.522m-9.162-10.867 10.69 2.705m-8.267-8.888 10.324 1.324"
      stroke="var(--NEUTRAL_3)"
    />
    <path
      d="m312.291 110.725 8.699 8.948 12.725-.21 6.104-5.3 6.103-5.299-6.957-11.638-7.029 4.372 3.057 7.059-7.176 5.777-5.897-5.029 6.123-8.275-5.843-2.756-9.909 12.351Z"
      fill="var(--NEUTRAL_4)"
      stroke="var(--NEUTRAL_4)"
    />
    <path d="m320.886 121.264 13.074-.03c-.018-2.164-1.314-6.5-6.36-6.531-5.045-.03-6.578 4.362-6.714 6.561Z" fill="var(--NEUTRAL_4)" />
    <path d="m269.197 159.443-12.496-35.701 56.408-19.636 12.496 42.842-56.408 12.495Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
    <path
      d="M267.054 131.953c-1.785-3.927 1.428-7.497 7.498-9.639 6.069-1.071 8.925 2.499 9.996 4.641 1.071 2.142.714 7.854-5.712 9.282s-9.997-.357-11.782-4.284Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
    />
    <path d="m267.769 125.984 18.564 6.042m-5.925-10.069-9.085 15.708" stroke="var(--BRAND_1)" strokeWidth={2} />
    <path
      d="M310.61 126.598c-3.213.714-7.854 1.071-10.353-4.641-1.428-6.069 3.213-8.568 6.783-9.997 3.57-1.428 7.854 0 9.997 4.999 2.142 4.998-3.214 8.925-6.427 9.639Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m307.212 119.64-4.273 6.667 1.683 1.079 4.578-7.141 8.259 2.513.583-1.913-7.721-2.35 4.003-6.243-1.684-1.08-4.306 6.718-8.143-2.478-.582 1.913 7.603 2.315Z"
      fill="var(--BRAND_1)"
    />
    <path
      d="M281.692 115.174c-2.618-3.333-8.854-9.997-12.852-9.997-3.999 0-3.332 3.094-2.499 4.641l-22.135-17.136M240.279 89.469c0 1.428 1.785 2.856 2.499 2.142.714-.714-1.785-3.927-2.499-2.142Z"
      stroke="var(--BRAND_1)"
    />
    <path
      d="m276.337 153.731-2.142-8.925c3.332-1.429 12.638-5.213 23.206-8.926 10.567-3.713 18.207-4.165 20.706-3.927l2.499 8.568c-3.451 1.904-12.352 6.284-20.349 8.569-7.997 2.285-19.279 4.046-23.92 4.641Z"
      fill="var(--BASE_BACKGROUND)"
      stroke="var(--BRAND_1)"
    />
    <path d="m283.12 141.592 2.499 10.354M292.403 138.022l3.213 12.139M301.685 134.809l4.284 12.139M309.896 133.024l4.284 10.711" stroke="var(--BRAND_1)" />
    <path
      d="M289.546 100.536c-6.783-9.64-4.284-12.138-1.785-12.138 1.999 0 2.023 8.092 1.785 12.138ZM297.4 100.536c-1.071-8.211-.714-9.64 1.428-9.64 1.714 0-.238 6.427-1.428 9.64ZM283.834 104.463c-4.998-1.428-6.783-3.213-6.069-4.641.714-1.428 4.998.714 6.069 4.641Z"
      fill="var(--BRAND_1)"
      fillOpacity={0.05}
    />
    <path
      d="M289.546 100.536c-6.783-9.64-4.284-12.138-1.785-12.138 1.999 0 2.023 8.092 1.785 12.138ZM297.4 100.536c-1.071-8.211-.714-9.64 1.428-9.64 1.714 0-.238 6.427-1.428 9.64ZM283.834 104.463c-4.998-1.428-6.783-3.213-6.069-4.641.714-1.428 4.998.714 6.069 4.641Z"
      stroke="var(--BRAND_1)"
      strokeOpacity={0.2}
    />
    <path
      d="M288.817 53.412c0-7.032 4.688-10.94 10.939-10.94s10.157 5.47 10.157 10.94-4.976 7.674-7.813 9.376c-2.837 1.702-3.126 4.688-3.126 6.25"
      stroke="var(--BRAND_1)"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <circle cx={298.975} cy={77.634} r={2.344} fill="var(--BRAND_1)" />
    <g opacity={0.2}>
      <path
        d="M210.909 54.902c-1.632-2.827-.654-5.486 1.859-6.937 2.513-1.451 5.354-.16 6.624 2.04 1.269 2.2-.22 4.241-.965 5.584-.746 1.343-.169 2.61.194 3.239"
        stroke="var(--BRAND_1)"
        strokeLinecap="round"
      />
      <circle cx={220.616} cy={62.284} r={1.088} transform="rotate(-30 220.616 62.284)" fill="var(--BRAND_1)" />
    </g>
    <g opacity={0.5} stroke="var(--BRAND_1)">
      <path
        d="M242.992 48.387c-1.407-5.891 1.738-10.102 6.974-11.354 5.237-1.25 9.604 2.549 10.699 7.13 1.095 4.583-2.632 7.426-4.668 9.42-2.036 1.993-1.68 4.553-1.367 5.862"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle cx={256.351} cy={66.645} r={1.519} transform="rotate(-13.44 256.351 66.645)" fill="var(--BRAND_1)" />
    </g>
    <g opacity={0.5}>
      <circle r={0.73} transform="matrix(-1 0 0 1 155.073 35.903)" stroke="var(--BRAND_1)" />
      <path d="M123.826 45.545v1.48M124.566 46.285h-1.48" stroke="var(--BRAND_1)" strokeWidth={0.5} strokeLinecap="round" />
      <circle cx={126.703} cy={51.269} r={0.5} transform="rotate(15 126.703 51.269)" stroke="var(--BRAND_1)" strokeWidth={0.726} />
      <path d="M173.146 77.048v2.098M172.097 78.097h2.098" stroke="var(--BRAND_1)" strokeLinecap="round" />
      <circle r={0.73} transform="matrix(-1 0 0 1 179.185 81.915)" stroke="var(--NEUTRAL_4)" />
      <circle r={0.5} transform="matrix(-1 0 0 1 183.245 77.609)" stroke="var(--BRAND_1)" strokeWidth={0.722} />
      <path
        d="m135.423 83.75 6.037-9.978c1.443-.557 5.751.125 5.751.125s-5.632 9.074-8.109 11.32c-1.596.432-3.679-1.467-3.679-1.467Z"
        fill="var(--NEUTRAL_4)"
        stroke="var(--NEUTRAL_3)"
      />
      <path
        opacity={0.3}
        d="m140.175 83.876-3.487-2.172m4.976.325-3.942-2.145m5.463.04-4.325-1.896m5.81-.32-4.881-1.465m6.297-.605-5.231-1.473"
        stroke="var(--NEUTRAL_3)"
      />
      <path d="m138.662 85.925-5.679-5.41-4.457 1.206 5.551 7.04c.373-.025 1.425-.204 2.653-.718 1.227-.515 1.8-1.627 1.932-2.118Z" fill="var(--NEUTRAL_3)" />
      <path d="M168.807 70.45c-.804-.17-3.912.466-5.366.805.619.92.946 6.057 1.032 8.51l4.888-1.273c.15-2.61.25-7.872-.554-8.042Z" fill="var(--NEUTRAL_4)" />
      <path d="m165.817 70.622-12.528-.153-3.014 3.935h15.542v-3.782Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
      <path
        opacity={0.3}
        d="m163.895 70.673-.151 3.599m-2.287-3.683-.247 3.714m-2.168-3.714-.367 3.745m-1.93-3.777-.605 3.807m-2.274.053.605-3.806"
        stroke="var(--NEUTRAL_3)"
      />
      <path
        d="m155.225 72.134-3.403-11.057c-1.557.168-5.486.61-8.745 1.029-3.259.419-7.582.76-9.336.878 1.225 5.355 3.139 8.294 5.275 12.887.788.244 2.825 2.605 7.828 1.402 5.004-1.202 7.609-4.639 8.381-5.139Z"
        fill="var(--NEUTRAL_4)"
        stroke="var(--NEUTRAL_3)"
      />
      <path d="M140.266 62.493a40.038 40.038 0 0 1-.282-4l6.5.22.043 2.99-6.261.79Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
      <path d="m146.491 60.535-6.315.61m-.065-1.404 6.362-.239" stroke="var(--NEUTRAL_3)" />
      <path
        d="m159.931 51.389 3.075 3.076c-1.189 1.148-4.035 3.789-5.905 5.167-2.337 1.722-3.199 2.583-5.905 4.306-2.264.196-2.42-1.477-2.215-2.338.944-.943 3.347-3.322 5.413-5.29 2.067-1.968 4.552-4.1 5.537-4.921Z"
        fill="var(--NEUTRAL_4)"
        stroke="var(--NEUTRAL_3)"
      />
      <path
        opacity={0.3}
        d="m161.038 56.187-3.076-3.076m.861 5.044-2.952-3.075m.615 4.798-2.83-2.83m.615 4.675-2.829-2.337m.615 3.813-2.461-2.091"
        stroke="var(--NEUTRAL_3)"
      />
      <path
        d="m168.296 52.373-4.921.738-2.706-2.337-.369-5.782 4.798-1.23.369 2.706-2.338.492.492 3.322h2.83l.492-3.445 3.076.861-1.723 4.675Z"
        fill="var(--NEUTRAL_4)"
      />
      <path d="M163.744 51.143c.246.369.862 1.476-.738 2.706l-2.46-2.46c1.599-1.968 2.952-.615 3.198-.246Z" fill="var(--NEUTRAL_4)" />
      <path d="m134.969 48.423 4.576 15.133-5.017.367-3.094-14.803 3.535-.697Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
      <path
        opacity={0.3}
        d="m138.788 61.152-4.579.95m3.664-3.738-4.191.925m3.308-3.832-3.922.869m3.157-3.745-3.683.932m2.848-3.063-3.557.457"
        stroke="var(--NEUTRAL_3)"
      />
      <path
        d="m138.437 44.566-2.998 3.084-4.385-.073-2.103-1.826-2.103-1.826 2.397-4.01 2.422 1.506-1.053 2.432 2.473 1.991 2.032-1.733-2.11-2.851 2.013-.95 3.415 4.256Z"
        fill="var(--NEUTRAL_4)"
        stroke="var(--NEUTRAL_4)"
      />
      <path d="m135.475 48.198-4.505-.01c.006-.747.453-2.24 2.191-2.251 1.739-.01 2.267 1.503 2.314 2.26Z" fill="var(--NEUTRAL_4)" />
      <path d="m153.287 61.354 4.306-12.302-19.438-6.767-4.306 14.763 19.438 4.306Z" fill="var(--NEUTRAL_4)" stroke="var(--NEUTRAL_3)" />
      <path
        d="M154.025 51.881c.615-1.353-.492-2.583-2.583-3.322-2.092-.368-3.076.862-3.445 1.6-.369.738-.246 2.706 1.968 3.199 2.215.492 3.445-.123 4.06-1.477Z"
        fill="var(--BASE_BACKGROUND)"
        stroke="var(--BRAND_1)"
        strokeWidth={0.5}
      />
      <path d="m153.779 49.824-6.397 2.082m2.042-3.47 3.13 5.414" stroke="var(--BRAND_1)" />
      <path
        d="M139.016 50.036c1.107.246 2.707.369 3.568-1.6.492-2.091-1.108-2.952-2.338-3.444s-2.706 0-3.444 1.722c-.739 1.722 1.107 3.076 2.214 3.322Z"
        fill="var(--BASE_BACKGROUND)"
        stroke="var(--BRAND_1)"
        strokeWidth={0.5}
      />
      <path d="m142.707 46.51-6.151 1.873M138.027 44.906l3.343 5.215" stroke="var(--BRAND_1)" />
      <path
        d="M148.981 46.099c.902-1.148 3.051-3.445 4.429-3.445 1.378 0 1.148 1.066.861 1.6l7.628-5.906M163.252 37.241c0 .492-.615.984-.861.738s.615-1.353.861-.738Z"
        stroke="var(--BRAND_1)"
        strokeWidth={0.5}
      />
      <path
        d="m150.827 59.386.738-3.076c-1.148-.492-4.355-1.796-7.997-3.076-3.641-1.279-6.274-1.435-7.135-1.353l-.861 2.953c1.189.656 4.256 2.165 7.012 2.952 2.756.788 6.643 1.395 8.243 1.6Z"
        fill="var(--BASE_BACKGROUND)"
        stroke="var(--BRAND_1)"
        strokeWidth={0.5}
      />
      <path
        d="m148.489 55.203-.861 3.568M145.291 53.973l-1.108 4.183M142.092 52.865l-1.477 4.183M139.262 52.25l-1.476 3.691"
        stroke="var(--BRAND_1)"
        strokeWidth={0.5}
      />
      <path
        d="M146.275 41.055c2.337-3.322 1.476-4.183.615-4.183-.689 0-.697 2.789-.615 4.183ZM143.568 41.055c.369-2.83.246-3.322-.492-3.322-.59 0 .082 2.215.492 3.322ZM148.243 42.408c1.723-.492 2.338-1.107 2.092-1.6-.246-.491-1.723.247-2.092 1.6Z"
        fill="var(--BRAND_1)"
        fillOpacity={0.05}
      />
      <path
        d="M146.275 41.055c2.337-3.322 1.476-4.183.615-4.183-.689 0-.697 2.789-.615 4.183ZM143.568 41.055c.369-2.83.246-3.322-.492-3.322-.59 0 .082 2.215.492 3.322ZM148.243 42.408c1.723-.492 2.338-1.107 2.092-1.6-.246-.491-1.723.247-2.092 1.6Z"
        stroke="var(--BRAND_1)"
        strokeOpacity={0.2}
      />
    </g>
    <path
      d="M152.143 12.55c1.942-3.556 5.391-4.237 8.551-2.511 3.161 1.726 3.626 5.57 2.116 8.335-1.51 2.766-4.635 2.506-6.54 2.584-1.904.077-2.874 1.507-3.306 2.297"
      stroke="var(--BRAND_1)"
      strokeWidth={2}
      strokeLinecap="round"
      opacity={0.1}
    />
    <g opacity={0.07}>
      <path
        d="M181.326 4.178c1.011-2.686 3.363-3.504 5.751-2.605 2.387.899 3.092 3.55 2.306 5.638-.786 2.089-3.004 2.215-4.332 2.457-1.328.242-1.868 1.341-2.093 1.938"
        stroke="var(--BRAND_1)"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle cx={181.722} cy={14.889} r={0.957} transform="rotate(20.631 181.722 14.889)" fill="var(--BRAND_1)" />
    </g>
    <g opacity={0.05}>
      <path
        d="M205.917 5.676c1.498-1.429 3.283-1.224 4.554.108 1.27 1.332.899 3.275-.266 4.387-1.166 1.111-2.647.5-3.586.24-.939-.258-1.634.288-1.967.605"
        stroke="var(--BRAND_1)"
        strokeLinecap="round"
      />
      <circle cx={202.821} cy={12.763} r={0.69} transform="rotate(46.35 202.821 12.763)" fill="var(--BRAND_1)" />
    </g>
  </svg>
);

export default OopsSvg;
