import { PropsWithChildren, createContext, memo, useCallback, useMemo, useRef, useState } from 'react';
import { Subject, takeUntil, timer } from 'rxjs';

import { ClientEngineContextValue, useFinalizeWhileMounted } from '@cyferd/client-engine';
import { ConfirmationModal, ConfirmationModalProps } from '@components/elements/ConfirmationModal';

export interface ModalContextValue {
  onConfirm: ClientEngineContextValue['modalInteraction']['onConfirm'];
  onAlert: ClientEngineContextValue['modalInteraction']['onAlert'];
}

export const ModalContext = createContext<ModalContextValue>({
  onConfirm: null,
  onAlert: null
});

export const ModalProvider = memo(({ children }: PropsWithChildren) => {
  const [confirmProps, setConfirmProps] = useState<Parameters<ClientEngineContextValue['modalInteraction']['onConfirm']>[0]>(null);
  const confirm$ = useRef<Subject<boolean>>();
  const [alertProps, setAlertProps] = useState<Parameters<ClientEngineContextValue['modalInteraction']['onAlert']>[0]>(null);

  const onConfirm: ModalContextValue['onConfirm'] = useCallback(props => {
    setConfirmProps(props);
    confirm$.current = new Subject();
    return confirm$.current.asObservable();
  }, []);

  const onConfirmChange = useCallback((answer: boolean) => {
    setConfirmProps(null);
    confirm$.current?.next(answer);
    confirm$.current?.complete();
  }, []);

  const finalize = useFinalizeWhileMounted();
  const unsubscribe$ = useRef(new Subject());

  const onAlert = useCallback(
    props => {
      unsubscribe$.current.next(null);
      setAlertProps(props);
      const delay = Number(props?.closeDelay);
      if (typeof delay === 'number' && delay > 0) {
        const timer$ = timer(delay);
        timer$
          .pipe(
            takeUntil(timer$),
            takeUntil(unsubscribe$.current),
            finalize(() => setAlertProps(null))
          )
          .subscribe();
      }
    },
    [finalize]
  );

  const onAlertChange = useCallback(() => {
    unsubscribe$.current.next(null);
    setAlertProps(null);
  }, [unsubscribe$]);

  const value = useMemo(() => ({ onConfirm, onAlert }), [onAlert, onConfirm]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      <ConfirmationModal
        open={!!confirmProps}
        title={confirmProps?.title}
        description={confirmProps?.description}
        icon={confirmProps?.icon as ConfirmationModalProps['icon']}
        confirmLabel={confirmProps?.confirmLabel}
        cancelLabel={confirmProps?.cancelLabel}
        status={confirmProps?.status}
        onChange={onConfirmChange}
      />

      <ConfirmationModal
        open={!!alertProps}
        title={alertProps?.title}
        description={alertProps?.description}
        icon={alertProps?.icon || alertProps?.image}
        color={alertProps?.color}
        size={alertProps?.size}
        confirmLabel={alertProps?.confirmLabel}
        singleAction={true}
        onChange={onAlertChange}
      />
    </ModalContext.Provider>
  );
});
