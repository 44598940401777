import { ComponentProps, createContext } from 'react';

import { actionHooks, noop, useFetchCollectionModel, useOnRefresh, useParsers } from '@cyferd/client-engine';
import { geocode } from 'opencage-api-client';

import { FilterRow } from '@components/elements/AdvancedFilter/components/FilterRow';

export interface CyWrapperContextValue {
  useFetchCollectionModel: typeof useFetchCollectionModel;
  useOnRefresh: typeof useOnRefresh;
  useParsers: typeof useParsers;
  useOnOpenEmailClient: typeof actionHooks.useOnOpenEmailClient;
  useOnOpenExternalUrl: typeof actionHooks.useOnOpenExternalUrl;
  useListAssociation: typeof actionHooks.useListAssociation;
  useOnFetchEntity: typeof actionHooks.useOnFetchEntity;
  useOnGetEntity: typeof actionHooks.useOnGetEntity;
  useOnCreateAssociatedRecord: typeof actionHooks.useOnCreateAssociatedRecord;
  useOnDownloadFile: typeof actionHooks.useOnDownloadFile;
  useAction: typeof actionHooks.useAction;
  useNavigateToRecord: () => (collectionId: string, recordId: string) => void;
  renderSchemaForm: ComponentProps<typeof FilterRow>['renderSchemaForm'];
  geocode: typeof geocode;
}

export const CyWrapperContext = createContext<CyWrapperContextValue>({
  useFetchCollectionModel: noop,
  useOnRefresh: noop,
  useParsers: noop,
  useOnOpenEmailClient: noop,
  useOnOpenExternalUrl: noop,
  useListAssociation: noop,
  useOnFetchEntity: noop,
  useOnGetEntity: noop,
  useOnCreateAssociatedRecord: noop,
  useOnDownloadFile: noop,
  useAction: noop,
  useNavigateToRecord: noop,
  renderSchemaForm: noop,
  geocode: noop
});
