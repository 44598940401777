import { ComponentProps, memo, ReactNode, useCallback } from 'react';

import { FOREGROUND_COLOR } from '@constants';
import { Icon } from '../Icon';
import { getStyles } from './styles';

export enum ToastStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warning',
  INFO = 'information'
}

export interface ToastProps {
  id: string;
  title?: ReactNode;
  message?: ReactNode;
  status: ToastStatus;
  onClose: (id: string) => void;
  testid?: string;
}

export const toastWidth = '350px';

export const Toast = memo(({ id, title, message, onClose, status, testid = 'toast' }: ToastProps) => {
  const { styles, iconName, foreground } = (() => {
    switch (status) {
      case ToastStatus.ERROR:
        return { styles: getStyles('RD_2', toastWidth), iconName: 'cancel', foreground: FOREGROUND_COLOR.RD_2 };
      case ToastStatus.SUCCESS:
        return { styles: getStyles('SUCCESS_3', toastWidth), iconName: 'check_circle', foreground: FOREGROUND_COLOR.SUCCESS_3 };
      case ToastStatus.WARN:
        return { styles: getStyles('OE_6', toastWidth), iconName: 'warning', foreground: FOREGROUND_COLOR.OE_6 };
      case ToastStatus.INFO:
      default:
        return { styles: getStyles('NEUTRAL_4', toastWidth), iconName: 'help', foreground: FOREGROUND_COLOR.NEUTRAL_4 };
    }
  })();
  const onInternalClose = useCallback(() => onClose(id), [onClose, id]);
  return (
    <div data-testid={`${testid}-container`} role="alert" css={styles.container} onClick={onInternalClose}>
      <div data-testid={`${testid}-status-${status}`} css={styles.content}>
        {!!title && (
          <header css={styles.header}>
            <p css={styles.title} data-testid={`${testid}-title`}>
              <span css={styles.iconTitle}>
                <Icon size="25px" name={iconName as ComponentProps<typeof Icon>['name']} fill={foreground} title="toast header close" />
              </span>
              {title}
            </p>
          </header>
        )}

        {!!message && (
          <div>
            <p data-testid={`${testid}-message`} css={styles.message}>
              {message}
            </p>
          </div>
        )}
      </div>
    </div>
  );
});

Toast.displayName = 'Toast';
