import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${GAP.XS};
    max-height: 380px;
    overflow-y: auto;
  `,
  content: css`
    display: flex;
    gap: ${GAP.XS};
    width: 100%;
    align-items: flex-start;
  `,
  optionList: css`
    flex: 1;
    display: grid;
    grid-gap: ${GAP.XS};
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  `
};
