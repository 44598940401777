import { PropsWithChildren, memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ClientEngineContextValue, ClientEngineProvider, GeneralModel, GlobalBehavior, noop, useTranslate } from '@cyferd/client-engine';

import { componentRecord } from '../components/componentRecord';
import { ModalContext } from '../components/ModalProvider';
import { ENV } from '@constants';
import { GeneralModel as LocalGeneralModel } from '@models';
import { logger } from '@utils';
import { useOnIncomingNotification } from '@utils/useOnIncomingNotification';
import { useUrl } from '@utils/useUrl';
import { useNotification } from './notification';
import { useUser } from './user';
import { useView } from './view';
import { useWS } from './WSProvider';
import { UIContext } from '@components/providers/UIprovider';

const useDataSelector = () => useSelector((state: LocalGeneralModel.State) => state.global);
const useActiveModalSelector = () => useSelector((state: LocalGeneralModel.State) => state.ui.activeModalList);

const config: GeneralModel.EngineConfig = { validateComponentProps: false };

export const EngineProviders = memo(({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { onConfirm, onAlert } = useContext(ModalContext);
  const { runtimeTheme, size } = useContext(UIContext);
  const { resolvedLanguage } = useTranslate();

  const platformConfig: GeneralModel.PlatformConfig = useMemo(
    () => ({
      app: GeneralModel.CyferdApp.WEB,
      env: ENV.ENVIRONMENT,
      fileUploadUrl: ENV.FILES_URL,
      theme: runtimeTheme,
      builderUrl: `${ENV.ADMIN_URL}/`,
      clientAppUrl: `${document.location.origin}/`,
      size,
      lang: resolvedLanguage
    }),
    [resolvedLanguage, runtimeTheme, size]
  );

  const getAbsolutePath = (str: string) => `/${str.replace(/^\//, '')}`;

  const navigation = useMemo(
    () =>
      ({
        navigateTo: ({ path, external, openInNewTab }) => {
          if (openInNewTab || external) return window.open(external ? path : getAbsolutePath(path), '_blank').focus();
          navigate(getAbsolutePath(path));
        },
        navigateBack: () => navigate(-1),
        navigateForward: () => navigate(1),
        navigateReplace: ({ path }) => navigate(getAbsolutePath(path), { replace: true })
      }) as ClientEngineContextValue['navigation'],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const modalInteraction = useMemo(() => ({ onConfirm, onAlert }) as ClientEngineContextValue['modalInteraction'], [onAlert, onConfirm]);

  const platformInteraction = useMemo(
    () =>
      ({
        onDownloadFile: payload => {
          const download = document.createElement('a');
          download.setAttribute('download', payload?.filename || 'file');
          download.setAttribute('href', payload?.data);
          download.click();
        }
      }) as ClientEngineContextValue['platformInteraction'],
    []
  );

  const notificationInteraction = useMemo(
    () =>
      ({
        onIncoming: useOnIncomingNotification,
        onRead: () => noop
      }) as ClientEngineContextValue['notificationInteraction'],
    []
  );

  return (
    <ClientEngineProvider
      config={config}
      useUrl={useUrl}
      useViewSelector={useView}
      useNotificationSelector={useNotification}
      useDataSelector={useDataSelector}
      useUserSelector={useUser}
      useActiveModalSelector={useActiveModalSelector}
      useWSContext={useWS}
      logger={logger}
      platformConfig={platformConfig}
      navigation={navigation}
      modalInteraction={modalInteraction}
      platformInteraction={platformInteraction}
      notificationInteraction={notificationInteraction}
      componentRecord={componentRecord}
    >
      <GlobalBehavior>{children}</GlobalBehavior>
    </ClientEngineProvider>
  );
});
