import { Children, useContext, useMemo } from 'react';
import { ApiModel, DeepDiff, useHasFiltersApplied, useTranslate, ViewModel } from '@cyferd/client-engine';
import { ProgressBar } from '@components/elements/ProgressBar';
import { Spinner } from '@components/elements/Spinner';
import { CyText } from '@components/smart/CyText';
import { TRANS } from '@constants';
import { CyWrapperContext } from '../../../CyWrapper';
import { styles } from './styles';
import { ReadOnlyTable } from './components/ReadOnlyTable';
import { EditTable } from './components/EditTable';
import { ITableEditModeProps } from '../../types';
import { useDataSource } from '../../hooks';

const testId = 'CyTableView';
const getTestId = (thisTestId: string) => `${testId}-${thisTestId}`;

export type CyTableViewProps = {
  refreshId: string;
  children: React.ReactNode;
  value: ApiModel.ApiValue;
  isLoading: boolean;
  isEditing: boolean;
  isFirstLoad: boolean;
  diffs: DeepDiff[];
  itemSchemas: Record<string, any>;
  rowActions: ViewModel.CyTableProps['rowActions'];
  appearance: ViewModel.CyTableProps['appearance'];
  options: ViewModel.CyTableProps['options'];
  completeValues: ITableEditModeProps['completeValues'];
  errors: ITableEditModeProps['errors'];
  handleEditChange: ITableEditModeProps['handleEditChange'];
  handleUpdateValue: (value: any) => void;
  updateCursor: ReturnType<typeof useDataSource>['updateCursor'];
};

export const CyTableView = ({
  refreshId,
  isLoading,
  value,
  itemSchemas,
  isFirstLoad,
  children,
  isEditing,
  completeValues,
  errors,
  diffs,
  options: { hideSorting } = {},
  appearance,
  rowActions,
  handleUpdateValue,
  handleEditChange,
  updateCursor
}: CyTableViewProps) => {
  const { useParsers } = useContext(CyWrapperContext);
  const { translate } = useTranslate();
  const hasFiltersApplied = useHasFiltersApplied(value?.query?.cursor);

  const { parseList } = useParsers({ query: value?.query, rowColor: appearance?.rowColor });

  const { head, items, definitionMap } = useMemo(
    () => parseList({ entity: value?.query, list: value?.list, rowColor: appearance?.rowColor }),
    [parseList, appearance?.rowColor, value?.list, value?.query]
  );

  return (
    <div data-testid={testId} css={styles.container}>
      {!!isLoading && !!value && (
        <div css={styles.progressBarContainer} data-testid={getTestId(`progress-bar`)}>
          <ProgressBar color="BRAND_2" size={5} alt={true} />
        </div>
      )}
      <div css={styles.content}>
        {isFirstLoad && (
          <div css={styles.spinnerContainer}>
            <Spinner testid={getTestId(`spinner`)} />
          </div>
        )}
        {!isLoading && !value?.list?.length && (
          <span data-testid={getTestId(`empty-list`)}>
            {Children.count(children) ? (
              children
            ) : (
              <div css={styles.spinnerContainer} data-testid={!hasFiltersApplied ? 'empty' : 'empty-with-filters'}>
                <CyText
                  content={translate(!hasFiltersApplied ? TRANS.client.emptyStates.cyList : TRANS.client.emptyStates.cyListWithFilters)}
                  titleAlignment={ViewModel.Alignment.CENTER}
                />
              </div>
            )}
          </span>
        )}
        {!isFirstLoad &&
          !!value?.list?.length &&
          (isEditing ? (
            <div data-testid="editing">
              <EditTable
                definitionMap={definitionMap}
                head={head}
                isLoading={isLoading}
                items={items}
                completeValues={completeValues}
                handleEditChange={handleEditChange}
                handleUpdateValue={handleUpdateValue}
                itemSchemas={itemSchemas}
                errors={errors}
                diffs={diffs}
              />
            </div>
          ) : (
            <ReadOnlyTable
              refreshId={refreshId}
              definitionMap={definitionMap}
              head={head}
              hideSorting={hideSorting}
              isLoading={isLoading}
              items={items}
              value={value}
              rowActions={rowActions}
              updateCursor={updateCursor}
            />
          ))}
      </div>
    </div>
  );
};
