import { useCallback, useEffect, useRef, isValidElement, ReactNode, useMemo } from 'react';
import { ENV } from '@constants';
import { styles } from './styles';
import { RichText } from '../RichTextEditor';
import { useOutsideClick } from '@utils/useOutsideClick';
import { useSafeState } from '@utils';
import { useCalculateCoords } from '../Popover';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { SerializedStyles } from '@emotion/react';
import { OptionMenu, OptionMenuProps } from '../OptionMenu';
import { CTAType } from '@components/elements/CTA';
import { createPortal } from 'react-dom';
import { usePopover } from '@components/providers/UIprovider';
import { GeneralModel, Translate, createUUID, useTranslate } from '@cyferd/client-engine';

export const POPOVER_PORTAL_ID = 'popover-portal-container';

export enum ArrowPosition {
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right'
}

export interface IPopoverTrigger {
  label?: string;
  value: string | ReactNode;
  testId?: string;
  children: React.ReactNode;
  containerCss?: SerializedStyles;
  contentCss?: SerializedStyles;
  optionList?: OptionMenuProps['optionList'];
  avoidOpenOnClick?: boolean;
  color?: GeneralModel.Color.ThemeColor;
  timeout?: number;
}

export interface IDialog {
  value: string | ReactNode;
  onClose: (e?: React.MouseEvent<HTMLDialogElement> | MouseEvent) => void;
  coords?: { top: number; left: number; arrowPosition: ArrowPosition };
  dialogRef: (node: any) => void;
  label: string;
  testId?: string;
  optionList?: OptionMenuProps['optionList'];
  color?: GeneralModel.Color.ThemeColor;
  timeout?: number;
}

export interface IPopover extends IDialog {
  open: boolean;
}

export const Dialog = ({ dialogRef, value, onClose, coords, label, testId, optionList, color, timeout = ENV.INFO_TIMEOUT }: IDialog) => {
  const { translate } = useTranslate();
  const dialogInnerRef = useOutsideClick(onClose);
  const timerRef = useRef<NodeJS.Timeout>();

  const { top = 0, left = 0, arrowPosition = ArrowPosition.TOP_LEFT } = coords || {};
  const shouldShow = top > 0 && left > 0;

  const onMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLDialogElement>) => {
      timerRef.current = setTimeout(() => onClose(e), timeout);
    },
    [onClose, timeout]
  );

  const onMouseEnter = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      onClose();
      if (timerRef.current) clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const output = (
    <dialog
      css={styles.dialog({ top, left, shouldShow, arrowPosition, color })}
      data-testid={testId}
      open={true}
      ref={dialogInnerRef}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <div css={styles.dialogWrapper({ arrowPosition, color })} ref={dialogRef}>
        <div css={styles.close}>
          <OptionMenu defaultBtnType={CTAType.LINK} defaultBtnColor="NEUTRAL_2" optionList={optionList} />
        </div>
        <div css={styles.dialogContent}>
          {!!label && (
            <p css={styles.label}>
              <Translate>{label}</Translate>
            </p>
          )}
          {isValidElement(value) ? value : <RichText value={translate(value)} />}
        </div>
      </div>
    </dialog>
  );

  /** used to avoid z-index issues when popover is contained in absoulte containers. Leaving conditional to facilitate testing */
  const container = document.getElementById(POPOVER_PORTAL_ID);

  return <>{container ? createPortal(output, container) : output}</>;
};

export const Popover = ({ value, open, onClose, coords, dialogRef, label, testId = 'popover-dialog', optionList, color }: IPopover) => {
  if (!open) return null;
  return <Dialog value={value} dialogRef={dialogRef} onClose={onClose} coords={coords} label={label} testId={testId} optionList={optionList} color={color} />;
};

export const PopoverTrigger = ({
  label,
  value,
  testId = 'popover-trigger',
  children,
  containerCss,
  contentCss,
  optionList,
  avoidOpenOnClick,
  color,
  timeout = ENV.INFO_TIMEOUT
}: IPopoverTrigger) => {
  const [open, setOpen] = useSafeState(false);
  const [{ top, left }, setCoords] = useSafeState({});
  const { openPopover, closePopover, currentPopoverId } = usePopover();
  const popoverId = useMemo(() => createUUID(), []);
  const hoverTimeoutRef = useRef<NodeJS.Timeout>(null);
  const { dialogRef, coords } = useCalculateCoords({ left, top });

  const onMouseEnter = useCallback(() => {
    hoverTimeoutRef.current = setTimeout(() => {
      setOpen(true);
    }, timeout);
  }, [setOpen, timeout]);

  const onMousemove = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      if (!open) {
        setCoords({ left: e.clientX, top: e.clientY });
      }
    },
    [setCoords, open]
  );

  const onMouseLeave = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
  };

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      /* istanbul ignore next line */
      if (avoidOpenOnClick) return;
      e.preventDefault();
      setCoords({ left: e.clientX, top: e.clientY });
      setOpen(!open);
    },
    [avoidOpenOnClick, setOpen, open, setCoords]
  );

  const onClose = useCallback(
    (e?: MouseEvent | React.MouseEvent<HTMLDialogElement>) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      setOpen(false);
    },
    [setOpen]
  );

  useEffect(() => {
    if (open) {
      openPopover(popoverId);
    } else {
      closePopover();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <PreventClickPropagation containerCss={containerCss}>
        <span
          css={[styles.wrapper, contentCss]}
          data-testid={testId}
          onMouseMove={onMousemove}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {children}
        </span>
      </PreventClickPropagation>
      <Popover
        dialogRef={dialogRef}
        coords={coords}
        label={label}
        value={value}
        open={popoverId === currentPopoverId}
        onClose={onClose}
        optionList={optionList}
        color={color}
      />
    </>
  );
};
