import { ReactEventHandler, useEffect, useRef, useState } from 'react';

import { styles } from './styles';

export interface ImageProps {
  src: string;
  alt: string;
  ratio?: '16/9' | '9/16' | '1/1' | '3/4' | '4/3' | null;
  constrainSize?: boolean;
  onError?: ReactEventHandler;
  testid?: string;
}

export const Image = ({ src, alt, ratio, constrainSize = true, onError, testid = 'image' }: ImageProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [size, setSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({ width: undefined, height: undefined });

  useEffect(() => {
    setSize({
      width: imageRef.current?.naturalWidth || undefined,
      height: imageRef.current?.naturalHeight || undefined
    });
  }, [imageRef]);

  return (
    <div css={[constrainSize && styles.constrain, ratio && styles.ratio[ratio]]} data-testid={`${testid}-container`}>
      <img
        ref={imageRef}
        css={styles.image}
        src={src}
        alt={alt}
        width={size.width}
        height={size.height}
        loading="lazy"
        data-testid={testid}
        onError={onError}
      />
    </div>
  );
};

Image.displayName = 'Image';
