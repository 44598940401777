import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { actionHooks } from '@cyferd/client-engine';

import { NOTIFICATION_LATEST_POINTER, NOTIFICATIONS_POINTER, useNotification } from '../client-app/state-mgmt/notification';
import { actions } from '../client-app/state-mgmt/ui/actions';

export const useOnIncomingNotification = () => {
  const dispatch = useDispatch();
  const notifications = useNotification();
  const fetchNotificationsList = actionHooks.useOnFetchUserNotifications(NOTIFICATIONS_POINTER);
  const fetchLatestNotifications = actionHooks.useOnFetchUserNotifications(NOTIFICATION_LATEST_POINTER);

  return useCallback(
    payload => {
      if (!payload.silent) dispatch(actions.addMessage(payload));
      if (notifications?.list?.query?.cursor?.options?.skip === 0) fetchNotificationsList();
      fetchLatestNotifications();
    },
    [fetchNotificationsList, fetchLatestNotifications, notifications?.list, dispatch]
  );
};
