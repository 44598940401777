import { COLOR, INPUT_HEIGHT } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  dialCode: css`
    background-color: ${COLOR.TRANSPARENT};
    align-items: center;
    color: ${COLOR.NEUTRAL_2};
    cursor: pointer;
    width: 78px;
    height: ${INPUT_HEIGHT};
    display: flex;
    justify-content: center;
  `,
  readonlyDialCode: css`
    justify-content: flex-start;
  `,
  countryButton: css`
    display: flex;
    align-items: center;
  `
};
