import { ApiModel, ClientEngineContext, GeneralModel, useTranslate } from '@cyferd/client-engine';
import { useContext, useState } from 'react';

import { Icon } from '@components/elements/Icon';
import { CTA, CTAType } from '@components/elements/CTA';
import { COLOR, FONT_SIZE, HC_COLOR, TRANS } from '@constants';
import { styles } from './styles';

export interface InfoMessageProps {
  value?: ApiModel.ApiValue<ApiModel.ApiRecord>;
}

export const InfoMessage = ({ value }: InfoMessageProps) => {
  const { translate } = useTranslate();
  const { useUserSelector } = useContext(ClientEngineContext);
  const userId = useUserSelector()?.id;
  const key = `${userId}-cyKanban-info-message-hidden`;
  const hiddenForever = Boolean(localStorage.getItem(key));
  const [hidden, setHidden] = useState<boolean>(false);

  const hideForever = () => {
    localStorage.setItem(key, 'true');
    setHidden(true);
  };

  return hiddenForever ||
    !value ||
    hidden ||
    // TODO: change condition when we have the real totalCount
    (value?.list?.length < value?.query?.cursor?.options?.limit && value?.query?.cursor?.options?.skip === 0) ? null : (
    <div data-testid="info-message" css={styles.container}>
      <div css={styles.content}>
        <Icon name="info" size={FONT_SIZE.M} fill={HC_COLOR.HC_15} />
        <p>
          <p css={styles.message}>{translate(TRANS.client.buldingBlocks.cyKanban.paginationInfoMessage, { limit: value?.query?.cursor?.options?.limit })} </p>
          <p css={styles.linkBtn} data-testid="link-btn" onClick={hideForever}>
            {translate(TRANS.client.buttons.dontShowMeAgain)}
          </p>
        </p>
      </div>
      <CTA type={CTAType.SEEMLESS} onClick={() => setHidden(true)}>
        <Icon name="close" fill={COLOR.NEUTRAL_1 as GeneralModel.Color.ThemeColor} size={FONT_SIZE.XXM} />
      </CTA>
    </div>
  );
};
