export interface Dimensions {
  width: number;
  height: number;
}

export interface DimensionsWithPositions extends Dimensions {
  x: number;
  y: number;
}

export interface Params {
  triggerDimensions: DimensionsWithPositions;
  menuDimensions: Dimensions;
  clientDimensions: Dimensions;
  horizontalPadding?: number;
  verticalPadding?: number;
}

export const getElementMenuPosition = ({ triggerDimensions, menuDimensions, clientDimensions, horizontalPadding = 0, verticalPadding = 0 }: Params) => {
  const { x, y, width: triggerWidth, height: triggerHeight } = triggerDimensions;
  const { height, width } = menuDimensions;
  const { width: clientWidth, height: clientHeight } = clientDimensions;

  const down = clientHeight - y;
  const up = y;
  const neededHeight = height + triggerHeight + verticalPadding;
  const spareHeightDown = down - neededHeight;
  const spareHeightUp = up - neededHeight;

  const spareWidth = clientWidth - (x + width + triggerWidth + horizontalPadding);

  const shouldRenderBottom = spareHeightDown > 0 || spareHeightDown > spareHeightUp; // down by default else where it has more space
  const shouldRenderRight = spareWidth > 0;

  return {
    style: {
      top: y + (shouldRenderBottom ? triggerHeight + verticalPadding : (height + verticalPadding) * -1) || 0,
      left: x + (shouldRenderRight ? horizontalPadding : triggerWidth - width - horizontalPadding) || 0
    },
    shouldRenderBottom,
    shouldRenderRight
  };
};
