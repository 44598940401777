import { PropsWithChildren } from 'react';
import { styles } from './styles';

export type SidebarProps = PropsWithChildren<{
  open: boolean;
  wide?: boolean;
}>;

export const Sidebar = ({ open, wide, children }: SidebarProps) => {
  return (
    <div css={[styles.pane, !!open && styles.paneOpen, !!open && !!wide && styles.wide]} data-testid="pane">
      {children}
    </div>
  );
};
