import { useContext } from 'react';

import { createUUID, GeneralModel, TagModel, ViewModel } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { dateSchema, defaultFormDetailGroupList, defaultFormDetailGroupMap, TRANS } from '@constants';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { EmptyState } from '@components/elements/EmptyState';
import { SchemaForm } from '../../../shared/SchemaForm';
import { styles } from './styles';
import { Layout } from '@components/elements/Layout';

const schema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      label: TRANS.client.fields.titles.name,
      minLength: 1,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    title: {
      type: 'string',
      label: TRANS.client.fields.titles.publicName,
      info: 'A public name is the name visible to your users. If a public name is created, "Name" will only be visible to Admins in the builder.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    description: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      label: TRANS.client.fields.titles.description,
      info: 'This description will be how it appears to your users.',
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    recordColor: {
      type: 'string',
      label: TRANS.client.fields.titles.color,
      format: GeneralModel.JSONSchemaFormat.COLOR,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    recordImage: {
      type: 'string',
      label: TRANS.client.fields.titles.icon,
      format: GeneralModel.JSONSchemaFormat.ICON_IMAGE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    notes: {
      type: 'string',
      label: TRANS.client.fields.titles.adminNotes,
      info: 'This description is only visible to admin users',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    globalSearchable: {
      type: 'boolean',
      label: TRANS.client.fields.titles.globalSearch,
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      default: false,
      metadata: {
        detailGroupId: defaultFormDetailGroupMap.configuration.id,
        detailOrder: 1
      }
    },
    hideApp: {
      type: 'boolean',
      label: TRANS.client.fields.titles.hideApp,
      info: '<p>Hide this app from appearing in:</p><ul><li><p>The list of apps on the main home page</p></li><li><p>&quot;My apps&quot; navigation menu</p></li></ul>',
      format: GeneralModel.JSONSchemaFormat.CHECKBOX,
      default: false,
      metadata: {
        detailGroupId: defaultFormDetailGroupMap.configuration.id,
        detailOrder: 1
      }
    }
  }
};

export const GeneralInfo = () => {
  const { getTestIdProps } = useTestingHelper('general-info');
  const { item, setItem } = useContext<EditorContextValue<TagModel.Tag>>(EditorContext);

  if (!item) return <EmptyState />;

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <Layout type={ViewModel.LayoutType.TWO_ALT_1}>
        <SchemaForm schema={schema} value={item} onChange={setItem} detailGroupList={defaultFormDetailGroupList} wrapDetailGroups={true} />
        <SchemaForm schema={dateSchema} value={item} onChange={setItem} detailGroupList={defaultFormDetailGroupList} wrapDetailGroups={true} />
      </Layout>
    </div>
  );
};
