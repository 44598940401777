import { useContext, useMemo } from 'react';

import { BuilderCyList } from '../../../shared/BuilderCyList';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { styles } from './styles';
import { EmptyState } from '@components/elements/EmptyState';
import { useRequest } from '@utils/useRequest';

export const IntegrationLogs = () => {
  const request = useRequest();
  const { item } = useContext<EditorContextValue<any>>(EditorContext);

  const collectionId = 'IntegrationLog';

  const fixedFilter = useMemo(() => ({ $and: [{ integrationId: { $eq: item?.id } }] }), [item?.id]);
  if (!item) return <EmptyState />;

  return (
    <div data-testid="integration-logs" className={styles.container}>
      <BuilderCyList request={request} id={collectionId} key={collectionId} initialFetchCriteria={{ collectionId }} fixedFilter={fixedFilter} />
    </div>
  );
};
