import { useCallback, useContext, useMemo, useState } from 'react';
import { CyWrapperContext } from '../../smart/CyWrapper/CyWrapper';
import { ApiModel, GeneralModel, ViewModel, actions, tapOnSuccess } from '@cyferd/client-engine';
import { map } from 'rxjs';
import { Modal } from '../Modal';
import { CyList } from '../../smart/CyList/CyList';
import { CyLayout } from '../../smart/CyLayout';
import { CTAType } from '../CTA';
import { OptionMenu } from '../OptionMenu';

export interface ReadonlyAssociationProps {
  title: string;
  schema: GeneralModel.JSONSchema;
  apiQuery: ApiModel.ApiValue['query'];
  onClose: () => void;
}

export const ReadonlyAssociation = ({ title, apiQuery, schema, onClose }: ReadonlyAssociationProps) => {
  const { useListAssociation, useAction } = useContext(CyWrapperContext);
  const [list, setList] = useState<ApiModel.ApiValue>(undefined);
  const subtype = schema.metadata?.subtype as unknown as ViewModel.CyListType;
  const initialFetchCriteria = useMemo(
    () =>
      ({
        ...schema?.metadata?.association?.cursor,
        options: { limit: 5, ...schema?.metadata?.association?.cursor?.options }
      }) as GeneralModel.FetchCriteria,
    [schema?.metadata?.association?.cursor]
  );
  const onNavigateTo = useAction('dispatchNavigateTo');

  const onListSelected = useListAssociation(apiQuery, schema, false);
  const onFetchSelected = useCallback(
    (fetchCriteria: GeneralModel.FetchCriteria) =>
      onListSelected(fetchCriteria).pipe(
        tapOnSuccess(setList),
        map(v => actions.dispatchSetData({ value: v }))
      ),
    [onListSelected]
  );

  /* istanbul ignore next line */
  const onNavigate = useCallback(
    (event: ApiModel.ApiRecord) => onNavigateTo({ path: 'DETAIL', qs: { id: event?.id, collectionId: event?.collectionId }, openInNewTab: true }),
    [onNavigateTo]
  );

  return (
    <Modal
      open={true}
      type={ViewModel.ModalType.REGULAR}
      onClose={onClose}
      title={title}
      footer={<OptionMenu optionList={[{ label: 'OK', important: true, type: CTAType.PRIMARY, onClick: onClose, testid: 'close' }]} />}
    >
      <CyLayout itemHeight="500px">
        <CyList
          framed={false}
          type={Object.values(ViewModel.CyListType).includes(subtype) ? subtype : ViewModel.CyListType.LIST}
          initialFetchCriteria={initialFetchCriteria}
          value={list}
          onFetch={onFetchSelected}
          typeSelectorHidden={true}
          onClickItem={onNavigate}
          actionListChildren={[
            {
              helperText: 'Go to',
              icon: 'open_in_new',
              type: CTAType.LINK,
              important: true,
              onClick: onNavigate as any
            }
          ]}
          density={ViewModel.Density.M}
        >
          <div />
        </CyList>
      </CyLayout>
    </Modal>
  );
};
