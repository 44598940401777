import { Modal } from '../Modal';
import { styles } from './styles';
import { SchemaForm } from '../../../builder/views/shared/SchemaForm';
import { THEME, TRANS, defaultFormDetailGroupMap } from '@constants';
import {
  ApiModel,
  CollectionModel,
  GeneralModel,
  ViewModel,
  createUUID,
  mergeTruthy,
  normalize,
  ofType,
  tapOnSuccess,
  useFinalizeWhileMounted,
  useUnmountObservable
} from '@cyferd/client-engine';
import { OptionMenu } from '../OptionMenu';
import { useContext, useState } from 'react';
import { CyWrapperContext } from '@components/smart/CyWrapper/CyWrapper';
import { takeUntil } from 'rxjs';
import { actions as uiActions } from '../../../client-app/state-mgmt/ui/actions';

import ngLight from '../../../assets/ng-light.gif';
import ngDark from '../../../assets/ng-dark.gif';
import { ToastStatus } from '../Toast';
import { useDispatch } from 'react-redux';
import { UIContext } from '@components/providers/UIprovider';

const schema: GeneralModel.JSONSchema = {
  $id: createUUID(),
  type: 'object',
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      label: TRANS.client.fields.titles.name,
      description: 'Use a descriptive name to help us understand your requirements',
      minLength: 1,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    },
    description: {
      type: 'string',
      format: GeneralModel.JSONSchemaFormat.MULTILINE,
      label: TRANS.client.fields.titles.description,
      description: 'Tell us the planned content, functionality or purpose of the data collection',
      info: `The more detailed the description is, the more relevant the collection will be. <br/><br/>For example, "Employee management system with both full time and contracted employees"`,
      metadata: { detailGroupId: defaultFormDetailGroupMap.basic.id }
    }
  }
};

export interface NGModalProps {
  value: CollectionModel.Collection;
  onChange: (event: CollectionModel.Collection) => void;
  onClose: () => void;
}

export const NGModal = ({ value, onChange, onClose }: NGModalProps) => {
  const { useAction } = useContext(CyWrapperContext);
  const onGenerateSchema = useAction('ngGenerateSchema');
  const onDestroy$ = useUnmountObservable();
  const [loading, setLoading] = useState<boolean>(false);
  const finalize = useFinalizeWhileMounted();
  const dispatch = useDispatch();

  const { runtimeTheme } = useContext(UIContext);
  const img = { [THEME.LIGHT]: ngLight, [THEME.DARK]: ngDark }[runtimeTheme];

  const onGenerate = () => {
    setLoading(true);
    onGenerateSchema({ name: value?.name, description: value?.description, app: 'operational app' /** @todo should not be required */ })
      .pipe(
        takeUntil(onDestroy$),
        ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
        tapOnSuccess(response => {
          const existingGroupIds = value.detailGroupList?.map?.(({ id }) => id);
          const newSchema = mergeTruthy(response?.record?.schema, value.schema);
          const newGroups = [...response?.record?.detailGroupList?.filter?.(g => !existingGroupIds.includes(g?.id)), ...value.detailGroupList];
          const merged = normalize.collection({ ...value, schema: newSchema, detailGroupList: newGroups });
          onChange(merged);
          setLoading(false);
          dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.SUCCESS, title: `Success` }));
          onClose();
        }),
        finalize(() => setLoading(false))
      )
      .subscribe();
  };

  return (
    <Modal
      testid="ng-modal"
      open={true}
      onClose={!loading && onClose}
      title="Describe your new collection"
      icon="auto_awesome"
      footer={
        <OptionMenu
          optionList={[
            !loading && {
              testid: 'generate-btn',
              image: 'auto_awesome',
              color: 'HC_3',
              type: ViewModel.CTAType.TERTIARY,
              label: 'Generate',
              important: true,
              disabled: [value?.name].every(i => !i),
              onClick: onGenerate
            }
          ]}
        />
      }
    >
      {loading ? (
        <div data-testid="ng-loading" css={styles.loading}>
          <img css={styles.img} src={img} alt="ng" />
          <p css={styles.txt}>Generating...</p>
        </div>
      ) : (
        <SchemaForm maxColumns={1} schema={schema} value={value} onChange={onChange} wrapDetailGroups={true} delayTime={0} />
      )}
    </Modal>
  );
};
