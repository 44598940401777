import { GeneralModel } from '@models';

import { getInitialState as getContextInitialState } from './context/reducer';
import { getInitialState as getDataInitialState } from './global/reducer';
import { getInitialState as getFavoriteInitialState } from './favorite/reducer';
import { getInitialState as getNotificationInitialState } from './notification/reducer';
import { getInitialState as getUiInitialState } from './ui/state';
import { getInitialState as getUserInitialState } from './user/reducer';
import { getInitialState as getViewInitialState } from './view/reducer';

export const getInitialState = (): GeneralModel.State => ({
  context: getContextInitialState(),
  global: getDataInitialState(),
  view: getViewInitialState(),
  user: getUserInitialState(),
  ui: getUiInitialState(),
  favorite: getFavoriteInitialState(),
  notification: getNotificationInitialState()
});
