import { Subject } from 'rxjs';

import { ConfirmationModalProps } from '@components/elements/ConfirmationModal';
import { ToastProps } from '@components/elements/Toast';

export interface UIState {
  loadingList: string[];
  toastList: { toast: Partial<ToastProps>; content: any }[];
  modalInteraction?: Omit<ConfirmationModalProps, 'open' | 'onChange' | 'singleAction'> & { change$?: Subject<boolean> };
}

export const getInitialState = (): UIState => ({
  loadingList: [],
  toastList: [],
  modalInteraction: null
});
