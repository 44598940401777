import { Translate, ViewModel, noop, validateSchemaInstance } from '@cyferd/client-engine';

import { COLOR } from '@constants';
import { getLabel } from '@utils';
import { Icon } from '../Icon';
import { styles } from './styles';
import { PopoverTrigger } from '../Popover';
import { CTA } from '../CTA';

export interface ErrorSummaryProps {
  title?: string;
  errorList: ReturnType<typeof validateSchemaInstance>['errors'];
  expanded?: boolean;
}

export const ErrorSummary = ({ title = 'Please review and update these fields in order to submit the form:', errorList, expanded }: ErrorSummaryProps) => {
  if (!errorList?.length) return null;

  const content = (
    <ul css={styles.list}>
      {errorList?.map((error, index) => (
        <li key={`${index}-${error.message}`} css={styles.item} data-testid="error-summary-item">
          <Translate>{getLabel(error.displayNamePath)}</Translate>: {error.message}
        </li>
      ))}
    </ul>
  );

  if (expanded) {
    return (
      <div css={styles.container} data-testid="error-summary">
        <h4 css={styles.title}>
          <Icon name="warning" size="17px" fill={COLOR.HC_5} />
          <span css={styles.titleText}>
            <Translate>{title}</Translate>
          </span>
        </h4>
        {content}
      </div>
    );
  }

  return (
    <PopoverTrigger label={title} value={content} color="HC_5">
      <CTA type={ViewModel.CTAType.ACTION} icon="question_mark" color="HC_5" label="See all errors" onClick={noop} testid="error-summary" />
    </PopoverTrigger>
  );
};
