/** @todo test */
import { useCallback, useEffect, useState } from 'react';
import { EMPTY, filter, mergeMap, Observable, of, takeUntil, tap } from 'rxjs';

import { ApiModel, GeneralModel, useFinalizeWhileMounted, useUnmountObservable } from '@cyferd/client-engine';

import { useSearchFilter } from './useSearchFilter';

type useSmartUtilsHook = {
  value?: ApiModel.ApiValue;
  onFetch?: (criteria?: GeneralModel.FetchCriteria) => Observable<ApiModel.APIAction>;
  fixedFilter?: GeneralModel.FetchCriteria['fixedFilter'];
};

export const useSmartUtils = ({ value, onFetch, fixedFilter }: useSmartUtilsHook) => {
  const finalize = useFinalizeWhileMounted();

  const onDestroy$ = useUnmountObservable();

  const [isLoading, setIsLoading] = useState(false);

  const onInternalFetch = useCallback(
    (cursor?: GeneralModel.FetchCriteria) => {
      return of(null).pipe(
        tap(() => setIsLoading(true)),
        mergeMap(() => (onFetch ? onFetch({ ...cursor, fixedFilter }) : EMPTY)),
        takeUntil(onDestroy$),
        filter(action => action?.type === ApiModel.TriggerActionType.DISPATCH_SET_DATA && !!action.payload),
        finalize(() => setIsLoading(false))
      );
    },
    [finalize, onDestroy$, onFetch, fixedFilter]
  );

  const { canSearch, onSearch, searchString, setSearchString } = useSearchFilter({ cursor: value?.query?.cursor, onFetch: onInternalFetch });

  useEffect(() => {
    setSearchString(value?.query?.cursor?.searchString || '');
  }, [value?.query?.cursor?.searchString, setSearchString]);

  return { onInternalFetch, isLoading, canSearch: !!(canSearch && onFetch), onSearch, searchString, setSearchString };
};
