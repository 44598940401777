import { useCallback, useMemo, useState } from 'react';
import {
  FormulaInputRow,
  UNIQUE_PATH_PREFIX,
  defaultInputList,
  getCleanPath,
  getFlatFormula,
  getHandlingEmptyPath,
  getNewKey,
  getStateAfterCast,
  getStateAfterDeleteContent,
  getStateAfterDuplicate,
  getStateAfterUnwrap,
  unescapeKeys
} from '../resources';
import { styles } from './styles';
import { EvaluatorTreeRow } from '../EvaluatorTreeRow';
import { ErrorBoundary, GeneralModel, ViewModel, createUUID, getFormulaKey, removeDeep, replaceDeep, safeParse, useDebounce } from '@cyferd/client-engine';
import { FormulaInput } from '../FormulaInput';
import { OptionMenu, OptionMenuProps } from '@components/elements/OptionMenu';
import { CTA, CTAType } from '@components/elements/CTA';
import { Droppable } from '@hello-pangea/dnd';
import { FormulaInputType, colorPerType } from '../getFormulaInputType';
import { TypeToCast, getType } from '../cast';
import { ENV, TRANS, getDataTypeIcon } from '@constants';

export interface EvaluatorTreeProps {
  value: any;
  inputList: FormulaInputRow[];
  activePath: string;
  pathBeingDragged: string;
  height: string;
  readonly?: boolean;
  onChange: (event: any) => void;
  onChangeActive: (event: string) => void;
  onKeyChange: (event: string) => void;
}

export const EvaluatorTree = ({
  value,
  inputList = [],
  activePath,
  pathBeingDragged,
  height,
  readonly,
  onChange: localOnChange,
  onChangeActive,
  onKeyChange
}: EvaluatorTreeProps) => {
  const [ddRenderKeyRaw, setddRenderKey] = useState(() => createUUID());
  /** patch needed for the d&d library for bug BUG-8E212 */
  const ddRenderKey = useDebounce(ddRenderKeyRaw, ENV.INPUT_DEBOUNCE_TIME);
  const [collapsedRows, setCollapsedRows] = useState<string[]>([]);
  const onChange = useCallback(arg => localOnChange(unescapeKeys(arg)), [localOnChange]);

  const rows = useMemo(() => getFlatFormula(value, inputList), [inputList, value]);
  const visibleRows = useMemo(() => rows.filter(row => !collapsedRows.some(c => row.path !== c && row.path.startsWith(c))), [collapsedRows, rows]);
  const activeRow = useMemo(() => rows.find(r => r.path === activePath), [activePath, rows]);
  const isEditionEnabled = !!activeRow;

  const onToggleCollapsed = useCallback((path: string) => {
    setCollapsedRows(prev => (prev.includes(path) ? prev.filter(p => p !== path) : [...prev, path]));
  }, []);

  const onCloseEdition = useCallback(() => onChangeActive(null), [onChangeActive]);

  const onValueChange = useCallback(
    (event: any) => {
      setddRenderKey(createUUID());
      onChange(replaceDeep(value, event, activePath));
    },
    [activePath, onChange, value]
  );

  const onWrap = useCallback(
    (event: string) => {
      const $element = getHandlingEmptyPath(value, event);
      const newValue = replaceDeep(value, { $cyf_coalesce: [$element] }, event);
      onChange(newValue);
      onCloseEdition();
    },
    [onChange, onCloseEdition, value]
  );

  const onRemove = useCallback(
    (event: string) => {
      onChange(event ? removeDeep(value, event) : undefined);
      onCloseEdition();
    },
    [onChange, onCloseEdition, value]
  );

  const onUnwrap = useCallback(
    (event: string) => {
      onChange(getStateAfterUnwrap(value, event));
      onCloseEdition();
    },
    [onChange, onCloseEdition, value]
  );

  const onDeleteContent = useCallback(
    (event: string) => {
      onChange(getStateAfterDeleteContent(value, event));
      onCloseEdition();
    },
    [onChange, onCloseEdition, value]
  );

  const onDuplicate = useCallback(
    (event: string) => {
      onChange(getStateAfterDuplicate(value, event));
      onCloseEdition();
    },
    [onChange, onCloseEdition, value]
  );

  const onCast = useCallback(
    (path: string, type: TypeToCast) => {
      onCloseEdition();
      onChange(getStateAfterCast(value, path, type));
    },
    [onChange, onCloseEdition, value]
  );

  /* istanbul ignore next */
  const onPasteAndReplace = useCallback(
    async (event: string) => {
      onChange(replaceDeep(value, safeParse(await navigator?.clipboard?.readText?.()), event));
      onCloseEdition();
    },
    [onChange, onCloseEdition, value]
  );

  /* istanbul ignore next */
  const onPasteInside = useCallback(
    async (event: string) => {
      const $element = getHandlingEmptyPath(value, event);
      const content = safeParse(await navigator?.clipboard?.readText?.());

      const replacement = (() => {
        switch (getType($element)) {
          case 'array':
            return [...$element, content];
          case 'object':
            return { ...$element, [getNewKey($element)]: content };
          case FormulaInputType.FORMULA:
            const formulaKey = getFormulaKey($element);
            return { ...$element, [formulaKey]: [...$element[formulaKey], content] };
        }
      })();

      onChange(replaceDeep(value, replacement, event));
      onCloseEdition();
    },
    [onChange, onCloseEdition, value]
  );

  /* istanbul ignore next */
  const onCopyToClipboard = useCallback((event: any) => {
    navigator?.clipboard?.writeText?.(JSON.stringify(event));
  }, []);

  const onQuickAdd = useCallback(
    (ddId: string, template: any) => {
      const cleanPath = getCleanPath(ddId);
      const parentPath = cleanPath.substring(0, cleanPath.lastIndexOf('.'));
      const $parent = getHandlingEmptyPath(value, parentPath);
      const $new = Array.isArray($parent) ? [...$parent, template] : { ...$parent, [`key_${Object.keys($parent).length + 1}`]: template };
      const newPath = [parentPath, Array.isArray($parent) ? $parent.length : `key_${Object.keys($parent).length + 1}`].join('.');
      onChange(replaceDeep(value, $new, parentPath));
      onCloseEdition();
      onChangeActive(newPath);
    },
    [onChange, onChangeActive, onCloseEdition, value]
  );

  const quickAddOptions: OptionMenuProps['optionList'] = useMemo(
    () =>
      [
        { label: 'formula', type: 'object', format: GeneralModel.JSONSchemaFormat.JSON, template: { $cyf_coalesce: [] } },
        ...defaultInputList,
        { label: 'color', type: 'string', format: GeneralModel.JSONSchemaFormat.COLOR, template: 'BRAND_1' },
        { label: 'icon', type: 'string', format: GeneralModel.JSONSchemaFormat.ICON_IMAGE, template: 'database' }
      ]
        .filter(i => i.format)
        .map(i => ({
          testid: `quick-value-${i.label}`,
          important: false,
          label: `Add ${i.label}`,
          type: CTAType.LINK,
          image: i.label === 'formula' ? 'function' : getDataTypeIcon(i.format),
          color: colorPerType[i.type],
          size: ViewModel.CTASize.SMALL,
          onClick: (event: string) => onQuickAdd(event, i.template)
        })),
    [onQuickAdd]
  );

  return (
    <div data-testid="evaluator-tree" css={styles.container}>
      <div css={styles.tree}>
        {!rows.length && (
          <Droppable droppableId={UNIQUE_PATH_PREFIX}>
            {droppableProvided => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
                css={styles.emptyStateContainer}
                style={{ height: `calc(${height} - 50px)`, transform: 'none!important' }}
              >
                <div css={styles.emptyState} data-testid="empty-state-container">
                  <CTA
                    type={CTAType.LINK}
                    size={ViewModel.CTASize.LARGE}
                    color="NEUTRAL_1"
                    label="Drop here to start creating formulas or click to paste"
                    testid="empty-state-cta"
                    onClick={/* istanbul ignore next */ () => onPasteAndReplace('') as any}
                  />
                </div>
                <div css={styles.hidden}>{droppableProvided.placeholder}</div>
              </div>
            )}
          </Droppable>
        )}
        {visibleRows.map(row => {
          const isActiveContent = row.path.startsWith(activeRow?.path);
          return (
            <EvaluatorTreeRow
              key={`key-${row.path}-${ddRenderKey}`}
              row={row}
              pathBeingDragged={pathBeingDragged}
              collapsed={collapsedRows.includes(row.path)}
              isActive={row.path === activeRow?.path}
              isActiveContent={isActiveContent}
              quickAddOptions={quickAddOptions}
              readonly={readonly}
              onKeyChange={onKeyChange}
              onToggleCollapsed={onToggleCollapsed}
              onChangeActive={onChangeActive}
              onWrap={onWrap}
              onRemove={onRemove}
              onUnwrap={onUnwrap}
              onDuplicate={onDuplicate}
              onDeleteContent={onDeleteContent}
              onPasteAndReplace={onPasteAndReplace}
              onPasteInside={onPasteInside}
              onCopyToClipboard={onCopyToClipboard}
              onCast={onCast}
            />
          );
        })}
      </div>
      <div style={{ width: !!isEditionEnabled ? '50%' : 0, borderLeftWidth: !!isEditionEnabled ? 1 : 0, minHeight: height }} css={styles.fieldContainer}>
        <div css={styles.formulaInputContainer}>
          {!!isEditionEnabled && (
            <ErrorBoundary>
              <div css={styles.closeContainer}>
                <OptionMenu
                  optionList={[
                    {
                      important: true,
                      testid: 'close-formula-input',
                      color: 'BRAND_1',
                      image: 'input',
                      tooltip: TRANS.client.buttons.close,
                      onClick: onCloseEdition,
                      type: CTAType.LINK
                    }
                  ]}
                />
              </div>
              <FormulaInput key={activePath} inputList={inputList} value={activeRow.value} config={activeRow.formulaInputRow} onChange={onValueChange} />
            </ErrorBoundary>
          )}
        </div>
      </div>
    </div>
  );
};

EvaluatorTree.displayName = 'EvaluatorTree';
