import { useEffect } from 'react';
import { TabListProps } from './TabList';

export const useSelectNonDisabledTab = ({
  activeTab,
  tabList,
  onChangeTab
}: {
  activeTab: TabListProps['activeTab'];
  tabList: TabListProps['tabList'];
  onChangeTab: TabListProps['onChangeTab'];
}) => {
  useEffect(() => {
    const currentTab = tabList.find(tab => tab.title === activeTab);
    const notDisabledTabTitle = tabList.find(tab => !tab.disabled)?.title;
    if (currentTab?.disabled && notDisabledTabTitle) onChangeTab(notDisabledTabTitle);
  }, [activeTab, tabList, onChangeTab]);
};
