import { tsvToArray } from '@utils';
import { isEditable } from './constants';

export const onPaste = (e: ClipboardEvent, { containerRef, selectedRow, selectedCol, itemSchemas, handleUpdateValue }) => {
  e.preventDefault();
  const plain = e.clipboardData?.getData('text/plain');
  const rows = tsvToArray(plain);
  const pastedValues = rows.reduce((acc, row, index) => {
    const targetRow = selectedRow + index;
    const targetTr = containerRef.current.querySelector(`[data-rowindex="${targetRow}"]`);
    const itemId = targetTr?.getAttribute('data-itemid');
    return {
      ...acc,
      [itemId]: row.reduce((acc, value, index) => {
        const targetCol = selectedCol + index;
        const targetTd = containerRef.current.querySelector(`[data-rowindex="${targetRow}"][data-colindex="${targetCol}"]`);
        const definitionId = targetTd?.getAttribute('data-definitionid');
        const schema = itemSchemas?.[itemId]?.[definitionId];
        if (!schema?.disabled && isEditable(schema)) {
          if (schema?.type === 'string') return { ...acc, [definitionId]: value };
          if (schema?.type === 'number') {
            const number = parseFloat(value);
            if (Number.isNaN(number)) return acc;
            return { ...acc, [definitionId]: number };
          }
          if (schema?.type === 'boolean') return { ...acc, [definitionId]: value === 'TRUE' ? true : value === 'FALSE' ? false : undefined };
        }
        return acc;
      }, {})
    };
  }, {});

  handleUpdateValue(pastedValues);
};
