import { ComponentProps, memo, PropsWithChildren, ReactNode } from 'react';

import { GeneralModel, getClassnames } from '@cyferd/client-engine';
import { COLOR, FONT_SIZE } from '@constants';
import { useTestingHelper } from '@utils';

import { getStyles } from './styles';
import { ToolTip } from '@components/elements/Tooltip';
import { Icon } from '@components/elements/Icon';

export type Props = PropsWithChildren<{
  testid?: string;
  title: string;
  isActive: boolean;
  isOverriden?: boolean;
  icon?: ComponentProps<typeof Icon>['name'];
  description?: string;
  nestedLevel: number;
  strongTitle?: boolean;
  hiddenIndicator?: boolean;
  toggleButton?: ReactNode;
  onClick?: () => void;
  color?: GeneralModel.Color.ThemeColor;
  shouldHighlight?: boolean;
}>;

export const EntityListItem = memo(
  ({
    testid,
    title,
    isActive,
    isOverriden = false,
    icon,
    nestedLevel,
    children,
    description,
    strongTitle,
    hiddenIndicator,
    toggleButton,
    shouldHighlight,
    color,
    onClick
  }: Props) => {
    const { getTestIdProps } = useTestingHelper(testid || 'entity-list-item');
    const styles = getStyles(nestedLevel, color);

    return (
      <div className={styles.container}>
        <div className={getClassnames(styles.colorBar)} />
        <div
          {...getTestIdProps('header')}
          className={getClassnames(styles.listItem, !!(shouldHighlight && !isActive) && styles.highlightedListItem, isActive && styles.activeListItem)}
          onClick={onClick}
        >
          {toggleButton}
          <div
            className={styles.listItemInnerContainer}
            data-testid={!!shouldHighlight ? 'highlighted' : 'not-highlighted'}
            data-field-highlighted={!!shouldHighlight}
          >
            <ToolTip text={description || title}>
              <div className={getClassnames(styles.listItemText, isActive && styles.listItemTextActive)}>
                {!!hiddenIndicator && (
                  <span className={styles.indicator}>
                    <Icon name="visibility_off" fill={isActive ? COLOR.BASE_BACKGROUND : COLOR.NEUTRAL_1} size={FONT_SIZE.M} />
                  </span>
                )}
                {!!icon && (
                  <span className={styles.indicator}>
                    <Icon name={icon} fill={isActive ? COLOR.BASE_BACKGROUND : COLOR.NEUTRAL_1} size={FONT_SIZE.M} />
                  </span>
                )}
                <span className={getClassnames(styles.listItemTitle, strongTitle && styles.strongTitle)}>{title}</span>
              </div>
            </ToolTip>
            {!!isOverriden && (
              <span className={getClassnames(styles.indicator, styles.overriden)}>
                <Icon name="rule_settings" fill={isActive ? COLOR.BASE_BACKGROUND : COLOR.OE_1} size={FONT_SIZE.M} />
              </span>
            )}
            {children}
          </div>
        </div>
      </div>
    );
  }
);
