import { ComponentProps, MouseEvent } from 'react';

import { GeneralModel } from '@cyferd/client-engine';

import { COLOR } from '@constants';
import { getEventPositionPercentage } from '@utils/getEventPositionPercentage';
import { useGetElementSize } from '@utils/useGetElementSize';
import { Icon } from '../Icon';
import { ToolTip } from '../Tooltip';
import { styles } from './styles';

export type IconListProps = {
  filled: number;
  length: number;
  roundBy?: number;
  color?: GeneralModel.Color.ThemeColor;
  secondaryColor?: GeneralModel.Color.ThemeColor;
  onClick?: (percentage: number) => void;
} & ComponentProps<typeof Icon>;

export const IconList = ({ length, filled = 0, roundBy = 1, onClick, color = 'OE_6', name = 'star', secondaryColor, ...iconProps }: IconListProps) => {
  const { ref, width } = useGetElementSize();
  const iconMargin = 2;
  const isInteractive = typeof onClick === 'function';
  const onChange = (event: MouseEvent) => {
    /* istanbul ignore if */
    if (event.clientX <= 0) return;
    const value = getEventPositionPercentage(event) * length;

    if (!roundBy) return onClick(value);
    if (roundBy === 1) return onClick(value > 0.5 ? /* istanbul ignore next */ Math.ceil(value) : 0);

    onClick(Math.round(value / roundBy) * roundBy);
  };

  const errorMargin = iconMargin * 2;
  const iconSize = iconProps.size || `${Math.min(Math.max((width - (length * iconMargin * 2 + errorMargin)) / length, 5), 25)}px`;

  return (
    <div css={styles.container} ref={ref}>
      <ToolTip text={Number(filled?.toFixed?.(2))}>
        <div
          data-testid="icon-list-dragger"
          css={[styles.dragger, isInteractive && styles.interactive]}
          style={{ maxWidth: `calc((${iconSize} + ${iconMargin}px * 2) * ${length})` }}
          draggable={isInteractive}
          onMouseDown={isInteractive ? onChange : undefined}
          onDrag={isInteractive ? onChange : undefined}
        />
        {Array.from(Array(length)).map((_, index) => (
          <div css={styles.content} style={{ margin: iconMargin }} key={index}>
            <Icon
              percentage={Math.max(Math.min(filled - index, 1), 0)}
              fill={COLOR[color]}
              emptyColor={COLOR[secondaryColor]}
              name={name}
              size={iconSize}
              {...iconProps}
            />
          </div>
        ))}
      </ToolTip>
    </div>
  );
};
