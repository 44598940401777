import { ApiModel, GeneralModel, replaceDeep } from '@cyferd/client-engine';
import { GeneralModel as ClientGeneralModel } from '@models';

import { NOTIFICATIONS_POINTER } from '../notification';

export const getInitialState = () => ({});

export const reducer = (state: ClientGeneralModel.DataState = getInitialState(), action: ClientGeneralModel.Action) => {
  const { type, payload } = action;

  switch (type) {
    case ApiModel.TriggerActionType.DISPATCH_SET_DATA:
      if ([NOTIFICATIONS_POINTER, GeneralModel.IGNORED_POINTER_ID].includes(payload?.pointer)) return state;
      if (![ApiModel.ApiSetDataSections.DATA, null, undefined].includes(payload?.section)) return state;
      if (!['global'].includes(payload?.scope)) return state;
      return replaceDeep(state, payload?.value, payload?.pointer);
    /** reseting data when view changes */
    case ApiModel.TriggerActionType.DISPATCH_SET_VIEW:
      return {};
    default:
      return state;
  }
};
