import { SvgProps } from '../types';

export const Layout_I = ({ size = 20, fill = 'var(--NEUTRAL_2)', ...props }: SvgProps) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="39.5" y="39.5" width="39" height="39" rx="3.5" transform="rotate(-180 39.5 39.5)" stroke={fill} />
    <path
      d="M11 5C11 3.89543 11.8954 3 13 3L27 3C28.1046 3 29 3.89543 29 5L29 35C29 36.1046 28.1046 37 27 37L13 37C11.8954 37 11 36.1046 11 35L11 5Z"
      fill={fill}
    />
    <path d="M9 35C9 36.1046 8.10457 37 7 37L5 37C3.89543 37 3 36.1046 3 35L3 5C3 3.89543 3.89543 3 5 3L7 3C8.10457 3 9 3.89543 9 5L9 35Z" fill={fill} />
    <path
      d="M37 35C37 36.1046 36.1046 37 35 37L33 37C31.8954 37 31 36.1046 31 35L31 5C31 3.89543 31.8954 3 33 3L35 3C36.1046 3 37 3.89543 37 5L37 35Z"
      fill={fill}
    />
  </svg>
);
