import { GeneralModel, ViewModel } from '@cyferd/client-engine';
import { css } from '@emotion/react';

import { COLOR, FONT_SIZE, FONT_WEIGHT, FOREGROUND_COLOR, GAP, HC_COLOR, HC_SECONDARY_COLOR, RADIUS, TRANSITION_SPEED } from '@constants';
import { CTAType, ICTAType } from './types';

interface SizeConfig {
  fontSize: string;
  padding: string;
}

interface ColorConfig {
  text: string;
  background: string;
  border: string;
  disabledText: string;
  disabledBackground: string;
  disabledBorder: string;
}

export const getColorFromStatus = (status: ViewModel.Status): GeneralModel.Color.ThemeColor => {
  return ({
    [ViewModel.Status.NEUTRAL]: 'BRAND_1',
    [ViewModel.Status.INFO]: 'BRAND_1',
    [ViewModel.Status.SUCCESS]: 'GN_2',
    [ViewModel.Status.WARN]: 'OE_3',
    [ViewModel.Status.ERROR]: 'HC_5'
  }[status] || 'BRAND_1') as GeneralModel.Color.ThemeColor;
};

const getSizeConfig = (type: ICTAType, size: ViewModel.CTASize): SizeConfig => {
  return (() => {
    switch (type) {
      case CTAType.PRIMARY:
      case CTAType.SECONDARY:
      case CTAType.TERTIARY:
        return {
          [ViewModel.CTASize.SMALL]: { fontSize: FONT_SIZE.XS, padding: '3.25px 16px' },
          [ViewModel.CTASize.MEDIUM]: { fontSize: FONT_SIZE.S, padding: '9px 35px' },
          [ViewModel.CTASize.LARGE]: { fontSize: FONT_SIZE.M, padding: '14px 40px' },
          [ViewModel.CTASize.XL]: { fontSize: FONT_SIZE.L, padding: '18px 80px' }
        };
      case CTAType.LINK:
        return {
          [ViewModel.CTASize.SMALL]: { fontSize: FONT_SIZE.XS, padding: `0` },
          [ViewModel.CTASize.MEDIUM]: { fontSize: FONT_SIZE.S, padding: `0` },
          [ViewModel.CTASize.LARGE]: { fontSize: FONT_SIZE.M, padding: `0` },
          [ViewModel.CTASize.XL]: { fontSize: FONT_SIZE.XXM, padding: `0` }
        };
      case CTAType.ACTION:
      case CTAType.ACTION_SECONDARY:
      case CTAType.ACTION_TERTIARY:
        return {
          [ViewModel.CTASize.SMALL]: { fontSize: FONT_SIZE.XM, padding: '2px' },
          [ViewModel.CTASize.MEDIUM]: { fontSize: FONT_SIZE.XXM, padding: `5px` },
          [ViewModel.CTASize.LARGE]: { fontSize: FONT_SIZE.XXM, padding: `${GAP.S}` },
          [ViewModel.CTASize.XL]: { fontSize: FONT_SIZE.XL, padding: `${GAP.S}` }
        };
    }
  })()?.[size];
};

const getSharedSizeStyles = (type: ICTAType, size: ViewModel.CTASize) => {
  if (
    ![CTAType.PRIMARY, CTAType.SECONDARY, CTAType.TERTIARY, CTAType.LINK, CTAType.ACTION, CTAType.ACTION_SECONDARY, CTAType.ACTION_TERTIARY].includes(
      type as ViewModel.CTAType
    )
  ) {
    return null;
  }
  const sizeConfig = getSizeConfig(type, size);
  return css`
    font-size: ${sizeConfig?.fontSize};
    padding: ${sizeConfig?.padding};
  `;
};

export const getClassName = (type: ICTAType, status?: ViewModel.Status, manualColor?: GeneralModel.Color.ThemeColor) => {
  const color = manualColor || getColorFromStatus(status);
  const safeColor = COLOR[color] ? color : 'BRAND_1';
  return `${type}_${safeColor}`;
};

export const getIconSize = (type: ICTAType): `${number}em` => {
  switch (type) {
    case CTAType.PRIMARY:
    case CTAType.SECONDARY:
    case CTAType.TERTIARY:
      return '1.2em';
    case CTAType.LINK:
      return '1.5em';
    default:
      return '1em';
  }
};

export const getStyles = (type: ICTAType, size?: ViewModel.CTASize) => {
  return {
    br: css`
      display: block;
    `,
    base: css`
      ${getSharedSizeStyles(type, size)};
      white-space: nowrap;
      text-decoration: none;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: auto;
      max-width: 100%;
      min-width: 0;

      cursor: pointer;

      transition: opacity ${TRANSITION_SPEED};

      border-width: 2px;
      border-style: solid;

      :hover {
        opacity: 0.8;
      }
    `,
    label: css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    seemlessButton: css`
      cursor: pointer;
      background-color: transparent;
    `,
    buttonPrimary: css`
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      border-radius: ${RADIUS.M};
      display: flex;
      gap: ${GAP.XXS};
    `,
    buttonSecondary: css`
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      border-radius: ${RADIUS.M};
      display: flex;
      gap: ${GAP.XXS};
    `,
    buttonTertiary: css`
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      border-radius: ${RADIUS.M};
      display: flex;
      gap: ${GAP.XXS};
    `,
    link: css`
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      border-radius: ${RADIUS.S};
      display: flex;
      gap: ${GAP.XXS};
      border-width: 0;
      div {
        text-decoration: underline;
      }
    `,
    actionButton: css`
      font-weight: ${FONT_WEIGHT.SEMI_BOLD};
      border-radius: 50%;
      min-height: 26px;
      min-width: 26px;

      .cta-spinner-container {
        position: fixed;
      }
    `,
    fullWidth: css`
      width: 100%;
    `,
    positionEnd: css`
      flex-direction: row-reverse;
    `
  };
};

export const COLOR_CONFIGURATIONS: Record<string, ColorConfig> = GeneralModel.Color.colorNameList.reduce(
  (acc, color) => ({
    ...acc,
    [color]: Object.values(CTAType).reduce(
      (typeAcc, ctaType) => ({
        ...typeAcc,
        [ctaType]: {
          text: {
            [CTAType.PRIMARY]: FOREGROUND_COLOR[color],
            [CTAType.SECONDARY]: HC_COLOR[color],
            [CTAType.TERTIARY]: HC_COLOR[color],
            [CTAType.LINK]: HC_COLOR[color],
            [CTAType.ACTION]: color === 'NEUTRAL_4' ? COLOR.BRAND_1 : FOREGROUND_COLOR[color],
            [CTAType.ACTION_SECONDARY]: HC_COLOR[color],
            [CTAType.ACTION_TERTIARY]: HC_COLOR[color]
          }[ctaType],
          background: {
            [CTAType.PRIMARY]: COLOR[color],
            [CTAType.SECONDARY]: COLOR.BASE_BACKGROUND,
            [CTAType.TERTIARY]: HC_SECONDARY_COLOR[color],
            [CTAType.LINK]: COLOR.TRANSPARENT,
            [CTAType.ACTION]: COLOR[color],
            [CTAType.ACTION_SECONDARY]: COLOR.BASE_BACKGROUND,
            [CTAType.ACTION_TERTIARY]: HC_SECONDARY_COLOR[color]
          }[ctaType],
          border: {
            [CTAType.PRIMARY]: COLOR[color],
            [CTAType.SECONDARY]: COLOR[color],
            [CTAType.TERTIARY]: COLOR.TRANSPARENT,
            [CTAType.LINK]: COLOR.TRANSPARENT,
            [CTAType.ACTION]: COLOR.TRANSPARENT,
            [CTAType.ACTION_SECONDARY]: COLOR[color],
            [CTAType.ACTION_TERTIARY]: COLOR.TRANSPARENT
          }[ctaType],
          disabledText: {
            [CTAType.PRIMARY]: COLOR.BASE_BACKGROUND,
            [CTAType.SECONDARY]: COLOR.NEUTRAL_3,
            [CTAType.TERTIARY]: COLOR.NEUTRAL_3,
            [CTAType.LINK]: COLOR.NEUTRAL_3,
            [CTAType.ACTION]: COLOR.BASE_BACKGROUND,
            [CTAType.ACTION_SECONDARY]: COLOR.NEUTRAL_3,
            [CTAType.ACTION_TERTIARY]: COLOR.NEUTRAL_3
          }[ctaType],
          disabledBackground: {
            [CTAType.PRIMARY]: COLOR.NEUTRAL_3,
            [CTAType.SECONDARY]: COLOR.BASE_BACKGROUND,
            [CTAType.TERTIARY]: COLOR.NEUTRAL_9,
            [CTAType.LINK]: COLOR.TRANSPARENT,
            [CTAType.ACTION]: COLOR.NEUTRAL_3,
            [CTAType.ACTION_SECONDARY]: COLOR.NEUTRAL_9,
            [CTAType.ACTION_TERTIARY]: COLOR.NEUTRAL_9
          }[ctaType],
          disabledBorder: {
            [CTAType.PRIMARY]: COLOR.NEUTRAL_3,
            [CTAType.SECONDARY]: COLOR.NEUTRAL_3,
            [CTAType.TERTIARY]: COLOR.TRANSPARENT,
            [CTAType.LINK]: COLOR.TRANSPARENT,
            [CTAType.ACTION]: COLOR.NEUTRAL_3,
            [CTAType.ACTION_SECONDARY]: COLOR.NEUTRAL_3,
            [CTAType.ACTION_TERTIARY]: COLOR.TRANSPARENT
          }[ctaType]
        }
      }),
      {}
    )
  }),
  {}
);

const generateEmotionClass = (config: ColorConfig) => css`
  background-color: ${config.background};
  color: ${config.text};
  border-color: ${config.border};

  &:disabled {
    cursor: not-allowed;
    background-color: ${config.disabledBackground};
    color: ${config.disabledText};
    border-color: ${config.disabledBorder};
  }
`;

export const allEmotionClasses: Record<string, ReturnType<typeof generateEmotionClass>> = Object.entries(COLOR_CONFIGURATIONS).reduce(
  (acc, [color, types]) =>
    Object.entries(types).reduce((total, [type, config]) => ({ ...total, [`${type}_${color}`]: generateEmotionClass(config as any) }), acc),
  {}
);
