import { useCallback, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';

import { ApiModel, CollectionModel, normalize } from '@cyferd/client-engine';

import { BUILDER_DESCRIPTION, BUILDER_ROUTE, ENV, isSavedRecord, QUERY_PARAM, SECTION_NAME, TRANS } from '@constants';
import { getNewEntity } from '@models/entity';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { useQueryParamState } from '@utils/useQueryParamState';
import { EditorHome, useEditorHomeEffect, useEditorHomeOnRemove, useEditorHomeOnSave } from '../shared/EditorHome';
import { AppBondEditor } from './components/AppBondEditor';
import { CustomViewEditor } from './components/CustomViewEditor';
import { DefaultSettings } from './components/DefaultSettings';
import { DefinitionEditor } from './components/DefinitionEditor';
import { FilterEditor } from './components/FilterEditor';
import { GeneralInfo } from './components/GeneralInfo';
import { GeneralList } from './components/GeneralList';
import { TriggerEditor } from './components/TriggerEditor';
import { DOC_ID, useOptionListForDocs } from '@components/elements/Docs/resources';
import { CollectionChangesSidebar } from '@components/elements/CollectionChanges';

export const EntityEditor = () => {
  const { pathname } = useLocation();
  const [queryParamState] = useQueryParamState();
  const itemId = queryParamState[QUERY_PARAM.RECORD_ID];
  const [original, internalSetOriginal] = useState<CollectionModel.Collection>();
  const setOriginal = useCallback((entity: CollectionModel.Collection) => internalSetOriginal(normalize.collection(entity)), []);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const onToggleSidebar = useCallback(() => setSidebarOpen(p => !p), []);
  const onCloseSidebar = useCallback(() => setSidebarOpen(false), []);

  const isNew = !isSavedRecord(original);
  const docIds = useMemo(
    () => [DOC_ID.COLLECTIONS_OVEVIEW, DOC_ID.COLLECTIONS_FIELDS, DOC_ID.COLLECTIONS_DEFAULTS, DOC_ID.COLLECTIONS_FILTERS, DOC_ID.COLLECTIONS_LINKED],
    []
  );
  const optionListForDocs = useOptionListForDocs(docIds);

  const tabList = useMemo(
    () =>
      [
        { route: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.LIST, label: null },
        { route: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.GENERAL, label: TRANS.client.nav.builder.tabs.details },
        { route: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.SCHEMA, label: TRANS.client.nav.builder.tabs.fields },
        !isNew && { route: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.SETTINGS, label: TRANS.client.nav.builder.tabs.settings },
        !isNew && { route: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.FILTERS, label: TRANS.client.nav.builder.tabs.filters },
        !isNew && { route: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.VIEW, label: TRANS.client.nav.builder.tabs.coreViewConfig },
        !isNew && { route: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.BOND, label: TRANS.client.nav.builder.tabs.linkedApps },
        !isNew && { route: BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.TRIGGER, label: TRANS.client.nav.builder.tabs.linkedFlows }
      ].filter(Boolean),
    [isNew]
  );

  const onSave = useEditorHomeOnSave(ApiModel.ApiEntity.ENTITY);
  const onRemove = useEditorHomeOnRemove(ApiModel.ApiEntity.ENTITY);

  const openExternalUrl = useOnOpenExternalUrl(`${ENV.CLIENT_APP_URL}/TABLE?collectionId=${original?.id}`);

  useEditorHomeEffect({ itemId, collectionId: ApiModel.ApiEntity.ENTITY, setOriginal, newItemGetter: getNewEntity, setLoading });

  return (
    <EditorHome
      editorTitle={SECTION_NAME.DATA}
      title={original?.name || TRANS.client.nav.builder.newNames.data}
      icon={original?.recordImage}
      color={original?.recordColor}
      item={original}
      subtitle={BUILDER_DESCRIPTION.DATA}
      isLoading={isLoading}
      /** header */
      routeList={tabList}
      rootPath={BUILDER_ROUTE.ENTITY_EDITOR.ROOT}
      isCurrentDraft={isNew}
      hideSubHeader={['/', BUILDER_ROUTE.ENTITY_EDITOR.ROOT, `${BUILDER_ROUTE.ENTITY_EDITOR.ROOT}${BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.LIST}`].includes(
        pathname
      )}
      onSave={onSave}
      onRemove={onRemove}
      onSetOriginalValue={setOriginal}
      additionalButtonList={!isNew && [{ icon: 'zoom_in', label: TRANS.client.buttons.open, onClick: openExternalUrl as any }]}
      tabOptionMenuProps={{ optionList: [{ image: 'family_history', tooltip: 'Changes', important: true, outlined: true, onClick: onToggleSidebar }] }}
      tabsOptionMenuProps={optionListForDocs}
      showCleanEntity={true}
      activityLogConfig={{ collectionId: ApiModel.ApiEntity.ENTITY, recordId: !isNew && itemId }}
    >
      {!!itemId && <CollectionChangesSidebar open={sidebarOpen} onClose={onCloseSidebar} original={original} />}
      <Routes>
        <Route path={BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.LIST} element={<GeneralList />} />
        <Route path={BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.BOND} element={<AppBondEditor />} />
        <Route path={BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.VIEW} element={<CustomViewEditor />} />
        <Route path={BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.TRIGGER} element={<TriggerEditor />} />
        <Route path={BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.SCHEMA} element={<DefinitionEditor />} />
        <Route path={BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.GENERAL} element={<GeneralInfo />} />
        <Route path={BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.SETTINGS} element={<DefaultSettings />} />
        <Route path={BUILDER_ROUTE.ENTITY_EDITOR.CHILDREN.FILTERS} element={<FilterEditor />} />
        <Route path="*" element={<GeneralList />} />
      </Routes>
    </EditorHome>
  );
};
