import { SvgProps } from '../types';

export const Layout_J = ({ size = 20, fill = 'var(--NEUTRAL_2)', ...props }: SvgProps) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="39.5" y="39.5" width="39" height="39" rx="3.5" transform="rotate(-180 39.5 39.5)" stroke={fill} />
    <path
      d="M3 5C3 3.89543 3.89543 3 5 3L8 3C9.10457 3 10 3.89543 10 5L10 35C9.99999 36.1046 9.10456 37 7.99999 37L4.99999 37C3.89543 37 2.99999 36.1046 3 35L3 5Z"
      fill={fill}
    />
    <path
      d="M12 5C12 3.89543 12.8954 3 14 3L17 3C18.1046 3 19 3.89543 19 5L19 35C19 36.1046 18.1046 37 17 37L14 37C12.8954 37 12 36.1046 12 35L12 5Z"
      fill={fill}
    />
    <path
      d="M21 5C21 3.89543 21.8954 3 23 3L26 3C27.1046 3 28 3.89543 28 5L28 35C28 36.1046 27.1046 37 26 37L23 37C21.8954 37 21 36.1046 21 35L21 5Z"
      fill={fill}
    />
    <path
      d="M30 5C30 3.89543 30.8954 3 32 3L35 3C36.1046 3 37 3.89543 37 5L37 35C37 36.1046 36.1046 37 35 37L32 37C30.8954 37 30 36.1046 30 35L30 5Z"
      fill={fill}
    />
  </svg>
);
