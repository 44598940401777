import { Modal } from '@components/elements/Modal';
import { OptionMenu } from '@components/elements/OptionMenu';
import { useState } from 'react';
import { CTAType } from '@components/elements/CTA';
import { GeneralModel, ViewModel } from '@cyferd/client-engine';
import { BaseForm } from '../../../smart/CyForm/components/BaseForm';
import { TRANS } from '@constants';
import { conditionalDotReplacement } from '../resources';

const schema: GeneralModel.JSONSchema = {
  type: 'string'
};

export interface EvaluatorKeyChangeModalProps {
  path: string;
  onApply: (event: string) => void;
  onCancel: () => void;
}

export const EvaluatorKeyChangeModal = ({ path, onApply, onCancel }: EvaluatorKeyChangeModalProps) => {
  const [value, setValue] = useState<string>(path?.substring?.(path?.lastIndexOf('.') + 1, path.length));
  return (
    <Modal
      open={true}
      icon="key"
      title="Enter key name"
      description="Warning: Applying an existing name will overwrite its content"
      type={ViewModel.ModalType.COMPACT}
      footer={
        <OptionMenu
          optionList={[
            {
              important: true,
              testid: 'cancel',
              type: CTAType.SECONDARY,
              label: TRANS.client.buttons.cancel,
              onClick: onCancel
            },
            {
              important: true,
              testid: 'apply-key-change',
              type: CTAType.PRIMARY,
              label: TRANS.client.buttons.apply,
              disabled: !value,
              onClick: () => onApply(conditionalDotReplacement(value, 'escape'))
            }
          ]}
        />
      }
    >
      <BaseForm avoidInitialSync={true} value={conditionalDotReplacement(value, 'unescape')} schema={schema} onChange={setValue} />
    </Modal>
  );
};

EvaluatorKeyChangeModal.displayName = 'EvaluatorKeyChangeModal';
