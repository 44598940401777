import { memorize, ViewModel } from '@cyferd/client-engine';
import { getComponentConfig } from '@models/view';

export const availableComponentList = Object.values(ViewModel.DisplayName).filter(displayName => getComponentConfig(displayName).isAvailable);

export const getComponentChildList = memorize((displayName: ViewModel.DisplayName): ViewModel.DisplayName[] => {
  return getComponentConfig(displayName)
    .allowedChildren.filter(n => availableComponentList.includes(n))
    .sort((displayNameA, displayNameB) => displayNameA.localeCompare(displayNameB));
});
