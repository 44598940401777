import { pickBy, identity } from 'lodash';

export const getSocket = (url: string, headers: any): WebSocket => {
  const { Authorization = '', ...rest } = headers || {};

  const params = new URLSearchParams(
    pickBy(
      {
        access_token: Authorization.split?.(' ')?.[1],
        ...rest
      },
      identity
    )
  ).toString();

  return new WebSocket(`${url}?${params}`);
};
