import { memo, useCallback, useMemo, useState } from 'react';

import { getClassnames, Translate, useTranslate, ViewModel } from '@cyferd/client-engine';

import { getComponentChildList } from '@utils/getComponentChildList';
import { ComponentAdder } from '../../../shared/ComponentAdder';
import { NodeTooltip } from '../../../shared/NodeCard/NodeTooltip';
import { getComponentIcon } from '../LayoutEditor/getComponentIcon';
import { getFlatNodeList } from '../LayoutEditor/getFlatNodeList';
import { getNodeStyles, styles } from './styles';
import { componentConfig, getComponentConfig } from '@models/view';
import { COLOR, FONT_SIZE, TRANS } from '@constants';
import { Modal } from '@components/elements/Modal';
import { OptionMenu } from '@components/elements/OptionMenu';
import { CTA, CTAType } from '@components/elements/CTA';
import { ToolTip } from '@components/elements/Tooltip';
import { PreventClickPropagation } from '@components/elements/PreventClickPropagation';
import { Icon } from '@components/elements/Icon';

export interface ViewTreeProps {
  activeNodeId: string;
  highlightedId: string;
  search: string;
  flatNodeList: ReturnType<typeof getFlatNodeList>;
  onToggleActiveNodeId: (id: string) => void;
  onAdd: (path: string, newNode: ViewModel.Node) => void;
  onRemove: (path: string) => void;
}

export const ViewTree = memo(({ flatNodeList, activeNodeId, highlightedId, search, onToggleActiveNodeId, onAdd, onRemove }: ViewTreeProps) => {
  const { translate } = useTranslate();
  const [collapsedList, setCollapsedList] = useState<string[]>([]);
  const [nodeConfigToAdd, setNodeConfigToAdd] = useState<ReturnType<typeof getFlatNodeList>[0]>(null);

  const eligibleChildList = useMemo(() => getComponentChildList(nodeConfigToAdd?.node?.component), [nodeConfigToAdd?.node?.component]);
  const nodeConfig = useMemo(() => getComponentConfig(nodeConfigToAdd?.node?.component), [nodeConfigToAdd?.node?.component]);

  const { modals, contains, header, globalHeader } = flatNodeList.reduce(
    (total, curr) => {
      const key = curr.path[0];
      return { ...total, [key]: [...(total[key] || []), curr] };
    },
    {} as Record<string, ReturnType<typeof getFlatNodeList>>
  );

  const onToggleCollapse = (id: string) => setCollapsedList(prev => (prev.includes(id) ? prev.filter(p => p !== id) : [...prev, id]));

  const onClearNodeConfigToAdd = useCallback(() => setNodeConfigToAdd(null), []);

  const onInternalAdd = useCallback(
    (event: ViewModel.Node) => {
      onAdd(nodeConfigToAdd?.path?.join('.'), event);
      onClearNodeConfigToAdd();
    },
    [onAdd, nodeConfigToAdd?.path, onClearNodeConfigToAdd]
  );

  return (
    <>
      {!!nodeConfigToAdd && (
        <Modal
          type={ViewModel.ModalType.FULL_SCREEN}
          open={true}
          icon={nodeConfig.icon}
          title={`Add to ${getComponentConfig(nodeConfigToAdd?.node?.component)?.label?.toLowerCase()}`}
          description={`Select the component to add to ${nodeConfigToAdd?.node?.name}`}
          onClose={onClearNodeConfigToAdd}
        >
          <ComponentAdder eligibleChildList={eligibleChildList} onSelect={onInternalAdd} />
        </Modal>
      )}
      <div className={styles.listContainer}>
        {[
          { title: componentConfig.globalHeader.label, rootPath: 'globalHeader', list: globalHeader, showTitle: false },
          { title: componentConfig.viewHeader.label, rootPath: 'header', list: header, showTitle: false },
          !!modals?.length && { title: TRANS.client.nav.builder.tabs.modals, rootPath: 'modals', list: modals, showTitle: true },
          !!contains?.length && { title: TRANS.client.nav.builder.tabs.mainView, rootPath: 'contains', list: contains, showTitle: true }
        ]
          .filter(Boolean)
          .map(({ title, rootPath, list, showTitle }) => (
            <div key={rootPath}>
              {!!showTitle && (
                <div className={getClassnames(styles.infoContainer, styles.listTitle)} onClick={() => onToggleCollapse(rootPath)}>
                  <CTA
                    type={CTAType.LINK}
                    testid="toggle-group-collapse"
                    icon={collapsedList.includes(rootPath) ? 'chevron_right' : 'expand_more'}
                    color="NEUTRAL_2"
                  />

                  <h3 className={styles.listTitleText}>
                    <Translate>{title}</Translate>
                  </h3>
                </div>
              )}
              <div className={styles.nodeContainer}>
                {list
                  .filter(
                    ({ path, node }) =>
                      (!search ||
                        [node.name, translate(getComponentConfig(node.component).label)]
                          .filter(Boolean)
                          .some(term => term.toLowerCase().includes(search.toLowerCase()))) &&
                      !collapsedList.some(id => id !== path.join('.') && path.join('.').startsWith(id))
                  )
                  .map(({ path, node }) => {
                    const flatPath = path.join('.');
                    const componentConfig = getComponentConfig(node.component);
                    const nestedLevel = Math.max(0, path.filter(chunk => !/^[0-9]$/.test(chunk)).length - 1);
                    const isActive = node.id === activeNodeId;
                    const isHighlighted = node.id === highlightedId;
                    const onToggle = () => onToggleActiveNodeId(node.id);
                    const nodeStyles = getNodeStyles({ isActive, isHighlighted, nestedLevel, displayName: node.component, componentConfig });
                    const isHidden = !!node.attrs?.hidden;
                    const isCollapsed = collapsedList.includes(flatPath);
                    const isHeader = ['header', 'globalHeader'].includes(flatPath);
                    const icon = getComponentIcon(node);
                    return (
                      <ToolTip key={`${flatPath}-${node.id}`} text={<NodeTooltip node={node} />}>
                        <div data-testid="sidebar-item" id={`sidebar-item-${node.id}`} className={nodeStyles.node} onClick={onToggle}>
                          <div className={styles.infoContainer}>
                            {!isHeader && (
                              <div className={getClassnames(!node.contains?.length && nodeStyles.invisible)}>
                                <PreventClickPropagation>
                                  <CTA
                                    type={CTAType.LINK}
                                    testid={!!node.contains?.length && 'toggle-collapse'}
                                    icon={isCollapsed ? 'chevron_right' : 'expand_more'}
                                    color="NEUTRAL_2"
                                    onClick={() => onToggleCollapse(flatPath)}
                                  />
                                </PreventClickPropagation>
                              </div>
                            )}

                            {isHidden && <Icon testid="hidden-indicator" name="visibility_off" size={FONT_SIZE.S} fill={COLOR.NEUTRAL_2} />}
                            <Icon name={icon} size={FONT_SIZE.S} fill={nodeStyles.iconFill} />
                            <span className={nodeStyles.title}>
                              <Translate>{node.name}</Translate>
                            </span>
                          </div>
                          <div>
                            <PreventClickPropagation>
                              <OptionMenu
                                defaultBtnType={ViewModel.CTAType.LINK}
                                defaultBtnTestid="view-tree-actions"
                                defaultBtnColor="NEUTRAL_2"
                                defaultBtnSize={ViewModel.CTASize.MEDIUM}
                                optionList={[
                                  !!getComponentChildList(node?.component).length && {
                                    testid: 'add-btn',
                                    label: 'Add component',
                                    image: 'add',
                                    onClick: () => setNodeConfigToAdd({ path, node })
                                  },
                                  node?.component !== ViewModel.DisplayName.VIEW_HEADER &&
                                    node?.component !== ViewModel.DisplayName.GLOBAL_HEADER && {
                                      label: TRANS.client.buttons.copyName,
                                      testid: 'copy-name-btn',
                                      image: 'content_copy',
                                      type: CTAType.ACTION_SECONDARY,
                                      onClick: () => navigator.clipboard.writeText(node?.name)
                                    },
                                  node?.component !== ViewModel.DisplayName.VIEW_HEADER &&
                                    node?.component !== ViewModel.DisplayName.GLOBAL_HEADER && {
                                      testid: 'remove-btn',
                                      image: 'delete',
                                      label: TRANS.client.buttons.delete,
                                      color: 'RD_4',
                                      onClick: () => onRemove(flatPath)
                                    }
                                ]}
                              />
                            </PreventClickPropagation>
                          </div>
                        </div>
                      </ToolTip>
                    );
                  })}
              </div>
            </div>
          ))}
      </div>
    </>
  );
});
