// istanbul ignore file
import { TRANS } from '@constants';
import { ApiModel, ViewModel, createUUID } from '@cyferd/client-engine';
import useCyActions from '@utils/useCyActions';
import { useCallback, useMemo } from 'react';
import { IUseHeaderActionsParams } from '../../../types';
import { useEvaluate, useOnActionClick } from '../../../hooks';

const testId = 'cyTableActions';

export const useHeaderActions = ({
  isLoading,
  value,
  header: { hideCreateNew, additionalListActions = [], hideListActions } = {}
}: IUseHeaderActionsParams) => {
  const refreshCompoId = useMemo(createUUID, []);
  const { onDispatchFormModal } = useCyActions();
  const evaluate = useEvaluate();
  const { onActionClick } = useOnActionClick();

  const collectionId = useMemo(() => value?.query?.cursor?.collectionId, [value?.query?.cursor?.collectionId]);

  const onCreateNew = useCallback(
    () =>
      onDispatchFormModal({
        type: ApiModel.ApiEntity.ENTITY,
        title: TRANS.client.buttons.createNew,
        formType: ViewModel.CyFormType.STEPPER, // should change STEPPER to be more similar to TABS
        collectionId: collectionId,
        reusableActionOnSuccess: refreshCompoId
      }),
    [onDispatchFormModal, refreshCompoId, collectionId]
  );

  const allListActions = useMemo(() => {
    const list = [
      ...(value?.query?.listActions || []).map(({ $r: { input: flowInput, ...each }, id }) => ({
        ...each,
        flowInput,
        id
      })),
      ...additionalListActions
    ].map(action => {
      const { helperText, icon, disabled, ...each } = evaluate(action, value);

      return {
        type: ViewModel.CTAType.TERTIARY,
        ...each,
        onClick: (_, event) => onActionClick(action, { ...value, event: { item: value, meta: { metaKey: event?.metaKey } } }),
        image: icon,
        tooltip: helperText,
        size: ViewModel.CTASize.SMALL,
        disabled: isLoading || !!disabled,
        testid: `${testId}-header-action-btn-${each.label}`
      };
    });

    if (hideCreateNew || !collectionId) {
      return list;
    }

    return [
      ...list,
      {
        type: ViewModel.CTAType.PRIMARY,
        size: ViewModel.CTASize.SMALL,
        disabled: isLoading,
        label: TRANS.client.buttons.createNew,
        image: 'add',
        important: true,
        onClick: onCreateNew,
        testid: `${testId}-header-action-btn-create-new`
      }
    ];
  }, [hideCreateNew, isLoading, evaluate, onActionClick, onCreateNew, value, collectionId, additionalListActions]);

  return hideListActions ? [] : allListActions;
};
