import { memo, useEffect, useMemo, useState } from 'react';

import { createUUID, isDeepEqual, noop, normalize, ViewModel } from '@cyferd/client-engine';

import { actions as uiActions } from '../../../../client-app/state-mgmt/ui/actions';
import { triggerDownload } from '@utils/triggerDownload';
import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { ToastStatus } from '@components/elements/Toast';
import { Modal } from '@components/elements/Modal';
import { Layout } from '@components/elements/Layout';
import { JSONSyntaxEditor } from '@components/elements/JSONSyntaxEditor';
import { CTAType } from '@components/elements/CTA';
import { OptionMenu, OptionMenuProps } from '@components/elements/OptionMenu';
import { TRANS } from '@constants';

export interface DevViewProps {
  title: string;
  value: any;
  open: boolean;
  showCleanEntity?: boolean;
  showCleanView?: boolean;
  subtitle?: string;
  onChange: (json: any) => void;
  onClose: () => void;
}

export const DevView = memo(({ onChange, value, title, open, showCleanEntity, showCleanView, subtitle, onClose }: DevViewProps) => {
  const [state, setState] = useState<any>(value);
  const dispatch = useDispatch();

  const hasChanges = useMemo(() => !!open && !isDeepEqual(value, state, true), [state, value, open]);

  const onInternalChange = () => {
    onChange(state);
    onClose();
  };

  const onCleanView = () => {
    setState(normalize.view(state));
  };

  const onCleanEntity = () => {
    setState(normalize.collection(state));
  };

  const onCopyToClipboard = (item: any) => {
    navigator.clipboard.writeText(JSON.stringify(item, null, 4));
    dispatch(uiActions.addToast({ id: createUUID(), status: ToastStatus.SUCCESS, title: `Copied` }));
  };

  const onDownload = () => {
    triggerDownload(`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(state, null, 4))}`, `${title}.json`);
  };

  useEffect(() => {
    setState(value);
  }, [value, open]);

  return (
    <Modal title={title} description={subtitle} open={open} type={ViewModel.ModalType.FULL_SCREEN} onClose={onClose}>
      <div data-testid="outcome-panel-container">
        <Layout type={ViewModel.LayoutType.FULL}>
          <Layout type={ViewModel.LayoutType.TWO_ALT_2}>
            <JSONSyntaxEditor
              expanded={true}
              avoidExpandOption={true}
              height="calc(100vh - 310px)"
              label="Original"
              onChange={noop}
              value={value}
              disabled={true}
            />
            <JSONSyntaxEditor expanded={true} avoidExpandOption={true} height="calc(100vh - 310px)" label="Changes" onChange={setState} value={state} />
          </Layout>
          <footer className={styles.footer}>
            <OptionMenu
              optionList={
                [
                  {
                    important: true,
                    testid: 'output-panel-cancel',
                    type: CTAType.SECONDARY,
                    label: TRANS.client.buttons.cancel,
                    onClick: onClose
                  },
                  !!showCleanView && {
                    important: true,
                    testid: 'output-panel-clean-view',
                    type: CTAType.SECONDARY,
                    label: 'Normalize view',
                    onClick: onCleanView
                  },

                  !!showCleanEntity && {
                    important: true,
                    testid: 'output-panel-clean-entity',
                    type: CTAType.SECONDARY,
                    label: 'Normalize collection',
                    onClick: onCleanEntity
                  },
                  {
                    important: true,
                    testid: 'output-panel-download',
                    type: CTAType.SECONDARY,
                    label: 'Download',
                    image: 'cloud_download',
                    onClick: onDownload
                  },
                  {
                    important: true,
                    testid: 'output-panel-copy',
                    type: CTAType.SECONDARY,
                    label: TRANS.client.buttons.copy,
                    image: 'content_copy',
                    onClick: () => onCopyToClipboard(state)
                  },
                  {
                    important: true,
                    testid: 'output-panel-apply',
                    type: CTAType.PRIMARY,
                    label: TRANS.client.buttons.apply,
                    disabled: !hasChanges,
                    onClick: onInternalChange
                  }
                ].filter(Boolean) as OptionMenuProps['optionList']
              }
            />
          </footer>
        </Layout>
      </div>
    </Modal>
  );
});
