/* istanbul ignore file */

import { memo, ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BUILDER_ROUTE } from '@constants';
import { useCanAccessRoute, cyWrapperContextValue } from '@utils';
import { DeviceManager } from './views/DeviceManager';
import { EntityEditor } from './views/EntityEditor';
import { FlowEditor } from './views/FlowEditor';
import { IntegrationEditor } from './views/IntegrationEditor';
import { InvitationEditor } from './views/InvitationEditor';
import { PlatformSettings } from './views/PlatformSettings';
import { TagEditor } from './views/TagEditor';
import { UserEditor } from './views/UserEditor';
import { ViewEditor } from './views/ViewEditor';
import { Guide } from './views/Guide';
import { CyWrapperContext } from '@components/smart/CyWrapper/CyWrapper';

const routesConfig: { path: string; element: ReactNode }[] = [
  { path: BUILDER_ROUTE.TAG_EDITOR.ROOT, element: <TagEditor /> },
  { path: BUILDER_ROUTE.ENTITY_EDITOR.ROOT, element: <EntityEditor /> },
  { path: BUILDER_ROUTE.VIEW_EDITOR.ROOT, element: <ViewEditor /> },
  { path: BUILDER_ROUTE.FLOW_EDITOR.ROOT, element: <FlowEditor /> },
  { path: BUILDER_ROUTE.USER_EDITOR.ROOT, element: <UserEditor /> },
  { path: BUILDER_ROUTE.INVITATION_EDITOR.ROOT, element: <InvitationEditor /> },
  { path: BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT, element: <IntegrationEditor /> },
  { path: BUILDER_ROUTE.DEVICE_MANAGER.ROOT, element: <DeviceManager /> },
  { path: BUILDER_ROUTE.PLATFORM_SETTINGS.ROOT, element: <PlatformSettings /> },
  { path: BUILDER_ROUTE.GUIDE.ROOT, element: <Guide /> }
];

export const BuilderApp = memo(() => {
  const canAccess = useCanAccessRoute();
  const accesibleRoutes = routesConfig.filter(route => canAccess(route.path));

  return (
    <CyWrapperContext.Provider value={cyWrapperContextValue}>
      <Routes>
        {accesibleRoutes.map(route => (
          <Route key={route.path} path={`${route.path.replace(/^\/builder/, '/')}/*`} element={route.element} />
        ))}
        <Route path="*" element={<Navigate replace={true} to={accesibleRoutes[0]?.path || '/'} />} />
      </Routes>
    </CyWrapperContext.Provider>
  );
});
