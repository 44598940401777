import { GAP } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  `,

  content: css`
    max-width: 1200px;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 400px), 1fr));
    padding: ${GAP.S};
  `,
  infoContainer: css`
    display: flex;
    justify-content: center;
  `,
  imageContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  bottomContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: ${GAP.L};
  `,
  poweredByContainer: css`
    display: flex;
    align-items: center;
  `,
  title: css`
    font-size: 28px;
    font-weight: bold;
    margin: 1em 0;
  `,
  subtitle: css`
    font-size: 18px;
    margin: 1em 0;
  `
};
