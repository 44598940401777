import { ToastProps } from '@components/elements/Toast';
import { NotificationModel } from '@cyferd/client-engine';

export enum ACTION_TYPE {
  ADD_TOAST = '[ui] add toast',
  REMOVE_TOAST = '[ui] remove toast',
  ADD_MESSAGE = '[ui] add message',
  REMOVE_MESSAGE = '[ui] remove message',
  SET_LOADING_LIST = '[ui] set loading list',
  ADD_LOADING = '[ui] add to loading list',
  REMOVE_LOADING = '[ui] remove from loading list'
}

export const actions = {
  addToast: (toast: Partial<ToastProps>, content?: any) => ({ type: ACTION_TYPE.ADD_TOAST, payload: { toast, content } }),
  removeToast: (id: string) => ({ type: ACTION_TYPE.REMOVE_TOAST, payload: { id } }),
  addMessage: (message: Partial<NotificationModel.Notification>) => ({ type: ACTION_TYPE.ADD_MESSAGE, payload: message }),
  removeMessage: (id: string) => ({ type: ACTION_TYPE.REMOVE_MESSAGE, payload: { id } }),
  setLoading: (list: string[]) => ({ type: ACTION_TYPE.SET_LOADING_LIST, payload: { list } }),
  addLoading: (id: string) => ({ type: ACTION_TYPE.ADD_LOADING, payload: { id } }),
  removeLoading: (id: string) => ({ type: ACTION_TYPE.REMOVE_LOADING, payload: { id } })
};
