import { memo, useCallback, useMemo } from 'react';

import { ErrorBoundary, GeneralModel, ViewModel, createUUID, mergeTruthy, removeKeyList } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { SchemaForm } from '../../../SchemaForm';
import { baseConfigSchema, creatorDetailGroupList, creatorDetailGroupMap, getFieldConfig, templateMap } from '../../schemas';
import { PropertyBasicForm } from '../PropertyBasicForm';
import { FormulaInputRow } from '@components/elements/Evaluator/resources';
import { Fieldset, FieldsetContent } from '@components/elements/Fieldset';
import { Checkbox } from '@components/elements/Checkbox';
import { EvaluatorInputContext } from '@components/elements/Evaluator/EvaluatorInput/EvaluatorInputContext';
import { TRANS } from '@constants';
import { styles } from '../../styles';

export const METADATA_PREFIX = createUUID();

const spreadMeta = (obj: object) => Object.fromEntries(Object.entries(obj || {}).map(([k, v]) => [`${METADATA_PREFIX}${k}`, v]));
const nestMeta = (obj: object) => {
  const { regular, meta } = Object.entries(obj).reduce(
    (total, [k, v]) => {
      const outputKey = k.startsWith(METADATA_PREFIX) ? 'meta' : 'regular';
      return { ...total, [outputKey]: [...total[outputKey], [k.replace(METADATA_PREFIX, ''), v]] };
    },
    { regular: [], meta: [] }
  );
  return { ...Object.fromEntries(regular), metadata: Object.fromEntries(meta) };
};

const typeOrderMap = ['string', 'number', 'any', 'object', 'null', 'boolean', 'array'].reduce((t, c, i) => ({ ...t, [c]: String(i) }), {});

const addToDetailOrder = (props: GeneralModel.JSONSchema['properties'], add: number) =>
  Object.fromEntries(
    Object.entries(props)
      .sort(([_, aProp], [_2, bProp]) => {
        if (!!aProp.type && !!bProp.type && aProp.type !== bProp.type) return typeOrderMap[aProp.type].localeCompare(typeOrderMap[bProp.type]);
        if (!!aProp.format && !!bProp.format && aProp.format !== bProp.format) return aProp.format.localeCompare(bProp.format);
        return 0;
      })
      .map(([k, v], i) => [
        k,
        {
          ...v,
          metadata: { ...v.metadata, detailOrder: (v.metadata?.detailOrder || i) + add /** makes sure meta fields go last when spreading them */ }
        }
      ])
  );

export interface SchemaAdvancedFormProps {
  payload: GeneralModel.JSONSchema;
  required: boolean;
  parentType: GeneralModel.JSONSchema['type'];
  parentFormat: GeneralModel.JSONSchema['format'];
  isTopLevel: boolean;
  inputList: FormulaInputRow[];
  allowApiFormats: boolean;
  onChange: (value: GeneralModel.JSONSchema) => void;
  onChangeRequired: (value: boolean) => void;
}

export const SchemaAdvancedForm = memo(
  ({ payload, required, parentType, isTopLevel, inputList, parentFormat, allowApiFormats, onChangeRequired, onChange }: SchemaAdvancedFormProps) => {
    const { getTestIdProps } = useTestingHelper('schema-creator-advanced');
    const topLevelSchema: GeneralModel.JSONSchema = useMemo(
      () => ({
        type: 'object',
        properties: {
          metadata: {
            ...getFieldConfig('metadata'),
            properties: {
              globalSearchable: {
                type: 'boolean',
                label: TRANS.client.fields.titles.globalSearch,
                format: GeneralModel.JSONSchemaFormat.CHECKBOX,
                metadata: {
                  hidden: !allowApiFormats || [GeneralModel.JSONSchemaFormat.RATING].includes(payload.format) || !['string', 'number'].includes(payload.type),
                  detailGroupId: creatorDetailGroupMap.general.id
                }
              }
            }
          }
        }
      }),
      [allowApiFormats, payload.format, payload.type]
    );

    /** spreads metadata fields along with regular ones to display the form in a custom way */
    const completeSchema: GeneralModel.JSONSchema = useMemo(() => {
      const baseSchema = mergeTruthy(baseConfigSchema, templateMap[payload.format] || {});
      const additionalMetadataSchema = !!isTopLevel ? topLevelSchema : {};
      const merged = mergeTruthy(additionalMetadataSchema, baseSchema) as GeneralModel.JSONSchema;

      return {
        ...merged,
        properties: {
          ...addToDetailOrder(merged.properties, 0),
          metadata: undefined,
          ...addToDetailOrder(spreadMeta(merged.properties.metadata.properties), 1000)
        }
      };
    }, [isTopLevel, payload, topLevelSchema]);

    const completeValue = useMemo(() => ({ ...payload, ...spreadMeta(payload.metadata) }), [payload]);

    const onInternalChange = useCallback(
      (schema: GeneralModel.JSONSchema) => {
        const metadata = removeKeyList(schema.metadata, [!schema.metadata?.optionList?.length && 'optionList'].filter(Boolean));
        onChange(removeKeyList({ ...schema, type: GeneralModel.formatToTypeMap[schema.format], metadata }, [!schema.enum?.length && 'enum'].filter(Boolean)));
      },
      [onChange]
    );

    const onInternalMetadataChange = useCallback((event: GeneralModel.JSONSchema) => onChange(nestMeta(event)), [onChange]);

    return (
      <EvaluatorInputContext.Provider value={{ titlePrefix: payload?.title }}>
        <div {...getTestIdProps('container')}>
          <FieldsetContent maxColumns={1}>
            <Fieldset isDetailGroup={true} title={payload?.title} maxColumns={1}>
              <ErrorBoundary>
                <PropertyBasicForm onChange={onInternalChange} value={payload} inputList={inputList} parentFormat={parentFormat} />
              </ErrorBoundary>
              {parentType !== 'array' &&
                ![GeneralModel.JSONSchemaFormat.ASSOCIATION, GeneralModel.JSONSchemaFormat.INFO_BLOCK, GeneralModel.JSONSchemaFormat.OBJECT].includes(
                  payload.format
                ) && (
                  <Checkbox
                    id="schema-creator-advanced-required-checkbox"
                    name="schema-creator-advanced-required-checkbox"
                    testid="required"
                    label={TRANS.client.schemaEditor.fields.titles.required}
                    value={!!required}
                    onChange={onChangeRequired}
                  />
                )}
            </Fieldset>
            <div css={styles.advFormContent}>
              <SchemaForm
                type={ViewModel.CyFormType.TABS}
                autofocusDisabled={true}
                onChange={onInternalMetadataChange}
                schema={completeSchema}
                value={completeValue}
                shouldValidate={true}
                detailGroupList={creatorDetailGroupList}
                inputList={inputList}
                wrapDetailGroups={true}
                maxColumns={2}
              />
            </div>
          </FieldsetContent>
        </div>
      </EvaluatorInputContext.Provider>
    );
  }
);
