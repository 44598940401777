import { useCallback, useContext, useMemo } from 'react';

import { CollectionModel } from '@cyferd/client-engine';
import { useTestingHelper } from '@utils';

import { QUERY_PARAM } from '@constants';
import { useQueryParamState } from '@utils/useQueryParamState';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { SchemaEditor } from '../../../shared/SchemaEditor';
import { styles } from './styles';
import { EmptyState } from '@components/elements/EmptyState';
import { FormulaTesterContainerContext } from '@components/elements/Evaluator/FormulaTester';
import { CyWrapperContext } from '@components/smart/CyWrapper';

export const DefinitionEditor = () => {
  const { getTestIdProps } = useTestingHelper('definition');
  const { item, setItem } = useContext<EditorContextValue<CollectionModel.Collection>>(EditorContext);
  const { useParsers } = useContext(CyWrapperContext);
  const { parseRecord } = useParsers();

  const [queryParamState, setQueryParamState] = useQueryParamState();
  const activePropertyId = queryParamState[QUERY_PARAM.ACTIVE_PROPERTY_ID];

  const evaluatorTesterPayload = useMemo(() => {
    try {
      const record = parseRecord({ value: item?.examples?.[0], entity: item as any, addDefaults: true })?.output;
      return { payload: { item: record, record } };
    } catch (error) /* istanbul ignore next */ {
      return undefined;
    }
  }, [item, parseRecord]);

  const setActivePropertyId = useCallback(
    (id: string) => setQueryParamState(prev => ({ ...prev, [QUERY_PARAM.ACTIVE_PROPERTY_ID]: id })),
    [setQueryParamState]
  );

  if (!item) return <EmptyState />;

  return (
    <FormulaTesterContainerContext.Provider value={evaluatorTesterPayload}>
      <div {...getTestIdProps('container')} className={styles.container}>
        <SchemaEditor
          allowApiFormats={true}
          allowNg={true}
          value={item}
          activePropertyId={activePropertyId}
          onActivePropertyIdChange={setActivePropertyId}
          onChange={setItem}
        />
      </div>
    </FormulaTesterContainerContext.Provider>
  );
};
