import { useCallback, useContext, useMemo } from 'react';

import { ClientEngineContext, ContextInputContext, Evaluator, GeneralModel, LooperContext, ViewFormationContext, ViewModel } from '@cyferd/client-engine';
import { logger } from '@utils';

const evaluate = (value: any, data: any) => {
  try {
    return Evaluator.parse(value).evaluate(data);
  } catch (error) /* istanbul ignore next */ {
    logger.warn('[data parser]', 'Error evaluating', { value, completeDataMap: data, error });
    return undefined;
  }
};

export const useEvaluationPayload = () => {
  const { useDataSelector, useUserSelector, useUrl, platformConfig, useViewSelector } = useContext(ClientEngineContext);
  const global = useDataSelector();
  const user = useUserSelector();
  const url = useUrl();
  const viewFormation = useContext(ViewFormationContext);
  const contextInput = useContext(ContextInputContext);
  const globalView = useViewSelector();
  const currentView = viewFormation?.view || globalView;
  const looper = useContext(LooperContext);

  return useMemo(() => {
    const baseMap = {
      [ViewModel.InputKey.LOCAL]: viewFormation?.localState,
      [ViewModel.InputKey.LOCAL_ALT]: viewFormation?.localState,
      [ViewModel.InputKey.GLOBAL]: global,
      [ViewModel.InputKey.USER]: user,
      [ViewModel.InputKey.URL]: url,
      [ViewModel.InputKey.PLATFORM]: platformConfig,
      [ViewModel.InputKey.INPUT]: viewFormation?.input,
      [ViewModel.InputKey.VIEW]: viewFormation?.view,
      [ViewModel.InputKey.CONTEXT]: contextInput,
      [ViewModel.InputKey.TENANT]: user?.tenant,
      [ViewModel.InputKey.LOOPER]: looper
    };
    return {
      ...baseMap,
      [ViewModel.InputKey.VARIABLES]: Object.entries(currentView?.vars || {}).reduce((t, [k, v]) => ({ ...t, [k]: evaluate(v, baseMap) }), {})
    };
  }, [contextInput, currentView?.vars, global, looper, platformConfig, url, user, viewFormation?.input, viewFormation?.localState, viewFormation?.view]);
};

// This is a simplified version of client-engine/useDataParser
export const useEvaluate = () => {
  const evaluationPayload = useEvaluationPayload();
  return useCallback(
    (value: any, data: { [k: string]: any }) => {
      const completeDataMap = { ...evaluationPayload, ...data } as GeneralModel.PlatformConfig;

      return evaluate(value, completeDataMap);
    },
    [evaluationPayload]
  );
};
