// istanbul ignore file
import { useCallback, useContext } from 'react';
import useCyActions from '@utils/useCyActions';
import { ApiModel, ClientEngineContext, ViewModel, createUUID, ofType, useUnmountObservable, tapOnSuccess } from '@cyferd/client-engine';
import { catchError, EMPTY, mergeMap, of, take, takeUntil, throwError } from 'rxjs';
import { useDispatch } from 'react-redux';
import { actions as uiActions } from '../../../../client-app/state-mgmt/ui/actions';
import { ToastStatus } from '@components/elements/Toast';
import { useEvaluate } from './useEvaluate';

export interface IOnActionClick {
  flowId: string;
  flowInput: any;
  skipForm?: boolean;
  confirmation?: boolean;
  confirmationMessage?: ViewModel.ModalInteractionProps.Confirmation;
  refreshOnSuccess?: boolean;
  onSuccess?: string;
  onError?: string;
}

export const useOnActionClick = () => {
  const { onDispatchFormModal, onDispatchRefresh, onDispatchUseAction, onFlowRun } = useCyActions();
  const onDestroy$ = useUnmountObservable();
  const dispatch = useDispatch();
  const evaluate = useEvaluate();
  const { modalInteraction } = useContext(ClientEngineContext);

  const onActionClick = useCallback(
    (input: IOnActionClick, event: any) => {
      const { flowId, flowInput, skipForm, confirmation, confirmationMessage, refreshOnSuccess, onSuccess, onError } = evaluate(input, event);

      if (skipForm === false) {
        onDispatchFormModal({
          type: ApiModel.ApiEntity.FLOW,
          flowId,
          defaultValue: flowInput,
          formType: ViewModel.CyFormType.STEPPER,
          refreshOnSuccess,
          reusableActionOnSuccess: onSuccess,
          reusableActionOnError: onError
        });
        return;
      }

      const confirmationCancel = Symbol('::confimation cancel::');

      of(null)
        .pipe(
          mergeMap(() => {
            if (!confirmation) return of(null);

            return modalInteraction.onConfirm(confirmationMessage).pipe(
              take(1),
              mergeMap(confirmed => (confirmed ? of(null) : throwError(() => confirmationCancel)))
            );
          }),
          mergeMap(() => onFlowRun({ id: flowId, payload: flowInput })),
          takeUntil(onDestroy$),
          ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
          tapOnSuccess(result => {
            dispatch(
              uiActions.addToast({
                id: createUUID(),
                status: ToastStatus.SUCCESS,
                title: 'Success'
              })
            );

            if (refreshOnSuccess) {
              onDispatchRefresh({});
            }
            if (onSuccess) {
              return onDispatchUseAction({ target: onSuccess, event: result as any });
            }
          }),
          /** user error */
          catchError(error => {
            if (error === confirmationCancel) return EMPTY;

            if (onError) {
              return onDispatchUseAction({ target: onError, event: { error } as any });
            }

            return throwError(() => error);
          })
        )
        .subscribe();
    },
    [onFlowRun, evaluate, onDestroy$, dispatch, onDispatchFormModal, modalInteraction, onDispatchRefresh, onDispatchUseAction]
  );

  return { onActionClick };
};
