import { ComponentProps, memo, useContext, useState } from 'react';
import { CTA, CTAType } from '../CTA';
import { styles } from './styles';
import { useOutsideClick } from '@utils/useOutsideClick';
import { CyWrapperContext } from '@components/smart/CyWrapper/CyWrapper';
import { ErrorBoundary, GeneralModel, ViewModel } from '@cyferd/client-engine';
import { BUILDER_ICON, BUILDER_ROUTE, SECTION_NAME, TRANS } from '@constants';
import { Playground } from '../../../builder/views/shared/Playground';
import { useLocation } from 'react-router-dom';
import { PreventClickPropagation } from '../PreventClickPropagation';
import { useCanAccessRoute } from '@utils/useCanAccessRoute';

export interface MainNavigationProps {
  isPinned: boolean;
  isOpen: boolean;
  onChangePinned: () => void;
  onOpenChange: (event: boolean) => void;
}

export const MainNavigation = memo(({ isPinned, onChangePinned, isOpen, onOpenChange }: MainNavigationProps) => {
  const { pathname } = useLocation();
  const [isPlaygroundOpen, setPlaygoundOpen] = useState<boolean>(false);
  const { useAction } = useContext(CyWrapperContext);
  const onNavigateTo = useAction('dispatchNavigateTo');
  const canAccess = useCanAccessRoute();

  const onTogglePlaygoundOpen = () => setPlaygoundOpen(p => !p);
  const onToggleOpen = () => onOpenChange(!isOpen);
  const onClose = () => onOpenChange(false);

  const getNavigateTo =
    (payload: Parameters<typeof onNavigateTo>[0], eventOverride?: Parameters<typeof onNavigateTo>) => (event: Parameters<typeof onNavigateTo>) => {
      if (!isPinned) onClose();
      return onNavigateTo(payload, { ...event, ...eventOverride });
    };

  const outsideRef = useOutsideClick(onClose);

  const renderRow = ({
    id,
    icon,
    label,
    onClick
  }: {
    id: string;
    icon: GeneralModel.IconName;
    label: string;
    onClick: ComponentProps<typeof CTA>['onClick'];
  }) => {
    const isActive = id !== '/' ? pathname.startsWith(id) : /* istanbul ignore next */ pathname === id;
    return (
      <div css={styles.option} key={label}>
        <PreventClickPropagation containerCss={styles.optionPrevent}>
          <div css={[styles.optionContent, isActive && /* istanbul ignore next */ styles.activeOptionContent]} onClick={onClick}>
            <CTA
              testid={label}
              outlined={true}
              icon={icon}
              label={!!isOpen && label}
              tooltip={label}
              type={CTAType.LINK}
              size={ViewModel.CTASize.LARGE}
              color={isActive ? /* istanbul ignore next */ 'BASE_BACKGROUND' : 'ABSOLUTE_WHITE'}
            />
          </div>
        </PreventClickPropagation>
      </div>
    );
  };

  return (
    <>
      <div css={styles.container} ref={outsideRef}>
        <div css={[styles.hamburger, isOpen && styles.openHamburger]}>
          <CTA
            testid="main-nav-menu"
            outlined={true}
            icon="menu"
            type={CTAType.LINK}
            size={ViewModel.CTASize.LARGE}
            color="NEUTRAL_1"
            tooltip={TRANS.client.nav.builder.adminMenu}
            onClick={onToggleOpen}
          />
        </div>
        {(!!isOpen || !!isPinned) && (
          <div css={[styles.menu, !isOpen && styles.collapsedMenu, isPinned && styles.pinnedMenu]} data-testid="main-nav-content" onClick={onToggleOpen}>
            <div css={[styles.content, styles.mainContent]}>
              {(
                [
                  { id: '/ADMIN', icon: 'home_repair_service', label: TRANS.client.nav.builder.builder, onClick: getNavigateTo({ path: '/ADMIN' }) },
                  ...[
                    {
                      id: BUILDER_ROUTE.TAG_EDITOR.ROOT,
                      icon: BUILDER_ICON.APPS,
                      label: SECTION_NAME.APPS,
                      onClick: getNavigateTo({ path: BUILDER_ROUTE.TAG_EDITOR.ROOT })
                    },
                    {
                      id: BUILDER_ROUTE.ENTITY_EDITOR.ROOT,
                      icon: BUILDER_ICON.DATA,
                      label: SECTION_NAME.DATA,
                      onClick: getNavigateTo({ path: BUILDER_ROUTE.ENTITY_EDITOR.ROOT })
                    },
                    {
                      id: BUILDER_ROUTE.FLOW_EDITOR.ROOT,
                      icon: BUILDER_ICON.FLOWS,
                      label: SECTION_NAME.FLOWS,
                      onClick: getNavigateTo({ path: BUILDER_ROUTE.FLOW_EDITOR.ROOT })
                    },
                    {
                      id: BUILDER_ROUTE.VIEW_EDITOR.ROOT,
                      icon: BUILDER_ICON.VIEWS,
                      label: SECTION_NAME.VIEWS,
                      onClick: getNavigateTo({ path: BUILDER_ROUTE.VIEW_EDITOR.ROOT })
                    },
                    {
                      id: BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT,
                      icon: BUILDER_ICON.INTEGRATIONS,
                      label: SECTION_NAME.INTEGRATIONS,
                      onClick: getNavigateTo({ path: BUILDER_ROUTE.INTEGRATION_EDITOR.ROOT })
                    },
                    {
                      id: BUILDER_ROUTE.DEVICE_MANAGER.ROOT,
                      icon: BUILDER_ICON.DEVICE_MANAGER,
                      label: SECTION_NAME.DEVICE_MANAGER,
                      onClick: getNavigateTo({ path: BUILDER_ROUTE.DEVICE_MANAGER.ROOT })
                    },
                    {
                      id: BUILDER_ROUTE.INVITATION_EDITOR.ROOT,
                      icon: BUILDER_ICON.INVITATIONS,
                      label: SECTION_NAME.INVITATIONS,
                      onClick: getNavigateTo({ path: BUILDER_ROUTE.INVITATION_EDITOR.ROOT })
                    },
                    {
                      id: BUILDER_ROUTE.USER_EDITOR.ROOT,
                      icon: BUILDER_ICON.USERS,
                      label: SECTION_NAME.USERS,
                      onClick: getNavigateTo({ path: BUILDER_ROUTE.USER_EDITOR.ROOT })
                    }
                  ].filter(({ id }) => canAccess(id))
                ] as Parameters<typeof renderRow>[0][]
              ).map(o => renderRow(o))}
            </div>
            <div css={styles.content}>
              <div css={styles.division} />
              {renderRow({ id: 'playground', icon: 'terminal', label: TRANS.client.nav.builder.playground, onClick: onTogglePlaygoundOpen })}
              {renderRow({
                id: 'docs',
                icon: BUILDER_ICON.HELP,
                label: TRANS.client.nav.builder.guide,
                onClick: getNavigateTo({ path: BUILDER_ROUTE.GUIDE.ROOT }, { metaKey: true } as any)
              })}
              <div css={[styles.togglePin, !isOpen && !!isPinned && styles.collapsedPin]}>
                <PreventClickPropagation>
                  <CTA
                    testid="main-nav-pin"
                    outlined={!isPinned}
                    icon="push_pin"
                    tooltip={isPinned ? TRANS.client.nav.builder.unpin : TRANS.client.nav.builder.pin}
                    type={CTAType.LINK}
                    size={ViewModel.CTASize.LARGE}
                    color="ABSOLUTE_WHITE"
                    onClick={onChangePinned}
                  />
                </PreventClickPropagation>
              </div>
            </div>
          </div>
        )}
      </div>
      <ErrorBoundary>
        <Playground open={isPlaygroundOpen} onClose={onTogglePlaygoundOpen} />
      </ErrorBoundary>
    </>
  );
});

MainNavigation.displayName = 'MainNavigation';
