import { memo, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EMPTY, filter, Observable, takeUntil, tap } from 'rxjs';

import {
  actions,
  ApiModel,
  GeneralModel,
  isDeepEqual,
  mergeTruthy,
  normalize,
  useFinalizeWhileMounted,
  usePrevious,
  useUnmountObservable,
  ViewModel
} from '@cyferd/client-engine';
import { CyList } from '@components/smart/CyList';

import { ENV, TRANS } from '@constants';
import { useQueryParamState } from '@utils/useQueryParamState';
import { styles } from './styles';
import { hasFilters } from '../../../../@utils';
import { GlobalContext } from '../../../state-mgmt/GlobalState';

export type Props = PropsWithChildren<
  {
    request?: (action: ApiModel.APIAction) => Observable<ApiModel.APIAction>;
    customEmptyState?: React.ReactElement;
    hideListOnNoFilteredResults?: boolean;
    showDelete?: boolean;
    select?: string[];
    omit?: string[];
    omitAssociations?: boolean;
  } & ViewModel.CyListProps
>;

export const BuilderCyList = memo(
  ({
    customEmptyState,
    id,
    request,
    collectionId,
    type = ViewModel.CyListType.TABLE,
    hideListOnNoFilteredResults,
    showDelete,
    select,
    omit,
    omitAssociations,
    ...props
  }: Props) => {
    const onDestroy$ = useUnmountObservable();
    const [queryParamState, setQueryParamState] = useQueryParamState();
    const queryParamStateKey = id ? `${id}-table` : undefined;
    const [value, setValue] = useState<ApiModel.ApiValue>(undefined);
    const finalize = useFinalizeWhileMounted();
    const { deps } = useContext(GlobalContext);

    const [internalCursor, setInternalCursor] = useState<GeneralModel.FetchCriteria>(
      props.initialFetchCriteria || {
        collectionId,
        options: { skip: 0, limit: ENV.LIST_DEFAULT_LIMIT, filter: {} }
      }
    );
    const initialFetchCriteria = useMemo<GeneralModel.FetchCriteria>(
      () => (id && queryParamState[queryParamStateKey] ? queryParamState[queryParamStateKey] : internalCursor),
      [id, queryParamState, queryParamStateKey, internalCursor]
    );

    const setQueryCursor = useCallback(
      (newState: GeneralModel.FetchCriteria, options?: { [key: string]: any }) => {
        setQueryParamState(prev => ({ ...prev, [queryParamStateKey]: newState }), options);
      },
      [setQueryParamState, queryParamStateKey]
    );

    const setInitialFetchCriteria = useCallback(
      (newState: GeneralModel.FetchCriteria, options?: { [key: string]: any }) => {
        if (id) setQueryCursor(newState, options);
        else setInternalCursor(newState);
      },
      [id, setQueryCursor]
    );

    const onFetch = useCallback(
      (fetchCriteria: GeneralModel.FetchCriteria) =>
        request(
          actions.coreList({
            pointer: GeneralModel.IGNORED_POINTER_ID,
            query: {
              cursor: mergeTruthy(fetchCriteria, { options: { limit: fetchCriteria?.options?.limit ?? ENV.LIST_DEFAULT_LIMIT } }),
              select,
              omit,
              omitAssociations
            }
          })
        ).pipe(
          takeUntil(onDestroy$),
          filter(action => action.type === ApiModel.TriggerActionType.DISPATCH_SET_DATA),
          tap(action => {
            const response = { ...action.payload.value, query: normalize.collection(action.payload.value?.query) } as ApiModel.ApiValue;
            setInitialFetchCriteria(response.query?.cursor, { replace: true });
            setValue(response);
          })
        ),
      [onDestroy$, request, setInitialFetchCriteria, select, omit, omitAssociations]
    );

    const onRemove = useCallback(
      (item: ApiModel.ApiRecord) => {
        request(actions.coreDelete({ query: { cursor: { collectionId: item.collectionId, id: item.id } } }))
          .pipe(finalize(() => deps?.refresh$.next(props.componentName)))
          .subscribe();
      },
      [request, finalize, deps?.refresh$, props.componentName]
    );

    const onInternalRemove = useCallback(
      (item: ApiModel.ApiRecord) => {
        deps?.modalInteraction.onConfirm(
          {
            status: ViewModel.Status.INFO,
            icon: 'delete',
            title: `Delete ${item.recordTitle}?`,
            description: TRANS.client.SubHeader.modalDescription,
            confirmLabel: TRANS.client.buttons.delete
          },
          () => onRemove(item)
        );
        return EMPTY;
      },
      [deps?.modalInteraction, onRemove]
    );

    const actionListChildren = useMemo(
      () =>
        [...(props.actionListChildren || []), !!showDelete && { icon: 'delete', label: 'Remove', color: 'RD_4', onClick: onInternalRemove as any }].filter(
          Boolean
        ) as ViewModel.CyListProps['actionListChildren'],
      [onInternalRemove, props.actionListChildren, showDelete]
    );

    const hasFiltersApplied: boolean = useMemo(() => hasFilters(initialFetchCriteria), [initialFetchCriteria]);

    const hideCyList = useMemo(
      () => !value?.list?.length && !!customEmptyState && (!hasFiltersApplied || (hasFiltersApplied && hideListOnNoFilteredResults)),
      [customEmptyState, hasFiltersApplied, hideListOnNoFilteredResults, value?.list?.length]
    );

    const prev = usePrevious(props.initialFetchCriteria);
    useEffect(() => {
      if (props.initialFetchCriteria && !isDeepEqual(props.initialFetchCriteria, prev)) setInternalCursor(props.initialFetchCriteria);
    }, [prev, props.initialFetchCriteria]);

    return (
      <>
        <div data-testid="cyList-container" css={[styles.cyList, hideCyList && styles.hiddenCylist]}>
          <CyList {...props} type={type} value={value} onFetch={onFetch} initialFetchCriteria={initialFetchCriteria} actionListChildren={actionListChildren} />
        </div>
        {!!value && hideCyList && customEmptyState}
      </>
    );
  }
);
