/* istanbul ignore file */

import Elk from 'elkjs';
import { useCallback, useEffect, useState } from 'react';
import ReactFlow, { Connection, Controls, Edge, Elements, isEdge } from 'react-flow-renderer';

import { ErrorBoundary, FlowModel, useIsMounted } from '@cyferd/client-engine';

import { getElementsWithPosition } from '@utils/getElementsWithPosition';
import { getStepElements } from './getStepElements';

const ReactFlowAny: any = ReactFlow;
const ControlsAny: any = Controls;

const elk = new Elk({
  defaultLayoutOptions: {
    'elk.algorithm': 'mrtree',
    'elk.direction': 'RIGHT',
    'elk.spacing.edgeEdge': '50',
    'elk.spacing.nodeNode': '110',
    'elk.spacing.edgeLabel': '120'
  }
});

export interface FlowChartProps {
  steps: FlowModel.Flow['steps'];
  initialStepId: string;
  onSelect: (stepId: string) => void;
  onSelectStart: () => void;
  onChange: (stepKey: string, step: FlowModel.FlowStep) => void;
  onStartChange: (onStart: FlowModel.Flow['onStart']) => void;
}

export const FlowChart = ({ steps, initialStepId, onSelect, onSelectStart, onChange, onStartChange }: FlowChartProps) => {
  const isMounted = useIsMounted();
  const [elements, setElements] = useState<Elements>([]);

  const setPosition = useCallback(async () => {
    const elementsWithPosition = await getElementsWithPosition(
      Object.values(steps || {}).reduce(
        (total, step) => [
          ...total,
          ...getStepElements(
            initialStepId,
            steps,
            step,
            total.map(({ id }) => id)
          )
        ],
        [] as any
      ),
      elk
    );
    if (!isMounted.current) return;
    setElements(elementsWithPosition);
  }, [steps, isMounted, initialStepId]);

  useEffect(() => {
    setPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  const onElementClick = useCallback(
    (_, element) => (element.data?.stepId !== initialStepId ? onSelect(element.data?.stepId || element.data?.parentId) : onSelectStart()),
    [initialStepId, onSelect, onSelectStart]
  );

  const onConnect = useCallback(
    (connection: Edge | Connection) => {
      const { source, target } = connection;
      if (isEdge(connection) || target === initialStepId) return;

      if (source === initialStepId) onStartChange([...steps[initialStepId].onResult, { goTo: target }]);
      else onChange(source, { ...steps[source], onResult: [...(steps[source].onResult || []), { goTo: target }] } as FlowModel.FlowStep);
    },
    [initialStepId, onChange, onStartChange, steps]
  );

  return (
    <ErrorBoundary>
      <ReactFlowAny elements={elements} nodesConnectable={true} nodesDraggable={true} onElementClick={onElementClick} onConnect={onConnect}>
        <ControlsAny />
      </ReactFlowAny>
    </ErrorBoundary>
  );
};
