import { COLOR, CONTAINER_WIDTH, FONT_SIZE, FONT_WEIGHT, GAP, RADIUS, SHADOW, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${GAP.XS};
  `,
  dropdown: css`
    background-color: ${COLOR.TOP_BACKGROUND};
    padding: ${GAP.S};
    border-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    display: grid;
    grid-gap: ${GAP.XXS};
    max-height: 275px;
    max-width: ${CONTAINER_WIDTH.M}PX;
    overflow: auto;
  `,
  option: css`
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: ${GAP.S};
    cursor: pointer;
    padding: ${GAP.S} ${GAP.M};
    transition: all ${TRANSITION_SPEED};
    border-radius: ${RADIUS.S};
    :hover {
      background-color: ${COLOR.NEUTRAL_6};
    }

    font-size: ${FONT_SIZE.S};
    font-size: ${FONT_WEIGHT.NORMAL};
    color: ${COLOR.NEUTRAL_1};
    white-space: nowrap;
  `,
  disabled: css`
    cursor: not-allowed;
    &:hover {
      background-color: ${COLOR.BASE_BACKGROUND};
    }
  `,
  UNSTABLE_rotation: css`
    transform: rotate(90deg);
  `,
  triggerContainer: css`
    transition: all ${TRANSITION_SPEED};
  `,
  iconContainer: css`
    position: relative;
  `,
  dot: css`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    right: 1px;
    transition: background-color 0.2s ease-in-out;
    background-color: ${COLOR.RD_1};
  `
};
