import { memo, useContext, useMemo, PropsWithChildren } from 'react';

import { ClientEngineContext, useParsers, ViewModel } from '@cyferd/client-engine';
import { GlobalHeader } from '@components/platform/GlobalHeader';

import { Global } from '@emotion/react';
import { styles } from './styles';

export const AppWrapper = memo(({ children }: PropsWithChildren) => {
  const { useViewSelector } = useContext(ClientEngineContext);
  const view = useViewSelector();
  const { parseData } = useParsers(view?.globalHeader);

  const globalHeaderOptions: ViewModel.View['globalHeader'] = useMemo(
    () => Object.entries(view?.globalHeader || /* istanbul ignore next */ {}).reduce((t, [k, v]) => ({ ...t, [k]: !!parseData(v) }), {}),
    [parseData, view?.globalHeader]
  );

  return (
    <>
      {!!globalHeaderOptions?.hiddenBg && <Global styles={styles.globalBg} />}
      <GlobalHeader {...globalHeaderOptions}>{children}</GlobalHeader>
    </>
  );
});
