import { combineReducers, createStore } from 'redux';

import { composeWithDevTools } from '@redux-devtools/extension';

import { reducer as context } from './context';
import { reducer as global } from './global';
import { reducer as favorite } from './favorite';
import { getInitialState } from './getInitialState';
import { reducer as notification } from './notification';
import { reducer as ui } from './ui';
import { reducer as user } from './user';
import { reducer as view } from './view';

const combinedReducers = combineReducers({
  context,
  global,
  view,
  user,
  notification,
  ui,
  favorite
});

export const store = createStore(combinedReducers, getInitialState(), composeWithDevTools());
