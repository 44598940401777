import { createUUID, getComponentSchema, getSchemaPlaceholder, isObject, ViewModel, useTranslate, getNameFromUUID } from '@cyferd/client-engine';
import { getComponentConfig } from '@models/view';

export const getNewNode = (component: ViewModel.DisplayName, translate: ReturnType<typeof useTranslate>['translate']): ViewModel.Node => {
  const schema = getComponentSchema(component);
  const id = createUUID();
  return {
    id: createUUID(),
    name: `${translate(getComponentConfig(component)?.label)} ${getNameFromUUID(id)}`,
    component,
    listeners: {},
    attrs: getSchemaPlaceholder(schema),
    contains: []
  };
};

export const cloneNode = (node: ViewModel.Node, translate: ReturnType<typeof useTranslate>['translate']): ViewModel.Node => {
  if (!isObject(node)) return node;
  const { id, name } = getNewNode(node.component, translate);
  return { ...node, id, name, contains: node.contains?.map(n => cloneNode(n, translate)) };
};
