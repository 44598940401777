import { useCallback, useState } from 'react';
import { takeUntil } from 'rxjs';

import { actions, tapOnSuccess, useUnmountObservable } from '@cyferd/client-engine';

import { KeyValueData } from '../KeyValueEditor';
import { useRequest } from '@utils/useRequest';
import { UserKeyValuesModal } from '../UserKeyValuesModal';

export interface UserTagSecurityModalProps {
  open: boolean;
  onClose: () => void;
  bondId: string;
  onSuccess?: () => void;
  associationKey?: string;
  initialValue?: KeyValueData;
  itemId?: string;
  collectionId?: string;
  fullItem?: any;
}
export const UserTagSecurityModal = ({
  open,
  onClose,
  onSuccess,
  bondId,
  itemId,
  collectionId,
  initialValue = [],
  associationKey,
  fullItem
}: UserTagSecurityModalProps) => {
  const request = useRequest();
  const [value, setValue] = useState<KeyValueData>(initialValue);
  const [saving, setSaving] = useState(false);
  const onDestroy$ = useUnmountObservable();

  const onSave = useCallback(
    (event: KeyValueData) => {
      setSaving(true);
      return request(
        actions.coreUpsert({
          $cyf_escape: [
            {
              query: { cursor: { collectionId, id: itemId } },
              record: {
                ...fullItem,
                [`$$${associationKey}`]: {
                  modify: [{ $r: { id: bondId, values: event.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value }), {}) } }]
                }
              }
            }
          ]
        } as any)
      ).pipe(
        takeUntil(onDestroy$),
        tapOnSuccess(() => {
          setSaving(false);
          if (onSuccess) onSuccess();
        })
      );
    },
    [request, bondId, onDestroy$, itemId, collectionId, associationKey, fullItem, onSuccess]
  );

  return <UserKeyValuesModal open={open} onClose={onClose} value={value} onChange={setValue} onSave={() => onSave(value)} saving={saving} />;
};
