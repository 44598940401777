import { SvgProps } from '../types';

export const Layout_H = ({ size = 20, fill = 'var(--NEUTRAL_2)', ...props }: SvgProps) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="39.5" y="39.5" width="39" height="39" rx="3.5" transform="rotate(-180 39.5 39.5)" stroke={fill} />
    <path
      d="M17 3.5L35 3.5C35.8284 3.5 36.5 4.17158 36.5 5L36.5 35C36.5 35.8284 35.8284 36.5 35 36.5L17 36.5C16.1716 36.5 15.5 35.8284 15.5 35L15.5 5C15.5 4.17157 16.1716 3.5 17 3.5Z"
      stroke={fill}
    />
    <path d="M13 35C13 36.1046 12.1046 37 11 37L5 37C3.89543 37 3 36.1046 3 35L3 5C3 3.89543 3.89543 3 5 3L11 3C12.1046 3 13 3.89543 13 5L13 35Z" fill={fill} />
  </svg>
);
