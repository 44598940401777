import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EMPTY } from 'rxjs';

import { decodeQueryString, ErrorBoundary, GeneralModel, isDeepEqual, useDebounce, useOnRefresh, usePrevious, ViewModel } from '@cyferd/client-engine';

import { CyWrapperContext } from '../../smart/CyWrapper';
import { useGetElementSize } from '@utils';
import { DropdownMenu } from '../DropdownMenu';
import { Modal } from '../Modal';
import { SearchInput } from '../SearchInput';
import { Spinner } from '../Spinner';
import { RecentSearches } from './components/RecentSearches';
import { ResultList } from './components/ResultList';
import { styles } from './styles';
import { searchQuickFilters, SearchValueItem, useSearch, UseSearchOptions } from './useSearch';
import { QuickFilters } from '../QuickFilters';
import { ENV, TRANS } from '@constants';
import { CyText } from '../../smart/CyText/CyText';
import { CtaProps } from '../CTA';

export interface SearchProps extends UseSearchOptions, ViewModel.CySearchProps {
  minChars?: number;
  delayTime?: number;
  compact?: boolean;
  expandCtaProps?: CtaProps;
}

export const Search = memo(
  ({
    minChars = 1,
    delayTime = ENV.LOADING_DEBOUNCE_TIME,
    storage,
    initialValue,
    onSearch: onSearchDep,
    id,
    componentName,
    appIdList,
    collectionIdList,
    placeholder = TRANS.client.placeholder.globalSearch,
    onResultClick,
    effectChildren,
    compact,
    expandCtaProps
  }: SearchProps) => {
    const { useAction } = useContext(CyWrapperContext);

    const [open, setOpen] = useState<boolean>(false);
    const onNavigateTo = useAction('dispatchNavigateTo');
    const [externalCriteria, setExternalCriteria] = useState<string>('');
    const [criteria, setCriteria] = useState<string>('');
    const delayedCriteria = useDebounce(criteria, delayTime);
    const { ref, width } = useGetElementSize();
    const [quickFiltersCursor, setQuickFiltersCursor] = useState<GeneralModel.FetchCriteria>();

    const activeCollections = useMemo(() => (Array.isArray(collectionIdList) ? collectionIdList : []), [collectionIdList]);
    const activeAppList = useMemo(() => (Array.isArray(appIdList) ? appIdList : []), [appIdList]);

    const searchOptions = useMemo(
      () => ({ storage, initialValue, id: componentName, onSearch: onSearchDep }),
      [initialValue, onSearchDep, storage, componentName]
    );

    const query: Parameters<typeof onSearch>[0] = useMemo(
      () => ({ searchString: delayedCriteria, apps: activeAppList, collections: activeCollections, quickFilters: quickFiltersCursor?.quickFilters }),
      [activeAppList, activeCollections, delayedCriteria, quickFiltersCursor?.quickFilters]
    );
    const prevQuery = usePrevious(query);

    const { isSearching, isError, searchResults, recentTerms, onSearch, onClearSearchResults, onClearRecentSearches, onRemoveRecentSearchEntry } =
      useSearch(searchOptions);

    const isLoading = isSearching || delayedCriteria !== criteria;

    const hasResults = !!Object.values(searchResults || {}).reduce((t, v) => t + (v?.list?.length || 0), 0);
    const { App: apps, Favorite: favorites, View: views, Collection: collections, Flow: flows, REST: rest, ...records } = searchResults || {};

    const onClose = useCallback(() => {
      setCriteria('');
      setOpen(false);
    }, []);

    const onInternalSearch = useCallback(() => {
      if (criteria?.trim().length >= minChars) return onSearch(query);
      return EMPTY;
    }, [criteria, minChars, onSearch, query]);

    const onSubmit = useCallback(
      (diffCriteria?: string) => {
        const finalCriteria = diffCriteria ?? externalCriteria;
        if (finalCriteria?.trim().length < minChars) return;
        setOpen(true);
        setCriteria(finalCriteria);
        setExternalCriteria('');
      },
      [externalCriteria, minChars]
    );

    const onRecentSearchClick = useCallback((event: string) => onSubmit(event), [onSubmit]);

    const onCloseSearch = useCallback(() => {
      onClearSearchResults();
      onClose();
    }, [onClearSearchResults, onClose]);

    const onInternalResultClick = useCallback(
      (event: SearchValueItem, meta: any) => {
        try {
          if (typeof onResultClick === 'function') return onResultClick(event.record, meta);
          /* istanbul ignore next line */
          const [path = '', queryString = ''] = event?.href?.split('?') || [];
          const queryParamsObject = decodeQueryString(queryString);
          return onNavigateTo({ path, qs: queryParamsObject }, meta);
        } finally {
          onClose();
        }
      },
      [onClose, onNavigateTo, onResultClick]
    );

    useOnRefresh({ id, componentName }, onInternalSearch);

    useEffect(() => {
      if (!isDeepEqual(query, prevQuery)) onInternalSearch();
    }, [onInternalSearch, query, prevQuery]);

    return (
      <div id={id} css={styles.mainContainer}>
        <div data-testid="effects">{effectChildren}</div>
        {!open && (
          <DropdownMenu
            styling={styles.externalContainer}
            horizontalResponsive={false}
            renderTrigger={({ onClick, ref: triggerRef }) => (
              <div css={styles.externalDropdownContainer} onClick={onClick} ref={ref} data-testid="toggle-search">
                <div ref={triggerRef}>
                  <SearchInput
                    alternative={!!componentName}
                    autoFocus={false}
                    value={externalCriteria}
                    placeholder={placeholder}
                    onChange={setExternalCriteria}
                    onClick={onSubmit}
                    compact={compact}
                    ctaProps={expandCtaProps}
                  />
                </div>
              </div>
            )}
          >
            {!!recentTerms?.length && (
              <div css={[styles.externalSearches]} style={{ minWidth: width }}>
                <RecentSearches
                  recentTerms={recentTerms}
                  onClearRecentSearches={onClearRecentSearches}
                  onItemClick={onRecentSearchClick}
                  onRemoveRecentSearchEntry={onRemoveRecentSearchEntry}
                />
              </div>
            )}
          </DropdownMenu>
        )}
        {!!open && (
          <Modal open={true} type={ViewModel.ModalType.FULL_SCREEN} placeOnTop={!componentName} onClose={onCloseSearch}>
            <div data-testid="search-section" css={styles.container}>
              <div css={styles.innerWrapper}>
                <section css={styles.section}>
                  <SearchInput autoFocus={true} value={criteria} placeholder={placeholder} onChange={setCriteria} />
                </section>
                <section>
                  <QuickFilters config={searchQuickFilters} cursor={quickFiltersCursor} onFetch={setQuickFiltersCursor as any} />
                </section>
                <section css={styles.section}>
                  {!!isLoading && (
                    <div css={styles.refiningContainer}>
                      <Spinner />
                      <p>We are refining your search</p>
                    </div>
                  )}
                  {!isLoading && !delayedCriteria && !!recentTerms?.length && (
                    <div css={[styles.recentSearchesContainer]}>
                      <RecentSearches
                        recentTerms={recentTerms}
                        onClearRecentSearches={onClearRecentSearches}
                        onItemClick={setCriteria}
                        onRemoveRecentSearchEntry={onRemoveRecentSearchEntry}
                      />
                    </div>
                  )}
                  {!!delayedCriteria && !!hasResults && (
                    <div css={styles.mainResultListContainer}>
                      {[
                        ...Object.values(records || /* istanbul ignore next */ {}).map((row, i) => ({
                          id: `${row?.query?.collectionId}-${i}`,
                          title: null,
                          density: ViewModel.Density.L,
                          type: ViewModel.CyListType.LIST,
                          results: row
                        })),
                        { id: 'rest', title: 'Others', density: ViewModel.Density.S, type: ViewModel.CyListType.GRID, results: rest },
                        { id: 'favs', title: 'Favorites', density: ViewModel.Density.S, type: ViewModel.CyListType.GRID, results: favorites },
                        { id: 'apps', title: 'Apps', density: ViewModel.Density.S, type: ViewModel.CyListType.GRID, results: apps },
                        {
                          id: 'collections',
                          title: 'Data collections',
                          density: ViewModel.Density.S,
                          type: ViewModel.CyListType.GRID,
                          results: collections
                        },
                        { id: 'flows', title: 'Flows', density: ViewModel.Density.S, type: ViewModel.CyListType.GRID, results: flows },
                        { id: 'views', title: 'Views', density: ViewModel.Density.S, type: ViewModel.CyListType.GRID, results: views }
                      ]
                        .filter(config => config?.results?.list?.length)
                        .map(config => (
                          <div key={config.id} css={[styles.resultListContainer]}>
                            <ErrorBoundary>
                              <ResultList
                                density={config.density}
                                title={config.title}
                                searchString={query?.searchString}
                                type={config.type}
                                results={config.results}
                                onResultClick={onInternalResultClick}
                              />
                            </ErrorBoundary>
                          </div>
                        ))}
                    </div>
                  )}
                  {!!delayedCriteria && !!searchResults && !hasResults && !isLoading && (
                    <div css={styles.emptyWrapper} data-testid="no-results">
                      <CyText title="Oops, no results found!" titleAlignment={ViewModel.Alignment.CENTER} />
                    </div>
                  )}
                  {!!isError && (
                    <p data-testid="search-error" css={styles.refiningContainer}>
                      Sorry, an error has occured.
                    </p>
                  )}
                </section>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
);

Search.displayName = 'Search';
