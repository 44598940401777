import { useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ApiModel, TagModel, ViewModel } from '@cyferd/client-engine';
import { CyLayout } from '@components/smart/CyLayout';
import { useTestingHelper } from '@utils';

import { ENV, getTempId, BUILDER_DESCRIPTION, BUILDER_ICON, QUERY_PARAM, BUILDER_ROUTE, SECTION_NAME, TRANS } from '@constants';
import { getNewTag } from '@models/tag';
import { getNavigateToArgs, getNavigateToTab } from '@utils/getNavigateToTab';
import { useOnNavigateToItem } from '@utils/useOnNavigateToItem';
import { useOnOpenExternalUrl } from '@utils/useOnOpenExternalUrl';
import { BuilderCyList } from '../../../shared/BuilderCyList';
import { EditorContext, EditorContextValue } from '../../../shared/EditorHome';
import { TempHeader } from '../../../shared/TempHeader';
import { styles } from './styles';
import { useRequest } from '@utils/useRequest';

export const GeneralList = () => {
  const { getTestIdProps } = useTestingHelper('general-list');
  const request = useRequest();
  const { setItem } = useContext<EditorContextValue<TagModel.Tag>>(EditorContext);
  const { search } = useLocation();
  const navigate = useNavigate();

  const baseConfig: getNavigateToArgs = useMemo(
    () => ({
      id: null,
      path: null,
      root: BUILDER_ROUTE.TAG_EDITOR.ROOT,
      search,
      key: QUERY_PARAM.RECORD_ID,
      push: navigate
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onCreate = (meta: any) => {
    const item: TagModel.Tag = getNewTag();
    setItem(item);
    getNavigateToTab({ ...baseConfig, id: getTempId(), path: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.GENERAL })(meta);
  };

  const openExternalUrl = useOnOpenExternalUrl();

  const actionListChildren = useMemo(
    () =>
      [
        {
          icon: 'edit',
          label: TRANS.client.buttons.edit,
          important: true,
          onClick: (item: TagModel.Tag, event) => getNavigateToTab({ ...baseConfig, id: item.id, path: BUILDER_ROUTE.TAG_EDITOR.CHILDREN.GENERAL })(event)
        },
        { icon: 'zoom_in', label: TRANS.client.buttons.open, onClick: item => openExternalUrl(`${ENV.CLIENT_APP_URL}/APP_HOME?id=${item.id}`) }
      ] as ViewModel.CyListProps['actionListChildren'],
    [baseConfig, openExternalUrl]
  );

  const onClickItem = useOnNavigateToItem(baseConfig, BUILDER_ROUTE.TAG_EDITOR.CHILDREN.GENERAL);

  useEffect(() => {
    setItem(undefined);
  }, [setItem]);

  return (
    <div {...getTestIdProps('container')} className={styles.container}>
      <TempHeader
        title={SECTION_NAME.APPS}
        icon={BUILDER_ICON.APPS}
        subtitle={BUILDER_DESCRIPTION.APPS}
        actionListChildren={[{ important: true, onClick: onCreate as any, icon: 'add', label: TRANS.client.buttons.createNew }]}
      />
      <CyLayout itemHeight="calc(100vh - 125px)">
        <BuilderCyList
          onClickItem={onClickItem}
          request={request}
          id={ApiModel.ApiEntity.TAG}
          componentName={ApiModel.ApiEntity.TAG}
          showDelete={true}
          key={ApiModel.ApiEntity.TAG}
          collectionId={ApiModel.ApiEntity.TAG}
          actionListChildren={actionListChildren}
        />
      </CyLayout>
    </div>
  );
};
