import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { filter, takeUntil, tap } from 'rxjs';

import { decodeQueryString, encodeQueryString, isObject, removeKeyList, useUnmountObservable } from '@cyferd/client-engine';
import { LoadingPage } from '@components/platform/LoadingPage';
import { LoginPage as WPLLoginPage } from '@components/platform/LoginPage';

import image from '../../../assets/login-main.svg';
import Logo from '../../../assets/Logo';
import { ENV } from '@constants';
import { tokenStorage, useWS } from '../../state-mgmt/WSProvider';

/* istanbul ignore next line */
const Image = (props: any) => <img {...props} src={image} />;

export const LoginPage = () => {
  const [hasToken, setHasToken] = useState<boolean>(false);
  const { enableConnection } = useWS();
  const onDestroy$ = useUnmountObservable();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    tokenStorage
      .get()
      .pipe(
        takeUntil(onDestroy$),
        filter(isObject),
        tap(tokenSet => {
          setHasToken(isObject(tokenSet));
          enableConnection(true);
          const { pathname, search } = location;
          const qs = encodeQueryString(removeKeyList(decodeQueryString(search), ['code']));
          navigate(`${pathname}${qs}`, { replace: true });
        })
      )
      .subscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDestroy$]);

  if (hasToken) return <LoadingPage />;
  return <WPLLoginPage authUrl={ENV.AUTH_URL} tokenUrl={ENV.TOKEN_URL} onTokenChange={tokenStorage.set} Image={Image} Logo={Logo} />;
};
