import { GeneralModel } from '@models';

import { ACTION_TYPE } from './actions';
import { getInitialState, UIState } from './state';

export const reducer = (state: UIState = getInitialState(), { type, payload }: GeneralModel.Action): UIState => {
  switch (type) {
    case ACTION_TYPE.SET_MODAL_INTERACTION:
      return { ...state, modalInteraction: payload.modalInteraction };
    default:
      return state;
  }
};
