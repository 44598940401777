import { GeneralModel, SchemaFormBaseProps } from '@cyferd/client-engine';
import { useCallback } from 'react';
import { Fieldset } from '@components/elements/Fieldset';

export const useRenderGroupContentWrapper = ({
  ungroupedKey,
  asStepper,
  currentStepId,
  wrapDetailGroups,
  maxColumns,
  id
}: {
  ungroupedKey: string;
  asStepper: boolean;
  currentStepId: string;
  wrapDetailGroups: boolean;
  id: string;
  maxColumns?: number;
}): SchemaFormBaseProps['componentRecord']['renderGroupContentWrapper'] =>
  useCallback(
    ({ id: wrapperId, children, groupName, group, isUngrouped, parentType }) => {
      const groupId = group?.id || ungroupedKey;
      const isNested = !!parentType;
      if (!!asStepper && currentStepId !== groupId && !isNested /** children from rendered group */) return null;
      if (isUngrouped && isNested) return children;

      return (
        <Fieldset
          id={group?.id}
          isDetailGroup={wrapDetailGroups && !isNested}
          maxColumns={maxColumns}
          testid={`${id}-${wrapperId}`}
          title={!asStepper && groupName}
          description={!asStepper && group?.description}
          info={!asStepper && group?.info}
          isCollapsible={!asStepper && group?.collapsible}
          startsCollapsed={!asStepper && group?.startsCollapsed}
          image={!asStepper && group?.image}
          color={group?.color as GeneralModel.Color.ThemeColor}
        >
          {children}
        </Fieldset>
      );
    },
    [asStepper, currentStepId, id, maxColumns, ungroupedKey, wrapDetailGroups]
  );
