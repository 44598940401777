import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EMPTY } from 'rxjs';

import { GeneralModel, prepareTermForReg, useDebounce, ViewModel } from '@cyferd/client-engine';

import { Modal } from '../../../Modal';
import { isIcon } from '@utils';
import { Input } from '../../../Input';
import { styles } from './styles';
import { SelectDropdown } from '../../../SelectDropdown';
import { CTA, CTAType } from '../../../CTA';
import { Layout } from '../../../Layout';
import { SearchInput } from '../../../SearchInput';
import { Icon } from '../../../Icon/Icon';
import { Image } from '../../../Image';
import { CyText } from '@components/smart/CyText';
import { TRANS } from '@constants';

const categoryOptions = [...GeneralModel.iconCategoryList].sort((a, b) => a.localeCompare(b)).map(c => ({ value: c, label: c.replace(/&/g, ' & ') }));

export interface IconImagePickerModalProps {
  label: string;
  description?: string;
  open: boolean;
  onChange: (value: string) => void;
  onClose: () => void;
  value?: string;
  iconOnly?: boolean;
  delay?: number;
}

export const IconImagePickerModal = ({ open, value, label, description, iconOnly, delay, onClose, onChange }: IconImagePickerModalProps) => {
  const tabList: ViewModel.LayoutTab[] = useMemo(() => [{ title: 'Icon' }, !iconOnly && { title: 'Image' }].filter(Boolean), [iconOnly]);

  const [activeTab, setActiveTab] = useState<string>(tabList[0].title);
  const [error, setError] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState(null);
  const debouncedSearchValue = useDebounce(searchValue, delay);

  const [selected, setSelected] = useState<string>(value);

  const iconList = useMemo(
    () =>
      GeneralModel.iconNameList.filter(iconName => {
        const safeIconName = iconName.replace(/_/g, ' ');
        const reg = new RegExp(prepareTermForReg(debouncedSearchValue), 'i');
        return (
          /** matches category or no category */
          (!category || GeneralModel.iconConfigMap[iconName]?.categories.includes(category)) &&
          /** matches icon name or tag */
          (reg.test(safeIconName) || reg.test(iconName) || GeneralModel.iconConfigMap[iconName]?.tags?.some(c => reg.test(c)))
        );
      }),
    [category, debouncedSearchValue]
  );

  const onChangeTab = useCallback((event: string) => {
    setActiveTab(event);
    setError(false);
    setSelected('');
    setSearchValue('');
    setCategory(null);
    return EMPTY;
  }, []);

  const closeModal = useCallback(() => {
    onClose();
    setSearchValue('');
    setCategory(null);
  }, [onClose]);

  const onSelect = useCallback(() => {
    onChange(selected);
    closeModal();
  }, [closeModal, onChange, selected]);

  const changeUrl = useCallback((event: string) => {
    setError(false);
    setSelected(event);
  }, []);

  useEffect(() => {
    setSelected(value);
    setActiveTab(!!value && !isIcon(value) && !iconOnly ? tabList[1]?.title : tabList[0].title);
  }, [iconOnly, tabList, value]);

  return (
    <Modal
      title={label}
      description={description}
      icon="add_circle"
      footer={
        <div css={styles.footer}>
          <CTA type={CTAType.SECONDARY} label={TRANS.client.buttons.cancel} onClick={closeModal} />
          <CTA type={CTAType.PRIMARY} disabled={!selected} label="Select" testid="select-icon-button" onClick={onSelect} />
        </div>
      }
      open={!!open}
      onClose={closeModal}
      testid="icon-picker-modal"
    >
      {!!open && (
        <Layout activeTab={activeTab} onChangeTab={onChangeTab} tabList={tabList} type={ViewModel.LayoutType.TAB}>
          <div>
            <div css={styles.searchContainer}>
              <SelectDropdown
                testid="category"
                emptyLabel="Select a category"
                allowEmpty={true}
                value={category}
                onChange={setCategory}
                options={categoryOptions}
              />
              <div css={styles.searchInput}>
                <SearchInput
                  autoFocus={true}
                  onChange={value => setSearchValue(value)}
                  searchDelay={500}
                  testid="search-icon"
                  value={searchValue}
                  alternative={true}
                />
              </div>
            </div>
            {!iconList.length && (
              <div css={styles.oops}>
                <CyText title="Oops, no result found!" titleAlignment={ViewModel.Alignment.CENTER} />
              </div>
            )}
            <div css={styles.iconPickerWrapper}>
              {!!iconList.length &&
                iconList.map(icon => (
                  <button
                    css={[styles.iconButton, selected === icon && styles.selectedIconButton]}
                    key={icon}
                    data-testid={icon}
                    onClick={() => setSelected(icon)}
                  >
                    <Icon name={icon} testid="icon-to-pick" />
                    <div data-testid="icon-name">{icon}</div>
                  </button>
                ))}
            </div>
          </div>
          <div>
            <div css={styles.inputContainer}>
              <Input autoFocus={true} label="Media" onChange={changeUrl} testid="image-url-input" type={GeneralModel.JSONSchemaFormat.URL} value={selected} />
              {!!selected && (
                <div css={styles.selectedImage}>
                  {
                    /* istanbul ignore next */ error ? (
                      <div css={styles.imageNotFound}>
                        <Icon name="broken_image" size="60px" />
                        <h3>Image not found</h3>
                        <span>Please make sure your URL is valid</span>
                      </div>
                    ) : (
                      <Image alt="selected-icon" testid="selected-img" onError={() => setError(true)} src={selected} />
                    )
                  }
                </div>
              )}
            </div>
          </div>
        </Layout>
      )}
    </Modal>
  );
};
