import { useCallback, useState } from 'react';
import { EMPTY, Observable, shareReplay } from 'rxjs';

import { ApiModel, GeneralModel, swallowError } from '@cyferd/client-engine';

export type useSearchFilterArgs = {
  cursor?: GeneralModel.FetchCriteria;
  onFetch?: (criteria: GeneralModel.FetchCriteria) => Observable<ApiModel.APIAction>;
};

export const useSearchFilter = ({ cursor, onFetch }: useSearchFilterArgs) => {
  const [searchString, setSearchString] = useState<string>('');

  const canSearch = !!(onFetch && cursor);

  const onSearch = useCallback(() => {
    /* istanbul ignore else */
    if (canSearch) {
      const observable = onFetch({ ...cursor, options: { ...cursor.options, skip: 0 }, searchString }).pipe(swallowError(), shareReplay());
      observable.subscribe();
      return observable;
    }
    return EMPTY;
  }, [cursor, onFetch, searchString, canSearch]);

  return { canSearch, onSearch, setSearchString, searchString };
};
