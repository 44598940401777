import { COLOR, FONT_SIZE, FONT_WEIGHT, GAP, GLOBAL_HEADER_HEIGHT, RADIUS, SHADOW, TRANSITION_SPEED } from '@constants';
import { css } from '@emotion/react';

export const MENU_WIDTH = '300px';
export const CLOSED_MENU_WIDTH = '55px';

const MAIN_NAV_COLOR = '#35383B'; // fix between themes

export const styles = {
  container: css`
    [data-selector='cta-label'] {
      text-decoration: none;
      font-weight: ${FONT_WEIGHT.NORMAL};
      font-size: ${FONT_SIZE.S};
      margin-left: ${GAP.M};
    }
  `,
  hamburger: css`
    height: ${GLOBAL_HEADER_HEIGHT};
    display: flex;
    align-items: center;
    transition: all calc(${TRANSITION_SPEED} / 2);
    padding-left: ${GAP.XS}; // this makes it misaligned, but looks like it's aligned with options
  `,
  openHamburger: css`
    transform: rotate(90deg);
  `,
  menu: css`
    position: fixed;
    top: ${GLOBAL_HEADER_HEIGHT};
    left: 0;
    z-index: 10;
    width: ${MENU_WIDTH};
    max-width: 100%;
    background-color: ${MAIN_NAV_COLOR};
    padding: ${GAP.L} 0;
    border-bottom-right-radius: ${RADIUS.S};
    box-shadow: ${SHADOW.S};
    display: flex;
    gap: ${GAP.XS};
    flex-direction: column;
    max-height: calc(100% - ${GLOBAL_HEADER_HEIGHT});
  `,
  collapsedMenu: css`
    width: ${CLOSED_MENU_WIDTH};
  `,
  pinnedMenu: css`
    height: calc(100% - ${GLOBAL_HEADER_HEIGHT});
    border-bottom-right-radius: 0;
  `,
  content: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${GAP.XS};
  `,
  mainContent: css`
    flex: 1;
    overflow: auto;
  `,
  option: css`
    width: 100%;
    padding: 0 ${GAP.XS};
  `,
  optionPrevent: css`
    display: block;
    width: 100%;
  `,
  optionContent: css`
    padding: 0 ${GAP.M};
    cursor: pointer;
    border-radius: ${RADIUS.S};
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: opacity ${TRANSITION_SPEED};
    :hover {
      opacity: 0.6;
    }
  `,
  activeOptionContent: css`
    background-color: ${COLOR.BRAND_1};
    :hover {
      opacity: 1;
    }
  `,
  division: css`
    width: 100%;
    border-top: 1px ${COLOR.NEUTRAL_6} solid;
  `,
  togglePin: css`
    padding: 0 ${GAP.S} 0 ${GAP.XS};
    width: 100%;
    display: flex;
    justify-content: flex-end;
  `,
  collapsedPin: css`
    justify-content: center;
    padding: 0;
  `
};
