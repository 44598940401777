import { TRANS } from '@constants';
import { getComponentSchema, ParsedList, ViewModel } from '@cyferd/client-engine';
import { ComponentConfig, getComponentConfig } from '@models/view';

export interface ComponentTemplate {
  id: string;
  componentConfig: ComponentConfig;
  item: Pick<ParsedList['items'][0], 'title' | 'description' | 'image' | 'color'> & { longDescription?: string };
  template: any;
  group: string;
}

const longDescriptionMap = {
  [ViewModel.DisplayName.CY_VIEW]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_VIEW],
  [ViewModel.DisplayName.CY_VIEW_CONTENT]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_VIEW_CONTENT],
  [ViewModel.DisplayName.CY_DATA_EFFECT]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_DATA_EFFECT],
  [ViewModel.DisplayName.CY_ACTION_EFFECT]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_ACTION_EFFECT],
  [ViewModel.DisplayName.CY_LOAD_EFFECT]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_LOAD_EFFECT],
  [ViewModel.DisplayName.CY_UNLOAD_EFFECT]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_UNLOAD_EFFECT],
  [ViewModel.DisplayName.CY_INTERVAL_EFFECT]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_INTERVAL_EFFECT],
  [ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_REUSABLE_ACTION_EFFECT],
  [ViewModel.DisplayName.CY_BLANK]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_BLANK],
  [ViewModel.DisplayName.CY_ACTION]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_ACTION],
  [ViewModel.DisplayName.CY_TEXT]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_TEXT],
  [ViewModel.DisplayName.CY_IMAGE]: TRANS.client.ComponentAdder[ViewModel.DisplayName.CY_IMAGE],
  [`${ViewModel.DisplayName.CY_LAYOUT}-${ViewModel.LayoutType.RESPONSIVE}`]:
    TRANS.client.ComponentAdder[`${ViewModel.DisplayName.CY_LAYOUT}-${ViewModel.LayoutType.RESPONSIVE}`],
  [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.LIST}`]:
    TRANS.client.ComponentAdder[`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.LIST}`],
  [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.TABLE}`]:
    TRANS.client.ComponentAdder[`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.TABLE}`],
  [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.CARD}`]:
    TRANS.client.ComponentAdder[`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.CARD}`],
  [`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.GRID}`]:
    TRANS.client.ComponentAdder[`${ViewModel.DisplayName.CY_LIST}-${ViewModel.CyListType.GRID}`],
  [`${ViewModel.DisplayName.CY_FORM}-${ViewModel.CyFormType.DEFAULT}`]:
    TRANS.client.ComponentAdder[`${ViewModel.DisplayName.CY_FORM}-${ViewModel.CyFormType.DEFAULT}`],
  [`${ViewModel.DisplayName.CY_FORM}-${ViewModel.CyFormType.STEPPER}`]:
    TRANS.client.ComponentAdder[`${ViewModel.DisplayName.CY_FORM}-${ViewModel.CyFormType.STEPPER}`]
};

const createTemplateListFromOptionList = (displayName: ViewModel.DisplayName, fieldName: string): ComponentTemplate[] =>
  getComponentSchema(displayName)
    .properties[fieldName].metadata.optionList.filter(option => !option.hidden)
    .map(option => {
      const componentConfig = getComponentConfig(displayName);
      return {
        id: String(option.value),
        group: componentConfig.label,
        componentConfig,
        item: {
          title: option.label,
          description: option.description,
          image: option.image,
          color: componentConfig.color,
          longDescription: longDescriptionMap[`${displayName}-${option.value}`]
        },
        template: { attrs: { [fieldName]: option.value } }
      };
    });

export const componentTemplates: Record<string, ComponentTemplate[]> = {
  ...Object.values(ViewModel.DisplayName).reduce((total, displayName) => {
    const componentConfig = getComponentConfig(displayName);
    return {
      ...total,
      [displayName]: [
        {
          id: componentConfig.label,
          group: componentConfig.category,
          componentConfig,
          item: {
            title: componentConfig.label,
            description: '',
            image: componentConfig.icon,
            color: componentConfig.color,
            longDescription: longDescriptionMap[displayName]
          },
          template: {}
        }
      ]
    };
  }, {}),
  [ViewModel.DisplayName.CY_LIST]: createTemplateListFromOptionList(ViewModel.DisplayName.CY_LIST, 'type'),
  [ViewModel.DisplayName.CY_CHART]: createTemplateListFromOptionList(ViewModel.DisplayName.CY_CHART, 'type'),
  [ViewModel.DisplayName.CY_FORM]: createTemplateListFromOptionList(ViewModel.DisplayName.CY_FORM, 'type'),
  [ViewModel.DisplayName.CY_LAYOUT]: (() => {
    const componentConfig = getComponentConfig(ViewModel.DisplayName.CY_LAYOUT);
    return [
      /** layouts */
      {
        id: 'Dynamic',
        group: componentConfig.category,
        componentConfig,
        item: {
          title: TRANS.client.ComponentAdder.dynamicLayout.title,
          description: '',
          image: componentConfig.icon,
          color: componentConfig.color,
          longDescription: TRANS.client.ComponentAdder.dynamicLayout.longDesc
        },
        template: {}
      },
      /** layout blocks */
      ...createTemplateListFromOptionList(ViewModel.DisplayName.CY_LAYOUT, 'type').map(config => {
        const group = [ViewModel.LayoutType.TAB, ViewModel.LayoutType.VERTICAL_TAB].includes(config.id as any)
          ? componentConfig.category
          : TRANS.client.viewCompoCategories.layoutBlocks;
        return { ...config, group, item: { ...config.item } };
      })
    ];
  })()
};

export const componentTemplateById: Record<string, ComponentTemplate> = Object.values(componentTemplates).reduce(
  (total, curr) => ({ ...total, ...curr.reduce((t, c) => ({ ...t, [c.id]: c }), {}) }),
  {}
);
