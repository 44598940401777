/* istanbul ignore file */

import { useCallback } from 'react';
import { EMPTY, filter, map, mergeMap, Observable, take, takeUntil } from 'rxjs';

import { ApiModel, CollectionModel, GeneralModel, isObject, ofType, ofTypeSetData, useUnmountObservable, ViewModel } from '@cyferd/client-engine';

import { useAction } from './useAction';

export const useFetchCollectionModel = (): ((collectionId: string) => Observable<CollectionModel.Collection>) => {
  const onDescribe = useAction('coreDescribe');

  return useCallback(
    (collectionId: string) =>
      onDescribe({ query: { cursor: { collectionId } } }).pipe(
        filter((action: any) => action?.type === ApiModel.TriggerActionType.DISPATCH_SET_DATA && isObject(action.payload?.value?.query)),
        take(1),
        map((action: any) => action.payload.value.query as CollectionModel.Collection)
      ),
    [onDescribe]
  );
};

export const useOnFetchEntity = () => {
  const onDescribe = useAction('coreDescribe');

  return useCallback((collectionId: string) => onDescribe({ query: { cursor: { collectionId } }, pointer: GeneralModel.IGNORED_POINTER_ID }), [onDescribe]);
};

export const useListAssociation = (apiQuery: ApiModel.ApiValue['query'], schema: GeneralModel.JSONSchema, excluded: boolean = false) => {
  const onDestroy$ = useUnmountObservable();
  const onList = useAction('coreList');

  return useCallback(
    (fetchCriteria: GeneralModel.FetchCriteria) =>
      apiQuery?.cursor?.collectionId && apiQuery.cursor.id
        ? onList({
            pointer: GeneralModel.IGNORED_POINTER_ID,
            query: {
              cursor: {
                associationKey: schema?.metadata?.association?.cursor?.associationKey,
                relatedTo: { collectionId: apiQuery.cursor.collectionId, id: apiQuery.cursor.id },
                excluded,
                options: fetchCriteria?.options,
                searchString: fetchCriteria?.searchString,
                filter: fetchCriteria?.filter,
                fixedFilter: fetchCriteria?.fixedFilter
              }
            }
          }).pipe(takeUntil<any>(onDestroy$), ofTypeSetData())
        : EMPTY,
    [apiQuery?.cursor?.collectionId, apiQuery?.cursor?.id, excluded, onList, onDestroy$, schema?.metadata?.association?.cursor?.associationKey]
  );
};

export const useOnGetEntity = (pointer: string = GeneralModel.IGNORED_POINTER_ID, section?: string) => {
  const onGet = useAction('coreGet');

  return useCallback((collectionId: string, id?: string) => onGet({ query: { cursor: { collectionId, id } }, pointer, section }), [onGet, pointer, section]);
};

export const useOnCreateAssociatedRecord = () => {
  const onUpsert = useAction('coreUpsert');

  return useCallback((formValue: ViewModel.CyFormProps['value']) => onUpsert({ ...formValue, pointer: GeneralModel.IGNORED_POINTER_ID }), [onUpsert]);
};

export const useOnDownloadFile = () => {
  const onFileGetUrl = useAction('fileGetUrl');
  const dispatchFileDownload = useAction('dispatchDownloadFile');

  return useCallback(
    (payload: ApiModel.FlowPayloadModel.FILE_GET_URL) =>
      onFileGetUrl({ ...payload }).pipe(
        ofType(ApiModel.TriggerActionType.DISPATCH_RESULT),
        take(1),
        mergeMap(value => dispatchFileDownload({ data: value.url, filename: value.name }))
      ),
    [onFileGetUrl, dispatchFileDownload]
  );
};
