/* istanbul ignore file | @todo */

import { useContext, useState } from 'react';

import { ApiModel, ClientEngineContext, noop, TenantConfigModel } from '@cyferd/client-engine';

import { BUILDER_DESCRIPTION, BUILDER_ICON, SECTION_NAME } from '@constants';
import { EditorHome, useEditorHomeEffect, useEditorHomeOnSave } from '../shared/EditorHome';
import { GeneralInfo } from './components/GeneralInfo';

export const PlatformSettings = () => {
  const { useUserSelector } = useContext(ClientEngineContext);
  const user = useUserSelector();
  const [original, setOriginal] = useState<TenantConfigModel.TenantConfig>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const onSave = useEditorHomeOnSave(ApiModel.ApiEntity.TENANTCONFIG);

  useEditorHomeEffect({ itemId: user?.id, collectionId: ApiModel.ApiEntity.TENANTCONFIG, setOriginal, setLoading });

  return (
    <EditorHome
      isLoading={isLoading}
      editorTitle={SECTION_NAME.PLATFORM_SETTINGS}
      title={SECTION_NAME.PLATFORM_SETTINGS}
      icon={BUILDER_ICON.PLATFORM_SETTINGS}
      item={original}
      subtitle={BUILDER_DESCRIPTION.PLATFORM_SETTINGS}
      /** header */
      routeList={[]}
      hideSubHeader={false}
      rootPath=""
      isCurrentDraft={false}
      onSave={onSave}
      onRemove={noop}
      onSetOriginalValue={setOriginal}
    >
      <GeneralInfo />
    </EditorHome>
  );
};
