// istanbul ignore file
import { DROPDOWN_PORTAL_ID } from '@components/elements/DropDownOptionEditTable';
import { useMemo, useCallback, useEffect, useState } from 'react';

const isBetween = (num: number, a: number, b: number) => Math.min(a, b) <= num && num <= Math.max(a, b);

// This hook should only take care of cell navigation and state. No changing values inside here.
export const useNavigableGrid = ({ bounds: { rows, cols }, containerRef }) => {
  const [selected, setSelected] = useState<{ row: number; col: number } | null>({ row: 0, col: 0 });
  const [cursor, setCursor] = useState<{ row: number; col: number } | null>(null);
  const [active, setActive] = useState(false);

  const isSelected = useCallback((row: number, col: number) => selected?.row === row && selected?.col === col, [selected]);
  const isCursor = useCallback((row: number, col: number) => cursor?.row === row && cursor?.col === col, [cursor]);
  const isInSelection = useCallback(
    // @todo: check absolute bounds
    (row: number, col: number) => isBetween(row, selected?.row, cursor?.row) && isBetween(col, selected?.col, cursor?.col),
    [selected, cursor]
  );
  const isEditing = useCallback((row: number, col: number) => selected?.row === row && selected?.col === col && active, [selected, active]);

  const { maxRowIndex, maxColIndex } = useMemo(
    () => ({
      maxRowIndex: rows ? rows - 1 : 0,
      maxColIndex: cols ? cols - 1 : 0
    }),
    [rows, cols]
  );

  const isValidPosition = useCallback((row, col) => !(row < 0 || row > maxRowIndex || col < 0 || col > maxColIndex), [maxRowIndex, maxColIndex]);

  const selectCell = useCallback(
    (row: number, col: number) => {
      if (isValidPosition(row, col)) setSelected({ row, col });
    },
    [isValidPosition]
  );

  const selectCursorCell = useCallback(
    (row: number, col: number) => {
      if (!isValidPosition(row, col)) return;
      if (row === selected?.row && col === selected?.col) return setCursor(null);
      setCursor({ row, col });
    },
    [isValidPosition, selected]
  );

  const setCellSelected = useCallback(
    (row: number, col: number) => {
      setActive(false);
      setCursor(null);
      selectCell(row, col);
    },
    [selectCell]
  );

  const setCellEditing = useCallback(
    (row: number, col: number, value = true) => {
      selectCell(row, col);
      setActive(value);
    },
    [selectCell]
  );

  const setCellCursor = useCallback(
    (rowCol: { row: number; col: number }) => {
      selectCursorCell(rowCol.row, rowCol.col);
    },
    [selectCursorCell]
  );

  const dismissSelection = useCallback(() => {
    setActive(false);
    setSelected(null);
    setCursor(null);
  }, []);

  // Keyboard navigation
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowRight':
          event.preventDefault();
          if (!event.shiftKey) {
            setCursor(null);
            selectCell(selected?.row, selected?.col + 1);
          } else {
            selectCursorCell(cursor?.row ?? selected?.row, (cursor?.col ?? selected?.col) + 1);
          }
          break;
        case 'ArrowLeft':
          event.preventDefault();
          if (!event.shiftKey) {
            setCursor(null);
            selectCell(selected?.row, selected?.col - 1);
          } else {
            selectCursorCell(cursor?.row ?? selected?.row, (cursor?.col ?? selected?.col) - 1);
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (!event.shiftKey) {
            setCursor(null);
            selectCell(selected?.row + 1, selected?.col);
          } else {
            selectCursorCell((cursor?.row ?? selected?.row) + 1, cursor?.col ?? selected?.col);
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (!event.shiftKey) {
            setCursor(null);
            selectCell(selected?.row - 1, selected?.col);
          } else {
            selectCursorCell((cursor?.row ?? selected?.row) - 1, cursor?.col ?? selected?.col);
          }
          break;
        case 'Tab':
          event.preventDefault();
          setCursor(null);
          if (event.shiftKey) selectCell(selected?.row, selected?.col - 1);
          else selectCell(selected?.row, selected?.col + 1);
          break;
        default:
          break;
      }
    };
    if (selected && !active) window.addEventListener('keydown', handleKeyDown);
    if (!selected || active) window.removeEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selected, active, selectCell, selectCursorCell, setCursor, cursor]);

  // Handle keyboard events while active
  useEffect(() => {
    const handleKeysWhileActive = (event: KeyboardEvent) => {
      if (event.key === 'Escape') setActive(false);
      if (event.key === 'Tab') event.preventDefault();
    };
    if (active) window.addEventListener('keydown', handleKeysWhileActive);
    if (!active) window.removeEventListener('keydown', handleKeysWhileActive);
    return () => window.removeEventListener('keydown', handleKeysWhileActive);
  }, [active]);

  useEffect(() => {
    const handleClickOutside = event => {
      const portalElement = document.getElementById(DROPDOWN_PORTAL_ID);
      if (containerRef.current && !containerRef.current.contains(event.target) && portalElement && !portalElement.contains(event.target)) {
        dismissSelection();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [containerRef, dismissSelection]);

  // Native focus on selected cell
  useEffect(() => {
    if (!selected) return;
    const focusableElements = containerRef.current.querySelector(`[data-colindex="${selected.col}"][data-rowindex="${selected.row}"]`);
    if (focusableElements && !active) focusableElements.focus();
  }, [containerRef, selected, active]);

  return {
    selected,
    isSelected,
    isCursor,
    isInSelection,
    isEditing,
    setCellSelected,
    setCellEditing,
    setCellCursor
  };
};
