import { GeneralModel } from '@cyferd/client-engine';
import { COLOR, SECONDARY_COLOR } from '../../../@constants';
import { styles } from './styles';

export enum DiagramElementType {
  EDGE = 'edge',
  NODE = 'node'
}

export interface DiagramElementProps {
  label: string;
  color?: GeneralModel.Color.ThemeColor;
  type: DiagramElementType;
  testid?: string;
}

export interface LinkingPageDiagramProps {
  value?: Array<DiagramElementProps | DiagramElementProps[]>;
  testid?: string;
}

export const DiagramElement = ({ type, label, color = 'BRAND_1' as GeneralModel.Color.ThemeColor, testid }: DiagramElementProps) => {
  if (type === 'edge')
    return (
      <div css={styles.edgeContainer} data-testid={testid}>
        <div css={styles.edgeRect} style={{ backgroundColor: COLOR[color] }} />
        <div css={styles.edgeLabel} style={{ color: COLOR.BASE_FOREGROUND }}>
          {label}
        </div>
        <div css={styles.edgeRect} style={{ backgroundColor: COLOR[color] }} />
      </div>
    );
  return (
    <div
      css={styles.nodeContainer}
      style={{ borderColor: COLOR[color], backgroundColor: SECONDARY_COLOR[color], color: COLOR.BASE_FOREGROUND }}
      data-testid={testid}
    >
      {label}
    </div>
  );
};

export const LinkingPageDiagram = ({ value, testid = 'diagram' }: LinkingPageDiagramProps) => {
  return (
    <div data-testid={testid} css={styles.container}>
      {value.map((node, idx) => {
        if (Array.isArray(node))
          return (
            <div key={idx} css={styles.subNodesContainer}>
              {node.map((subNode, innerIdx) => (
                <DiagramElement
                  key={innerIdx}
                  label={subNode.label}
                  color={subNode.color}
                  type={subNode.type}
                  testid={`${testid}-element-${idx}-${innerIdx}`}
                />
              ))}
            </div>
          );
        return <DiagramElement key={idx} label={node.label} color={node.color} type={node.type} testid={`${testid}-element-${idx}`} />;
      })}
    </div>
  );
};
