import { BaseFieldProps, GeneralModel, SchemaFormBaseProps } from '@cyferd/client-engine';
import { useCallback } from 'react';
import { getLabel } from '@utils';
import { FormulaInputRow, shouldAllowFormula } from '@components/elements/Evaluator/resources';
import { MenuOption } from '@components/elements/OptionMenu';
import { Input, InputTypes } from '@components/elements/Input';
import { EvaluatorWrapper } from '@components/elements/Evaluator/EvaluatorWrapper';

export const useRenderNumber = ({
  getIds,
  allowFormula,
  inputList,
  getOptionMenu,
  getErrorMessage
}: {
  getIds: Function;
  allowFormula: boolean;
  inputList: FormulaInputRow[];
  getOptionMenu: (event: BaseFieldProps) => MenuOption[];
  getErrorMessage: (hasError: boolean, error: string) => string;
}): SchemaFormBaseProps['componentRecord']['renderNumber'] =>
  useCallback(
    props => {
      const {
        id,
        value,
        displayNamePath,
        onChange,
        maximum,
        minimum,
        description,
        color,
        disabled,
        disabledType,
        required,
        hasError,
        error,
        format,
        schema,
        path
      } = props;
      const actualInputList = [...(schema.metadata?.inputList || []), ...(inputList || [])];
      return (
        <EvaluatorWrapper
          label={getLabel(displayNamePath)}
          allowFormula={shouldAllowFormula(allowFormula, schema.metadata?.allowFormula)}
          value={value}
          disabled={disabled}
          description={description}
          required={required}
          onChange={onChange}
          inputList={actualInputList}
          format={format}
          openModalOnFocus={schema?.metadata?.openModalOnFocus}
          info={schema?.info}
          color={color}
          disabledType={disabledType || schema?.metadata?.disabledType}
          optionList={getOptionMenu(props)}
        >
          {fixedProps => (
            <Input
              {...getIds(id)}
              type={
                ([...GeneralModel.typeToFormatMap.number, ...GeneralModel.typeToFormatMap.integer].includes(format as GeneralModel.JSONSchemaFormat)
                  ? format
                  : GeneralModel.JSONSchemaFormat.NUMBER) as InputTypes
              }
              name={getLabel(displayNamePath)}
              label={getLabel(displayNamePath)}
              value={fixedProps.value}
              description={description}
              disabled={disabled}
              max={maximum}
              color={color}
              min={minimum}
              onChange={fixedProps.onChange}
              required={required}
              errorMessage={getErrorMessage(hasError, error)}
              optionList={[...fixedProps.evaluatorOptionList, ...getOptionMenu(props)]}
              suggestionList={schema.metadata?.suggestionList}
              metadata={schema.metadata}
              info={schema.info}
              disabledType={disabledType || schema.metadata?.disabledType}
              showClear={true}
              path={path}
            />
          )}
        </EvaluatorWrapper>
      );
    },
    [allowFormula, getErrorMessage, getIds, getOptionMenu, inputList]
  );
