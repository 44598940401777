import { ApiModel } from '@cyferd/client-engine';
import { GeneralModel } from '@models';

export const getInitialState = () => ({});

export const reducer = (state: GeneralModel.ContextState = getInitialState(), action: GeneralModel.Action) => {
  const { type, payload } = action;
  switch (type) {
    case ApiModel.TriggerActionType.DISPATCH_MESSAGE:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
